// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2ys2Wg86Ly8yPfk_yPh18u{position:absolute;top:0;left:0;width:100%;display:flex;justify-content:center;align-items:center;flex-direction:column;height:100%;background-color:rgba(255,255,255,0.8);z-index:999;box-sizing:border-box;padding:0 5rem}._2ys2Wg86Ly8yPfk_yPh18u .KrXxyDCwm6uOFDuyZVdzn{font-size:2.8rem;margin:3rem 0;color:#263238;font-weight:500;font-family:Montserrat, sans-serif;text-align:center;line-height:1.5}._2ys2Wg86Ly8yPfk_yPh18u ._1_lv0cu-31f3dfiUxXK40Y{width:100%;display:flex;justify-content:center;align-items:center;margin-bottom:5rem;text-align:center;font-size:1.4rem;color:#263238}\n", ""]);
// Exports
exports.locals = {
	"host": "_2ys2Wg86Ly8yPfk_yPh18u",
	"header": "KrXxyDCwm6uOFDuyZVdzn",
	"info": "_1_lv0cu-31f3dfiUxXK40Y"
};
module.exports = exports;
