import React from 'react';
import {connect} from 'react-redux';
import {of, Subscription} from 'rxjs';
import {catchError, delay, tap} from 'rxjs/operators';
import {Link} from 'react-router-dom';
import {IFormConfig, UserRole} from '../../..';
import {sendResetPasswordEmailAPI} from '../../../api/sendResetPasswordEmail';
import {addAlert} from '../../../store/reducers/alertSlice';
import {AlertType} from '../../../types';
import Form from '../../Form';
import Loader from '../../Loader';
import Translation from '../../Translation';

interface IConnectedResetPasswordProps {
    addAlert: typeof addAlert;
}

interface IExternalResetPasswordProps {
    userRole: UserRole;
    envData: any;
    formConfig: IFormConfig;
}

interface IResetPasswordProps extends IConnectedResetPasswordProps, IExternalResetPasswordProps {}

interface IResetPasswordState {
    formConfig: IFormConfig;
    value: any;
    isLoading: boolean;
}

class ResetPassword extends React.Component<IResetPasswordProps, IResetPasswordState> {
    private subscriptions: Subscription[] = [];

    constructor(props: IResetPasswordProps) {
        super(props);

        this.state = {
            formConfig: this.props.formConfig,
            value: null,
            isLoading: false,
        };
    }

    componentWillUnmount(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        const imgBack =
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACmSURBVHgB7ZOxEYMwDEUlm/Rhg4yQFRglRe5SsgdNytylyCgZIRmBDUgfsEDcQQOGb9zyKhX/nk+WRLQTC4eE82t1Tgy/2MmleKbfuUwCunqZZX6LENWWfr6cAVyjTOtGJLs/0tKXXW15IvO0CglDZYvC/FadrONPiEzx/2FDx+HJA+FgLXcp4yQrYlreKoUWO0QKXwoqhRZb0SnrtLuy/C9cyk48LYRZU+4k8PvmAAAAAElFTkSuQmCC';
        return (
            <article className="col-xl-10">
                <header className="auth-form-header">
                    <h1 className="auth-title">
                        <Translation text="auth.resetPassword.title" />
                    </h1>
                    <div className="auth-subtitle-container">
                        <p className="auth-subtitle reset-subtitle">
                            <Translation text="auth.resetPassword.subtitle" />
                        </p>
                    </div>
                </header>
                <Loader showLoader={this.state.isLoading} />
                <Form config={this.state.formConfig} submitForm={this.resetPassword} controlName={'resetPasswordForm'} value={{}} />
                <p className="auth-subtitle redirect-link-container">
                    <Link className="auth-redirect-link" to="/auth/login">
                        <img src={imgBack} alt="Arrow back" />
                        <Translation text="auth.register.backToLogin" />
                    </Link>
                </p>
            </article>
        );
    }
    private resetPassword = (event: any, value: any, valid: boolean, touched: boolean) => {
        event.preventDefault();
        if (!valid || !touched) {
            return;
        }

        const baseUrl =
                this.props.userRole === UserRole.ORGANIZATION
                    ? `${this.props.envData.REACT_APP_ORGANIZATION_URL}`
                    : `${this.props.envData.REACT_APP_CANDIDATE_URL}`,
            resetPasswordPayload = {
                username: value.email,
                returnUrl: `${baseUrl}/auth/new-password`,
            };

        this.setState({isLoading: true});
        this.subscriptions.push(
            sendResetPasswordEmailAPI(resetPasswordPayload)
                .pipe(
                    catchError((error: any) => {
                        let message = '';
                        const response = error.response;

                        if (response['hydra:description']) {
                            message = response['hydra:description'];
                        } else if (response.message) {
                            if (response.message === 'JWT Token not found') {
                                message = 'auth.alert.authenticationError';
                            } else {
                                message = response.message;
                            }
                        }
                        this.props.addAlert({message: message, type: AlertType.WARNING});
                        this.setState({isLoading: false});

                        return of();
                    }),
                    tap(() => this.props.addAlert({message: 'auth.alert.sendResetPasswordMailSuccess'})),
                    delay(1500),
                    tap(() => {
                        window.location.href = window.location.origin + '/';
                        this.setState({isLoading: false});
                    })
                )
                .subscribe()
        );
    };
}

export default connect(() => ({}), {
    addAlert,
})(ResetPassword);
