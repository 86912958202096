import React, {Component} from 'react';
import LayoutWrapper from '../LayoutWrapper';
import SubscriptionPlan from './SubscriptionPlan';
import styles from './styles.module.scss';
import {Translation} from 'jobhunter-common-web';
import {mockServiceVariants} from './serviceVariants';
import {mockRelocationServices} from './relocationServices';

class Pricing extends Component {
    render() {
        return (
            <LayoutWrapper>
                <div className="page-layout">
                    <div className="container plans">
                        <div className="row">
                            <div className="page-container plans-container">
                                <div className="header">
                                    <p className="header-title mb-4">
                                        <Translation text="plans.relocationServicePackage.title" />
                                    </p>
                                </div>
                                <div className={styles.plansContainer}>
                                    {mockRelocationServices.map((plan: any) => {
                                        return <SubscriptionPlan subscriptionPlan={plan} key={plan.id} />;
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="page-container plans-container">
                                <div className="header">
                                    <p className="header-title mb-4">
                                        <Translation text="plans.subscriptionVariants.title" />
                                    </p>
                                </div>
                                <div className={styles.plansContainer}>
                                    {mockServiceVariants.map((plan: any) => {
                                        return <SubscriptionPlan subscriptionPlan={plan} key={plan.id} />;
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutWrapper>
        );
    }
}

export default Pricing;
