import {
    collectionInputDataMapper,
    collectionOutputDataMapper,
    formatRangePickerLabel,
    FormControlType, IRangePickerOption,
    MultiSelectType,
    multiCollectionInputDataMapper,
    multiCollectionOutputDataMapper,
    ValidationRules,
} from "../../../../index";
import {IFormConfig, IMultiselectOption} from "../../../../types";
import {FormButtonType} from "../../FormButton";

export const addTechnologyToolFormConfig = (
    seniorityLevels: {[key: number]: IRangePickerOption},
    technologyTools: IMultiselectOption[] | [],
    technologyList: IMultiselectOption[] | [],
): IFormConfig => ({
    controlType: 'form',
    class: 'default-form',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'technology',
            controlType: 'group',
            class: 'row',
            controls: {
                technology: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    multiselectOptions: technologyList,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    placeholder: 'offers.editOffer.form.placeholders.technology',
                    label: 'offers.editOffer.form.labels.technology',
                    hostClass: 'col-xl-6 form-control',
                    inputDataMapper: collectionInputDataMapper,
                    outputDataMapper: collectionOutputDataMapper,
                },
                technologyTool: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.DEFAULT,
                    multiselectOptions: technologyTools,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    placeholder: 'offers.editOffer.form.placeholders.technologyTool',
                    label: 'offers.editOffer.form.labels.technologyTool',
                    hostClass: 'col-xl-6 form-control',
                    inputDataMapper: multiCollectionInputDataMapper,
                    outputDataMapper: multiCollectionOutputDataMapper,
                },
            },
        },
        {
            key: 'level',
            controlType: 'group',
            class: 'row',
            controls: {
                technologyLevel: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.RANGE_PICKER,
                    validationRules: [],
                    rangeLabels: seniorityLevels,
                    label: 'offers.editOffer.form.labels.seniority',
                    labelFormat: (value: number) => formatRangePickerLabel(value, seniorityLevels),
                    maxRangeStep: Object.keys(seniorityLevels).length * 10,
                    hostClass: 'col-xl-12 form-control',
                    isRangeLabelTranslated: true,
                },
            }
        },
        {
            controlType: 'group',
            key: 'submit',
            class: 'row btn-row',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    inputStyles: 'btn btn-primary',
                    defaultContainerStyles: '',
                    hostClass: 'btn-container align-items-end',
                    containerStyles: '',
                    defaultValue: null,
                    isLabelHidden: true,
                    btnText: 'buttons.add',
                    buttonDisabled: (mappedOutputValue: any, isFormValid: boolean) => {
                        return !isFormValid;
                    },
                },
            },
        },
    ],
});
