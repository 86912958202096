import React from 'react';
import './App.scss';
import {connect} from 'react-redux';
import {RootState} from './store/reducers';
import {isAuthenticatedSelector} from 'jobhunter-common-web';
import PanelHost from './components/PanelHost';
import {Route, Routes} from 'react-router-dom';
import VideoHost from './components/VideoHost';

interface IConnectedAppProps {
    isAuthenticated: boolean;
}

interface IAppProps extends IConnectedAppProps {}

interface IAppState {
    isAuthenticated: boolean;
}

class App extends React.Component<IAppProps, IAppState> {
    constructor(props: IAppProps) {
        super(props);
        this.state = {
            isAuthenticated: this.props.isAuthenticated,
        };
    }

    componentDidUpdate(prevProps: Readonly<IAppProps>): void {
        if (this.props.isAuthenticated !== prevProps.isAuthenticated) {
            this.setState({isAuthenticated: this.props.isAuthenticated});
        }
    }

    render() {
        return (
            <div className="App">
                <Routes>
                    <Route path="/*" element={<PanelHost envData={process.env} />} key="panel" />
                    <Route path="video" element={<VideoHost />} key="consultation" />
                </Routes>
            </div>
        );
    }
}

export default connect(
    (state: RootState) => ({
        isAuthenticated: isAuthenticatedSelector(state),
    }),
    {}
)(App);
