import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelDictionaryDatum} from '../../model/dictionaryDatum';
import {IBaseDictionaryState, IChangeIsInitialized, IChangeIsLoading, ISetError} from './workTypeSlice';

export interface ICountryState extends IBaseDictionaryState {
    countries: IModelDictionaryDatum[] | null;
}

export interface IChangeCountry {
    countries: IModelDictionaryDatum[] | null;
}

const initialState: ICountryState = {
    countries: null,
    isLoading: false,
    isInitialized: false,
    error: null,
};

const countrySlice = createSlice({
    name: 'countrySize',
    initialState: initialState,
    reducers: {
        changeCountry: {
            reducer: (state: ICountryState, action: PayloadAction<IChangeCountry>) => {
                return {
                    countries: action.payload.countries,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(countries: IModelDictionaryDatum[] | null) {
                return {
                    payload: {countries: countries},
                };
            },
        },
        changeIsCountryLoading: {
            reducer: (state: ICountryState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    countries: state.countries,
                    isLoading: action.payload.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        changeIsCountryInitialized: {
            reducer: (state: ICountryState, action: PayloadAction<IChangeIsInitialized>) => {
                return {
                    countries: state.countries,
                    isLoading: state.isLoading,
                    isInitialized: action.payload.isInitialized,
                    error: state.error,
                };
            },
            prepare(isInitialized: boolean) {
                return {
                    payload: {isInitialized: isInitialized},
                };
            },
        },
        setCountryError: {
            reducer: (state: ICountryState, action: PayloadAction<ISetError>) => {
                return {
                    countries: state.countries,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
        fetchCountries: (state: ICountryState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
    },
});

export const {changeCountry, changeIsCountryLoading, changeIsCountryInitialized, setCountryError, fetchCountries} = countrySlice.actions;

export default countrySlice.reducer;
