import {createSelector} from '@reduxjs/toolkit';
import {IContractTypeState} from '../reducers/contractTypeSlice';

export const selectContractType = (state: any): IContractTypeState => {
    return state.contractType;
};

export const contractTypesSelector = createSelector([selectContractType], (state: IContractTypeState) => state.contractTypes);

export const contractTypesLoadingSelector = createSelector([selectContractType], (state: IContractTypeState) => state.isLoading);

export const contractTypesInitializedSelector = createSelector([selectContractType], (state: IContractTypeState) => state.isInitialized);

export const contractTypesErrorSelector = createSelector([selectContractType], (state: IContractTypeState) => state.error);
