import {Observable} from 'rxjs';
import {jobHunterAPI} from './provider/jobHunterAPI';
import {RestQueryParams} from './base/queryParams';

export function deleteRelocationFileAPI(authToken: string | null, id: string): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return jobHunterAPI.delete(`api/relocation_files/${id}`, new RestQueryParams(), headers);
}
