import moment, {Moment} from 'moment';

export function setTimeOfDay(date: Moment, timeOfDay: string): Moment {
    if (timeOfDay === 'start') {
        return date.startOf('day');
    }
    return date.endOf('day');
}

export function getUTCMomentFromString(date: string): Moment {
    return moment.utc(date);
}

export function formatServerDateToIsoString(date: string): string {
    return getUTCMomentFromString(date).toISOString();
}

export function formatServerDateToString(date: string): string {
    return getUTCMomentFromString(date).format('YYYY-MM-DD');
}

export function formatMomentDateToString(date: Moment): string {
    return date.format('YYYY-MM-DD');
}

export function getDateFromString(stringDate: string | Date): Date {
    return moment(stringDate, 'YYYY-MM-DD').toDate();
}

export function formatDateToString(date: Date | undefined): string | null {
    if (!date) {
        return null;
    }

    return date
        .toLocaleDateString('pl-PL', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
        })
        .replace(/ /g, '.');
}

function formatMomentDateToIsoString(date: Moment): string {
    return date.toISOString();
}

export function parseToStartOfDay(date: string): string {
    const parsedDate = getUTCMomentFromString(date),
        setTimeOfDayDate = setTimeOfDay(parsedDate, 'start');

    return formatMomentDateToIsoString(setTimeOfDayDate);
}

export function parseToEndOfDay(date: string): string {
    const parsedDate = getUTCMomentFromString(date),
        setTimeOfDayDate = setTimeOfDay(parsedDate, 'end');

    return formatMomentDateToIsoString(setTimeOfDayDate);
}

export function getDefaultTimezone(): string {
    let timezoneOffsetMin = new Date().getTimezoneOffset(),
        offsetHrs = parseInt(String(Math.abs(timezoneOffsetMin / 60))),
        offsetMin = Math.abs(timezoneOffsetMin % 60),
        defaultTimezone,
        newOffsetHours = '',
        newOffsetMinutes = '';

    if (offsetHrs < 10) {
        newOffsetHours = '0' + offsetHrs;
    } else {
        newOffsetHours = offsetHrs.toString();
    }

    if (offsetMin < 10) {
        newOffsetMinutes = '0' + offsetMin;
    } else {
        newOffsetMinutes = offsetMin.toString();
    }

    if (timezoneOffsetMin < 0) defaultTimezone = '+' + newOffsetHours + ':' + newOffsetMinutes;
    else if (timezoneOffsetMin > 0) defaultTimezone = '-' + newOffsetHours + ':' + newOffsetMinutes;
    else if (timezoneOffsetMin == 0) defaultTimezone = '+00:00';

    return defaultTimezone;
}

export function getDefaultTimezoneFromOffset(offset: number): string {
    let timezoneString = '',
        newOffsetHours = `${'0' + Math.abs(offset)}`,
        newOffsetMinutes = '00';
    if (offset < 0) timezoneString = '-' + newOffsetHours + ':' + newOffsetMinutes;
    else if (offset > 0) timezoneString = '+' + newOffsetHours + ':' + newOffsetMinutes;
    else if (offset == 0) timezoneString = '+00:00';

    return timezoneString;
}
