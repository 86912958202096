import {API_URL} from '../config/entrypoint';
import {ajax} from 'rxjs/ajax';
import {of} from 'rxjs';
import {tap, catchError} from 'rxjs/operators';
import {saveAs} from 'file-saver';
import {addAlert} from '../store/reducers/alertSlice';
import {AlertType} from '../types';

export function downloadVerificationFileAPI(authToken: string, verificationFileId: string) {
    return ajax({
        url: `${API_URL}/api/organization_verification_files/${verificationFileId}/stream`,
        method: 'GET',
        responseType: 'blob',
        headers: {
            Authorization: 'Bearer ' + authToken,
            Accept: 'application/ld+json',
            'Content-type': 'application/pdf',
        },
    }).pipe(
        tap((response: any) => {
            let filename = null;
            const disposition = response?.responseHeaders?.['content-disposition'];
            if (disposition?.includes('attachment')) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(disposition);
                if (matches?.[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                    filename = decodeURIComponent(filename);
                    filename = filename.replace(/^UTF-8/i, '').trim();
                }
            }
            saveAs(response.response, filename);
        }),
        catchError((error) => {
            let errorMessage = null;
            if (error && error.response && error.response.message) {
                errorMessage = error.response.message;
            }
            return of(
                addAlert({
                    message: errorMessage ? errorMessage : 'alerts.baseError',
                    type: AlertType.WARNING,
                })
            );
        })
    );
}
