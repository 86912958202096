import React, {Component} from 'react';
import Translation from "../../../Translation";
import Form from "../../../Form";
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import {FormControlChangeType, IAddTechnologySkillProps, IAddTechnologySkillState} from "../../../../types";
import {BehaviorSubject, Subscription} from 'rxjs';
import {debounceTime, filter, tap} from 'rxjs/operators';
import {convertToMultiselectLabels} from "../../../../utils/formUtils";
import {addTechnologyToolFormConfig} from "./addTechnologyToolFormConfig";
import {withTranslation} from 'react-i18next';


type Props = IAddTechnologySkillProps;
type State = IAddTechnologySkillState;


class AddTechnologySkill extends Component<Props, State> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: Props) {
        super(props);

        this.state = {
            formValue: null,
            formConfig: null,
        };
    }

    componentDidMount() {
        this.setFormConfig();
        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    debounceTime(500),
                    tap((data: any) => this.setState({formValue: data.value}))
                )
                .subscribe()
        );
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (this.props.technologyTools !== prevProps.technologyTools
            || this.props.seniorityLevels !== prevProps.seniorityLevels
            || this.props.value !== prevProps.value) {
            this.setFormConfig();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.isModalVisible} toggle={() => this.props.toggleModal()}>
                    <ModalHeader className="custom-header" toggle={() => this.props.toggleModal()} />
                    <ModalBody className="service-payment-modal">
                        <p className="modal-title">
                            <Translation text={'offers.editOffer.addSkills'} />
                        </p>

                        <Form
                            config={this.state.formConfig}
                            onValueStateChange={this.onValueStateChange}
                            value={this.state.formValue}
                            controlName={'addTechnologySkillForm'}
                            submitForm={this.saveSelectedValue}
                        />
                    </ModalBody>
                </Modal>
            </div>
        );
    }

    private saveSelectedValue = () => {
        this.props.onAddValue(this.state.formValue);
    }

    private onValueStateChange = (controlName: string, value: any, changeType: FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private setFormConfig = () => {
        const {t} = this.props,
            seniorityLevels = this.props.seniorityLevels,
            technologyToolsOptions = this.props.technologyTools?.filter(tool => {
                return !this.props.value.technologyTools?.some((item) => tool.id === item.technologyToolId);
            }),
            technologyTools = technologyToolsOptions ? convertToMultiselectLabels(technologyToolsOptions, t) : [],
            technologies = this.props.technologies ? convertToMultiselectLabels(this.props.technologies, t) : [];

        this.setState({
            formConfig: addTechnologyToolFormConfig(seniorityLevels, technologyTools, technologies),
        });
    };

}


export default withTranslation()(AddTechnologySkill);
