import {flattenObj} from '../../utils/objectUtils';
import {IRawRestQueryParams, LegalRestQueryParamValue} from '../../types';

export abstract class QueryParams<T> {
    constructor(private readonly _params: T) {}

    protected get params(): T {
        return this._params;
    }

    abstract getRaw(): T;
}

export class RestQueryParams extends QueryParams<IRawRestQueryParams> {
    constructor(params: IRawRestQueryParams = []) {
        super(params);
    }

    get empty(): boolean {
        return Object.keys(this.params).length === 0;
    }

    add(key: string, value: LegalRestQueryParamValue): RestQueryParams {
        const raw: any = this.getRaw();
        raw.push({
            path: key,
            val: value,
        });

        return new RestQueryParams(raw);
    }

    getRaw(): IRawRestQueryParams {
        return Array.from(this.params);
    }

    hasKey(key: string): boolean {
        return this.params.some((param) => {
            return param.path === key;
        });
    }

    value<T extends LegalRestQueryParamValue = any>(key: string): T | null {
        const value = this.params.find((param) => param.path === key);

        return value ? (value.val as T) : null;
    }

    prepareQuery(urlEncode: boolean = true): string {
        if (null === this.params || undefined === this.params) {
            return '';
        }
        let query: string[] = this.params.map((obj) => {
            return urlEncode ? `${encodeURIComponent(obj.path)}=${obj.val ? encodeURIComponent(obj.val) : ''}` : `${obj.path}=${obj.val}`;
        });

        return query.length > 0 ? `?${query.join('&')}` : '';
    }

    static prepareNestedParamsQuery(obj: {[key: string]: any}, urlEncode: boolean = true): string {
        const queryParams = flattenObj(obj);

        return new RestQueryParams(queryParams).prepareQuery(urlEncode);
    }
}
