import {IUserMenuItem} from 'jobhunter-common-web';

export const userMenu = (action?: () => void): typeof IUserMenuItem[] => [
    {
        id: 'profile',
        link: 'panel/profile',
        icon: 'User',
        title: 'userMenu.profile',
    },
    {
        id: 'divider',
        isDivider: true,
    },
    {
        id: 'settings',
        link: 'panel/settings',
        icon: 'Settings',
        title: 'userMenu.settings',
    },
    {
        id: 'logout',
        action: action,
        icon: 'Power',
        title: 'userMenu.logout',
    },
];

export default userMenu;
