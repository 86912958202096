import React from 'react';
import 'emoji-mart/css/emoji-mart.css';
import {
    ChatContact,
    ChatMessage,
    ChatServerMessagePayload,
    FileStreamType,
    MessageContentType,
    PeerConnection
} from "../../../types";
import {Observable} from "rxjs";
import {WithTranslation, withTranslation} from "react-i18next";
import ChatHeader from "./ChatHeader";
import ChatList from "./ChatList";


interface IConnectedChatHostProps {
}

interface IExternalChatHostProps {
    getMessagesFromUser: (accountId: string, page?: number) => Observable<boolean>;
    peerConnection: PeerConnection | null;
    selectedChatRoomId: string | null;
    addMessage: (message: ChatMessage | string, room: string) => void;
    error: string;
    errorModuleHandle: (errorMessage: string) => void;
    accountId: string;
    userName: string;
    chatContact: ChatContact | undefined;
    confirmMessageRead: (messages: string[]) => void;
    showOnlineIndicator?: boolean;
    errorHandler?: (errorMessage: string) => any;
    saveMessage?: (message: ChatServerMessagePayload) => Observable<any>;
    saveFile?: (file:any) => Observable<any>;
    avatarUrl?: string;
}

interface IChatHostProps
    extends IConnectedChatHostProps, WithTranslation,
        IExternalChatHostProps {}


class Chat extends React.Component<IChatHostProps> {
    uuid = require("uuid/v4");

    render() {
        const messages = this.props?.peerConnection?.messages || [];
        return (
            <div className='chat-app-window'>
                <div className='active-chat'>
                    {this.props.selectedChatRoomId &&
                    <ChatHeader
                        chatContact={this.props.chatContact}
                        peerUserName={this.props.userName}
                        showOnlineIndicator={this.props.showOnlineIndicator}
                    />
                    }
                    <ChatList
                        avatarUrl={this.props.avatarUrl}
                        confirmMessageRead={this.props.confirmMessageRead}
                        getMessagesFromUser={this.props.getMessagesFromUser}
                        chatContact={this.props.chatContact}
                        selectedChatRoomId={this.props.selectedChatRoomId}
                        messages={messages}
                        peerConnection={this.props.peerConnection}
                        addMessage={this.addMessage}
                        addFile={this.addFile}
                        accountId={this.props.accountId}
                        error={this.props.error}
                        errorModuleHandle={this.props.errorModuleHandle}
                    />
                </div>
            </div>
        );
    }

    private addFile = ({file, done, fileName, fullFile}: FileStreamType) => {
        const selectedChat = this.props.selectedChatRoomId;
        if(done && fileName) {
            const fileMessage = new ChatMessage({
                from: this.props.accountId,
                to: this.props.chatContact.accountId,
                messageType: MessageContentType.FILE,
                messageContent: fileName,
                messageId: this.uuid(),
            })

            this.props.addMessage(Object.assign(fileMessage, {
                messageContent: fileName,
                messageMedia: fullFile,
                messageType: MessageContentType.FILE}), this.props.selectedChatRoomId)
        } else {
            this.props.addMessage(file as string, selectedChat)
        }
    }

    private addMessage = (message: string) => {
        if(!message) {
            return;
        }

        const textMessage = new ChatMessage({
            from: this.props.accountId,
            to: this.props.chatContact.accountId,
            messageType: MessageContentType.TEXT,
            messageContent: message,
            messageId: this.uuid(),
        })

        this.props.addMessage(textMessage, this.props.selectedChatRoomId)
    };
}

export default withTranslation()(Chat);
