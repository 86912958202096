import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelApiResponseViewObject, isSameValue} from 'jobhunter-common-web';
import {IModelService} from '../../model/service';

export interface IMarketplaceFilters {
    serviceTypes?: string[] | null;
    title?: string | null;
    grossPrice?: string | null;
}

export interface ISetMarketplaceServices {
    marketplaceServices: IModelService[] | null;
}

export interface ISetMarketplaceMetadata {
    marketplaceMetadata: typeof IModelApiResponseViewObject | null;
}

export interface IChangeIsMarketplaceLoading {
    isMarketplaceLoading: boolean;
}

export interface IChangeIsMarketplaceInitialized {
    isMarketplaceInitialized: boolean;
}

export interface IChangeMarketplaceError {
    error: string | null;
}

export interface IChangeMarketplaceFilters {
    filters: IMarketplaceFilters;
}

export interface IMarketplaceState {
    filters: IMarketplaceFilters | null;
    marketplaceServices: IModelService[] | null;
    marketplaceMetadata: typeof IModelApiResponseViewObject | null;
    isMarketplaceServicesLoading: boolean;
    isMarketplaceServicesInitialized: boolean;
    marketplaceServicesError: string | null;
}

const initialState: IMarketplaceState = {
    filters: null,
    marketplaceServices: null,
    marketplaceMetadata: null,
    isMarketplaceServicesLoading: false,
    isMarketplaceServicesInitialized: false,
    marketplaceServicesError: null,
};

const marketplacePageSlice = createSlice({
    name: 'marketplace',
    initialState: initialState,
    reducers: {
        setMarketplaceServicesData: {
            reducer: (state: IMarketplaceState, action: PayloadAction<ISetMarketplaceServices>) => {
                return {
                    ...state,
                    marketplaceServices: action.payload.marketplaceServices,
                };
            },
            prepare(marketplaceServices: IModelService[] | null) {
                return {
                    payload: {
                        marketplaceServices: marketplaceServices,
                    },
                };
            },
        },
        changeIsMarketplaceServicesLoading: {
            reducer: (state: IMarketplaceState, action: PayloadAction<IChangeIsMarketplaceLoading>) => {
                return {
                    ...state,
                    isMarketplaceServicesLoading: action.payload.isMarketplaceLoading,
                };
            },
            prepare(isMarketplaceLoading: boolean) {
                return {
                    payload: {isMarketplaceLoading: isMarketplaceLoading},
                };
            },
        },
        changeIsMarketplaceServicesInitialized: {
            reducer: (state: IMarketplaceState, action: PayloadAction<IChangeIsMarketplaceInitialized>) => {
                return {
                    ...state,
                    isMarketplaceServicesInitialized: action.payload.isMarketplaceInitialized,
                };
            },
            prepare(isMarketplaceInitialized: boolean) {
                return {
                    payload: {isMarketplaceInitialized: isMarketplaceInitialized},
                };
            },
        },
        changeMarketplaceServicesError: {
            reducer: (state: IMarketplaceState, action: PayloadAction<IChangeMarketplaceError>) => {
                return {
                    ...state,
                    marketplaceServicesError: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
        fetchMarketplaceServices: (state: IMarketplaceState) => {
            return {
                ...state,
                isMarketplaceServicesLoading: true,
            };
        },
        applyMarketplaceFilters: (state: IMarketplaceState) => {
            return {
                ...state,
                isMarketplaceServicesLoading: true,
            };
        },
        changeMarketplaceFilters: {
            reducer: (state: IMarketplaceState, action: PayloadAction<IChangeMarketplaceFilters>) => {
                if (isSameValue(action.payload.filters, state.filters)) {
                    return state;
                }

                return {
                    ...state,
                    filters: action.payload.filters,
                };
            },
            prepare(filters: IMarketplaceFilters) {
                return {
                    payload: {
                        filters: {
                            serviceTypes: filters?.serviceTypes,
                            title: filters?.title,
                            grossPrice: filters?.grossPrice,
                        },
                    },
                };
            },
        },
        setMarketplaceMetadata: {
            reducer: (state: IMarketplaceState, action: PayloadAction<ISetMarketplaceMetadata>) => {
                return {
                    ...state,
                    marketplaceMetadata: action.payload.marketplaceMetadata,
                };
            },
            prepare(marketplaceMetadata: typeof IModelApiResponseViewObject | null) {
                return {
                    payload: {
                        marketplaceMetadata: marketplaceMetadata,
                    },
                };
            },
        },
        resetToInitialMarketplacePageState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setMarketplaceServicesData,
    changeIsMarketplaceServicesLoading,
    changeIsMarketplaceServicesInitialized,
    changeMarketplaceServicesError,
    setMarketplaceMetadata,
    fetchMarketplaceServices,
    applyMarketplaceFilters,
    changeMarketplaceFilters,
    resetToInitialMarketplacePageState,
} = marketplacePageSlice.actions;

export default marketplacePageSlice.reducer;
