import {Observable} from 'rxjs';
import {jobHunterAPI} from './provider/jobHunterAPI';
import {RestQueryParams} from './base/queryParams';

export function deleteRelocationEventAPI(authToken: string | null, calendarEventId: string): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return jobHunterAPI.delete(`api/calendar_events/${calendarEventId}`, new RestQueryParams(), headers);
}
