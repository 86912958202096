import React from 'react';
import {isNullOrUndefined} from '../../utils/runtimeUtils';
import {isSameValue} from '../..';

interface ITabsProps {
    sourceTypes: any[];
    chosenTab: number;
    selectedTab: any;
}

interface ITabsState {
    activeTab: number;
}

class Tabs extends React.Component<ITabsProps, ITabsState> {
    constructor(props: ITabsProps) {
        super(props);
        this.state = {
            activeTab: 0,
        };
    }

    componentDidMount() {
        if (this.props.chosenTab !== null) {
            this.select(this.props.chosenTab);
        }
    }

    componentDidUpdate(prevProps: Readonly<ITabsProps>, prevState: Readonly<ITabsState>, snapshot?: any) {
        if (!isSameValue(this.props.chosenTab, prevProps.chosenTab) && this.props.chosenTab !== null) {
            this.setState({activeTab: this.props.chosenTab});
        }
    }

    render() {
        return (
            <div className="tabs">
                <ul className="nav nav-tabs" id="nav-tab" role="tablist">
                    {this.renderTabs()}
                </ul>
                <div className="tab-content-container">{this.renderContent()}</div>
            </div>
        );
    }

    private renderContent() {
        return React.Children.map(this.props.children, (item: any) => {
            if (item.props.className !== 'tabs-content') {
                return null;
            }

            let activeTabBody = null;
            item.props.children.forEach((tabBodyDiv: any, i) => {
                if (!tabBodyDiv) {
                    return;
                }
                if (i === this.state.activeTab) {
                    activeTabBody = tabBodyDiv;
                }
            });

            return activeTabBody;
        });
    }

    private renderTabs = () => {
        return React.Children.map(this.props.children, (item: any) => {
            if (item.props.className !== 'tab-headers') {
                return null;
            }
            const header: any = [];
            item.props.children.forEach((tabHeaderDiv: any, i) => {
                if (!tabHeaderDiv) {
                    return;
                }
                const disabled = tabHeaderDiv.props.disabled ? 'disabled' : '';
                const active = this.state.activeTab === i ? 'active' : '';

                header.push(
                    <li id={'header' + i} className="nav-item" onClick={() => this.select(i)} data-toggle="tab" role="tab">
                        <button
                            className={`nav-link ${active} ${disabled}`}
                            id={'header-tab-' + i}
                            data-toggle="tab"
                            role="tab"
                            aria-controls={'header-tab-' + i}
                            aria-selected="false">
                            {tabHeaderDiv}
                        </button>
                    </li>
                );
            });
            return header;
        });
    };

    private select = (activeTab: number | null) => {
        if (isNullOrUndefined(activeTab)) {
            return;
        }
        if (activeTab === this.state.activeTab) {
            return;
        }
        this.props.selectedTab(activeTab);
        this.setState({activeTab: activeTab});
    };
}

export default Tabs;
