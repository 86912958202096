import React from 'react';
import {merge} from 'lodash';
import AgoraRTC from 'agora-rtc-sdk';
import styles from './styles.module.scss';
import {connect} from 'react-redux';
import {of, Subscription} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
// import UnloadHandler from "../UnloadHandler";
import {RootState} from '../../../store/reducers';
import VideoSettings from './VideoSettings';
import {changeScreenMode} from '../../../store/reducers/videoCallDetailsSlice';
import {changeOnlineConsultationStatus} from '../../../store/reducers/onlineConsultationSlice';
import {ConsultationStatus, IAgoraVideoCallProps, IAgoraVideoCallState, UserRole} from '../../../types';
import {confirmPresenceAPI, isNotNullOrUndefined, isSameValue, setConsultationDurationAPI} from '../../..';
import {
    audioInputDeviceSelector,
    audioOutputDeviceSelector,
    videoInputDeviceSelector
} from '../../../store/selectors/videoCallDetailsSelectors';
import Counter from '../Counter';


type Props = IAgoraVideoCallProps;
type State = IAgoraVideoCallState;

class AgoraVideoCall extends React.Component<Props, State> {
    private subscriptions: Subscription[] = [];
    private participantPresenceInterval: any = null;
    private client: any = null;
    private localStream: any = null;

    constructor(props: Props) {
        super(props);

        this.state = {
            displayMode: 'pip',
            streamList: [],
            readyState: false,
            isFullScreen: false, // this.props.isFullScreenMode,
            isConsultationFinished: false,
            isMicEnabled: true,
            isCameraEnabled: true,
            windowWidth: window.innerWidth,
            localStreamId: null,
            isFullScreenMode: false,
            isSettingsModalShown: false,
        }
    }

    componentDidMount() {
        if (this.props.attendeeRole === UserRole.CANDIDATE && isNotNullOrUndefined(this.props.token) && isNotNullOrUndefined(this.props.uid)) {
            this.joinAsPatient();
        }

        window.addEventListener('resize', this.getDimensions);
    }

    componentDidUpdate(
        prevProps: Props,
        prevState: State) {
        let canvas = document.querySelector('#ag-canvas');
        if (this.state.displayMode === 'pip') {
            // let no = this.state.streamList.length;
            this.state.streamList.map((item: any, index: number) => {
                let id = item.getId();
                let dom = document.querySelector('#ag-item-' + id);
                if (!dom) {
                    dom = document.createElement('section');
                    dom.setAttribute('id', 'ag-item-' + id);
                    dom.setAttribute('class', `${styles.agItem}`);
                    if (canvas) {
                        canvas.appendChild(dom);
                    }
                    item.play('ag-item-' + id)
                }
                // if (index === 1) {
                if (item.elementID === this.state.localStreamId) {
                    dom.setAttribute('style', `z-index:9;position:absolute;top:1rem;left:1.5rem;height:15rem;width:15rem`)
                } else {
                    dom.setAttribute('style', `z-index:1;width:100%;height:100%`)
                }
                if (item && item.player) {
                    item.player.resize && item.player.resize()
                }
            })
        }

        if (this.props.videoInputDevice !== prevProps.videoInputDevice && this.props.videoInputDevice) {
            this.localStream.getVideoTrack().stop();
            this.localStream.switchDevice(
                'video',
                this.props.videoInputDevice,
                () => console.log('videoInputDevice has changed.'),
                () => console.log('videoInputDevice change failed.')
            );
        }

        if (this.props.audioInputDevice !== prevProps.audioInputDevice && this.props.audioInputDevice) {
            this.localStream.getVideoTrack().stop();
            this.localStream.switchDevice(
                'audio',
                this.props.audioInputDevice,
                () => console.log('audioInputDevice has changed.'),
                () => console.log('audioInputDevice change failed.')
            );
        }

        if ((!isSameValue(this.props.token, prevProps.token) && this.props.token) ||
            (!isSameValue(this.props.uid, prevProps.uid) && this.props.uid)) {
            if (this.props.attendeeRole === UserRole.CANDIDATE) {
                this.joinAsPatient();
            }
        }
        // if (this.props.isFullScreenMode !== prevProps.isFullScreenMode) {
        //     this.setState({isFullScreen: this.props.isFullScreenMode});
        // }
    }

    componentWillUnmount() {
        this.setParticipantPresence(false);

        if ((this.props.attendeeRole === UserRole.ORGANIZATION || this.props.attendeeRole === UserRole.HEADHUNTER) &&
            isNotNullOrUndefined(this.client)) {
            this.client.unpublish(this.localStream);
        }

        if (this.localStream && Object.keys(this.localStream).length > 0 && this.localStream.constructor === Object &&
            this.client && Object.keys(this.client).length > 0 && this.client.constructor === Object) {
            this.localStream && this.localStream.close();
            this.client && this.client.leave(() => {
                console.log('Client succeed to leave.')
            }, () => {
                console.log('Client failed to leave.')
            });
        }

        this.subscriptions.forEach(subscription => subscription.unsubscribe());

        if (this.participantPresenceInterval) clearInterval(this.participantPresenceInterval);
        window.removeEventListener('resize', this.getDimensions);
    }

    streamInit = (uid: string, attendeeMode: string, videoProfile: string, config?: any) => {
        let defaultConfig = {
            streamID: uid,
            audio: true,
            video: true
        };

        switch (attendeeMode) {
            case 'audio-only':
                defaultConfig.video = false;
                break;
            case 'audience':
                defaultConfig.video = false;
                defaultConfig.audio = false;
                break;
            default:
            case 'video':
                break;
        }

        let stream = AgoraRTC.createStream(merge(defaultConfig, config));
        stream.setVideoProfile(videoProfile);
        return stream;
    };

    subscribeStreamEvents = () => {
        let rt = this;
        rt.client.on('stream-added', (evt: any) => {
            let stream = evt.stream;
            console.log('New stream added: ' + stream.getId());
            rt.client.subscribe(stream, (err: any) => {
                console.log('Subscribe stream failed', err)
            })
        });

        rt.client.on('peer-leave', (evt: any) => {
            console.log('Peer has left: ' + evt.uid);
            rt.removeStream(evt.uid)
        });

        rt.client.on('stream-subscribed', (evt: any) => {
            let stream = evt.stream;
            console.log('Subscribe remote stream successfully: ' + stream.getId());
            this.initStreamWhenReady();
            rt.addStream(stream, true)
        });

        rt.client.on('stream-removed', (evt: any) => {
            let stream = evt.stream;
            console.log('Stream removed: ' + stream.getId());
            rt.removeStream(stream.getId())
        });
    };

    removeStream = (uid: string) => {
        this.state.streamList.map((item: any, index: number) => {
            if (item.getId() === uid) {
                item.close();
                let element = document.querySelector('#ag-item-' + uid);
                if (element && element.parentNode) {
                    element.parentNode.removeChild(element)
                }
                let tempList = [...this.state.streamList];
                tempList.splice(index, 1);
                this.setState({
                    streamList: tempList
                })
            }

        })
    };

    addStream = (stream: any, push: boolean = false) => {
        let repeatition = this.state.streamList.some(item => {
            return item.getId() === stream.getId()
        });
        if (repeatition) {
            return
        }
        if (push) {
            this.setState({
                streamList: this.state.streamList.concat([stream])
            })
        } else {
            this.setState({
                streamList: [stream].concat(this.state.streamList)
            })
        }

    };

    handleCamera = (e: any) => {
        if (this.isStreamInitialized()) {
            e.currentTarget.classList.toggle('off');
            this.localStream.isVideoOn() ?
                this.localStream.disableVideo() : this.localStream.enableVideo();
            this.setState({isCameraEnabled: !this.state.isCameraEnabled});
        }
    };

    handleMic = (e: any) => {
        if (this.isStreamInitialized()) {
            e.currentTarget.classList.toggle('off');
            this.localStream.isAudioOn() ?
                this.localStream.disableAudio() : this.localStream.enableAudio();
            this.setState({isMicEnabled: !this.state.isMicEnabled});
        }
    };

    private initAgoraVideoCall = () => {
        this.client = AgoraRTC.createClient({mode: 'live', codec: 'h264'});
        this.client.setClientRole('host', (err: any) => {
            if (!err) {
                console.log('Set client role success');
            } else {
                console.log('Set Client Role error', err);
            }
        });

        this.client.init(this.props.appId, () => {
            console.log('AgoraRTC client initialized');
            this.subscribeStreamEvents();

            if (this.props.channel) {
                this.client.join(this.props.token, this.props.channel, this.props.uid, (uid: string) => {
                    console.log('User ' + uid + ' join channel successfully');
                    this.setState({localStreamId: 'ag-item-' + uid});
                    // create local stream
                    // It is not recommended to setState in function addStream
                    this.localStream = this.streamInit(uid, this.props.attendeeMode, this.props.videoProfile);
                    this.localStream.init(() => {
                            this.addStream(this.localStream, true);
                            this.localStream.play('me');

                            this.initStreamWhenReady();

                            this.setState({readyState: true});
                        },
                        (err: any) => {
                            console.log('getUserMedia failed', err);
                            this.setState({readyState: false})
                        })
                });
            }
        });

        // Subscribe to the remote stream when it is published
        this.client.on('stream-added', (evt: any) => {
            this.client.subscribe(evt.stream, this.handleError);
        });
    };

    private joinAsPatient = () => {
        this.client = AgoraRTC.createClient({mode: 'live', codec: 'h264'});
        this.client.setClientRole('host', (err: any) => {
            console.log('Set client role error: ' + err)
        });

        this.client.init(this.props.appId, () => {
            console.log('AgoraRTC client initialized');
            this.subscribeStreamEvents();

            if (this.props.channel) {
                this.client.join(this.props.token, this.props.channel, this.props.uid, (uid: string) => {
                    console.log('User ' + uid + ' join channel successfully');
                    this.setState({localStreamId: 'ag-item-' + uid});
                    // create local stream
                    this.localStream = this.streamInit(uid, this.props.attendeeMode, this.props.videoProfile);
                    this.localStream.init(() => {
                            this.addStream(this.localStream, true);
                            this.initStreamWhenReady();
                            this.setState({readyState: true});
                        },
                        (err: any) => {
                            console.log('getUserMedia failed', err);
                            this.setState({readyState: false})
                        })
                });
            }
        });

        this.client.on('stream-added', (evt: any) => {
            this.client.subscribe(evt.stream, this.handleError);
        });

        // Play the remote stream when it is subscribed
        this.client.on('stream-subscribed', (evt: any) => {
            let stream = evt.stream;
            let streamId = String(stream.getId());
            this.addVideoStream(streamId);
            stream.play(streamId);

            this.initStreamWhenReady();

            this.addStream(stream)
        });

        this.client.on('user-published', async (user: any, mediaType: string) => {
            await this.client.subscribe(user, mediaType);

            if (mediaType === 'video') {
                const remoteVideoTrack = user.videoTrack,
                    playerContainer = document.createElement('div');
                playerContainer.id = user.uid.toString();
                playerContainer.style.width = '640px';
                playerContainer.style.height = '480px';
                document.body.append(playerContainer);

                remoteVideoTrack.play(playerContainer);
            }
        });

        this.client.on('stream-removed', (evt: any) => {
            let stream = evt.stream;
            console.log('Stream removed: ' + stream.getId());
            this.removeStream(stream.getId())
        });
    };

    private initStreamWhenReady = () => {
        let interval: any = null;
        interval = setInterval(() => {
            if (!this.localStream.stream) {
                return;
            }
            clearInterval(interval);

            this.client.publish(this.localStream, (err: any) => { // In a live interactive streaming channel, whoever calls this API is the host.
                console.log('Publish local stream error: ' + err);
            });
        }, 50);
    };

    private handleError = (err: any) => {
        console.log('Error: ', err);
    };

    private addVideoStream = (elementId: any) => {
        let canvas = document.querySelector('#ag-canvas'),
            streamDiv = document.createElement('div');
        streamDiv.id = elementId;
        streamDiv.style.transform = 'rotateY(180deg)';
        if (canvas) {
            canvas.appendChild(streamDiv);
        }
    };

    handleExit = (e?: any) => {
        if (e && e.currentTarget.classList.contains('disabled')) {
            return;
        }

        this.state.streamList.forEach((item: any) => this.removeStream(item.params.streamID));
        try {
            if (isNotNullOrUndefined(this.client)) {
                this.client.unpublish(this.localStream);
            }
            this.localStream && this.localStream.close();
            this.client && this.client.leave(() => {
                console.log('Client succeed to leave.')
            }, () => {
                console.log('Client failed to leave.')
            })
        } finally {
            this.setState({readyState: false, localStreamId: null});
            this.client = null;
            this.localStream = null;
            window.location.hash = ''
        }

        if (this.props.attendeeRole === UserRole.ORGANIZATION || this.props.attendeeRole === UserRole.HEADHUNTER) {
            let consultationDuration = Math.floor((new Date().getTime() - new Date(this.props.consultationStartTime).getTime()) / 1000) * 2;
            this.setState({isConsultationFinished: true});
            this.setParticipantPresence(false);
            if (this.participantPresenceInterval) {
                clearInterval(this.participantPresenceInterval);
            }
            this.changeConsultationStatus(consultationDuration);
        }
    };

    render() {
        const isCallInitiated = this.localStream && Object.keys(this.localStream).length &&
            this.localStream.constructor === Object;

        return (
            <React.Fragment>
                <Counter onlineConsultation={this.props.consultation}
                         endConsultation={this.handleExit}
                         userSecret={this.props.userSecret}
                         userRole={this.props.attendeeRole}/>

                <div id="ag-canvas" className={styles.agCanvas}>
                    <button onClick={() => this.changeFullScreenMode()}
                            className={`${styles.fullScreenBtn} 
                            // ${!this.state.isMicEnabled ? styles.disabled : ''}
                            `}
                            title="Full screen">
                    </button>

                    <div className={`${styles.agBtnGroup} ag-btn-group`}>
                        {this.renderVideoControlBtn()}
                        {this.renderAudioControlBtn()}
                        {this.renderSettingsBtn()}
                        {/*{this.renderScreenSharingBtn()}*/}
                        {isCallInitiated ? this.renderExitBtn() : (!this.state.isConsultationFinished ? this.renderStartVideoCall() : null)}
                    </div>
                </div>

                {/*{this.props.attendeeRole === UserRole.ORGANIZATION ? <UnloadHandler /> : null}*/}
                {this.state.isSettingsModalShown ? (<VideoSettings isModalVisible={this.state.isSettingsModalShown}
                                                                   toggleModal={this.toggleSettingsModal}/>) : null}
            </React.Fragment>
        )
    }

    private renderStartVideoCall = () => {
        return (
            <React.Fragment>
                {(this.props.attendeeRole === UserRole.ORGANIZATION || this.props.attendeeRole === UserRole.HEADHUNTER)?
                    (<div className={styles.agBtnContainer}>
                        <button className={`${styles.agBtn} ${styles.startBtn}`}
                                onClick={() => {
                                    this.initAgoraVideoCall();
                                    this.setParticipantPresence(true);
                                    this.props.changeOnlineConsultationStatus(ConsultationStatus.STARTED);
                                    if (this.participantPresenceInterval) clearInterval(this.participantPresenceInterval);
                                    this.participantPresenceInterval = setInterval(() => {
                                        this.setParticipantPresence(true);
                                    }, 50 * 1000);
                                }}>
                            <span className={styles.startBtnText}>Start Consultation</span>
                        </button>
                    </div>) :
                    null
                }
            </React.Fragment>
        )
    };

    private renderVideoControlBtn = () => {
        return (
            <React.Fragment>
                {this.props.attendeeMode === 'video' ?
                    (<div className={styles.agBtnContainer}>
                        <button onClick={this.handleCamera}
                                className={`${styles.agBtn} ${styles.videoControlBtn} ${!this.state.isCameraEnabled ? styles.disabled : ''}`}
                                title="Enable/Disable Video">
                        </button>
                    </div>) :
                    null
                }
            </React.Fragment>
        )
    };

    private renderAudioControlBtn = () => {
        return (
            <React.Fragment>
                {this.props.attendeeMode !== 'audience' ?
                    (<div className={styles.agBtnContainer}>
                        <button onClick={this.handleMic}
                                className={`${styles.agBtn} ${styles.audioControlBtn} ${!this.state.isMicEnabled ? styles.disabled : ''}`}
                                title="Enable/Disable Audio">
                        </button>
                    </div>) :
                    null
                }
            </React.Fragment>
        )
    };

    private renderSettingsBtn = () => {
        return (
            <div className={styles.agBtnContainer}>
                <button onClick={this.toggleSettingsModal}
                        className={`${styles.agBtn} ${styles.settingsBtn}`}
                        title="Settings">
                </button>
            </div>
        )
    };

    // private renderScreenSharingBtn = () => {
    //     return (
    //         <React.Fragment>
    //             {this.props.attendeeMode !== 'audience' ?
    //                 (<div className={styles.agBtnContainer}>
    //                     <button onClick={this.screenShareInit}
    //                             className={`${styles.agBtn} ${styles.audioControlBtn}`}
    //                             title="Enable/Disable Screen Sharing">
    //                     </button>
    //                 </div>) :
    //                 null
    //             }
    //         </React.Fragment>
    //     )
    // };

    private renderExitBtn = () => {
        return (
            <div className={`${styles.agBtnContainer} ${this.state.readyState ? '' : styles.disabled}`}>
                <button onClick={this.handleExit}
                        className={`${styles.agBtn} ${styles.exitBtn}`}
                        title="Exit">
                    {/*<i className="ag-icon ag-icon-leave"/>*/}
                </button>
            </div>
        );
    };

    private changeConsultationStatus = (consultationDuration: number) => {
        if (this.props.userSecret && this.props.consultation && this.props.consultation.id) {
            return setConsultationDurationAPI(this.props.userSecret, this.props.consultation.id, consultationDuration).pipe(
                tap((resp: any) => {
                    this.props.changeOnlineConsultationStatus(resp.status);
                }),
                catchError((error: any) => {
                    return of(error);
                })
            ).subscribe();
        }
    };

    private isStreamInitialized = (): boolean => {
        return this.localStream && Object.keys(this.localStream).length > 0 &&
            this.localStream.constructor === Object;
    };

    private setParticipantPresence = (isAvailable: boolean) => {
        if (!this.props.userSecret) {
            return;
        }

        this.subscriptions.push(
            confirmPresenceAPI(this.props.userSecret, isAvailable).pipe(
                catchError((error: any) => {
                    return of(error);
                })
            ).subscribe()
        )
    };

    private getDimensions = () => {
        this.setState({windowWidth: window.innerWidth});
    };

    private changeFullScreenMode = () => {
        this.setState({isFullScreenMode: !this.state.isFullScreenMode}, () => {
            this.props.changeScreenMode(this.state.isFullScreenMode)
        });
    };

    private toggleSettingsModal = () => {
        this.setState({isSettingsModalShown: !this.state.isSettingsModalShown});
    }
}

export default connect(
    (state: RootState) => ({
        audioInputDevice: audioInputDeviceSelector(state),
        audioOutputDevice: audioOutputDeviceSelector(state),
        videoInputDevice: videoInputDeviceSelector(state)
    }),
    {
        changeScreenMode,
        changeOnlineConsultationStatus
    }
)(AgoraVideoCall);
