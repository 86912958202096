import React, {Component} from 'react';
import {isNotNullOrUndefined} from '../../utils/runtimeUtils';
import {IListItemDetailsProps} from '../../types';
import Translation from '../Translation';

type Props = IListItemDetailsProps;

class ListItemDetails extends Component<Props> {
    render() {
        const iconUrl = this.props.avatar?.fileUrls?.smallThumb;
        return (
            <div className="custom-description">
                <div
                    className={`icon ${this.props.iconStyles ? this.props.iconStyles : ''}`}
                    style={{backgroundImage: `url(${isNotNullOrUndefined(iconUrl) ? iconUrl : ''})`}}></div>
                <div className="details">
                    <p className="title">{this.props.name}</p>

                    {this.props.description ? (
                        <p className="description">
                            <Translation text={this.props.description} />
                        </p>
                    ) : null}
                </div>
            </div>
        );
    }
}

export default ListItemDetails;
