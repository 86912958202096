import {Observable} from 'rxjs';
import {RestQueryParams} from './base/queryParams';
import {jobHunterServerMessagesAPI} from './provider/jobHunterServerMessagesAPI';
import {MessageMapperResult} from '../components/hoc/chat.types';
import {MESSAGES_NUMBER_PER_PAGE} from '../components/Chat/config';

export function getMessagesFromUserAPI(accessToken: string, accountId: string, page: number = 1): Observable<MessageMapperResult> {
    return jobHunterServerMessagesAPI(accountId).get(
        `api/messages?account_id=${accountId}&page=${page}&itemsPerPage=${MESSAGES_NUMBER_PER_PAGE}`,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`,
        }
    );
}
