import React, {Component} from 'react';
import { IRatingProps, IRatingState } from '../../../types';
import Symbol from '../RatingSymbol';


type Props = IRatingProps;
type State = IRatingState;

class Rating extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            displayValue: this.props.value,
            interacting: false,
        };
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevState.interacting && !this.state.interacting) {
            return this.props.onHover();
        }

        if (this.state.interacting && prevProps.value == this.props.value) {
            this.props.onHover(this.state.displayValue);
        }
    }

    render() {
        const symbolNodes = [],
            empty = [].concat(this.props.emptySymbol),
            full = [].concat(this.props.fullSymbol),
            placeholder = [].concat(this.props.placeholderSymbol),
            direction = this.props.direction ? {direction: this.props.direction} : {direction: 'ltr'},
            shouldDisplayPlaceholder = this.props.placeholderValue !== 0 && this.props.value === 0 && !this.state.interacting,
            renderedValue = shouldDisplayPlaceholder
                ? this.props.placeholderValue
                : this.props.quiet
                ? this.props.value
                : this.state.displayValue,
            fullSymbols = Math.floor(renderedValue);

        for (let i = 0; i < this.props.totalSymbols; i++) {
            let percent;
            if (i - fullSymbols < 0) {
                percent = 100;
            } else if (i - fullSymbols === 0) {
                percent = (renderedValue - i) * 100;
            } else {
                percent = 0;
            }

            symbolNodes.push(
                <Symbol
                    key={i}
                    index={i}
                    readonly={this.props.readonly}
                    inactiveIcon={empty[i % empty.length]}
                    activeIcon={shouldDisplayPlaceholder ? placeholder[i % full.length] : full[i % full.length]}
                    percent={percent}
                    direction={this.props.direction ? this.props.direction : 'ltr'}
                    {...(!this.props.readonly && {
                        onClick: this.symbolClick,
                        onMouseMove: this.symbolMouseMove,
                        onTouchMove: this.symbolMouseMove,
                        onTouchEnd: this.symbolEnd,
                    })}
                />
            );
        }

        return (
            <span
                id={this.props.id}
                style={direction as any}
                className={`rating-container ${this.props.className}`}
                tabIndex={this.props.tabIndex}
                aria-label={this.props['aria-label']}
                {...(!this.props.readonly && {
                    onMouseLeave: this.onMouseLeave,
                })}>
                {symbolNodes}
            </span>
        );
    }

    private symbolEnd = (symbolIndex: number, event: any) => {
        if (!this.props.quiet) {
            this.symbolClick(symbolIndex, event);
            event.preventDefault();
        }
        this.onMouseLeave();
    };

    private symbolClick = (symbolIndex: number, event: any) => {
        const value = this.calculateDisplayValue(symbolIndex, event);
        this.props.onClick(value, event);
    };

    private symbolMouseMove = (symbolIndex: number, event: any) => {
        const value = this.calculateDisplayValue(symbolIndex, event);
        this.setState({
            interacting: !this.props.readonly,
            displayValue: value,
        });
    };

    private onMouseLeave = () => {
        this.setState({
            displayValue: this.props.value,
            interacting: false,
        });
    };

    private calculateDisplayValue(symbolIndex: number, event: any) {
        const percentage = this.calculateHoverPercentage(event),
            fraction = Math.ceil((percentage % 1) * this.props.fractions) / this.props.fractions,
            precision = 10 ** 3,
            displayValue = symbolIndex + (Math.floor(percentage) + Math.floor(fraction * precision) / precision);

        return displayValue > 0
            ? displayValue > this.props.totalSymbols
                ? this.props.totalSymbols
                : displayValue
            : 1 / this.props.fractions;
    }

    private calculateHoverPercentage(event: any) {
        const clientX =
            event.nativeEvent.type.indexOf('touch') > -1
                ? event.nativeEvent.type.indexOf('touchend') > -1
                    ? event.changedTouches[0].clientX
                    : event.touches[0].clientX
                : event.clientX;

        const targetRect = event.target.getBoundingClientRect();
        const delta = this.props.direction === 'rtl' ? targetRect.right - clientX : clientX - targetRect.left;

        return delta < 0 ? 0 : delta / targetRect.width;
    }
}

export default Rating;
