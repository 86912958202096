import React, {Component} from 'react';
import {Badge, Button} from 'reactstrap';
import {ChevronDown, ChevronUp} from 'react-feather';
import styles from './styles.module.scss';
import {Translation, Avatar, AvatarColor, AvatarSize} from 'jobhunter-common-web';
import {IModelService} from '../../../../model/service';

interface IServiceDetailsProps {
    service: IModelService | null;
}

interface IServiceDetailsState {
    isDescriptionExpanded: boolean;
}

class ServiceDetails extends Component<IServiceDetailsProps, IServiceDetailsState> {
    constructor(props: IServiceDetailsProps) {
        super(props);

        this.state = {
            isDescriptionExpanded: false,
        };
    }

    render() {
        const service = this.props.service,
            shortenedDescription = this.props.service?.description.substr(0, 150) + '...';

        return (
            <div className={styles.serviceDetails}>
                <div className={styles.header}>
                    <div className={styles.company}>
                        <Avatar
                            color={AvatarColor.LIGHT_INFO}
                            size={AvatarSize.LG}
                            img={service?.author?.avatar?.fileUrls?.original}
                            tag={'div'}
                        />
                        <p>
                            <span>
                                <Translation text="marketplace.serviceDetails.by" />
                            </span>
                            {service?.author.organization?.organizationCompany?.name}
                        </p>
                    </div>
                    <div className={styles.labels}>
                        {/*<Badge color="primary" pill>
                            {service.location}
                        </Badge>*/}

                        {service && service.types
                            ? service.types.map((serviceType: any) => {
                                  return (
                                      <Badge color="primary" pill key={`type_${serviceType.id}`}>
                                          <p>
                                              <Translation text={serviceType.name} />
                                          </p>
                                      </Badge>
                                  );
                              })
                            : null}
                    </div>
                </div>

                <p className={styles.description}>{this.state.isDescriptionExpanded ? service?.description : shortenedDescription}</p>

                <div className={styles.btnContainer}>
                    <Button color="flat-primary" onClick={() => this.setState({isDescriptionExpanded: !this.state.isDescriptionExpanded})}>
                        <span className="align-middle me-1">
                            {this.state.isDescriptionExpanded ? (
                                <Translation text="marketplace.serviceDetails.readLess" />
                            ) : (
                                <Translation text="marketplace.serviceDetails.readMore" />
                            )}
                        </span>
                        {this.state.isDescriptionExpanded ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
                    </Button>
                </div>
            </div>
        );
    }
}

export default ServiceDetails;
