import React, {Component} from 'react';
import {
    Tooltip,
    Translation,
    WithNavigate,
    withNavigation,
    LoaderType,
    Loader,
    convertToPriceString,
    ListItemDetails,
    IModelDictionaryDatum,
} from 'jobhunter-common-web';
import {Badge, Button, Table} from 'reactstrap';
import {Search, Star} from 'react-feather';
import {WithTranslation, withTranslation} from 'react-i18next';
import OfferApplicationModal from '../OfferView/OfferApplicationModal';
import {IOffer, IOfferItem} from '../../../model/offersModel';
import styles from './styles.module.scss';

export enum OffersListType {
    OFFERS = 'offers',
    APPLICATIONS = 'applications',
    RECRUITMENT = 'recruitment',
    OBSERVED = 'observed',
}

declare type WithNavigationProps = typeof WithNavigate;
interface IExternalOffersList {
    offers: IOfferItem[] | null;
    isLoading: boolean;
}
interface IOffersListProps extends WithTranslation, WithNavigationProps, IExternalOffersList {
    type: OffersListType;
}

interface IOffersListState {
    isApplicationModalOpen: boolean;
    selectedOffer: {[key: string]: any} | null;
}

class OffersList extends Component<IOffersListProps, IOffersListState> {
    constructor(props: IOffersListProps) {
        super(props);

        this.state = {
            isApplicationModalOpen: false,
            selectedOffer: null,
        };
    }
    render() {
        return <div className={styles.offersContainer}>{this.renderOffers()}</div>;
    }

    private renderOffers = () => {
        return (
            <div>
                <Table striped responsive className="offers-table">
                    <thead>
                        <tr>
                            <th>
                                <Translation text="offers.table.project" />
                            </th>
                            <th>
                                <Translation text="offers.table.seniority" />
                            </th>
                            <th>
                                <Translation text="offers.table.salary" />
                            </th>
                            <th>
                                <Translation text="offers.table.remote" />
                            </th>
                            <th>
                                <Translation text="offers.table.status" />
                            </th>
                            <th>
                                <Translation text="offers.table.actions" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>{this.renderOffersTableRows()}</tbody>
                </Table>

                {this.state.isApplicationModalOpen ? (
                    <OfferApplicationModal
                        isModalOpen={this.state.isApplicationModalOpen}
                        toggleModal={this.toggleApplicationModal}
                        offer={this.state.selectedOffer}
                    />
                ) : null}
                <Loader type={LoaderType.Local} showLoader={this.props.isLoading} />
            </div>
        );
    };

    private toggleApplicationModal = (item?: {[key: string]: any} | null) => {
        this.setState({
            isApplicationModalOpen: !this.state.isApplicationModalOpen,
            selectedOffer: item ? item : null,
        });
    };

    private renderOffersTableRows() {
        const {t} = this.props;

        if (null === this.props.offers || this.props.offers.length === 0) {
            return (
                <tr key="no-data">
                    <td colSpan={6}>
                        <Translation text="offers.table.noOffers" />
                    </td>
                </tr>
            );
        }

        return this.props.offers?.map((item: IOfferItem) => {
            return (
                <tr key={`offer_${item.offer.id}`}>
                    <td>{this.renderDescription(item.offer)}</td>
                    <td>
                        <Translation text={item.offer.seniority.name} />
                    </td>
                    <td>
                        <p>
                            {convertToPriceString(item.offer.minimumSalary)} - {convertToPriceString(item.offer.maximumSalary)}
                            <span className="currency">
                                <Translation text="offers.table.currency" />
                            </span>
                        </p>
                    </td>
                    <td>
                        {item.offer && item.offer.workTypes.length
                            ? item.offer.workTypes.map((type: typeof IModelDictionaryDatum, index: number) => {
                                  return (
                                      <span key={type.id}>
                                          {index ? ', ' : ''}
                                          <Translation text={type.name} />
                                      </span>
                                  );
                              })
                            : '---'}
                    </td>
                    <td>
                        <Badge pill color="light-success">
                            {item.status}
                        </Badge>
                    </td>
                    <td>
                        <div className="">
                            {this.props.type === OffersListType.OFFERS || this.props.type === OffersListType.APPLICATIONS ? (
                                <Button color="flat-primary" onClick={() => this.toggleApplicationModal(item)}>
                                    <Star id={`apply_${item.offer.id}`} />
                                    <Tooltip target={`apply_${item.offer.id}`} tooltipText={t('offers.table.tooltips.apply')} />
                                </Button>
                            ) : null}

                            <Button color="flat-primary" onClick={() => this.showDetails(item.offer.id)}>
                                <Search id={`details_${item.offer.id}`} />
                                <Tooltip target={`details_${item.offer.id}`} tooltipText={t('offers.table.tooltips.details')} />
                            </Button>
                        </div>
                    </td>
                </tr>
            );
        });
    }

    private renderDescription = (item: IOffer) => {
        const {t} = this.props,
            description = `${t(item.city.country.name)}, ${t(item.city.name)}`;
        return <ListItemDetails avatar={item.avatar} name={item.name} description={description} />;
    };

    private showDetails = (offerId: string) => {
        this.props.navigate(`/offers/offer-view/${offerId}`);
    };
}

export default withTranslation()(withNavigation(OffersList));
