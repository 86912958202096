import React from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {
    Form,
    IFormConfig,
    isAuthenticatedSelector,
    Loader,
    loginErrorSelector,
    loginLoadingSelector,
    Translation,
    WithNavigate,
    withNavigation,
} from '../../..';
import {RootState} from '../../../store/reducers';
import {getLoginCredentials} from '../../../store/reducers/loginSlice';
import {UserRole} from '../../../types';

interface IConnectedLoginProps {
    isAuthenticated: boolean;
    isLoading: boolean;
    loginError: string;
    getLoginCredentials: typeof getLoginCredentials;
}

interface IExternalLoginProps {
    userRole: UserRole;
    formConfig: IFormConfig;
}

interface ILoginProps extends IConnectedLoginProps, IExternalLoginProps, WithTranslation, WithNavigate {}

interface ILoginState {
    formConfig: IFormConfig;
    value: any;
}

class Login extends React.Component<ILoginProps, ILoginState> {
    constructor(props: ILoginProps) {
        super(props);

        this.state = {
            formConfig: this.props.formConfig,
            value: null,
        };
    }

    componentDidMount(): void {
        if (this.props.isAuthenticated) {
            this.props.navigate('/');
        }
    }

    componentDidUpdate(): void {
        if (this.props.isAuthenticated) {
            this.props.navigate('/');
        }
    }

    render() {
        return (
            <article>
                <Loader showLoader={this.props.isLoading} />
                <header className="auth-form-header">
                    <h1 className="auth-title">
                        <Translation text="auth.login.title" />
                    </h1>
                </header>
                <Form config={this.state.formConfig} submitForm={this.login} controlName={'loginForm'} value={{}} />
                {this.props.userRole !== UserRole.ADMIN ? (
                    <div className="auth-subtitle">
                        <Translation text="auth.login.haveAccount" />
                        <Link className="auth-redirect-link" to="/auth/register">
                            <Translation text="auth.login.register" />
                        </Link>
                    </div>
                ) : null}
            </article>
        );
    }

    private login = (event: any, value: any, valid: boolean, touched: boolean): any => {
        event.preventDefault();
        if (!valid || !touched) {
            return;
        }

        return this.props.getLoginCredentials(value.email, value.password, this.props.userRole);
    };
}

export default withTranslation()(
    connect(
        (state: RootState) => ({
            isAuthenticated: isAuthenticatedSelector(state),
            isLoading: loginLoadingSelector(state),
            loginError: loginErrorSelector(state),
        }),
        {
            getLoginCredentials,
        }
    )(withNavigation(Login))
);
