import {ServerChatContact} from '../../../components/hoc/chat.types';
import {ChatContact} from '../../../types';

export const serverChatContactIntoChatContactMapper = (contacts: ServerChatContact[], accountId: string): ChatContact[] => {
    return contacts.map((contact) => {
        if (contact.contact.organizationFullInfo) {
            return fromServerOrganisationIntoChatContact(contact, accountId);
        }
        return fromServerUserIntoChatContact(contact, accountId);
    });
};

function fromServerOrganisationIntoChatContact(contact: ServerChatContact, accountId: string): ChatContact {
    return {
        roomId: createChannelId(contact.contact.organizationFullInfo.user.id, accountId),
        accountId: contact?.contact?.organizationFullInfo?.user?.id,
        firstName: contact?.contact?.organizationFullInfo?.user?.firstName,
        lastName: contact?.contact?.organizationFullInfo?.user?.lastName,
        unreadMessagesCount: contact?.unread,
        organisationId: contact?.contact?.organizationFullInfo?.organization?.id,
        organisationName: contact?.contact?.organizationFullInfo?.organization?.organizationCompany?.name,
        avatarUrl: contact?.contact?.organizationFullInfo?.user?.avatar?.fileUrls?.tinyThumb
            ? contact?.contact?.organizationFullInfo?.user?.avatar?.fileUrls?.tinyThumb
            : undefined,
    };
}

function fromServerUserIntoChatContact(contact: ServerChatContact, accountId: string): ChatContact {
    return {
        roomId: createChannelId(contact?.contact?.candidateFullInfo?.account?.id, accountId),
        accountId: contact?.contact?.candidateFullInfo?.account?.id,
        firstName: contact?.contact?.candidateFullInfo?.account?.firstName,
        lastName: contact?.contact?.candidateFullInfo?.account?.lastName,
        unreadMessagesCount: contact?.unread,
        avatarUrl: contact?.contact?.candidateFullInfo?.account?.avatar?.fileUrls?.tinyThumb
            ? contact.contact.candidateFullInfo.account.avatar.fileUrls.tinyThumb
            : undefined,
    };
}

function createChannelId(userAccountId: string, contactAccountId: string) {
    return [userAccountId, contactAccountId].sort().join('.');
}
