import React, {Component} from 'react';
import DescriptionSection, {IDescriptionItem} from '../LandingPage/DescriptionSection';
import RegisterSection from '../LandingPage/RegisterSection';
import QuestionSection from '../LandingPage/QuestionSection';
import {IAccordionItem, Translation} from 'jobhunter-common-web';
import LayoutWrapper from '../LayoutWrapper';

const description: IDescriptionItem[] = [
    {
        title: 'company.descriptionSection.integration.title',
        subtitle: 'company.descriptionSection.integration.subtitle',
        description: 'company.descriptionSection.integration.description',
        icon: 'MessageSquare',
    },
    {
        title: 'company.descriptionSection.selection.title',
        subtitle: 'company.descriptionSection.selection.subtitle',
        description: 'company.descriptionSection.selection.description',
        icon: 'FileText',
    },
    {
        title: 'company.descriptionSection.relocation.title',
        subtitle: 'company.descriptionSection.relocation.subtitle',
        description: 'company.descriptionSection.relocation.description',
        icon: 'Award',
    },
    {
        title: 'company.descriptionSection.employee.title',
        subtitle: 'company.descriptionSection.employee.subtitle',
        description: 'company.descriptionSection.employee.description',
        icon: 'Award',
    },
];

const questionsData: typeof IAccordionItem[] = [
    {
        title: 'Where do you get your candidates from?',
        description:
            'Our platform is open to anyone who wants to join. Country of origin is not decisive, although we focus on the India and middle Asia market.',
        icon: 'Heart',
    },
    {
        title: 'Can I offer my services on the marketplace without hiring candidates or issuing offers?',
        description:
            'Yes of course. Our platform consists of three main modules: Employer, Headhunter, Marketplace. They are independent from each other.',
        icon: 'Layout',
    },
    {
        title: 'How much do you charge for your service?',
        description:
            'It depends on a scope of the service. Please visit our offer page for details or write an email with your requirements for a personalized offer.',
        icon: 'DollarSign',
    },
];

class Company extends Component {
    render() {
        return (
            <LayoutWrapper>
                <div className="page-layout">
                    <div className="banner">
                        <h1 className="title">
                            <Translation text="company.title" />
                        </h1>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="page-container">
                                <DescriptionSection
                                    title="company.descriptionSection.title"
                                    description="company.descriptionSection.description"
                                    descriptionItems={description}
                                />
                                <RegisterSection
                                    redirectLink={`${process.env.REACT_APP_ORGANIZATION_URL}/auth/register`}
                                    title={'company.registrationSection.title'}
                                />
                                <QuestionSection questionsData={questionsData} />
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutWrapper>
        );
    }
}

export default Company;
