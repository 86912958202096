import {createSelector} from '@reduxjs/toolkit';
import {IRelocationsPageState} from '../reducers/relocationsPageSlice';

export const selectRelocationsPage = (state: any): IRelocationsPageState => {
    return state.relocationsPage;
};

export const relocationsSelector = createSelector([selectRelocationsPage], (state: IRelocationsPageState) => state.relocations);

export const relocationsMetadataSelector = createSelector(
    [selectRelocationsPage],
    (state: IRelocationsPageState) => state.relocationsMetadata
);

export const relocationsPaginationSelector = createSelector([selectRelocationsPage], (state: IRelocationsPageState) => state.pagination);

export const isRelocationsPageLoadingSelector = createSelector(
    [selectRelocationsPage],
    (state: IRelocationsPageState) => state.isRelocationsPageLoading
);

export const isRelocationsPageInitializedSelector = createSelector(
    [selectRelocationsPage],
    (state: IRelocationsPageState) => state.isRelocationsPageInitialized
);

export const relocationsErrorSelector = createSelector(
    [selectRelocationsPage],
    (state: IRelocationsPageState) => state.relocationsPageError
);
