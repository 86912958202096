import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ConsultationStatus} from '../../types';

export interface IOnlineConsultation {
    id: string | null;
    agoraChannel: string | null;
    agoraToken: string | null;
    cancelledBy: string | null;
    clinic: any | null;
    createdBy: any | null;
    endsAt: string | null;
    initialDescription: string | null;
    isAccepted: boolean | null;
    notes: string | null;
    paidAt: string | null;
    participants: any[] | null;
    payments: any[] | null;
    startsAt: string | null;
    status: ConsultationStatus | null;
    treatmentType: any | null;
}
export interface IOnlineConsultationState {
    onlineConsultation: IOnlineConsultation | null;
    isLoading: boolean;
    error: string | null;
}

export interface IGetOnlineConsultation {
    consultationId: string;
    secret: string | null;
}

export interface IChangeOnlineConsultation {
    onlineConsultation: IOnlineConsultation;
}

export interface IChangeOnlineConsultationStatus {
    onlineConsultationStatus: ConsultationStatus;
}

export interface IChangeShowLoader {
    isLoading: boolean;
}

export interface IChangeOnlineConsultationError {
    error: string;
}

const initialState: IOnlineConsultationState = {
    onlineConsultation: null,
    isLoading: false,
    error: null,
};

const onlineConsultationSlice = createSlice({
    name: 'onlineConsultation',
    initialState: initialState,
    reducers: {
        getOnlineConsultation: {
            reducer: (state: IOnlineConsultationState) => {
                return {
                    onlineConsultation: state.onlineConsultation,
                    isLoading: state.isLoading,
                    error: state.error,
                };
            },
            prepare(consultationId: string, secret: string | null) {
                return {
                    payload: {
                        consultationId: consultationId,
                        secret: secret,
                    },
                };
            },
        },
        changeOnlineConsultation: {
            reducer: (state: IOnlineConsultationState, action: PayloadAction<IChangeOnlineConsultation>) => {
                return {
                    onlineConsultation: action.payload.onlineConsultation,
                    isLoading: state.isLoading,
                    error: state.error,
                };
            },
            prepare(onlineConsultation: IOnlineConsultation) {
                return {
                    payload: {onlineConsultation: onlineConsultation},
                };
            },
        },
        changeOnlineConsultationStatus: {
            reducer: (state: IOnlineConsultationState, action: PayloadAction<IChangeOnlineConsultationStatus>) => {
                const consultation = state.onlineConsultation;
                return {
                    onlineConsultation: {
                        id: consultation && consultation.id ? consultation.id : null,
                        agoraChannel: consultation && consultation.agoraChannel ? consultation.agoraChannel : null,
                        agoraToken: consultation && consultation.agoraToken ? consultation.agoraToken : null,
                        cancelledBy: consultation && consultation.cancelledBy ? consultation.cancelledBy : null,
                        clinic: consultation && consultation.clinic ? consultation.clinic : null,
                        createdBy: consultation && consultation.createdBy ? consultation.createdBy : null,
                        endsAt: consultation && consultation.endsAt ? consultation.endsAt : null,
                        initialDescription: consultation && consultation.initialDescription ? consultation.initialDescription : null,
                        isAccepted: consultation && consultation.isAccepted ? consultation.isAccepted : null,
                        notes: consultation && consultation.notes ? consultation.notes : null,
                        paidAt: consultation && consultation.paidAt ? consultation.paidAt : null,
                        participants: consultation && consultation.participants ? consultation.participants : null,
                        payments: consultation && consultation.payments ? consultation.payments : null,
                        startsAt: consultation && consultation.startsAt ? consultation.startsAt : null,
                        status: action.payload.onlineConsultationStatus,
                        treatmentType: consultation && consultation.treatmentType ? consultation.treatmentType : null,
                    },
                    isLoading: state.isLoading,
                    error: state.error,
                };
            },
            prepare(onlineConsultationStatus: ConsultationStatus) {
                return {
                    payload: {onlineConsultationStatus: onlineConsultationStatus},
                };
            },
        },
        changeOnlineConsultationShowLoader: {
            reducer: (state: IOnlineConsultationState, action: PayloadAction<IChangeShowLoader>) => {
                return {
                    onlineConsultation: state.onlineConsultation,
                    isLoading: action.payload.isLoading,
                    error: state.error,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        changeOnlineConsultationError: {
            reducer: (state: IOnlineConsultationState, action: PayloadAction<IChangeOnlineConsultationError>) => {
                return {
                    onlineConsultation: state.onlineConsultation,
                    isLoading: state.isLoading,
                    error: action.payload.error,
                };
            },
            prepare(error: string) {
                return {
                    payload: {error: error},
                };
            },
        },
    },
});

export const {
    changeOnlineConsultation,
    changeOnlineConsultationShowLoader,
    changeOnlineConsultationError,
    getOnlineConsultation,
    changeOnlineConsultationStatus,
} = onlineConsultationSlice.actions;

export default onlineConsultationSlice.reducer;
