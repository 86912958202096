import {createSelector} from '@reduxjs/toolkit';
import {IConsultationDataState} from '../reducers/consultationDataSlice';

export const selectConsultationData = (state: any): IConsultationDataState => {
    return state.consultationData;
};

export const availableConsultationSlotsSelector = createSelector(
    [selectConsultationData],
    (state: IConsultationDataState) => state.availableConsultationSlots
);

export const consultationSlotsToBlockSelector = createSelector(
    [selectConsultationData],
    (state: IConsultationDataState) => state.slotsToBlock
);

export const consultationDataLoadingSelector = createSelector([selectConsultationData], (state: IConsultationDataState) => state.isLoading);

export const consultationDataErrorSelector = createSelector([selectConsultationData], (state: IConsultationDataState) => state.error);
