import {jobHunterAPI} from './provider/jobHunterAPI';
import {Observable} from 'rxjs';
import {RestQueryParams} from './base/queryParams';

export function getOnlineConsultationAPI(consultationId: string, secret: string | null, authToken?: string): Observable<any> {
    let headers: any = {
        accept: 'application/ld+json',
        'content-type': 'application/json',
    };

    if (undefined !== authToken) {
        headers['Authorization'] = `Bearer ${authToken}`;
    } else if (null !== secret) {
        headers['X-Online-Consultation-Participant-Token'] = secret;
    }

    return jobHunterAPI.get(`api/online_consultations/${consultationId}`, new RestQueryParams(), headers);
}
