import {Observable} from 'rxjs';
import {jobHunterAPI} from './provider/jobHunterAPI';
import {RestQueryParams} from './base/queryParams';

export function getDictionaryDataAPI(dictionaryName: string, authToken: string | null): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return jobHunterAPI.get(`api/dictionary/${dictionaryName}`, new RestQueryParams(), headers);
}
