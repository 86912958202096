import React, {Component} from 'react';
import styles from './styles.module.scss';
import {AccordionComponent, IAccordionItem, Translation} from 'jobhunter-common-web';

interface IQuestionSectionProps {
    questionsData: typeof IAccordionItem[];
}

class QuestionSection extends Component<IQuestionSectionProps> {
    render() {
        return (
            <div className={styles.questionSection}>
                <p className={styles.title}>
                    <Translation text="company.questionSection.title" />
                </p>
                <p className={styles.description}>
                    <Translation text="company.questionSection.subtitle" />
                </p>
                <AccordionComponent items={this.props.questionsData} />
            </div>
        );
    }
}

export default QuestionSection;
