import React, {Component} from 'react';
import styles from './styles.module.scss';
import {Translation} from 'jobhunter-common-web';

interface ISubscriptionPlanProps {
    subscriptionPlan: {[key: string]: any} | null;
}

class SubscriptionPlan extends Component<ISubscriptionPlanProps> {
    render() {
        if (!this.props.subscriptionPlan) {
            return null;
        }
        const plan = this.props.subscriptionPlan,
            color = plan.color;
        return (
            <div className={`${styles.planContainer} ${styles[color]}`}>
                <div className={styles.header}>
                    <p className={styles.title}>{plan.name}</p>
                    <p className={styles.details}>{plan.details}</p>
                </div>
                <div className={styles.description}>
                    {this.renderRelocationServiceOptions(plan.relocationPackageServices)}
                    {this.renderSubscriptionVariants(plan.subscriptionVariants)}
                </div>
                <div className={styles.price}>
                    <span className={styles.amount}>{plan.netPrice / 100}</span>
                    {plan.priceCurrency}
                </div>
            </div>
        );
    }
    private renderRelocationServiceOptions = (services: any) => {
        if (!services) return null;
        return (
            <ul>
                {services.map((service: any, index: number) => (
                    <li key={index}>
                        <Translation text={`plans.relocationServicePackage.${service}`} />
                    </li>
                ))}
            </ul>
        );
    };
    private renderSubscriptionVariants = (variants: any) => {
        if (!variants) return null;
        console.log(variants);
        return (
            <ul>
                {variants.map((variant: any, index: number) => (
                    <li key={index}>
                        <Translation text={`plans.subscriptionVariants.${variant.name}`} config={{value: variant.value}} />
                    </li>
                ))}
            </ul>
        );
    };
}

export default SubscriptionPlan;
