import {IModelApiObject} from './base';
import {IModelMediaObject} from './mediaObject';
import {isNotEmpty, isNotNullOrUndefined} from '../utils/runtimeUtils';

export interface ISimpleAccountOutput {
    readonly id: string;
    readonly firstName: string | null;
    readonly lastName: string | null;
    readonly displayName: string | null;
    readonly avatar: IModelMediaObject | null;
}

export interface IModelAccount extends IModelApiObject {
    readonly address: string | null;
    readonly avatar: IModelMediaObject | null;
    readonly birthdate: string | null;
    readonly displayName: string | null;
    readonly displayableName: string;
    readonly firstName: string | null;
    readonly id: string | null;
    readonly lastName: string | null;
    readonly phone: string | null;
    readonly serviceInstances: []; // todo
    readonly timeZone: null;
    readonly wallet: IModelWallet;
}

export interface IModelWallet extends IModelApiObject {
    readonly amount: number | null;
    readonly currency: string | null;
    readonly realCurrency: {
        code: string | null;
    };
}

export const getDisplayName = (
    account: IModelAccount,
    defaultValue: string | null = null,
    firstNameOnly: boolean = false
): string | null => {
    if (isNotNullOrUndefined(account?.displayName)) {
        return account.displayName;
    }
    if (isNotNullOrUndefined(account?.firstName)) {
        return `${account.firstName}${!firstNameOnly && isNotEmpty(account.lastName) ? ` ${account.lastName}` : ''}`;
    }

    return defaultValue;
};
