import {Observable} from 'rxjs';
import {jobHunterAPI} from './provider/jobHunterAPI';
import {RestQueryParams} from './base/queryParams';

export function getAccountDataAPI(authToken: string | null, accountId: string): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return jobHunterAPI.get(`api/accounts/${accountId}`, new RestQueryParams(), headers);
}
