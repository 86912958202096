import React, {Component} from 'react';
import {Tooltip} from 'reactstrap';
import {ITooltipProps, ITooltipState, TooltipPlacement} from '../../types';
import Translation from '../Translation';

type Props = ITooltipProps;
type State = ITooltipState;

class TooltipComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    render() {
        return (
            <Tooltip
                placement={this.props.placement ? this.props.placement : TooltipPlacement.TOP}
                isOpen={this.state.isOpen}
                target={this.props.target}
                toggle={() => this.setState({isOpen: !this.state.isOpen})}>
                <Translation text={this.props.tooltipText} />
            </Tooltip>
        );
    }
}

export default TooltipComponent;
