import React from 'react';
import styles from './styles.module.scss';
import {IProgressBarProps} from "../../../../../types";


type Props = IProgressBarProps;

class ProgressBar extends React.Component<Props> {

    render() {
        const {completed} = this.props;
        const fillerStyles = {
            width: `${completed * 100}%`,
        };

        return (
            <div className={styles.container}>
                <div className={styles.filler} style={fillerStyles}/>
            </div>
        );
    }
}

export default ProgressBar;
