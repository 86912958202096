import {combineEpics} from 'redux-observable';
import {accountEpic, changePasswordEpic, dictionaryDataEpic, loginEpic, reauthenticateEpic, renewAuthTokenEpic} from 'jobhunter-common-web';
import offersPageEpic from './offersPageEpic';
import marketplacePageEpic from './marketplacePageEpic';
import offerViewPageEpic from './offerViewPageEpic';

export const rootEpic = combineEpics(
    loginEpic,
    renewAuthTokenEpic,
    reauthenticateEpic,
    changePasswordEpic,
    accountEpic,
    dictionaryDataEpic,
    offersPageEpic,
    offerViewPageEpic,
    marketplacePageEpic
);
