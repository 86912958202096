import React from 'react';
import styles from './styles.module.scss';
import DatePicker from 'react-datepicker';
import {IDatePickerInputProps} from '../../../types';
import {getDateFromString} from '../../../utils/dateUtils';
import moment from 'moment';

type Props = IDatePickerInputProps;

class DatePickerInput extends React.Component<Props> {
    render() {
        return (
            <div
                className={`${
                    this.props.showMonthDropdown || this.props.showYearDropdown ? 'select-month-year' : ''
                } ${'react-datepicker-host'} ${this.props.isTimePicker ? 'select-date-time' : ''}`}>
                <DatePicker
                    showTimeSelect={this.props.isTimePicker}
                    name={`${this.props.name}`}
                    id={`${this.props.name}`}
                    dateFormat={this.props.dateFormat}
                    placeholderText={this.props.placeholderText}
                    className={`${this.props.inputStyles} ${styles.dateInput}`}
                    disabled={this.props.disabled}
                    // value={this.props.value ? new Date(this.props.value) : null}
                    weekLabel={''}
                    autoComplete={'off'}
                    onChangeRaw={this.handleDateChangeRaw}
                    selected={this.props.value ? new Date(this.props.value) : null}
                    minDate={this.props.minDate ? getDateFromString(this.props.minDate) : null}
                    maxDate={this.props.maxDate ? getDateFromString(this.props.maxDate) : null}
                    openToDate={this.props.openToDate ? getDateFromString(this.props.openToDate) : undefined}
                    onChange={(e) => this.changeInputValue(e)}
                    showMonthDropdown={this.props.showMonthDropdown}
                    showYearDropdown={this.props.showYearDropdown}
                    dropdownMode="select"
                    inline={this.props.inline}
                    formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
                    popperPlacement={this.props.popperPlacement ? this.props.popperPlacement : 'bottom-end'}
                    timeIntervals={60}
                    // minTime={this.calculateMinTime(new Date())}
                    minTime={moment().hours(8).minutes(0).toDate()}
                    maxTime={moment().hours(21).minutes(0).toDate()}
                />
            </div>
        );
    }

    handleDateChangeRaw = (e: any) => {
        e.preventDefault();
    };

    private changeInputValue = (e: Date | [Date, Date] | null) => {
        if (e) {
            const eventData = {target: {name: this.props.name, value: e}};
            this.props.handleChange(eventData);
        }
    };

    private calculateMinTime = (date) => {
        let isToday = moment(date).isSame(moment(), 'day');
        if (isToday) {
            let nowAddOneHour = moment(new Date()).add({hours: 1}).toDate();
            return nowAddOneHour;
        }
        return moment().startOf('day').toDate();
    };
}

export default DatePickerInput;
