import {Observable} from 'rxjs';
import {jobHunterAPI} from './provider/jobHunterAPI';
import {RestQueryParams} from './base/queryParams';
import {LanguageLocaleType} from "../constants/locales";


export interface IRegisterPayload {
    username: string;
    password: string;
    locale: LanguageLocaleType;
    type: string;
    returnUrl: string;
}

export function registerAPI(payload: IRegisterPayload): Observable<any> {
    return jobHunterAPI.post(
        `api/users/register`,
        payload,
        new RestQueryParams(),
        {}
    );
}
