import React from 'react';
import styles from '../CallExpired/styles.module.scss';
import Translation from "../../Translation";


class CallNotFound extends React.Component<any> {
    render() {
        return (
            <div className={`${styles.host}`}>
                <h1 className={styles.header}><Translation text={'videoChat.noAccess'}/></h1>
                <p className={styles.info}><Translation text={'videoChat.contactAdmin'}/></p>
            </div>
        );
    }
}

export default CallNotFound;
