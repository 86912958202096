import React from 'react';
import {DollarSign, Globe, Grid, Search, User} from 'react-feather';
import {ISideMenuGroup, ISideMenuHeader, ISideMenuItem} from 'jobhunter-common-web';

const menu = (): (typeof ISideMenuGroup | typeof ISideMenuItem | typeof ISideMenuHeader)[] => [
    {
        id: 'sidebar-candidates',
        title: 'footer.forCandidates',
        icon: <User size={20} />,
        navLink: '/candidate',
        badge: 'primary',
    },
    {
        id: 'sidebar-company',
        title: 'footer.forCompanies',
        icon: <Globe size={20} />,
        navLink: '/company',
    },
    {
        id: 'sidebar-marketplace',
        title: 'footer.marketplace',
        icon: <Grid size={20} />,
        navLink: '/marketplace',
    },
    {
        id: 'sidebar-offers',
        title: 'footer.findYourJob',
        icon: <Search size={20} />,
        navLink: '/#offers-list',
    },
    {
        id: 'plans',
        title: 'footer.pricing',
        icon: <DollarSign size={20} />,
        navLink: '/pricing',
    },
];
export default menu;
