import {Observable} from 'rxjs';
import {RestQueryParams} from './base/queryParams';
import {jobHunterAPI} from './provider/jobHunterAPI';

export function confirmPresenceAPI(accessSecret: string, available: boolean): Observable<any> {
    let payload = {
        accessSecret: accessSecret,
        available: available,
    };
    return jobHunterAPI.post(`api/online_consultation_participants/set_availability`, payload, new RestQueryParams());
}
