import {WithTranslation} from 'react-i18next';
import {FormControlType, InputType, MultiselectMenuPlacement, MultiSelectType} from './components/FormControl';
import {FormButtonDisabledStrategy, FormButtonType} from './components/FormControl/FormButton';
import {addAlert, AlertObject, removeAlert} from './store/reducers/alertSlice';
import {ReactElement, ReactNode} from 'react';
import {LanguageLocale} from './constants/locales';
import {Observable, Subject} from 'rxjs';
import {IFileOutput} from './model/user';
import {IModelDictionaryDatum} from './model/dictionaryDatum';
import {initAuthTokenChange} from './store/reducers/authSlice';

export enum AuthType {
    LOGIN = 'login',
    REGISTER = 'register',
    NEW_PASSWORD = 'new_password',
    CHANGE_PASSWORD = 'change_password',
    RESET_PASSWORD = 'reset_password',
    ACCEPT_INVITATION = 'accept_invitation',
    CONFIRM_REGISTRATION = 'confirm_registration',
}

export enum LoaderType {
    Local = 'local',
    Global = 'global',
}

export enum AlertType {
    INFO = 'info',
    WARNING = 'warning',
    SUCCESS = 'success',
    ERROR = 'error',
}

export enum CustomCardType {
    MODAL_CARD = 'modal-card',
    BASIC = 'basic',
}

export enum FormControlChangeType {
    Init = 'init', // when setting initial value
    Internal = 'internal', // when internal program changes change the value
    User = 'user', // when user (UI) changes the value
}

export enum CurrencyType {
    EURO = 'EUR',
    DOLLAR = 'DOL',
    PLN = 'PLN',
}

export interface IBlockerEvent {
    startsAt: Date;
    endsAt: Date;
    isFree: boolean;
}

export enum UserRole {
    USER = 'ROLE_USER',
    CANDIDATE = 'ROLE_CANDIDATE',
    ORGANIZATION = 'ROLE_ORGANIZATION',
    ORGANIZATION_EMPLOYEE = 'ROLE_ORGANIZATION_EMPLOYEE',
    STANDARD_EMPLOYEE = 'ROLE_STANDARD_EMPLOYEE',
    ORGANIZATION_ADMIN = 'ROLE_ORGANIZATION_ADMIN',
    HEADHUNTER = 'ROLE_HEADHUNTER',
    ADMIN = 'ROLE_ADMIN',
}

export enum TimeSlotsType {
    CALENDAR = 'calendar',
    WEEK_CALENDAR = 'week-calendar',
}

export enum TooltipPlacement {
    AUTO = 'auto',
    AUTO_START = 'auto-start',
    AUTO_END = 'auto-end',
    TOP = 'top',
    TOP_START = 'top-start',
    TOP_END = 'top-end',
    RIGHT = 'right',
    RIGHT_START = 'right-start',
    RIGHT_END = 'right-end',
    BOTTOM = 'bottom',
    BOTTOM_START = 'bottom-start',
    BOTTOM_END = 'bottom-end',
    LEFT = 'left',
    LEFT_START = 'left-start',
    LEFT_END = 'left-end',
}

export enum ConsultationStatus {
    FINISHED = 'finished',
    NOT_STARTED = 'not_started',
    STARTED = 'started',
    SCHEDULED = 'scheduled',
    CANCELLED = 'cancelled',
    DRAFT = 'draft',
}

export enum DateRangePickerPlacement {
    TOP = 'top',
    BOTTOM = 'bottom',
    RIGHT = 'right',
    LEFT = 'left',
    BOTTOM_START = 'bottomStart',
    BOTTOM_END = 'bottomEnd',
    TOP_START = 'topStart',
    TOP_END = 'topEnd',
    LEFT_START = 'leftStart',
    RIGHT_START = 'rightStart',
    LEFT_END = 'leftEnd',
    RIGHT_END = 'rightEnd',
    AUTO = 'auto',
    AUTO_VERTICAL = 'autoVertical',
    AUTO_VERTICAL_START = 'autoVerticalStart',
    AUTO_VERTICAL_END = 'autoVerticalEnd',
    AUTO_HORIZONTAL = 'autoHorizontal',
    AUTO_HORIZONTAL_START = 'autoHorizontalStart',
    AUTO_HORIZONTAL_END = 'autoHorizontalEnd',
}

export enum MediaDeviceType {
    AUDIO_INPUT = 'audioinput',
    AUDIO_OUTPUT = 'audiooutput',
    VIDEO_INPUT = 'videoinput',
}

export enum MessageContentType {
    TEXT = 'text',
    IMAGE = 'image',
    FILE = 'file',
    PLACEHOLDER = 'placeholder',
    ERROR = 'error',
}

export enum NodeErrorTypes {
    NOT_AUTHORIZED = 'not_authorized',
    NO_TOKEN = 'no_token',
    ALREADY_ONLINE = 'already_online',
    EXPIRED_TOKEN = 'expired_token',
    WEBSOCKET_ERROR = 'websocket error',
}

export enum ChatType {
    MESSAGE = 'message',
    RESPONSE = 'response',
    // UPDATE = "update"
}

export enum ApexRadialbarType {
    LINE = 'line',
    AREA = 'area',
    BAR = 'bar',
    HISTOGRAM = 'histogram',
    PIE = 'pie',
    DONUT = 'donut',
    RADIAL_BAR = 'radialBar',
    SCATTER = 'scatter',
    BUBBLE = 'bubble',
    HEATMAP = 'heatmap',
    TREEMAP = 'treemap',
    BOX_PLOT = 'boxPlot',
    CANDLESTICK = 'candlestick',
    RADAR = 'radar',
    POLAR_AREA = 'polarArea',
    RANGE_BAR = 'rangeBar',
}

export enum ReactstrapColors {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    SUCCESS = 'success',
    DANGER = 'danger',
    WARNING = 'warning',
    INFO = 'info',
    LIGHT = 'light',
    DARK = 'dark',
}

export enum AvatarSize {
    SM = 'sm',
    LG = 'lg',
    XL = 'xl',
}

export enum AvatarStatus {
    ONLINE = 'online',
    OFFLINE = 'offline',
    AWAY = 'away',
    BUSY = 'busy',
}

export enum AvatarColor {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    SUCCESS = 'success',
    DANGER = 'danger',
    INFO = 'info',
    WARNING = 'warning',
    DARK = 'dark',
    LIGHT_PRIMARY = 'light-primary',
    LIGHT_SECONDARY = 'light-secondary',
    LIGHT_SUCCESS = 'light-success',
    LIGHT_DANGER = 'light-danger',
    LIGHT_INFO = 'light-info',
    LIGHT_WARNING = 'light-warning',
    LIGHT_DARK = 'light-dark',
}

export enum JobHunterDocuments {
    PrivacyPolicy = '/assets/pdf/0/Polityka%20Prywatno%C5%9Bci.pdf',
    TermsOfUse = '/assets/pdf/0/Terms%20of%20Use.pdf',
}

export type IAvatarProps = {
    img?: any;
    icon?: ReactElement<any>;
    src?: string;
    badgeUp?: boolean;
    content?: string;
    badgeText?: string;
    className?: string;
    imgClassName?: string;
    contentStyles?: {[key: string]: any};
    size?: AvatarSize;
    tag: any;
    status?: AvatarStatus;
    imgHeight?: string | number;
    imgWidth?: string | number;
    badgeColor?: AvatarColor;
    color: AvatarColor;
    initials?: any;
};

export type ISidebarProps = {
    menuCollapsed: boolean;
    currentActiveItem: any;
    skin: string;
    logoImage: string;
    logoMobileImage: string;
    menuData: (ISideMenuGroup | ISideMenuItem | ISideMenuHeader)[][];
    setMenuCollapsed: (isMenuCollapsed: boolean) => void;
    isMenuMobile?: boolean;
    toggleMobileMenu: () => void;
};

export type ISideMenuGroup = {
    id: string;
    title: string;
    icon: any;
    navLink?: string;
    children?: ISideMenuItem[];
    badge?: string;
    badgeText?: string;
    navLinkClassname?: string;
};

export type ISideMenuItem = {
    id: string;
    title: string;
    icon: any;
    navLink?: string;
    isLabel?: boolean;
    onClick?: () => void;
    badge?: string;
    badgeText?: string;
    navLinkClassname?: string;
};

export type ISideMenuHeader = {
    header: string;
};

export type IVerticalMenuHeaderProps = {
    menuCollapsed: boolean;
    setMenuCollapsed: (isCollapsed: boolean) => void;
    setGroupOpen: any;
    menuHover: any;
    logoImage: string;
    logoImageMobile: string;
    isMenuMobile?: boolean;
    toggleMobileMenu: () => void;
};

export type IVerticalMenuHeaderState = {
    logo: string | null;
    isLogoMobile: boolean;
};

export type IVerticalNavMenuGroupProps = {
    item: any;
    groupOpen: any;
    menuHover: any;
    activeItem: any;
    parentItem: any;
    groupActive: any;
    currentActiveGroup: any;
    setGroupOpen: any;
    setActiveItem: any;
    menuCollapsed: boolean;
    setGroupActive: any;
    setCurrentActiveGroup: any;
    currentActiveItem: any;
};

export type IVerticalNavMenuLinkProps = {
    item: any;
    activeItem: any;
    setActiveItem: any;
    currentActiveItem: any;
    toggleMobileMenu?: () => void;
};

export type IVerticalNavMenuSectionHeaderProps = {
    item: ISideMenuHeader;
};

export type IVerticalNavMenuItemsProps = {
    items: any;
    activeItem: any;
    setActiveItem: any;
    currentActiveItem: any;
    groupOpen: {[key: string]: any}[] | [];
    menuHover: boolean;
    parentItem: ISideMenuGroup | null;
    groupActive: {[key: string]: any}[] | [];
    currentActiveGroup: {[key: string]: any}[] | [];
    setGroupOpen: any;
    menuCollapsed: boolean;
    setGroupActive: any;
    setCurrentActiveGroup: any;
    toggleMobileMenu?: () => void;
};

export type IPrice = {
    readonly amount: string;
    readonly currency: ICurrency;
    config?: any;
};

export type ICurrency = {
    readonly code: string;
};

export type ILoaderProps = {
    showLoader: boolean;
    type?: string;
    showGlobalLoader?: boolean;
    showLocalLoader?: boolean;
    ref?: any;
    color?: ReactstrapColors;
    transparent?: boolean;
};

export type ILoaderState = {
    showLocalLoader: boolean;
};

export type IToastProps = {
    readonly alerts: AlertObject[];
    readonly alertsDisabled: boolean;
    readonly removeAlert: typeof removeAlert;
    readonly alertType?: AlertType;
};

export type IToastState = {
    alerts: AlertObject[];
    alertsDisabled: boolean;
};

export type ITranslationProps = {
    readonly text: string;
    readonly config?: {[key: string]: any};
};

export type IProgressRingProps = {
    radius: number;
    stroke: number;
    progress: number;
};

export type IDetailsProps = {
    children?: any;
    showLocalLoader?: boolean;
};

export type IValidationRuleResult = {
    valid: boolean;
    errorMessages: string[];
};

export type IValidationRule = {
    name: string;
    params?: {[key: string]: any};
};

export type IValidationConfig = IValidationRule[];

export type IInputControlOptions = {
    value: any;
    displayValue: string;
};

export type InputDataMapper = (data: any, config?: any) => any;

export type OutputDataMapper = (data: any, previousStateSnapshot: any, controlName: string, changeType: FormControlChangeType) => any;

export type ChangeHandler = (e: any) => void;

export type ButtonClickHandler = (key: string, type: FormButtonType, event: any) => void;

export type MultiselectChangeHandler = (values: IMultiselectOption | IMultiselectOption[], action: any) => void;

export type DataAccessor = (data: any, key: string | number) => any;

export type IFormControls = IFormGroupConfig[] | {[key: string]: IFormGroupConfig | IFormControlConfig};

export type IBaseFormConfig = {
    inputDataMapper?: InputDataMapper; // used for mapping input (props) value to form value
    outputDataMapper?: OutputDataMapper; // used for mapping form value to output value
    validationRules?: IValidationConfig;
};

export type IBaseGroupedFormConfig = {
    class: string;
    dataAccessor?: DataAccessor; // used for retrieving control value from group value
    controls: IFormControls;
} & IBaseFormConfig;

export type IFormGroupConfig = {
    controlType: 'group' | 'fieldset';
    key: string; // used for identifying group in the model
    fieldsetTitle?: string;
    groupLabel?: string;
} & IBaseGroupedFormConfig;

export type IFormControlConfig = {
    controlType: 'control';
    defaultValue?: any;

    formControlType: FormControlType;
    placeholder?: string;
    label?: string;
    richTextLabel?: string;
    isLabelHidden?: boolean;
    hostClass: string;
    type?: InputType;
    isCurrencyInput?: boolean;
    options?: IInputControlOptions[];
    multiselectOptions?: IMultiselectOption[] | (() => IMultiselectOption[]);
    disabled?: boolean;
    additionalStyles?: any;
    renderPreIcon?: () => any;
    checkboxSlots?: boolean;
    isDateFiltered?: boolean;
    isDayDisabled?: boolean;
    isTimePicker?: boolean;

    multiselectType?: MultiSelectType;
    isCurrencySelect?: boolean;
    isComponentCustom?: boolean;
    isCustomLogoOption?: boolean;
    isCustomMultiValueContainer?: boolean;
    isGroupedComponentCustom?: boolean;
    isGroupedLabelTranslated?: boolean;
    openMenuOnClick?: boolean;
    hideValueOnFocus?: boolean;
    isSearchable?: boolean;
    handleMultiselectInputChange?: any;
    menuPlacement?: MultiselectMenuPlacement;
    firstOptionValue?: MultiSelectType;
    handleChange?: MultiselectChangeHandler;
    cols?: number;
    rows?: number;
    maxDate?: string | Date;
    minDate?: string | Date;
    popperPlacement?: any;
    dateFormat?: string;
    minLength?: number;
    maxLength?: number;
    pattern?: string;
    openToDate?: string | Date;
    showMonthDropdown?: boolean;
    showYearDropdown?: boolean;
    inline?: boolean;
    selected?: any;
    onChange?: any;
    selectedDate?: Date | null;
    availableDates?: Date[] | null;
    availableTimeSlots?: {[key: string]: any}[] | null;
    checkboxLabel?: string;
    labelStyles?: string;
    isCheckboxLabelRaw?: boolean;
    isCheckboxReversed?: boolean;
    checked?: boolean;
    stripePublicKey?: any;
    stripeAccount?: string;
    tooltipText?: string;
    currency?: CurrencyType;
    recommendationsDates?: Date[] | null;
    eventDates?: Date[] | null;
    buttonName?: string;
    defaultStyles?: string;
    inputStyles?: string;
    wrapperStyles?: string;
    defaultContainerStyles?: string;
    containerStyles?: string;
    btnText?: string;
    customClickHandler?: ButtonClickHandler;
    buttonType?: FormButtonType;
    buttonDisabled?:
        | boolean
        | FormButtonDisabledStrategy
        | ((mappedOutputValue: any, isFormValid: boolean, isFormTouched: boolean) => void);
    disabledStyles?: string;
    enabledStyles?: string;
    btnInnerStyles?: string;
    btnPreIconStyles?: string;
    btnPostIconStyles?: string;
    stepValue?: number;
    isDisplayValueTranslated?: boolean;
    maxRangeStep?: number;
    isRangeLabelTranslated?: boolean;
    isMultiRangeControl?: boolean;
    onCopy?: () => any;
    onPaste?: () => any;

    onDateChange?: any;
    onMonthChange?: any;
    handleRadioChange?: (e: any) => void;

    daysNumberDisplay?: number;
    startDate?: Date;
    availableConsultationSlots?: {[key: string]: any}[] | null;
    timeSlotsType?: TimeSlotsType;
    slotsNumberDisplayed?: number;

    rangeStep?: number;
    rangeMinValue?: number;
    rangeMaxValue?: number;
    rangeValueUnit?: string;
    autocomplete?: string;
    customIncrementButtons?: boolean;
    step?: number;

    maxValue?: number;
    minValue?: number;

    dateRangePlacement?: any;
    dateRangesConfig?: any[];
    defaultStartValue?: Date;
    defaultEndValue?: Date;

    rangeLabels?: {[key: string]: any};
    labelFormat?: (value: number) => string;
    useWholePathAsName?: boolean;

    onFileChange?: (imageId: string, imageFile: FileType) => void;
    isFileRemovable?: boolean;
    acceptedFileExtension?: string;
    iconSize?: number;

    btnHasTooltip?: boolean;
    btnTooltipText?: string;
    buttonPreIcon?: any;
    buttonPostIcon?: any;
    isDefaultValueController?: boolean;

    technologyTools?: IModelDictionaryDatum[] | null;
    technologies?: IModelDictionaryDatum[] | null;
    seniorityLevels?: {[key: number]: IRangePickerOption};

    maxTags?: number;
    tagsAutocompleteList?: ITagAutocompleteItem[] | [];
} & IBaseFormConfig;

export type IGroupedMultiSelectConfig = {
    label: DateRangePickerPlacement;
    options: IMultiselectOption[];
};

export type IMultiselectOption = {
    value: string;
    label: string;
};

export type IGroupedMultiselectOption = {
    label: string;
    options: IMultiselectOption[] | [];
};

export type IRangePickerOption = {
    label: string;
    level: number;
    value: string;
};

export type IBaseFormControlProps = {
    config: IFormConfig | IFormGroupConfig | IFormControlConfig;

    controlName: string;
    value: any;
    key?: string;
    formControlName?: string;

    onValidationStateChange?: (controlName: string, isValid: boolean, errorMessages: ReadonlyArray<string>) => void;
    onTouchedStateChange?: (controlName: string, touched: boolean) => void;
    onValueStateChange?: (controlName: string, value: any, changeType: FormControlChangeType) => void;
    onButtonClicked?: ButtonClickHandler;
};

export type IFormConfig = {
    controlType: 'form' | 'fieldset';
    fieldsetTitle?: string;
} & IBaseGroupedFormConfig;

export type IBaseGroupedFormControlProps = {
    config: IFormConfig | IFormGroupConfig;
} & IBaseFormControlProps;

export type IBaseGroupedFormControlState = {
    childValidationState: {[key: string]: boolean};
    mappedOutputValue: {[key: string]: any}; // output
} & IBaseFormControlState;

export type IBaseInternalFormControlProps = {
    handleChange: ChangeHandler;
    handleMultiselectChange?: MultiselectChangeHandler;
    submitTouched: boolean;
    onButtonClicked?: ButtonClickHandler;
    formId: string;
    controlPath: string;
};

export type IBaseFormControlState = {
    valid: boolean;
    touched: boolean;
    value: any; // input
    errorMessages: string[];
};

export type IFormProps = {
    config: IFormConfig;
    submitForm?: (event: any, mappedOutputValue: any, isFormValid: boolean, isFormTouched: boolean) => void;
} & IBaseGroupedFormControlProps;

export type IFormState = {
    submitted: boolean;
    formId: string;
} & IBaseGroupedFormControlState;

export type IFormControlsGroupProps = {
    config: IFormGroupConfig;
} & IBaseGroupedFormControlProps &
    IBaseInternalFormControlProps;

export type IFormControlsGroupState = {} & IBaseGroupedFormControlState;

export type IFormControlProps = {
    config: IFormControlConfig;
    controlName: string;
} & IBaseFormControlProps &
    IBaseInternalFormControlProps &
    WithTranslation;

export type IFormControlState = {} & IBaseFormControlState;

export type ICalendarProps = {
    onChange: any;
    selectedDate: any;
    availableDates: Date[] | null;
    availableTimeSlots: {[key: string]: any}[] | null;
    placeholder: string;
    checkboxSlots: boolean;
    isDateFiltered?: boolean;
    isDayDisabled?: boolean;
    isLabelHidden?: boolean;
    maxDate?: string | Date;
};

export type IDatePickerInputProps = {
    value: string;
    name: string;
    id: string;
    placeholderText: string;
    disabledKeyboardNavigation: boolean;
    handleChange: (e: any) => void;
    inputStyles?: string;
    disabled?: boolean;
    maxDate?: string | Date;
    minDate?: string | Date;
    openToDate?: string | Date;
    minLength?: number;
    maxLength?: number;
    pattern?: string;
    onChange: (e: any) => void;
    onChangeRaw: (e: any) => void;
    onSelect: (e: any) => void;
    showMonthDropdown?: boolean;
    showYearDropdown?: boolean;
    inline?: boolean;
    dateFormat?: string;
    popperPlacement?: any;
    isTimePicker?: boolean;
};

export type IDateRangePickerProps = {
    onChange: any;
    name: string;
    value?: any;
    placeholder?: string;
    placement?: DateRangePickerPlacement;
    ranges?: any[];
    defaultStartValue?: Date;
    defaultEndValue?: Date;
    defaultValue?: any;
    renderValue?: any;
} & WithTranslation;

export type IDateRangePickerState = {
    defaultDateValue: any;
};

export type ICalendarState = {
    availableDates: Date[] | null;
    selectedDay: Date | null;
};

export type ICalendarTimeSlotsProps = {
    onChange: any;
    selectedDate: any;
    availableTimeSlots: {[key: string]: any}[] | null;
    placeholder: string;
    checkboxSlots: boolean;
    isLabelHidden?: boolean;
    expandedSlotsShown?: boolean;
    timeSlotsType?: TimeSlotsType;
    slotsNumberDisplayed?: number;
};

export type ICalendarTimeSlotsState = {
    selectedDate: string | null;
};

export type ICheckboxProps = {
    name: string;
    handleChange: ChangeHandler;
    inputStyles?: string;
    labelStyles?: string;
    checked: boolean;
    value?: any;
    disabled?: boolean;
    label?: string;
    isLabelRaw?: boolean;
    isLabelTranslated?: boolean;
    additionalStyles?: any;
    isCheckboxReversed?: boolean;
};

export type ICheckboxState = {
    checked: boolean;
};

export type IEventCalendarProps = {
    onChange: any;
    selectedDate: Date | null;
    eventDates: any[] | null;
    placeholder: string;
    minDate?: string | Date;
    maxDate?: string | Date;
};

export type IEventCalendarState = {
    availableDates: Date[] | null;
    selectedDate: Date | null;
    selectedDay: Date | null;
};

export type IFormButtonProps = {
    name: string;
    btnText: string;
    buttonType?: FormButtonType;
    defaultInputStyles?: string;
    inputStyles?: string;
    defaultContainerStyles?: string;
    containerStyles?: string;
    disabled?: boolean | FormButtonDisabledStrategy | ((mappedOutputValue: any, isFormValid: boolean, isFormTouched: boolean) => void);
    disabledStyles?: string;
    enabledStyles?: string;
    onButtonClicked?: ButtonClickHandler;
    customClickHandler?: ButtonClickHandler;
    innerStyles?: string;
    preIconStyles?: string;
    postIconStyles?: string;
    btnHasTooltip?: boolean;
    btnTooltipText?: string;
    buttonPreIcon?: any;
    buttonPostIcon?: any;
    formId: string;
};

export type IFormButtonState = {
    mappedOutputValue: any;
    isFormValid: boolean;
    isFormTouched: boolean;
    disabled?: boolean;
};

export type IInputProps = {
    value: any;
    name: string;
    type: InputType;
    placeholder: string;
    handleChange: ChangeHandler;
    inputStyles?: string;
    disabled?: boolean;
    maxDate?: string | Date;
    minDate?: string | Date;
    minLength?: number;
    maxLength?: number;
    pattern?: string;
    step?: number;
    currency?: CurrencyType;
    isCurrencyInput?: boolean;
    autocomplete?: string;
    customIncrementButtons?: boolean;
    isDefaultValueController?: boolean;
    renderPreIcon?: () => any;
    onCopy?: () => any;
    onPaste?: () => any;
};

export type IMultiSelectProps = {
    multiselectType: MultiSelectType;
    handleChange?: MultiselectChangeHandler;
    value?: IMultiselectOption[] | {[key: string]: any};
    options: IMultiselectOption[] | IGroupedMultiSelectConfig[] | (() => IMultiselectOption[] | IGroupedMultiSelectConfig[]);
    name: string;
    isClearable?: boolean;
    isDisabled?: boolean;
    placeholder?: string;
    groupedOptions?: IGroupedMultiSelectConfig[];
    menuPlacement?: MultiselectMenuPlacement;
    isCurrencySelect?: boolean;
    control?: IFormControlConfig;
    isComponentCustom?: boolean;
    isCustomLogoOption?: boolean;
    isCustomMultiValueContainer?: boolean;
    isGroupedComponentCustom?: boolean;
    openMenuOnClick?: boolean;
    hideValueOnFocus?: boolean;
    handleInputChange?: any;
    isGroupedLabelTranslated?: boolean;
    isSearchable?: boolean;
};

export type IMultiSelectState = {
    value: string | null;
    valueHidden: boolean;
};

export type IRadioProps = {
    value: any;
    name: string;
    placeholder: string;
    handleChange: ChangeHandler;
    options: any[];
    inputStyles?: string;
    wrapperStyles?: string;
    disabled?: boolean;
    isDisplayValueTranslated?: boolean;
};

export type ISelectProps = {
    value: any;
    name: string;
    placeholder: string;
    handleChange: ChangeHandler;
    options: any[] | (() => any[]);
    inputStyles?: string;
    disabled?: boolean;
    firstOptionValue?: string;
};

export type ISwitchProps = {
    name: string;
    handleChange: ChangeHandler;
    inputStyles?: string;
    checked: boolean;
    disabled?: boolean;
};

export type ISwitchState = {
    isChecked: boolean;
};

export type ITextareaProps = {
    name: string;
    value: string;
    placeholder: string;
    handleChange: ChangeHandler;
    inputStyles: string;
    cols?: number;
    rows?: number;
    minLength?: number;
    maxLength?: number;
    disabled: boolean;
};

export type ITechnologyControlDisplayValueItem = {
    id: string;
    label: string;
    level: number;
};

export type ISelectedTechnologyToolItem = {
    technologyToolId: string;
    seniorityId: string;
    id?: string;
};

export type ISelectedTechnologyItem = {
    technologyId: string;
    seniorityId: string;
};

export type ITechnologyControlValue = {
    technologyTools: ISelectedTechnologyToolItem[] | null;
    technologies: ISelectedTechnologyItem[] | null;
};

export type ITechnologyControlProps = {
    readonly label: string;
    readonly value: ITechnologyControlValue | null;
    readonly name: string;
    readonly handleChange: (value: ITechnologyControlValue) => void;
    readonly technologyTools: IModelDictionaryDatum[] | null;
    readonly technologies: IModelDictionaryDatum[] | null;
    readonly seniorityLevels: {[key: number]: IRangePickerOption};
};

export type ITechnologyControlState = {
    controlValue: ITechnologyControlDisplayValueItem[] | null;
    value: ITechnologyControlValue | null;
    isModalVisible: boolean;
};

export type IAddTechnologySkillProps = {
    readonly value: ITechnologyControlValue | null;
    readonly isModalVisible: boolean;
    readonly technologyTools: IModelDictionaryDatum[] | null;
    readonly technologies: IModelDictionaryDatum[] | null;
    readonly seniorityLevels: {[key: number]: IRangePickerOption};
    readonly toggleModal: () => void;
    readonly onAddValue: (value: {[key: string]: any}) => void;
} & WithTranslation;

export type IAddTechnologySkillState = {
    formValue: any;
    formConfig: IFormConfig | null;
};

export type ITagsInputControlProps = {
    value: string[] | [];
    placeholder: string;
    handleChange: any;
    name: string;
    maxTags?: number;
} & WithTranslation;

export type ITagAutocompleteItem = {
    id: string;
    name: string;
};

export type ITagsInputAutocompleteControlProps = {
    value: string[] | [];
    placeholder: string;
    handleChange: any;
    name: string;
    autocompleteList: ITagAutocompleteItem[];
} & WithTranslation;

export type ITimelineItem = {
    title: string;
    content: string;
    color: string;
    className?: string;
    isTimelineComplete?: boolean;
    timelineLink?: string;
    meta?: string;
    metaIcon?: boolean;
    metaClassName?: string;
    customContent?: React.ReactElement;
    icon?: React.ReactElement;
    activeTab?: number;
    linkClassName?: string;
};

export type ITimelineProps = {
    data: ITimelineItem[];
    className?: string;
} & WithTranslation;

export type IBreadcrumbsItemPath = {
    labelKey: string;
    path: string;
    icon?: string;
    isLinkExternal?: boolean;
    isItemActive?: boolean;
};

export type IBreadcrumbsProps = {
    breadcrumbs: IBreadcrumbsItemPath[];
};

export interface IBlockerEvent {
    startsAt: Date;
    endsAt: Date;
    isFree: boolean;
}

export type IWeekCalendarProps = {
    daysNumberDisplay: number;
    startDate?: Date; // string | Date;
    onChange: any;
    availableConsultationSlots: {[key: string]: any} | null;
    selectedDate?: Date;
    placeholder?: string;
    checkboxSlots?: any;
    isLabelHidden?: boolean;
    timeSlotsType?: TimeSlotsType;
    slotsNumberDisplayed?: number;
};

export type IWeekCalendarState = {
    value: any;
    isLoading: boolean;
    calendarStartDate: Date;
    selectedDatesList: Date[] | null;
    selectedDate: Date | null;
    availableDates: any | null;
    timeSlots: {[key: string]: any}[] | null;
    expandedSlotsShown: boolean;
};

export type IPaginationProps = {
    listMetadata: {[key: string]: any} | null;
    changePage: any;
    itemsPerPage?: number;
    currentPage?: number;
};

export type IPaginationState = {
    selectedItemsPerPage: number;
    selectedPage: number;
    pageCount: number;
};

export type IRangeControlProps = {
    step: number;
    minValue: number;
    maxValue: number;
    valueUnit: string;
    onChange: any;
    label: string;
    value: any;
    defaultValue?: number[];
};

export type IRangeControlState = {
    values: number[];
};

export type IRangePickerProps = {
    labels: {[key: string]: any};
    onChange: any;
    value: any;
    defaultValue?: string;
    labelFormat?: (value: number) => string;
    disabled?: boolean;
    maxRangeStep?: number;
    isRangeLabelTranslated?: boolean;
    isMultiRangeControl?: boolean;
} & WithTranslation;

export type IRangePickerState = {
    value: string[] | null;
};

export type IQuantityInputProps = {
    name: string;
    handleChange: any;
    value?: number;
    maxValue?: number;
    minValue?: number;
    inputStyles?: string;
    disabled?: boolean;
    stepValue?: number;
};

export type ITooltipProps = {
    target: string;
    tooltipText: string;
    placement?: TooltipPlacement;
};

export type ITooltipState = {
    isOpen: boolean;
};

export type IQuantityInputState = {};

export type LegalRestQueryParamValue = string | number | boolean | null;

export type IRawRestQueryParams = {path: string; val: LegalRestQueryParamValue}[];

export interface IControlConfigUpdateStrategy {
    process(control: IFormControlConfig, value: any, targetValue: any): void;
}

export type IPriceProps = {
    readonly price?: IPrice | null;
    readonly amountComponent?: (amount: number) => ReactNode;
    readonly currencyComponent?: (code: string) => ReactNode;
    readonly separateWithNbsp?: boolean;
};

export type IPriceState = {};

export type IItemDescriptionProps = {
    description: string | null;
    descriptionLength?: number;
};

export type IItemDescriptionState = {
    isDescriptionShortened: boolean;
};

export type IHtmlParserComponentProps = {
    htmlContent: string;
};

export type FileType = {
    name?: string;
    fileKey?: number | string;
    blobFile?: File;
    status?: 'inited' | 'uploading' | 'error' | 'finished';
    progress?: number;
    url?: string;
};

export type IFileUploaderProps = {
    readonly authToken: string;
    readonly addAlert: typeof addAlert;
    readonly onImageChange: (imageId: string, imageFile: FileType) => void;
    readonly label?: string;
    readonly isFileRemovable?: boolean;
    readonly onFileChange?: (file: FileType[]) => void;
    readonly onFileUpload?: (file: any) => void;
    readonly isDragDropHidden?: boolean;
    readonly onUpload?: () => void;
    readonly acceptedFileExtension?: string;
    readonly isMultiple?: boolean;
    readonly defaultFileValue?: IFileOutput | null;
    readonly iconSize?: number;
};

export type IFileUploaderState = {
    readonly value: any[];
    readonly selectedFile: string | Blob | null;
    readonly isProcessing: boolean;
    readonly inputFileUploaded: boolean;
    readonly progressValue: number | null;
};

export type IDateComponentProps = {
    date: (string | number | Date | moment.Moment) & ReactNode;
    activeLanguage: LanguageLocale;
    interval?: number;
    locale?: string;
    format?: string;
};

export type WithLocation = {
    location: {
        hash: string;
        key: string;
        pathname: string;
        search: string;
        state: {[key: string]: any} | null;
    };
};

export type WithNavigate = {
    navigate: (to: string, options?: {replace?: boolean; state?: any}) => void;
};

export type WithParams = {
    params: {[key: string]: any};
};

export type WithRouterWrapperProps = {
    router: {
        params: {[key: string]: any};
        navigate: (to: string, options?: {replace?: boolean; state?: any}) => void;
        location: {
            hash: string;
            key: string;
            pathname: string;
            search: string;
            state: {[key: string]: any} | null;
        };
    };
};

export type IDeviceSelectProps = {
    data: any;
    onSelect: any;
    label: string;
    isDisabled?: boolean;
} & WithTranslation;

export type IDeviceSelectState = {
    items: any[];
    value: {[key: string]: any} | null;
};

export type IProgressBarProps = {
    completed: any;
};

export type IVideoSettingsProps = {
    changeAudioInputDevice: (id: string) => void;
    changeAudioOutputDevice: (id: string) => void;
    changeVideoInputDevice: (id: string) => void;
    isModalVisible: boolean;
    toggleModal: () => void;
};

export type IVideoSettingsState = {
    cameraDevices: any[];
    speakerDevices: any[];
    microphoneDevices: any[];
    selectedAudioInputId: string | null;
    selectedAudioOutputId: string | null;
    selectedVideoInputId: string | null;
    microphoneAudioLevel: number | undefined;
};

export type IBasicModalProps = {
    isModalShown: boolean;
    closeModal: any;
    children?: any;
    isModalLocal?: boolean;
    isModalCustom?: boolean;
    wrapperInlineStyles?: React.CSSProperties;
};

export type IAgoraVideoCallProps = {
    userSecret: string | null;
    consultation: {[key: string]: any} | null;
    changeScreenMode: (isFullScreen: boolean) => void;
    changeOnlineConsultationStatus: (status: ConsultationStatus) => void;
    audioInputDevice: string | null;
    audioOutputDevice: string | null;
    videoInputDevice: string | null;
    appId: string;
    transcode: string;
    attendeeMode: string;
    attendeeRole: UserRole | null;
    videoProfile: string;
    baseMode: string;
    consultationStartTime: string;
    channel: string | null;
    uid: string | null;
    token: string | null;
};

export type IAgoraVideoCallState = {
    displayMode: string;
    streamList: any[];
    readyState: boolean;
    isFullScreen: boolean;
    isConsultationFinished: boolean;
    isMicEnabled: boolean;
    isCameraEnabled: boolean;
    windowWidth: number;
    localStreamId: string | null;
    isFullScreenMode: boolean;
    isSettingsModalShown: boolean;
};

export type FileStreamType = {
    file?: string;
    done?: boolean;
    fileName?: string;
    fullFile?: File;
};

//chat types
export class ChatMessage {
    from: string;
    to: string;
    date: Date;
    messageType: MessageContentType;
    messageContent?: string;
    messageMedia?: any;
    messageId?: string;
    messagePreview?: string;
    constructor({from, to, messageType, messageContent, messageId}) {
        this.from = from || '';
        this.to = to || '';
        this.messageType = messageType || '';
        this.messageContent = messageContent || '';
        this.date = new Date();
        this.messageId = messageId;
    }
}

export type PeerConnection = {
    biggestPageNumber: number;
    peerIsOnline: boolean;
    connection: RTCPeerConnection | null;
    channelId: string;
    messagesBuffer: ([ChatMessage, string] | string)[];
    messages: ChatMessage[];
    unseenMessages: number;
    channel: RTCDataChannel | null;
    peerIsPolite: boolean;
    makingOffer: boolean;
    connectionAttemptNumber: number;
    totalMessagesNumber: number;
};

export type ChatServerMessagePayload = {
    toAccountId: string;
    mediaObjectId: string | null;
    content: string;
};

export type AllUserConnections = {
    [roomId: string]: PeerConnection;
};

export type ChatContact = {
    roomId: string;
    accountId: string;
    firstName: string;
    lastName: string;
    unreadMessagesCount: number;
    avatarUrl?: string;
    organisationId?: string;
    organisationName?: string;
};

export type Notification = {
    content: string;
    reminderId: string;
    disabled: boolean;
    sendAtDateTime: string;
};

export type NotificationMessage = {
    repeat: boolean;
    targets: NotificationMessageTarget[];
    owners: string[];
};

export type NotificationMessageTarget = {
    account: string;
    sendAtDateTime: string;
    content: {
        fromUserId: string;
        rawMessage: string;
    };
};

export type ToggleNotificationPayload = {
    notificationsToSwitch: [
        {
            disabled: boolean;
            disabledUntil?: string;
            notificationId: string;
        }
    ];
};

export type ChatServerMessageConfirmPayload = {
    messageId: string;
    receivedAt: string;
};

export type SignalAnswer = {
    toRoom: string;
    answer: RTCSessionDescription;
};

export type SignalOffer = {
    toRoom: string;
    offer: RTCSessionDescription;
};

export type SignalCandidate = {
    toRoom?: string;
    candidate: RTCIceCandidate;
};

export type IChatProps = {
    peerConnections: AllUserConnections;
    addMessage: (message: ChatMessage | string, room: string) => void;
    setSelectedChatRoomId: (roomId: string) => void;
    selectedChatRoomId: string;
    isConnected: boolean;
    userName: string;
    userId: string;
    accountId: string;
    hasUnreadMessages: string[];
    showOnlineIndicator?: boolean;
    chatContactsList?: ChatContact[];
    errors: Subject<Error>;

    errorHandler?: (errorMessage: string) => any;
    saveMessage?: (message: ChatServerMessagePayload) => Observable<any>;
    getNotification?: (accountId: string) => Observable<Notification>;
    saveNotification?: (notification: NotificationMessage) => Observable<any>;
    toggleNotification?: (payload: ToggleNotificationPayload) => Observable<any>;
    editNotification?: (payload: NotificationMessage, notificationId: string) => Observable<any>;
    clearNotification?: (notificationId: string) => Observable<any>;

    saveFile?: (file: any) => Promise<any>;
    downloadFile?: (url: string) => Promise<any>;
};

export type IChatState = {
    nodeErrors: any;
    isLoading: boolean;
    error: string | null;
};

export type IChatActionsProps = {
    addFile: (data: FileStreamType) => void;
    error: string;
    connection: PeerConnection | null;
    errorModuleHandle: (errorMessage: string) => void;
} & WithTranslation &
    IBaseChatProps;

export type IChatActionsState = {
    isEmojiShown: boolean;
    messageInput: string;
    imgUrl: string | null;
    isDeleteModalOpen: boolean;
    file: File | null;
    currentChunk: number;
    sendingFile: boolean;
};

export type IChatHeaderProps = {
    readonly peerUserName: string;
    readonly showOnlineIndicator?: boolean;
};

export type IChatListProps = {
    messages: ChatMessage[];
    userId: string;
    addFile: (data: FileStreamType) => void;
    error: string;
    errorModuleHandle: (errorMessage: string) => void;
    downloadFile?: (url: string) => Promise<any>;
    connection: PeerConnection | null;
} & IBaseChatProps;

export type IChatListItemMenuProps = {
    readonly message: ChatMessage;
    readonly contentType: ChatType;
    readonly messageType?: MessageContentType;
    readonly downloadFile?: (url: string) => Promise<any>;
    readonly errorModuleHandle: (errorMessage: string) => void;
};

export type IChatListItemProps = {
    readonly message: any;
    readonly type: MessageContentType;
    readonly downloadFile?: (url: string) => Promise<any>;
    readonly errorModuleHandle: (errorMessage: string) => void;
};

export type IBaseChatProps = {
    readonly addMessage: (comment: string) => void;
    readonly addFile: (data: FileStreamType) => void;
};

export type IChatViewProps = {
    readonly messages: ChatMessage[];
    readonly connection: PeerConnection | null;
    readonly userId: string;
    readonly userName: string;
    readonly error: string;
    readonly errorModuleHandle: (errorMessage: string) => void;
    readonly showOnlineIndicator?: boolean;
    readonly downloadFile?: (url: string) => Promise<any>;
} & WithTranslation &
    IBaseChatProps;

export type IChatHostProps = {
    connection: PeerConnection | null;
    selectedChat: string | null;
    addMessage: (message: ChatMessage | string, room: string) => void;
    error: string;
    errorModuleHandle: (errorMessage: string) => void;
    userId: string;
    userName: string;
    chatContactId: string;
    showOnlineIndicator?: boolean;
    errorHandler?: (errorMessage: string) => any;
    saveMessage?: (message: ChatServerMessagePayload) => Observable<any>;
    saveFile?: (file: any) => Promise<any>;
    downloadFile?: (url: string) => Promise<any>;
} & WithTranslation;

export type ICounterProps = {
    onlineConsultation: {[key: string]: any};
    userRole: UserRole;
    endConsultation: () => void;
    userSecret: string | null;
};

export type ICounterState = {
    count: number;
    isConsultationExceeded: boolean;
};

export type IUpcomingCallProps = {
    consultationTime: string;
    attendeeRole: UserRole | null;
    consultationId: string | null;
    changeConsultationStatus: (status: ConsultationStatus) => void;
    authToken: string | null;
    participantAgoraToken?: string | null;
};

export type IVideoChatProps = {
    error: string | null;
    isFullScreenMode: boolean;
    authToken: string | null;
    isAuthenticated: boolean;
    changeOnlineConsultationStatus: (status: ConsultationStatus) => void;
    onlineConsultation: {[key: string]: any};
    userSecret: string | null;
    userId: string | null;
    userToken: string | null;
    userRole: UserRole;
    consultationStatus: ConsultationStatus;
    participantAgoraToken?: string | null;
}; // & RouteComponentProps;

export type IVideoChatState = {
    isLoading: boolean;
    participantsPresent: boolean;
    shouldConsultationStart: boolean;
    contacts: any;
};

export type IWizardStep = {
    id: string;
    title: string;
    subtitle: string;
    icon: any;
    content: any;
    className?: string;
};

export type IWizardProps = {
    steps: IWizardStep[];
    type?: string;
    instance?: any;
    options?: {[key: string]: any};
    className?: string;
    separator?: any;
    headerClassName?: string;
    contentClassName?: string;
    activeTab?: number;
};

export type IWizardState = {
    activeIndex: number;
};

export type IApexRadialbarProps = {
    item: {
        value: number;
        series: number[];
    };
    chart?: {
        type?: ApexRadialbarType;
        height?: number;
        width?: number;
    };
};

export type IMapProps = {
    isMarkerShown: boolean;
    position: {lat: number; lng: number};
    googleMapURL: string;
    loadingElement: ReactElement<any>;
    containerElement: ReactElement<any>;
    mapElement: ReactElement<any>;
};

export type INavBarItem = {
    id: string;
    target: string;
    isBookmarked: boolean;
    title: string;
    icon: string;
    link: string;
    isIconOnly?: boolean;
    isHashLink?: boolean;
};

export type IUserMenuItem = {
    id: string;
    title?: string;
    icon?: string;
    action?: () => void;
    link?: string;
    isDivider?: boolean;
};

export type INavbarNavigationProps = {
    navigationMenu: INavBarItem[];
    isTooltipVisible: boolean;
    toggleMobileMenu: () => void;
};

export type INavbarUserProps = {
    account: {[key: string]: any} | null;
    userMenu: (action?: () => void) => IUserMenuItem[];
    unauthorizedUserMenu: IUserMenuItem[];
    isAuthenticated: boolean;
    customDropdownMenuClassName?: string;
};

export type IUserDropdownProps = {
    account: {[key: string]: any} | null;
    isAuthenticated: boolean;
    userMenu: (action?: () => void) => IUserMenuItem[];
    unauthorizedUserMenu: IUserMenuItem[];
    customDropdownMenuClassName?: string;
};

export type INavbarProps = {
    navigationMenu: INavBarItem[];
    account: {[key: string]: any} | null;
    isAuthenticated: boolean;
    userMenu: (action?: () => void) => IUserMenuItem[];
    unauthorizedUserMenu: IUserMenuItem[];
    toggleMobileMenu: () => void;
    customComponent?: ReactElement<any>;
    customDropdownMenuClassName?: string;
};

export type IAccordionItem = {
    title: string;
    description: string;
    icon: string;
};

export type IAccordionComponentProps = {
    items: IAccordionItem[];
};

export type IRatingComponentProps = {
    id: string;
    start?: number;
    stop?: number;
    step?: number;
    initialRating?: number;
    placeholderRating?: number;
    readonly?: boolean;
    quiet?: boolean;
    fractions?: number;
    direction?: string;
    emptySymbol?: any;
    fullSymbol?: any;
    placeholderSymbol?: any;
    onHover?: (value?: number) => void;
    onClick?: (value: number) => void;
    onChange?: (value: number) => void;
    className?: string;
    style?: any;
    tabIndex?: number;
    'aria-label'?: string;
};

export type IRatingComponentState = {
    value: number;
};

export type IRatingSymbolProps = {
    index: number;
    readonly: boolean;
    inactiveIcon: any;
    activeIcon: any;
    percent: number;
    direction: string;
    onClick?: (index: number, e: any) => void;
    onMouseMove?: (index: number, e: any) => void;
    onTouchMove?: (index: number, e: any) => void;
    onTouchEnd?: (index: number, e: any) => void;
};

export type IRatingProps = {
    totalSymbols: number;
    value: number; // Always >= 0
    placeholderValue: number;
    readonly: boolean;
    quiet: boolean;
    fractions: number;
    direction: string;
    emptySymbol: any;
    fullSymbol: any;
    placeholderSymbol: any;
    onClick: (value: number, event: any) => void;
    onHover: (value?: number) => void;
    className?: string;
    id: string;
    tabIndex: number;
    'aria-label'?: string;
};

export type IRatingState = {
    displayValue: number;
    interacting: boolean; // Indicates if the user is currently hovering over the rating element
};

export type IListItemDetailsProps = {
    avatar: IFileOutput | null;
    name: string;
    description?: string;
    iconStyles?: string;
};

export type IAuthPanelProps = {
    readonly userRole: UserRole;
    readonly formConfig: any;
    readonly envData: any;
    readonly initAuthTokenChange: typeof initAuthTokenChange;
} & WithRouterWrapperProps;
