import React from 'react';
import {IInputProps} from '../../../types';
import Translation from '../../Translation';
import {InputType} from '../index';
import moment from 'moment';
import {isNotNullOrUndefined} from '../../..';

type Props = IInputProps;

class InputBasic extends React.Component<Props> {
    render() {
        return this.renderNumberInput();
    }

    private renderNumberInput = () => {
        if (!this.props.customIncrementButtons) {
            return (
                <React.Fragment>
                    {isNotNullOrUndefined(this.props.renderPreIcon) ? (
                        <div className="input-pre-icon">{this.props.renderPreIcon()}</div>
                    ) : null}

                    <input
                        type={this.props.type}
                        className={`${this.props.inputStyles} input`}
                        name={this.props.name}
                        value={this.props.value || ''}
                        placeholder={this.props.placeholder}
                        onChange={this.props.handleChange}
                        disabled={this.props.disabled}
                        min={this.getFormattedMinMax(this.props.minDate)}
                        max={this.getFormattedMinMax(this.props.maxDate)}
                        minLength={this.props.minLength}
                        maxLength={this.props.maxLength}
                        pattern={this.props.pattern}
                        step={this.props.step}
                        autoComplete={this.props.autocomplete}
                        onPaste={this.props.onPaste}
                        onCopy={this.props.onCopy}
                    />
                    {this.props.type === InputType.NUMBER && !this.props.isCurrencyInput && !this.props.isDefaultValueController ? (
                        <span>
                            <span />
                        </span>
                    ) : null}
                </React.Fragment>
            );
        } else {
            return (
                <div className="custom-number-input-wrapper">
                    <button
                        className="value-handler-button decrement"
                        onClick={(event: any) => event.target.parentNode.querySelector('input[type=number]').stepDown()}
                        type="button">
                        <span className="sr-only">
                            <Translation text={'button.srDecrementButton'} />
                        </span>
                    </button>
                    <input
                        type={this.props.type}
                        className={`${this.props.inputStyles} custom-number-input`}
                        name={this.props.name}
                        value={this.props.value || ''}
                        placeholder={this.props.placeholder}
                        onChange={this.props.handleChange}
                        disabled={this.props.disabled}
                        min={this.getFormattedMinMax(this.props.minDate)}
                        max={this.getFormattedMinMax(this.props.maxDate)}
                        minLength={this.props.minLength}
                        maxLength={this.props.maxLength}
                        pattern={this.props.pattern}
                        autoComplete={this.props.autocomplete}
                    />
                    <button
                        className="value-handler-button increment"
                        onClick={(event: any) => event.target.parentNode.querySelector('input[type=number]').stepUp()}
                        type="button">
                        <span className="sr-only">
                            <Translation text={'button.srIncrementButton'} />
                        </span>
                    </button>
                </div>
            );
        }
    };

    private getFormattedMinMax = (stringDate: string | Date) => {
        if (typeof stringDate === 'string') {
            return stringDate;
        }

        return moment(stringDate, 'YYYY-MM-DD').format();
    };
}

export default InputBasic;
