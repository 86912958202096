import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelDictionaryDatum} from '../../model/dictionaryDatum';
import {IBaseDictionaryState, IChangeIsInitialized, IChangeIsLoading, ISetError} from './workTypeSlice';

export interface IEmploymentTypeState extends IBaseDictionaryState {
    employmentTypes: IModelDictionaryDatum[] | null;
}

export interface IChangeEmploymentType {
    employmentTypes: IModelDictionaryDatum[] | null;
}

const initialState: IEmploymentTypeState = {
    employmentTypes: null,
    isLoading: false,
    isInitialized: false,
    error: null,
};

const employmentTypeSlice = createSlice({
    name: 'employmentType',
    initialState: initialState,
    reducers: {
        changeEmploymentType: {
            reducer: (state: IEmploymentTypeState, action: PayloadAction<IChangeEmploymentType>) => {
                return {
                    employmentTypes: action.payload.employmentTypes,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(employmentTypes: IModelDictionaryDatum[] | null) {
                return {
                    payload: {employmentTypes: employmentTypes},
                };
            },
        },
        changeIsEmploymentTypeLoading: {
            reducer: (state: IEmploymentTypeState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    employmentTypes: state.employmentTypes,
                    isLoading: action.payload.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        changeIsEmploymentTypeInitialized: {
            reducer: (state: IEmploymentTypeState, action: PayloadAction<IChangeIsInitialized>) => {
                return {
                    employmentTypes: state.employmentTypes,
                    isLoading: state.isLoading,
                    isInitialized: action.payload.isInitialized,
                    error: state.error,
                };
            },
            prepare(isInitialized: boolean) {
                return {
                    payload: {isInitialized: isInitialized},
                };
            },
        },
        setEmploymentTypeError: {
            reducer: (state: IEmploymentTypeState, action: PayloadAction<ISetError>) => {
                return {
                    employmentTypes: state.employmentTypes,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
        fetchEmploymentTypes: (state: IEmploymentTypeState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
    },
});

export const {
    changeEmploymentType,
    changeIsEmploymentTypeLoading,
    changeIsEmploymentTypeInitialized,
    setEmploymentTypeError,
    fetchEmploymentTypes,
} = employmentTypeSlice.actions;

export default employmentTypeSlice.reducer;
