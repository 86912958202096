import {Observable} from 'rxjs';
import {jobHunterAPI} from './provider/jobHunterAPI';
import {RestQueryParams} from './base/queryParams';

export interface IAddRelocationEventPayload {
    startsAt: string;
    endsAt: string;
    title: string;
    description: string;
    iconType: string;
}

export function addRelocationEventAPI(authToken: string, offerApplicationId: string, payload: IAddRelocationEventPayload): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }
    return jobHunterAPI.put(
        `api/offer_applications/${offerApplicationId}/create_relocation_event`,
        payload,
        new RestQueryParams(),
        headers
    );
}
