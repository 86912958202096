const pl = {
    navbar: {
        offers: 'Znajdź pracę',
        homepage: 'Strona główna',
        candidates: 'Dla kandydatów',
        companies: 'Dla firm',
        pricing: 'Cennik',
        marketplace: 'Marketplace',
        eu: 'Unia Europejska',
    },
    userMenu: {
        profile: 'Profil',
        settings: 'Ustawienia',
        logout: 'Wyloguj',
        candidateLogin: 'Zaloguj się jako kandydat',
        organisationLogin: 'Zaloguj się jako firma',
    },
    landingPage: {
        title: 'Najlepsza odpowiedź na potrzeby rynku pracy. Najlepsi kandydaci zza granicy, zautomatyzowane procesy znajdowania idealnego pracownika.',
    },
    offers: {
        title: 'Znajdź nową pracę',
        description: 'Znajdź pracę w Polsce',
        form: {
            placeholders: {
                search: 'Szukaj',
            },
        },
        filters: {
            moreFilters: 'Więcej filtrów',
            lessFilters: 'Mniej filtrów',
            form: {
                placeholders: {
                    select: 'Wybierz',
                },
                labels: {
                    technology: 'Technologia',
                    seniority: 'Staż',
                    minSalary: 'Min. pensja',
                    bestMatch: 'Najlepsze dopasowanie',
                    contactType: 'Typ kontaktu',
                    industry: 'Branża',
                    companyType: 'Rodzaj firmy',
                    jobType: 'Rodzaj pracy',
                    employmentType: 'Rodzaj zatrudnienia',
                    workType: 'Rodzaj pracy',
                    withRelocation: 'Z możliwością relokacji',
                },
            },
        },
        table: {
            project: 'Projekt',
            seniority: 'Staż',
            salary: 'Pensja',
            remote: 'Zdalnie / w biurze',
            status: 'Status',
            actions: 'Akcje',
            tooltips: {
                observe: 'Obserwuj',
                unobserve: 'Usuń z obserwowanych',
                apply: 'Aplikuj',
                details: 'Detale',
                share: 'Udostępnij',
            },
            noOffers: 'There are no offers added',
            currency: 'EUR',
        },
        offerView: {
            labels: {
                seniority: 'Seniority',
                employees: 'Employees',
                relocation: 'Relocation',
                salary: 'Salary',
                industry: 'Industry',
                companyType: 'Company type',
                contractType: 'Contract type',
                employmentType: 'Employment type',
                jobType: 'Job type',
            },
            description: {
                technology: 'Tech stack',
                jobDescription: 'Job description',
                responsibilities: 'Your responsibilities',
                benefits: 'What we offer',
                preferenceTags: 'Preference tags',
            },
            applicationForm: {
                title: 'Apply',
                description: 'Lorem ipsum',
                labels: {
                    applicationAgreement: 'I confirm that I want to apply for this job.',
                    dataProcessingAgreement: 'I agree to the processing of my personal data for recruitment procedures',
                },
            },
            applicationFormLogin: {
                description: 'You must be logged in to apply for this job offer',
                form: {
                    labels: {
                        email: 'Email',
                        password: 'Password',
                    },
                },
                noAccount: 'New on our platform?',
                createAccount: 'Create an account',
            },
        },
    },
    candidate: {
        title: 'Chcesz zmienić swoje życie? Gotowy do drogi? Pracuj w Unii .',
        description:
            'Create your profile and let us find the best, most suitable job offers tailored to you and your preferences - free of charge.',
        descriptionSection: {
            title: 'Save 80% time on your employment process.',
            description:
                'Our unique one-stop-shop platform allows you to fully remotely and automatically source candidates on a vast array of ' +
                'foreign markets, conduct interviews, test potential candidates’ skills and not to worry about the relocation process.',
            selection: {
                title: 'Automatic selection',
                subtitle: "Don't waste time on browsing",
                description:
                    'Our matching algorithm will present you only the most suitable, available job offers meeting your skills, experience and expectations. ',
            },
            employee: {
                title: 'Employee dashboard',
                subtitle: 'Fully remote and automated recruitment process',
                description:
                    'Through your candidate profile you will receive proposals of offers, confirm your technology/language skills ' +
                    'via on-line tests, schedule and conduct on-line interviews with your new potential employers.   ',
            },
            relocation: {
                title: 'Relocation services',
                subtitle: 'Worried about moving? There is nothing to fear but fear itself and leave the rest for us',
                description:
                    'The process of relocation to other country might be quite complex and time consuming. Please let us take care of the whole process ' +
                    'from auditing your legal status through filling up all necessary documentation to helping you and your family finding the new home - a place to live and enjoy yourselves. \n' +
                    'What is more? Relocation services are provided free of charge, since covered by your employer.',
            },
        },
        registrationSection: {
            title: 'Create your profile and let us find the best, most suitable job offers tailored to you and your preferences - free of charge.',
        },
        questionSection: {
            subscription: {
                title: 'Does my subscription automatically renew?',
                description:
                    'Tiramisu marshmallow dessert halvah bonbon cake gingerbread. Jelly beans chocolate pie powder. ' +
                    'Dessert pudding chocolate cake bonbon bear claw cotton candy cheesecake. Biscuit fruitcake macaroon carrot cake. ' +
                    'Chocolate cake bear claw muffin chupa chips pudding.',
            },
            storage: {
                title: 'Can I store item on an intranet so everyone has access?',
                description:
                    'Tiramisu marshmallow dessert halvah bonbon cake gingerbread. Jelly beans chocolate pie powder. ' +
                    'Dessert pudding chocolate cake bonbon bear claw cotton candy cheesecake. Biscuit fruitcake macaroon carrot cake. ' +
                    'Chocolate cake bear claw muffin chupa chips pudding.',
            },
            purchasedItems: {
                title: 'Am I allowed to modify the item that I purchased?',
                description:
                    'Tiramisu marshmallow dessert halvah bonbon cake gingerbread. Jelly beans chocolate pie powder. ' +
                    'Dessert pudding chocolate cake bonbon bear claw cotton candy cheesecake. Biscuit fruitcake macaroon carrot cake. ' +
                    'Chocolate cake bear claw muffin chupa chips pudding.',
            },
        },
    },
    marketplace: {
        title: 'Find a service',
        description: 'find the service that fits your needs',
        form: {
            labels: {
                serviceType: 'Service type',
                salaryRange: 'Salary range',
            },
        },
        serviceDetails: {
            by: 'by',
            readMore: 'Read more',
            readLess: 'Read less',
        },
        buyService: {
            title: 'Payment',
            description: 'Add card for further billing',
            billingSummary: 'Billing summary',
        },
        purchaseComplete: {
            title: 'Thanks for purchase!',
            invoice: 'Invoice #',
            serviceName: 'Service name',
            subTotal: 'Subtotal',
            tax: 'Tax',
            total: 'Total',
            details: 'Your details',
            serviceTitle: 'Service title',
            companyName: 'Company name',
            noticeError: 'Notice something wrong?',
            report: 'let us know',
        },
        services: {
            title: 'My services',
            table: {
                headers: {
                    service: 'Service',
                    description: 'Description',
                    price: 'Price',
                    status: 'Status',
                    actions: 'Actions',
                },
                tooltips: {
                    delete: 'Delete',
                    details: 'Details',
                },
            },
            addService: {
                title: 'Add Service',
                description: 'The services added by you will appear in the marketplace after being accepted by the system administrator',
                form: {
                    placeholders: {
                        location: 'Location',
                        type: 'Type',
                        description: 'Service description',
                    },
                    labels: {
                        location: 'Location',
                        type: 'Type',
                        description: 'Description',
                        price: 'Price',
                        commission: 'Commission',
                        netto: 'Your netto',
                        brutto: 'Your brutto',
                    },
                },
            },
        },
        purchasedServices: {
            title: 'Purchased Services',
            table: {
                headers: {
                    invoice: 'Invoice',
                    service: 'Service',
                    info: 'Info',
                    serviceDescription: 'Service description',
                    cost: 'Cost',
                    status: 'Status',
                    actions: 'Actions',
                },
                tooltips: {
                    buyAgain: 'Buy again',
                    details: 'Details',
                },
            },
        },
    },
    euGrantInfo: {
        projectInformation:
            'Przedsiębiorstwo JobHunter Sp. z o.o. w okresie od 01.05.2021 – 31.10.2022 r. realizuje projekt pt. „Jobhunter.io - platforma rekrutacji specjalistów IT z zagranicy”“ w ramach Programu Operacyjnego Polska Wschodnia, Oś Priorytetowa 1 Przedsiębiorcza Polska Wschodnia, Działanie 1.1 Platformy startowe dla nowych pomysłów, Poddziałanie 1.1.2 Rozwój startupów w Polsce Wschodniej.',
        projectDetails: {
            agreementNumber: 'Numer umowy: POPW.01.01.02-20-0050/20-00',
            beneficiary: 'Beneficjent: JobHunter Sp. z o.o.',
            totalPrice: 'Całkowita wartość projektu: 1 174 734,00 zł',
            euFunding: 'Dofinansowanie projektu z UE: 972 366,00 zł',
            projectPurpose:
                'Cel projektu:\nCelem projektu jest rozwinięcia w makroregionie działalności biznesowej przedsiębiorstwa Job Hunter Sp. z o.o. w oparciu o innowacyjny produkt platformę rekrutacji specjalistów IT jobhunter.io i model biznesowy przygotowany w ramach poddziałania 1.1.1 POPW.',
            results:
                'Planowane efekty:\n - zbudowanie platformymy IT i udostępnienie jej w internecie;\n - zrealizowanie założeń strategi marketingowej, której celem jest pozyskanie kandydatów, pracodawców i ogłoszeń;\n - uruchomienie usługi relokacji i legalizacji pobytu we współpracy z partnerem firmą EWL (pozyskana do współpracy w trakcie inkubacji);\n - zapewnienie stabilnej sytuacji wzrostu firmy po zakońceniu projektu (działający produkt, powracający klienci, osiagnięcie BEP)',
        },
        labels: {
            agreementNumber: 'Numer umowy:',
            beneficiary: 'Beneficjent:',
            totalPrice: 'Całkowita wartość projektu:',
            euFunding: 'Dofinansowanie projektu z UE:',
            projectPurpose:
                'Celem projektu jest rozwinięcia w makroregionie działalności biznesowej przedsiębiorstwa Job Hunter Sp. z o.o. w oparciu o innowacyjny produkt platformę rekrutacji specjalistów IT jobhunter.io i model biznesowy przygotowany w ramach poddziałania 1.1.1 POPW.',
            results:
                'Planowane efekty:\n - zbudowanie platformymy IT i udostępnienie jej w internecie;\n - zrealizowanie założeń strategi marketingowej, której celem jest pozyskanie kandydatów, pracodawców i ogłoszeń;\n - uruchomienie usługi relokacji i legalizacji pobytu we współpracy z partnerem firmą EWL (pozyskana do współpracy w trakcie inkubacji);\n - zapewnienie stabilnej sytuacji wzrostu firmy po zakońceniu projektu (działający produkt, powracający klienci, osiagnięcie BEP)',
        },
        data: {
            agreementNumber: 'POPW.01.01.02-20-0050/20-00',
            beneficiary: 'JobHunter Sp. z o.o.',
            totalPrice: '1 174 734,00 zł',
            euFunding: '972 366,00 zł',
            projectPurpose:
                'Celem projektu jest rozwinięcia w makroregionie działalności biznesowej przedsiębiorstwa Job Hunter Sp. z o.o. w oparciu o innowacyjny produkt platformę rekrutacji specjalistów IT jobhunter.io i model biznesowy przygotowany w ramach poddziałania 1.1.1 POPW.',
            results:
                'Planowane efekty:\\n zbudowanie platformy IT i udostępnienie jej w internecie;\\n – zrealizowanie założeń strategi marketingowej, której celem jest pozyskanie kandydatów, pracodawców i ogłoszeń;\\n– uruchomienie usługi relokacji i legalizacji pobytu we współpracy z partnerem firmą EWL (pozyskana do współpracy w trakcie inkubacji);\\n– zapewnienie stabilnej sytuacji wzrostu firmy po zakońceniu projektu (działający produkt, powracający klienci, osiagnięcie BEP)',
        },
    },
    auth: {
        authFooter: {
            srTitle: 'Footer containing contact information and social links',
            addressColumn: {
                title: 'JobHunter Sp z o.o',
                address1: 'ul. Zygmunta Krasińskiego 2',
                address2: '20-709 Lublin',
                nip: 'NIP: 9662131616',
            },
            copyright: 'JobHunter {{date}} © Wszystkie prawa zastrzeżone.',
            socialIcons: {
                email: 'Email',
                facebook: 'Link to Facebooku profile',
                instagram: 'Link to Instagram profile',
                linkedin: 'Link to Linkedin profile',
            },
        },
    },
    buttons: {
        apply: 'Zastosuj',
        buy: 'Kup',
        showAll: 'Pokaż wszystkie',
        submit: 'Zatwierdź',
        cancel: 'Anuluj',
        confirm: 'Potwierdź',
        ok: 'Ok',
        register: 'Zarejestruj',
    },
    formValidation: {
        errors: {
            minLength: 'Wartość jest zbyt krótka',
            isEmailValid: 'Adres email jest nieprawidłowy',
            isRequired: 'Pole wymagane',
            isCheckedTrue: 'Musi być zaznaczone',
            isTrue: 'Musi być zaznaczone',
            isPasswordSame: 'Hasła nie mogą być różne',
            isStartDateValid: 'Data musi być w przyszłości',
            isStartDateGreaterOrEvenValid: 'Data musi być w przyszłości lub dziś',
            isEndDateValid: 'Data powrotu musi być po dacie wylotu',
            isNumber: 'Wartość musi być liczbą',
            isPositiveNumberOrZero: 'Value should be greater than 0',
        },
    },
    plans: {
        title: 'Plany taryfowe',
        subscriptionVariants: {
            title: 'Warianty abonamentu dostępu do serwisu',
            employerModule: 'Moduł pracodawcy',
            relocationModule: 'Moduł relokacji',
            headHunterModule: 'Moduł HeadHuntera',
            markerplaceModule: 'Moduł marketplace',
            positionsOffers: 'Pozycje / oferty: {{value}}',
            videocallMinutes: 'Minuty wideorozmowy: {{value}}',
        },
        relocationServicePackage: {
            title: 'Warianty pakietów usług relokacyjnych',
            visaDocuments: 'Dokumenty wizowe',
            temporaryAccommodation: 'Mieszkanie tymczasowe',
            permanentAccommodation: 'Mieszkanie na stałe',
            bankAccount: 'Konto bankowe',
            accountingOffice: 'Biuro księgowe (1 rok)',
            legalSupport: 'Obsługa prawna (10h)',
            familyVisaDocuments: 'Dokumenty wizowe rodzina',
            polishLessons: 'Lekcje polskiego',
            buddy: 'Buddy',
        },
    },
    footer: {
        copyright: `JobHunter.io {{date}} © Wszystkie prawa zastrzeżone`,
        forCandidates: 'Dla kandydatów',
        forCompanies: 'Dla firm',
        marketplace: 'Marketplace',
        pricing: 'Cennik',
        findYourJob: 'Znajdź pracę',
    },
    maintenance: {
        pageNotFound: 'Error - page not found',
    },
    db: {
        seniority: {
            novice: 'Novice',
            trainee: 'Trainee',
            junior: 'Junior',
            middle: 'Mid',
            senior: 'Senior',
            architect: 'Architect',
        },
        technology: {
            javascript: 'JavaScript',
            php: 'PHP',
            java: 'Java',
            cpp: 'C++',
            csharp: 'C#',
            python: 'Python',
            html: 'HTML',
            html5: 'HTML5',
            ruby: 'Ruby',
            scala: 'Scala',
            golang: 'Go',
            sql: 'SQL',
            rust: 'Rust',
        },
        technology_tool: {
            laravel: 'Laravel',
            reactjs: 'Reactjs',
            yii: 'Yii',
            angularjs: 'Angular',
            angular: 'Angular',
            symfony: 'Symfony',
            doctrine: 'Doctrine',
            phalcon: 'Phalcon',
            spring: 'Spring',
            hibernate: 'Hibernate',
            javalin: 'Javalin',
            dotnet: '.NET',
            xamarin: 'Xamarin',
            infernet: 'Infer.NET',
            vuejs: 'Vue.js',
            gatsby: 'Gatsby',
            django: 'Django',
            flask: 'Flask',
            framework7: 'Framework7',
            kendoui: 'Kendo UI',
            rubyonrails: 'Ruby on Rails',
            sinatra: 'Sinatra',
            hanami: 'Hanami',
            grape: 'Grape',
            ramaze: 'Ramaze',
            slick: 'Slick',
            playframework: 'Play Framework',
            akka: 'Akka',
            gin: 'Gin',
            echo: 'Echo',
            kit: 'Kit',
            fasthttp: 'Fasthttp',
            aeron: 'Aeron',
            tensorflow: 'TensorFlow',
            devart: 'Devart',
            dbvisualizer: 'DbVisualizer',
            dbschema: 'DbSchema',
            manageengine: 'ManageEngine',
            tauri: 'Tauri',
            yew: 'Yew',
            rocket: 'Rocket',
            juniper: 'Juniper',
            dioxus: 'Dioxus',
        },
        language: {
            de: 'German',
            en: 'English',
            pl: 'Polish',
        },
        language_level: {
            a1: 'A1',
            a2: 'A2',
            b1: 'B1',
            b2: 'B2',
            c1: 'C1',
            c2: 'C2',
        },
        contract_type: {
            employment: 'Employment',
            freelance: 'Freelance',
            b2b: 'B2B',
        },
        company_type: {
            support: 'Support',
            hoster: 'Hoster',
            softwarehouse: 'Softwarehouse',
            agency: 'Agency',
            integrator: 'Integrator',
            startup: 'Startup',
            interactive_agency: 'Interactive Agency',
            team_leasing_company: 'Team Leasing Company',
            body_leasing_company: 'Body Leasing Company',
            own_it_department: 'Own IT department',
        },
        work_type: {
            partially_remote: 'Partially remote',
            stationary: 'Stationary',
            remote: 'Remote',
        },
        industry: {
            government: 'Government',
            finance: 'Finance',
            it: 'IT',
            education: 'Education',
            healthcare: 'Healthcare',
            hardware: 'Hardware',
            software: 'Software',
            saas: 'Software as a Service',
            platforms: 'Platforms',
            systems_integrator: 'Systems Integrator',
            business_process_outsourcing: 'Business Process Outsourcing',
            infrastructure: 'Infrastructure',
            data_centers: 'Data Centers',
            telecom: 'Telecom',
            it_consulting: 'IT Consulting',
            creative_services: 'Creative Services',
            information_security: 'Information Security',
            data_services: 'Data Services',
            ai: 'Artificial Intelligence',
            robotics: 'Robotics',
            gaming: 'Gaming',
            media: 'Media',
            simulation: 'Simulation',
            research_n_development: 'Research & Development',
            ecommerce: 'Ecommerce',
            energy: 'Energy',
            marketing: 'Marketing',
            market_research: 'Market Research',
            competitive_intelligence: 'Competitive Intelligence',
            public_services: 'Public Services',
            smart_cities: 'Smart Cities',
            building_n_home_automation: 'Building & Home Automation',
            immersive_environments: 'Immersive Environments',
            sharing_economy: 'Sharing Economy',
            consumer_products: 'Consumer Products',
            mass_customization: 'Mass Customization',
            consumer_services: 'Consumer Services',
            business_services: 'Business Services',
            reputation_systems: 'Reputation Systems',
            architecture: 'Architecture',
            agriculture: 'Agriculture',
            automotive: 'Automotive',
            others: 'Others',
        },
        employment_type: {
            part_time: 'Part time',
            full_time: 'Full time',
        },
        city: {
            warsaw: 'Warsaw',
            poznan: 'Poznan',
            wroclaw: 'Wroclaw',
            krakow: 'Krakow',
        },
        preference_tag: {
            clubs_high: 'Clubs high',
            nature_high: 'Nature high',
            education_medium: 'Education medium',
            'holidays_26-30': 'Holidays 26-30',
            climate_hot: 'Climate hot',
            pace_of_life_slow: 'Pace of life slow',
            climate_dry: 'Climate dry',
            climate_forest: 'Climate forest',
            climate_mountains: 'Climate mountains',
            nature_medium: 'Nature medium',
            'holidays_21-25': 'Holidays 21-25',
            climate_humid: 'Climate humid',
            holidays_11_15: 'Holidays 11-15',
            education_low: 'Education low',
            nature_low: 'Nature low',
            holidays_no_more_than_10: 'Holidays no more than 10',
            religion_buddhism: 'Religion buddhism',
            climate_snow: 'Climate snow',
            religion_judaism: 'Religion judaism',
            holidays_31_or_more: 'Holidays 31 or more',
            religion_muslim: 'Religion muslim',
            religion_hindu: 'Religion hindu',
            clubs_medium: 'Clubs medium',
            climate_cold: 'Climate cold',
            education_high: 'Education high',
            pace_of_life_medium: 'Pace of life medium',
            religion_taoist: 'Religion taoist',
            religion_protestant: 'Religion protestant',
            'holidays_16-20': 'Holidays 16-20',
            climate_temperate: 'Climate temperate',
            pace_of_life_fast: 'Pace of life fast',
            religion_catholic: 'Religion catholic',
            clubs_low: 'Clubs low',
        },
        country: {
            AF: 'Afghanistan',
            AL: 'Albania',
            DZ: 'Algeria',
            AS: 'American Samoa',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antarctica',
            AG: 'Antigua and Barbuda',
            AR: 'Argentina',
            AM: 'Armenia',
            AW: 'Aruba',
            AU: 'Australia',
            AT: 'Austria',
            AZ: 'Azerbaijan',
            BS: 'Bahamas (the)',
            BH: 'Bahrain',
            BD: 'Bangladesh',
            BB: 'Barbados',
            BY: 'Belarus',
            BE: 'Belgium',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermuda',
            BT: 'Bhutan',
            BO: 'Bolivia (Plurinational State of)',
            BQ: 'Bonaire, Sint Eustatius and Saba',
            BA: 'Bosnia and Herzegovina',
            BW: 'Botswana',
            BV: 'Bouvet Island',
            BR: 'Brazil',
            IO: 'British Indian Ocean Territory (the)',
            BN: 'Brunei Darussalam',
            BG: 'Bulgaria',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            CV: 'Cabo Verde',
            KH: 'Cambodia',
            CM: 'Cameroon',
            CA: 'Canada',
            KY: 'Cayman Islands (the)',
            CF: 'Central African Republic (the)',
            TD: 'Chad',
            CL: 'Chile',
            CN: 'China',
            CX: 'Christmas Island',
            CC: 'Cocos (Keeling) Islands (the)',
            CO: 'Colombia',
            KM: 'Comoros (the)',
            CD: 'Congo (the Democratic Republic of the)',
            CG: 'Congo (the)',
            CK: 'Cook Islands (the)',
            CR: 'Costa Rica',
            HR: 'Croatia',
            CU: 'Cuba',
            CW: 'Curaçao',
            CY: 'Cyprus',
            CZ: 'Czechia',
            CI: "Côte d'Ivoire",
            DK: 'Denmark',
            DJ: 'Djibouti',
            DM: 'Dominica',
            DO: 'Dominican Republic (the)',
            EC: 'Ecuador',
            EG: 'Egypt',
            SV: 'El Salvador',
            GQ: 'Equatorial Guinea',
            ER: 'Eritrea',
            EE: 'Estonia',
            SZ: 'Eswatini',
            ET: 'Ethiopia',
            FK: 'Falkland Islands (the) [Malvinas]',
            FO: 'Faroe Islands (the)',
            FJ: 'Fiji',
            FI: 'Finland',
            FR: 'France',
            GF: 'French Guiana',
            PF: 'French Polynesia',
            TF: 'French Southern Territories (the)',
            GA: 'Gabon',
            GM: 'Gambia (the)',
            GE: 'Georgia',
            DE: 'Germany',
            GH: 'Ghana',
            GI: 'Gibraltar',
            GR: 'Greece',
            GL: 'Greenland',
            GD: 'Grenada',
            GP: 'Guadeloupe',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Guinea',
            GW: 'Guinea-Bissau',
            GY: 'Guyana',
            HT: 'Haiti',
            HM: 'Heard Island and McDonald Islands',
            VA: 'Holy See (the)',
            HN: 'Honduras',
            HK: 'Hong Kong',
            HU: 'Hungary',
            IS: 'Iceland',
            IN: 'India',
            ID: 'Indonesia',
            IR: 'Iran (Islamic Republic of)',
            IQ: 'Iraq',
            IE: 'Ireland',
            IM: 'Isle of Man',
            IL: 'Israel',
            IT: 'Italy',
            JM: 'Jamaica',
            JP: 'Japan',
            JE: 'Jersey',
            JO: 'Jordan',
            KZ: 'Kazakhstan',
            KE: 'Kenya',
            KI: 'Kiribati',
            KP: "Korea (the Democratic People's Republic of)",
            KR: 'Korea (the Republic of)',
            KW: 'Kuwait',
            KG: 'Kyrgyzstan',
            LA: "Lao People's Democratic Republic (the)",
            LV: 'Latvia',
            LB: 'Lebanon',
            LS: 'Lesotho',
            LR: 'Liberia',
            LY: 'Libya',
            LI: 'Liechtenstein',
            LT: 'Lithuania',
            LU: 'Luxembourg',
            MO: 'Macao',
            MG: 'Madagascar',
            MW: 'Malawi',
            MY: 'Malaysia',
            MV: 'Maldives',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Marshall Islands (the)',
            MQ: 'Martinique',
            MR: 'Mauritania',
            MU: 'Mauritius',
            YT: 'Mayotte',
            MX: 'Mexico',
            FM: 'Micronesia (Federated States of)',
            MD: 'Moldova (the Republic of)',
            MC: 'Monaco',
            MN: 'Mongolia',
            ME: 'Montenegro',
            MS: 'Montserrat',
            MA: 'Morocco',
            MZ: 'Mozambique',
            MM: 'Myanmar',
            NA: 'Namibia',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Netherlands (the)',
            NC: 'New Caledonia',
            NZ: 'New Zealand',
            NI: 'Nicaragua',
            NE: 'Niger (the)',
            NG: 'Nigeria',
            NU: 'Niue',
            NF: 'Norfolk Island',
            MP: 'Northern Mariana Islands (the)',
            NO: 'Norway',
            OM: 'Oman',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'Palestine, State of',
            PA: 'Panama',
            PG: 'Papua New Guinea',
            PY: 'Paraguay',
            PE: 'Peru',
            PH: 'Philippines (the)',
            PN: 'Pitcairn',
            PL: 'Poland',
            PT: 'Portugal',
            PR: 'Puerto Rico',
            QA: 'Qatar',
            MK: 'Republic of North Macedonia',
            RO: 'Romania',
            RU: 'Russian Federation (the)',
            RW: 'Rwanda',
            RE: 'Réunion',
            BL: 'Saint Barthélemy',
            SH: 'Saint Helena, Ascension and Tristan da Cunha',
            KN: 'Saint Kitts and Nevis',
            LC: 'Saint Lucia',
            MF: 'Saint Martin (French part)',
            PM: 'Saint Pierre and Miquelon',
            VC: 'Saint Vincent and the Grenadines',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Sao Tome and Principe',
            SA: 'Saudi Arabia',
            SN: 'Senegal',
            RS: 'Serbia',
            SC: 'Seychelles',
            SL: 'Sierra Leone',
            SG: 'Singapore',
            SX: 'Sint Maarten (Dutch part)',
            SK: 'Slovakia',
            SI: 'Slovenia',
            SB: 'Solomon Islands',
            SO: 'Somalia',
            ZA: 'South Africa',
            GS: 'South Georgia and the South Sandwich Islands',
            SS: 'South Sudan',
            ES: 'Spain',
            LK: 'Sri Lanka',
            SD: 'Sudan (the)',
            SR: 'Suriname',
            SJ: 'Svalbard and Jan Mayen',
            SE: 'Sweden',
            CH: 'Switzerland',
            SY: 'Syrian Arab Republic',
            TW: 'Taiwan (Province of China)',
            TJ: 'Tajikistan',
            TZ: 'Tanzania, United Republic of',
            TH: 'Thailand',
            TL: 'Timor-Leste',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad and Tobago',
            TN: 'Tunisia',
            TR: 'Turkey',
            TM: 'Turkmenistan',
            TC: 'Turks and Caicos Islands (the)',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ukraine',
            AE: 'United Arab Emirates (the)',
            GB: 'United Kingdom of Great Britain and Northern Ireland (the)',
            UM: 'United States Minor Outlying Islands (the)',
            US: 'United States of America (the)',
            UY: 'Uruguay',
            UZ: 'Uzbekistan',
            VU: 'Vanuatu',
            VE: 'Venezuela (Bolivarian Republic of)',
            VN: 'Viet Nam',
            VG: 'Virgin Islands (British)',
            VI: 'Virgin Islands (U.S.)',
            WF: 'Wallis and Futuna',
            EH: 'Western Sahara',
            YE: 'Yemen',
            ZM: 'Zambia',
            ZW: 'Zimbabwe',
            AX: 'Åland Islands',
        },
        service_type: {
            language: 'Language',
            relocation: 'Relocation',
            family: 'Family',
            housing: 'Housing',
            buddy: 'Buddy',
        },
        organization_size: {
            tiny: 'Tiny',
            small: 'Small',
            medium: 'Medium',
            large: 'Large',
            huge: 'Huge',
        },
    },
    accessibilityMenu: {
        title: 'Menu dostępności',
        openAccessibilityMenu: 'Otworz menu dostępności',
        closeAccessibilityMenu: 'Zamknij menu dostępności',
        contrastMode: {
            title: 'Tryb kontrastu {{mode}}',
            toggleButton: 'Przełącz tryb kontrastu',
            on: 'włączony',
            off: 'wyłączony',
        },
        fontSize: {
            title: 'Rozmiar tekstu',
            increase: 'Zwiększ rozmiar tekstu',
            decrease: 'Zmniejsz rozmiar tekstu',
        },
    },
};

export default pl;
