import {combineEpics, Epic, ofType} from 'redux-observable';
import {catchError, switchMap, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {PayloadAction} from '@reduxjs/toolkit';
import {renewAuthToken, IRenewAuthToken, setAuthTokens, setAuthStateFailure} from '../reducers/authSlice';
import {addAlert} from '../reducers/alertSlice';
import {refreshTokenAPI} from '../../api/refreshToken';

const sendRefreshToken: Epic = (action$) =>
    action$.pipe(
        ofType(renewAuthToken.type),
        switchMap((action: PayloadAction<IRenewAuthToken>): any => {
            return refreshTokenAPI(action.payload.refreshToken).pipe(
                mergeMap((resp: any) => {
                    const actions = [];
                    actions.push(addAlert({message: 'alerts.tokenRefreshed'}));
                    actions.push(setAuthTokens(resp.authToken, resp.refreshToken));

                    return of(...actions);
                }),
                catchError((error: any) => {
                    let errorMessage = error.response ? error.response.message : 'alerts.baseError';
                    return of(setAuthStateFailure(error.toString()), addAlert({message: errorMessage}));
                })
            );
        })
    );

const renewAuthTokenEpic = combineEpics(sendRefreshToken);

export default renewAuthTokenEpic;
