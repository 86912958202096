import React from 'react';
import {InputType} from '../index';
import {ISwitchProps, ISwitchState} from '../../../types';
import {createFormInputClass} from '../../../utils/formUtils';

type Props = ISwitchProps;
type State = ISwitchState;

class Switch extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            isChecked: this.props.checked,
        };
    }

    componentDidUpdate(prevProps: Readonly<any>): void {
        if (this.props.checked !== prevProps.checked) {
            this.setState({isChecked: this.props.checked});
        }
    }

    render() {
        return (
            <React.Fragment>
                <label className={`switch ${this.props.inputStyles}`}>
                    <input
                        type={InputType.CHECKBOX}
                        name={this.props.name}
                        checked={this.state.isChecked}
                        onChange={this.onChange}
                        disabled={this.props.disabled}
                        className={createFormInputClass(this.props.name)}
                    />
                    <span className="slider" />
                </label>
            </React.Fragment>
        );
    }

    private onChange = (e: any) => {
        this.setState({isChecked: e.target.checked});
        this.props.handleChange(e);
    };
}

export default Switch;
