// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._7wjuUQ-wXQpg3CMugfHkD{color:#fff;font-size:1.4rem;font-weight:400;letter-spacing:0;line-height:2rem;font-family:\"Ubuntu\", sans-serif;white-space:pre-wrap;overflow-wrap:break-word;word-break:break-word;overflow:hidden;max-width:100%}._7wjuUQ-wXQpg3CMugfHkD._3ep3G-YaOdQvF7MQQKRx5X{color:#334048}._3IoP09ZmcYfrrO972XxeK7{position:relative;width:2rem;height:2rem}._31hmfr4TB1Q5oG0wvWiTup{display:flex;flex-direction:column;justify-content:center;align-items:center}._31hmfr4TB1Q5oG0wvWiTup .zSohEBGDB6oQbWF7EPz1B{display:inline-flex;justify-content:center;align-items:center;text-align:center;height:2.2rem;width:100%;color:#000000}._31hmfr4TB1Q5oG0wvWiTup .zSohEBGDB6oQbWF7EPz1B div{max-width:calc(100% - 3.5rem);text-overflow:ellipsis;overflow:hidden;margin:1rem}._31hmfr4TB1Q5oG0wvWiTup .zSohEBGDB6oQbWF7EPz1B i{color:#ff585f}._31hmfr4TB1Q5oG0wvWiTup .zSohEBGDB6oQbWF7EPz1B i:hover{color:#fff}._1TeSHst9ORcoZ7A9V0wEXK{opacity:0.75}._3nm1CmywBIoHWSIc_C7Hlr{height:12.9rem;width:17.2rem;border-radius:1.72rem}\n", ""]);
// Exports
exports.locals = {
	"chatMessage": "_7wjuUQ-wXQpg3CMugfHkD",
	"message": "_3ep3G-YaOdQvF7MQQKRx5X",
	"loaderWrapper": "_3IoP09ZmcYfrrO972XxeK7",
	"filePreviewContainer": "_31hmfr4TB1Q5oG0wvWiTup",
	"fileDescriptionBar": "zSohEBGDB6oQbWF7EPz1B",
	"responseMessage": "_1TeSHst9ORcoZ7A9V0wEXK",
	"chatImage": "_3nm1CmywBIoHWSIc_C7Hlr"
};
module.exports = exports;
