import {Observable} from 'rxjs';
import {jobHunterAPI} from './provider/jobHunterAPI';
import {RestQueryParams} from './base/queryParams';
import {NotificationMessage} from '../types';

export function postDelayedMessageAPI(accessToken: string, message: NotificationMessage): Observable<any> {
    return jobHunterAPI.post(`notifications/chat`, message, new RestQueryParams(), {
        Authorization: `Bearer ${accessToken}`,
    });
}
