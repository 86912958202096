import React, {Component} from 'react';
import UserDropdown from "../UserDropdown";
import {INavbarUserProps} from "../../../types";

type Props = INavbarUserProps;

class NavbarUser extends Component<Props> {

    render() {
        return (
            <UserDropdown account={this.props.account}
                          userMenu={this.props.userMenu}
                          unauthorizedUserMenu={this.props.unauthorizedUserMenu}
                          isAuthenticated={this.props.isAuthenticated}
                          customDropdownMenuClassName={this.props.customDropdownMenuClassName}
                          />
        );
    }
}

export default NavbarUser;
