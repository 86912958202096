import {createSelector} from '@reduxjs/toolkit';
import {ITechnologyToolState} from '../reducers/technologyToolSlice';

export const selectTechnologyTool = (state: any): ITechnologyToolState => {
    return state.technologyTool;
};

export const technologyToolsSelector = createSelector([selectTechnologyTool], (state: ITechnologyToolState) => state.technologyTools);

export const technologyToolsLoadingSelector = createSelector([selectTechnologyTool], (state: ITechnologyToolState) => state.isLoading);

export const technologyToolsInitializedSelector = createSelector(
    [selectTechnologyTool],
    (state: ITechnologyToolState) => state.isInitialized
);

export const technologyToolsErrorSelector = createSelector([selectTechnologyTool], (state: ITechnologyToolState) => state.error);
