import React from 'react';
import styles from "./styles.module.scss";
import Translation from "../../../../Translation";
import MultiSelect from "../../../../FormControl/MultiSelect";
import {MultiSelectType} from "../../../../FormControl";
import {IDeviceSelectProps, IDeviceSelectState} from "../../../../../types";
import {isSameValue} from "../../../../..";
import {withTranslation} from 'react-i18next';


type Props = IDeviceSelectProps;
type State = IDeviceSelectState;

class DeviceSelect extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            items: this.props.data,
            value: null
        };
    }

    componentDidUpdate(
        prevProps: Readonly<Props>,
        prevState: Readonly<State>,
        snapshot?: any
    ): void {
        if(!isSameValue(this.props.data, prevProps.data)) {
            this.setState({items: this.props.data});
        }
    }

    render() {
        const { items } = this.state,
            {t} = this.props,
            selectOptions = items.map((item: {[key: string]: any}) => {
            return {
                value: item.deviceId,
                label: item.label,
                kind: item.kind
            }
        });

        return (
            <div className={styles.settingsItem}>
                <span className={styles.label}>
                    <Translation text={this.props.label}/>
                </span>

                <div className={styles.select}>
                    <MultiSelect
                        multiselectType={MultiSelectType.SINGLE}
                        handleChange={(e: any) => {
                            this.setState({value: e});
                            this.props.onSelect(e);
                        }}
                        name={''}
                        value={this.state.value}
                        options={selectOptions}
                        placeholder={t('videoChat.settings.select')}
                    />
                </div>
            </div>
        );
    }
}

export default withTranslation()(DeviceSelect);
