import {createSelector} from '@reduxjs/toolkit';
import {ICompanyTypeState} from '../reducers/companyTypeSlice';

export const selectCompanyType = (state: any): ICompanyTypeState => {
    return state.companyType;
};

export const companyTypesSelector = createSelector([selectCompanyType], (state: ICompanyTypeState) => state.companyTypes);

export const companyTypesLoadingSelector = createSelector([selectCompanyType], (state: ICompanyTypeState) => state.isLoading);

export const companyTypesInitializedSelector = createSelector([selectCompanyType], (state: ICompanyTypeState) => state.isInitialized);

export const companyTypesErrorSelector = createSelector([selectCompanyType], (state: ICompanyTypeState) => state.error);
