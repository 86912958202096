import {Navigate} from 'react-router-dom';
import {initAuthTokenChange, isAuthenticatedSelector, UserRole, withRouterWrapper, WithRouterWrapperProps} from '../..';
import {connect} from 'react-redux';
import React, {useEffect} from 'react';

type Props = {
    isAuthenticated: boolean;
    children: any;
    initAuthTokenChange: typeof initAuthTokenChange;
    userRole: UserRole;
    authUrl?: string;
    [key: string]: any;
} & WithRouterWrapperProps;

const PrivateRoute = ({isAuthenticated, authUrl = '/auth/login', children, initAuthTokenChange, userRole, router}: Props) => {
    useEffect(() => {
        if (!isProductionMode()) {
            if (router && router.location && router.location.search) {
                const token = router.location.search.split('?token=').pop();
                setAuthToken(token);
            }
        }
    }, []);

    const setAuthToken = (token: string | null) => {
        if (!token) {
            return;
        }

        initAuthTokenChange(token, userRole);
    };

    const isProductionMode = (): boolean => {
        return process.env.REACT_APP_ENV === 'prod';
    };

    if (!isAuthenticated) {
        return <Navigate replace to={authUrl} />;
    }

    return children;
};

export default connect(
    (state: any) => ({
        isAuthenticated: isAuthenticatedSelector(state),
    }),
    {initAuthTokenChange}
)(withRouterWrapper(PrivateRoute));
