export const REACT_APP_NODE_CHAT='http://localhost:9002';
export const REACT_APP_NODE_CHAT_PRODUCTION='https://api.jobhunter.ovh:9002';

export const REACT_APP_STUN_1_URL='stun:stun.l.google.com:19302';
export const REACT_APP_STUN_2_URL='stun:stun1.l.google.com:19302';

export const REACT_APP_TURN_1_URL = 'turn:turn.solvee.pl';
export const REACT_APP_TURN_1_CREDENTIAL = 'stolec123';
export const REACT_APP_TURN_1_USERNAME = 'solvee';

export const BYTES_PER_CHUNK = 12000;

export const MESSAGES_NUMBER_PER_PAGE = 10
