import {Observable} from 'rxjs';
import {jobHunterAPI} from './provider/jobHunterAPI';
import {RestQueryParams} from './base/queryParams';
import {ToggleNotificationPayload} from '../types';

export function toggleNotificationAPI(accessToken: string, payload: ToggleNotificationPayload): Observable<any> {
    return jobHunterAPI.put(`notifications/toggle`, payload, new RestQueryParams(), {
        Authorization: `Bearer ${accessToken}`,
    });
}
