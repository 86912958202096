import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelDictionaryDatum} from '../../model/dictionaryDatum';
import {IBaseDictionaryState, IChangeIsInitialized, IChangeIsLoading, ISetError} from './workTypeSlice';

export interface IOrganizationSizeState extends IBaseDictionaryState {
    organizationSizes: IModelDictionaryDatum[] | null;
}

export interface IChangeOrganizationSize {
    organizationSizes: IModelDictionaryDatum[] | null;
}

const initialState: IOrganizationSizeState = {
    organizationSizes: null,
    isLoading: false,
    isInitialized: false,
    error: null,
};

const organizationSizeSlice = createSlice({
    name: 'organizationSize',
    initialState: initialState,
    reducers: {
        changeOrganizationSize: {
            reducer: (state: IOrganizationSizeState, action: PayloadAction<IChangeOrganizationSize>) => {
                return {
                    organizationSizes: action.payload.organizationSizes,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(organizationSizes: IModelDictionaryDatum[] | null) {
                return {
                    payload: {organizationSizes: organizationSizes},
                };
            },
        },
        changeIsOrganizationSizeLoading: {
            reducer: (state: IOrganizationSizeState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    organizationSizes: state.organizationSizes,
                    isLoading: action.payload.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        changeIsOrganizationSizeInitialized: {
            reducer: (state: IOrganizationSizeState, action: PayloadAction<IChangeIsInitialized>) => {
                return {
                    organizationSizes: state.organizationSizes,
                    isLoading: state.isLoading,
                    isInitialized: action.payload.isInitialized,
                    error: state.error,
                };
            },
            prepare(isInitialized: boolean) {
                return {
                    payload: {isInitialized: isInitialized},
                };
            },
        },
        setOrganizationSizeError: {
            reducer: (state: IOrganizationSizeState, action: PayloadAction<ISetError>) => {
                return {
                    organizationSizes: state.organizationSizes,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
        fetchOrganizationSizes: (state: IOrganizationSizeState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
    },
});

export const {
    changeOrganizationSize,
    changeIsOrganizationSizeLoading,
    changeIsOrganizationSizeInitialized,
    setOrganizationSizeError,
    fetchOrganizationSizes,
} = organizationSizeSlice.actions;

export default organizationSizeSlice.reducer;
