// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".xsI93k890lQJBvZAFPoX6{display:flex;justify-content:center;align-items:center;flex-direction:column;position:absolute;top:0;left:0;width:100%;height:100%;background-color:rgba(255,255,255,0.8);z-index:999;box-sizing:border-box;padding:0 4rem}.xsI93k890lQJBvZAFPoX6 ._2hGcHHYyiQiJqCCSmH-MFT{margin-bottom:4rem;text-align:center}.xsI93k890lQJBvZAFPoX6 ._2hGcHHYyiQiJqCCSmH-MFT h1{font-size:2.8rem;margin:3rem 0;color:#263238;font-weight:500;font-family:Montserrat, sans-serif;line-height:1.5}.xsI93k890lQJBvZAFPoX6 ._2hGcHHYyiQiJqCCSmH-MFT h1 ._1t1pt7hs2usUEBv2qKcL06{margin-left:0.5rem}.xsI93k890lQJBvZAFPoX6 ._2hGcHHYyiQiJqCCSmH-MFT .cMFZB1UVDpQ5yN7DD7EI5{font-size:1.4rem;color:#666;font-family:Montserrat, sans-serif;text-align:center}.xsI93k890lQJBvZAFPoX6 ._1xj-D6mo_Z7zp7_NF-PsQ7{width:100%;margin-bottom:5rem;text-align:center}.xsI93k890lQJBvZAFPoX6 ._1xj-D6mo_Z7zp7_NF-PsQ7 ._2hnhTAh1pFW6TbKRyxyFUP{font-size:1.6rem;color:#7367F0}\n", ""]);
// Exports
exports.locals = {
	"host": "xsI93k890lQJBvZAFPoX6",
	"header": "_2hGcHHYyiQiJqCCSmH-MFT",
	"date": "_1t1pt7hs2usUEBv2qKcL06",
	"timezone": "cMFZB1UVDpQ5yN7DD7EI5",
	"information": "_1xj-D6mo_Z7zp7_NF-PsQ7",
	"warning": "_2hnhTAh1pFW6TbKRyxyFUP"
};
module.exports = exports;
