import {createSelector} from '@reduxjs/toolkit';
import {IEmploymentTypeState} from '../reducers/employmentTypeSlice';

export const selectEmploymentType = (state: any): IEmploymentTypeState => {
    return state.employmentType;
};

export const employmentTypesSelector = createSelector([selectEmploymentType], (state: IEmploymentTypeState) => state.employmentTypes);

export const employmentTypesLoadingSelector = createSelector([selectEmploymentType], (state: IEmploymentTypeState) => state.isLoading);

export const employmentTypesInitializedSelector = createSelector(
    [selectEmploymentType],
    (state: IEmploymentTypeState) => state.isInitialized
);

export const employmentTypesErrorSelector = createSelector([selectEmploymentType], (state: IEmploymentTypeState) => state.error);
