import {combineEpics, Epic, ofType} from 'redux-observable';
import {catchError, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {
    changeIsOfferViewPageLoading,
    changeOfferViewPageError,
    fetchOfferView,
    IOfferViewAction,
    setOfferView,
} from '../reducers/offerViewPageSlice';
import {getOfferViewAPI} from '../../api/getOfferViewApi';
import {PayloadAction} from '@reduxjs/toolkit';
import {addAlert, AlertType, getErrorMessage} from 'jobhunter-common-web';

const fetchOfferViewListEpic: Epic = (action$) => {
    return action$.pipe(
        ofType(fetchOfferView.type),
        switchMap((action: PayloadAction<IOfferViewAction>) => {
            const offerId = action.payload.offerId;
            return getOfferViewAPI(offerId).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([setOfferView(resp)]);
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const successActions = (changeSliceList?: any[]): any[] => {
    const actions = [changeIsOfferViewPageLoading(false)];

    if (changeSliceList) {
        return actions.concat(changeSliceList);
    }
    return actions;
};

const updateListErrorActions = (error: any): any[] => {
    return [
        changeIsOfferViewPageLoading(false),
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
        changeOfferViewPageError(getErrorMessage(error)),
    ];
};

const offerViewPageEpic = combineEpics(fetchOfferViewListEpic);

export default offerViewPageEpic;
