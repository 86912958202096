import {createAPIHandler} from '../base/apiFactory';
import {API_URL} from '../../config/entrypoint';
import {ContactsListResponse} from "../../components/hoc/chat.types";
import {ChatContact} from "../../types";
import {serverChatContactIntoChatContactMapper} from "./mappers/serverChatContactIntoChatContactMapper";

export const jobHunterChatContactsAPI = (accountId: string) =>  createAPIHandler({
	url: API_URL,
	defaultHeaders: {
		'Accept': 'application/ld+json',
		'Content-Type': 'application/ld+json',
		'Accept-Language': 'pl-PL, pl;q=0.9, en;q=0.5, *;q=0.2'
	},
	mapper: (data: ContactsListResponse): ChatContact[] => serverChatContactIntoChatContactMapper(data['hydra:member'], accountId),
	isList: false
});
