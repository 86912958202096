import {IModelApiObject} from './base';

export enum DictionaryName {
    CITY = 'cities',
    COMPANY_TYPE = 'company_types',
    CONTRACT_TYPE = 'contract_types',
    COUNTRY = 'countries',
    EMPLOYMENT_TYPE = 'employment_types',
    INDUSTRY = 'industries',
    LANGUAGE_LEVEL = 'language_levels',
    LANGUAGE = 'languages',
    ORGANIZATION_SIZE = 'organization_sizes',
    ORGANIZATION_VERIFICATION_FILE_TYPE = 'organization_verification_file_types',
    SENIORITY = 'seniorities',
    SERVICE_TYPE = 'service_types',
    TECHNOLOGY = 'technologies',
    TECHNOLOGY_TOOL = 'technology_tools',
    WORK_TYPE = 'work_types',
    PREFERENCE_TAGS = 'preference_tags',
}

export interface IModelDictionaryDatum extends IModelApiObject {
    readonly id: string;
    readonly name: string;
}

export interface IModelSeniority extends IModelDictionaryDatum {
    level: number;
}

export interface IModelCity extends IModelDictionaryDatum {
    country: IModelDictionaryDatum;
}
