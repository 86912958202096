import {IFormConfig, Form, getLoginCredentials, Translation, UserRole, FormControlChangeType} from 'jobhunter-common-web';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {loginFormConfig} from './loginFormConfig';
import {BehaviorSubject} from 'rxjs';

interface IConnectedLoginFormProps {
    readonly getLoginCredentials: typeof getLoginCredentials;
}

interface IExternalLoginFormProps {
    readonly modalDescription: string;
}

interface ILoginFormProps extends IExternalLoginFormProps, IConnectedLoginFormProps {}

interface ILoginFormState {
    formConfig: typeof IFormConfig | null;
    value: any;
}

class LoginForm extends Component<ILoginFormProps, ILoginFormState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    constructor(props: ILoginFormProps) {
        super(props);

        this.state = {
            formConfig: loginFormConfig,
            value: null,
        };
    }
    render() {
        return (
            <>
                <p className="modal-description fw-bold">
                    <Translation text={this.props.modalDescription} />
                </p>
                <Form
                    config={this.state.formConfig}
                    onValueStateChange={this.onValueStateChange}
                    value={this.state.value}
                    submitForm={this.login}
                    controlName={'loginForm'}
                />

                <div className="modal-description mt-1">
                    <Translation text="offers.offerView.applicationFormLogin.noAccount" />
                    <Link className="ms-1" to="/auth/register">
                        <Translation text="offers.offerView.applicationFormLogin.createAccount" />
                    </Link>
                </div>
            </>
        );
    }

    private login = (event: any, value: any, valid: boolean, touched: boolean): any => {
        event.preventDefault();
        if (!valid || !touched) {
            return;
        }

        return this.props.getLoginCredentials(value.email, value.password, UserRole.CANDIDATE);
    };
    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };
}

export default connect(() => ({}), {
    getLoginCredentials,
})(LoginForm);
