import {createAPIHandler} from '../base/apiFactory';
import {API_URL} from '../../config/entrypoint';
import {serverMessageIntoChatMessageMapper} from "./mappers/serverMessageIntoChatMessageMapper";
import {ChatServerMessagePayload} from "../../types";
import {MessageMapperResult} from "../../components/hoc/chat.types";

export const jobHunterServerMessagesAPI = (accountId: string) => createAPIHandler({
	url: API_URL,
	defaultHeaders: {
		'Accept': 'application/ld+json',
		'Content-Type': 'application/ld+json',
		'Accept-Language': 'pl-PL, pl;q=0.9, en;q=0.5, *;q=0.2'
	},
	mapper: (data: ChatServerMessagePayload): MessageMapperResult => ({
		messages: serverMessageIntoChatMessageMapper(data['hydra:member'], accountId),
		totalResults: data['hydra:totalItems']
	}),
	isList: false
});
