import React from 'react';
import {connect} from 'react-redux';
import {of, Subscription} from 'rxjs';
import {catchError, delay, tap} from 'rxjs/operators';
import {Link} from 'react-router-dom';
import {registerAPI} from '../../../api/register';
import {addAlert} from '../../../store/reducers/alertSlice';
import {AlertType, IFormConfig, UserRole, WithNavigate} from '../../../types';
import Form from '../../Form';
import Loader from '../../Loader';
import Translation from '../../Translation';
import {LanguageLocaleType} from '../../../constants/locales';
import {withNavigation} from '../../hoc/withRouter';

interface IConnectedRegisterProps {
    addAlert: typeof addAlert;
}

interface IExternalRegisterProps {
    userRole: UserRole;
    envData: any;
    formConfig: IFormConfig;
}

interface IRegisterProps extends IConnectedRegisterProps, IExternalRegisterProps, WithNavigate {}

interface IRegisterState {
    formConfig: IFormConfig;
    value: any;
    isLoading: boolean;
}

class Registration extends React.Component<IRegisterProps, IRegisterState> {
    private subscriptions: Subscription[] = [];

    constructor(props: IRegisterProps) {
        super(props);

        this.state = {
            formConfig: this.props.formConfig,
            value: null,
            isLoading: false,
        };
    }

    componentWillUnmount(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <article className="auth-article">
                <header className="auth-form-header">
                    <h1 className="auth-title">
                        <Translation text="auth.register.title" />
                    </h1>
                </header>
                <Loader showLoader={this.state.isLoading} />
                <Form config={this.state.formConfig} submitForm={this.register} controlName={'registrationForm'} value={{}} />
                <div className="auth-subtitle-container">
                    <p className="auth-subtitle redirect-link-container">
                        <Translation text="auth.register.haveAccount" />
                        <Link className="auth-redirect-link" to="/auth/login">
                            <Translation text="auth.register.logIn" />
                        </Link>
                    </p>
                </div>
            </article>
        );
    }

    private register = (event: any, formData: any, valid: boolean, touched: boolean): void => {
        event.preventDefault();
        if (!valid || !touched) {
            return;
        }
        const returnUrl =
                this.props.userRole === UserRole.CANDIDATE
                    ? `${this.props.envData.REACT_APP_CANDIDATE_URL}/auth/confirm-registration`
                    : `${this.props.envData.REACT_APP_ORGANIZATION_URL}/auth/confirm-registration`,
            role = this.props.userRole === UserRole.CANDIDATE ? 'candidate' : 'organization';

        let userPayload = {
            username: formData.email,
            password: formData.plainPassword,
            locale: LanguageLocaleType.EN,
            type: role,
            returnUrl: returnUrl,
        };

        this.setState({isLoading: true});
        this.subscriptions.push(
            registerAPI(userPayload)
                .pipe(
                    catchError((error: any) => {
                        let message = '';
                        const response = error.response;

                        if (response['hydra:description']) {
                            message = response['hydra:description'];
                        } else if (response.message) {
                            if (response.message === 'JWT Token not found') {
                                message = 'auth.alert.authenticationError';
                            } else {
                                message = response.message;
                            }
                        }
                        this.props.addAlert({message: message, type: AlertType.WARNING});
                        this.setState({isLoading: false});
                        return of();
                    }),
                    tap(() => this.props.addAlert({message: 'auth.alert.registrationSuccess', displayFor: 20 * 1000})),
                    delay(1500),
                    tap(() => {
                        this.props.navigate('/');
                        this.setState({isLoading: false});
                    })
                )
                .subscribe()
        );
    };
}

export default connect(() => ({}), {
    addAlert,
})(withNavigation(Registration));
