import {Observable} from 'rxjs';
import {RestQueryParams} from './base/queryParams';
import {jobHunterChatContactsAPI} from './provider/jobHunterChatContactsAPI';
import {ChatContact} from '../types';

export function getChatContactsListAPI(accessToken: string, accountId: string): Observable<ChatContact[]> {
    return jobHunterChatContactsAPI(accountId).get(`api/accounts/chat_contacts`, new RestQueryParams(), {
        Authorization: `Bearer ${accessToken}`,
    });
}
