import React, {Component} from 'react';
import OffersList, {OffersListType} from '../OffersList';
import {WithLocation, withLocation} from 'jobhunter-common-web';
import OfferFilters from '../OfferFilters';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {isOffersLoadingSelector, offersListSelector} from '../../../store/selectors/offersPageSelectors';
import {fetchOffersList, resetToInitialOffersState} from '../../../store/reducers/offersPageSlice';
import {IOfferItem} from '../../../model/offersModel';

declare type WithLocationProps = typeof WithLocation;

interface IConnectedOffersProps {
    readonly isLoading: boolean;
    readonly offersList: IOfferItem[] | null;
    readonly fetchOffersList: typeof fetchOffersList;
    readonly resetToInitialOffersState: typeof resetToInitialOffersState;
}

interface IExternalOffersProps {}

interface IOffersProps extends IConnectedOffersProps, IExternalOffersProps, WithLocationProps {}

interface IOffersState {
    isBestMatchSet: boolean;
}

class Offers extends Component<IOffersProps, IOffersState> {
    constructor(props: IOffersProps) {
        super(props);

        this.state = {
            isBestMatchSet: false,
        };
    }

    componentDidMount(): void {
        if (this.props.location.state && this.props.location.state.isBestMatchSelected) {
            this.setState({isBestMatchSet: true});
        }
        this.props.fetchOffersList();
    }
    componentWillUnmount(): void {
        this.props.resetToInitialOffersState();
    }
    render() {
        return (
            <React.Fragment>
                <OfferFilters />
                <OffersList type={OffersListType.OFFERS} offers={this.props.offersList} isLoading={this.props.isLoading} />
            </React.Fragment>
        );
    }
}

export default connect(
    (state: RootState) => ({
        offersList: offersListSelector(state),
        isLoading: isOffersLoadingSelector(state),
    }),
    {fetchOffersList, resetToInitialOffersState}
)(withLocation(Offers));
