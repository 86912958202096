import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalHeader} from 'reactstrap';
import {
    Form,
    FormControlChangeType,
    IFormConfig,
    Loader,
    LoaderType,
    Price,
    Translation,
    authTokenSelector,
    getLoginCredentials,
    isAuthenticatedSelector,
} from 'jobhunter-common-web';
import {BehaviorSubject, Subscription, of, Observable} from 'rxjs';
import {catchError, filter, switchMap, tap} from 'rxjs/operators';
import {paymentFormConfig} from './paymentFormConfig';
import ServiceDetails from '../ServiceDetails';
import styles from './styles.module.scss';
import PaymentCompleteModal from '../PaymentComplete';
import {IModelService, IModelServiceConfirmation} from '../../../../model/service';
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import {CardElement} from '@stripe/react-stripe-js';
import {StripeCardElement} from '@stripe/stripe-js';
import {buyServiceAPI} from '../../../../api/buyService';
import {WithTranslation, withTranslation} from 'react-i18next';
import {getPaymentSecretAPI} from '../../../../api/getPaymentSecret';
import {lazyInject} from '../../../../ioc';
import {IAlertManagerService} from '../../../../service/alertManagerService';
import {confirmPaymentAPI} from '../../../../api/confirmPayment';
import LoginForm from '../LoginForm';

interface IConnectedBuyServiceModalProps {
    readonly isAuthenticated: boolean;
    readonly getLoginCredentials: typeof getLoginCredentials;
    readonly authToken: string | null;
}
interface IBuyServiceModalProps extends IConnectedBuyServiceModalProps, WithTranslation {
    isModalOpen: boolean;
    toggleModal: () => void;
    service: IModelService;
}

interface IBuyServiceModalState {
    value: any;
    formConfig: typeof IFormConfig | null;
    isLoading: boolean;
    isPurchaseComplete: boolean;
    invoiceNumber: string | null;
}

class BuyServiceModal extends Component<IBuyServiceModalProps, IBuyServiceModalState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;
    private stripe: any;
    private elements: any;

    constructor(props: IBuyServiceModalProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: null,
            isLoading: false,
            isPurchaseComplete: false,
            invoiceNumber: null,
        };
    }

    componentDidMount(): void {
        if (this.props.isAuthenticated) {
            this.setState({formConfig: paymentFormConfig()});
        }

        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    tap((data: any) => this.onFormValueChange(data.value))
                )
                .subscribe()
        );
    }

    componentDidUpdate(prevProps: Readonly<IBuyServiceModalProps>): void {
        if (this.props.isAuthenticated !== prevProps.isAuthenticated && this.props.isAuthenticated) {
            this.setState({formConfig: paymentFormConfig()});
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        const service = this.props.service;

        return (
            <Modal isOpen={this.props.isModalOpen} toggle={() => this.props.toggleModal()}>
                <ModalHeader className="custom-header" toggle={() => this.props.toggleModal()} />
                <ModalBody className="service-payment-modal">
                    {!this.state.isPurchaseComplete ? this.renderModalContent(service) : <PaymentCompleteModal service={service} />}
                </ModalBody>
            </Modal>
        );
    }

    private renderModalContent = (service: IModelService) => {
        return (
            <>
                <p className="modal-title">{service.title}</p>

                <ServiceDetails service={this.props.service} />

                <hr />
                {this.props.isAuthenticated ? (
                    <>
                        <div className={styles.formContainer}>
                            {service.grossPrice !== '0'
                                ? this.state.formConfig && (
                                      <Form
                                          config={this.state.formConfig}
                                          onValueStateChange={this.onValueStateChange}
                                          value={this.state.value}
                                          controlName={'profileForm'}
                                      />
                                  )
                                : null}
                        </div>
                        <hr />

                        <div className={styles.billingSummary}>
                            <div className={styles.amount}>
                                <p className={styles.amountLabel}>
                                    <Translation text="marketplace.buyService.billingSummary" />
                                </p>
                                <p>
                                    <Price
                                        price={{
                                            amount: service.grossPrice,
                                        }}
                                    />
                                </p>
                            </div>
                            <Button
                                color="primary"
                                onClick={() => this.buyService()}
                                disabled={service.grossPrice !== '0' && !this.isFormComplete()}>
                                <Translation text="buttons.buy" />
                            </Button>
                        </div>
                    </>
                ) : (
                    <LoginForm modalDescription="marketplace.buyService.applicationFormLogin.description" />
                )}

                <Loader type={LoaderType.Local} showLoader={this.state.isLoading} />
            </>
        );
    };

    private onFormValueChange = (value: any) => {
        const mappedValue: any = {};
        Object.keys(value).forEach((key: string) => {
            let fieldValue = value[key];
            if (undefined === fieldValue || null === fieldValue) {
                mappedValue[key] = null;

                return;
            }
            if (key === 'stripecard') {
                fieldValue = Object.assign({}, fieldValue);
                this.stripe = fieldValue['stripe'];
                this.elements = fieldValue['elements'];
                mappedValue[key] = fieldValue;
                // delete fieldValue['stripe'];
                // delete fieldValue['elements'];
            } else {
                mappedValue[key] = fieldValue;
            }
        });
        this.setState({value: mappedValue});
    };

    private confirmPaymentFlow = (): Observable<any> => {
        const serviceId = this.props.service.id,
            {t} = this.props;

        return buyServiceAPI(this.props.authToken, serviceId).pipe(
            switchMap((response: any) => {
                return getPaymentSecretAPI(this.props.authToken, response.payment.id).pipe(
                    switchMap((getPaymentSecretResp: any) => {
                        const confirmCardPaymentPayload = {
                            payment_method: {
                                card: this.elements.getElement(CardElement) as StripeCardElement,
                                billing_details: {
                                    name: this.state.value.cardholderName,
                                },
                            },
                        };
                        return this.stripe.confirmCardPayment(getPaymentSecretResp.client_secret, confirmCardPaymentPayload);
                    }),
                    catchError((error: any) => {
                        this.setState({isLoading: false});
                        this.alertManager?.handleApiError(error);
                        return of();
                    }),

                    switchMap((paymentResponse: any) => {
                        if (paymentResponse.error) {
                            const errorMessage =
                                paymentResponse.error && paymentResponse.error.message
                                    ? paymentResponse.error.message
                                    : t('formValidation.errors.stripeError');
                            this.alertManager.handleApiError(errorMessage);
                            this.setState({isLoading: false});
                            return of();
                        } else {
                            return confirmPaymentAPI(this.props.authToken, response.payment.id).pipe(
                                catchError((error: any) => {
                                    this.setState({isLoading: false});
                                    this.alertManager?.handleApiError(error);

                                    return of();
                                }),
                                tap((response: IModelServiceConfirmation) => {
                                    this.setState({invoiceNumber: response.invoiceNumber});
                                }),
                                tap(() => {
                                    this.alertManager.addAlert(t('marketplace.buyService.servicePurchased'));
                                    this.setState({
                                        isLoading: false,
                                        isPurchaseComplete: true,
                                    });
                                })
                            );
                        }
                    })
                );
            }),
            catchError((error: any) => {
                this.setState({isLoading: false});
                this.alertManager.handleApiError(error);
                return of();
            })
        );
    };

    private confirmFreePaymentFlow = (): Observable<any> => {
        const serviceId = this.props.service.id,
            {t} = this.props;

        return buyServiceAPI(this.props.authToken, serviceId).pipe(
            tap(() => {
                this.alertManager.addAlert(t('marketplace.buyService.servicePurchased'));
                this.setState({
                    isLoading: false,
                    isPurchaseComplete: true,
                });
            }),
            catchError((error: any) => {
                this.setState({isLoading: false});
                this.alertManager.handleApiError(error);
                return of();
            })
        );
    };

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private isFormComplete = (): boolean => {
        return (
            this.state.value !== null &&
            this.state.value.cardholderName !== null &&
            this.state.value.cardholderName !== '' &&
            this.state.value.cardholderName !== undefined &&
            this.state.value.stripecard !== null &&
            this.state.value.stripecard !== undefined &&
            (this.state.value.stripecard.length > 0 || Object.keys(this.state.value.stripecard).length > 0)
        );
    };

    private buyService = () => {
        this.setState({isLoading: true});
        const service = this.props.service;

        return Number(service.grossPrice) === 0
            ? this.subscriptions.push(this.confirmFreePaymentFlow().subscribe())
            : this.subscriptions.push(this.confirmPaymentFlow().subscribe());
    };
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        isAuthenticated: isAuthenticatedSelector(state),
    }),
    {
        getLoginCredentials,
    }
)(withTranslation()(BuyServiceModal));
