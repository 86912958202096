import {FormControlType, IFormConfig, ValidationRules} from 'jobhunter-common-web';

export const offerApplicationFormConfig: typeof IFormConfig = () => ({
    controlType: 'form',
    class: 'default-form application-form',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'is_working',
            controlType: 'group',
            class: 'row justify-content-center mb-4',
            controls: {
                confirmApplication: {
                    controlType: 'control',
                    defaultValue: false,
                    formControlType: FormControlType.CHECKBOX,
                    validationRules: [{name: ValidationRules.IS_REQUIRED, params: {}}],
                    placeholder: '',
                    label: '',
                    isLabelHidden: true,
                    isCheckboxLabelRaw: false,
                    checkboxLabel: 'offers.offerView.applicationForm.labels.applicationAgreement',
                    hostClass: 'col-xl-10 form-row',
                },
                confirmDataProcessing: {
                    controlType: 'control',
                    defaultValue: false,
                    formControlType: FormControlType.CHECKBOX,
                    validationRules: [{name: ValidationRules.IS_REQUIRED, params: {}}],
                    placeholder: '',
                    label: '',
                    isLabelHidden: true,
                    isCheckboxLabelRaw: false,
                    checkboxLabel: 'offers.offerView.applicationForm.labels.dataProcessingAgreement',
                    hostClass: 'col-xl-10 form-row',
                },
            },
        },
        {
            controlType: 'group',
            key: 'submit',
            class: 'row btn-row',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: 'submit',
                    inputStyles: 'btn btn-primary',
                    defaultContainerStyles: '',
                    hostClass: 'btn-container',
                    containerStyles: '',
                    defaultValue: null,
                    isLabelHidden: true,
                    btnText: 'buttons.apply',
                    buttonDisabled: (mappedOutputValue: any, isFormValid: boolean) => {
                        return !isFormValid;
                    },
                },
            },
        },
    ],
});
