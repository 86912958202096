import React, {Component} from 'react';
import {Button} from 'reactstrap';
import {ChevronUp, ChevronDown} from 'react-feather';
import parse from 'html-react-parser';
import {IItemDescriptionProps, IItemDescriptionState} from '../../types';
import Translation from '../Translation';

type Props = IItemDescriptionProps;
type State = IItemDescriptionState;

class ItemDescription extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isDescriptionShortened: true,
        };
    }

    render() {
        return <>{this.renderDescription()}</>;
    }

    private renderDescription = () => {
        if (!this.props.description) {
            return null;
        }

        const descriptionLength = this.props.descriptionLength ? this.props.descriptionLength : 300,
            shortenedDescription = this.props.description.substr(0, descriptionLength) + '...';

        if (this.props.description.length < descriptionLength) {
            return <span>{parse(this.props.description)}</span>;
        }

        return (
            <>
                <span>{this.state.isDescriptionShortened ? parse(shortenedDescription) : parse(this.props.description)}</span>

                <Button color="flat-primary" onClick={() => this.setState({isDescriptionShortened: !this.state.isDescriptionShortened})}>
                    <span className="align-middle me-1">
                        {this.state.isDescriptionShortened ? (
                            <Translation text="marketplace.serviceDetails.readMore" />
                        ) : (
                            <Translation text="marketplace.serviceDetails.readLess" />
                        )}
                    </span>
                    {this.state.isDescriptionShortened ? <ChevronDown size={14} /> : <ChevronUp size={14} />}
                </Button>
            </>
        );
    };
}

export default ItemDescription;
