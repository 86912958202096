export const mockServiceVariants: {[key: string]: any}[] = [
    {
        id: '1668cc22-27a6-408b-b6ef-2bc7470d23b2',
        name: 'Employer Basic',
        netPrice: '29000',
        priceCurrency: 'PLN',
        subscriptionVariants: [
            {name: 'employerModule', value: true},
            {name: 'relocationModule', value: true},
            {name: 'markerplaceModule', value: true},
            {name: 'positionsOffers', value: '3'},
            {name: 'videocallMinutes', value: '300'},
        ],
        color: 'lightBlue2',
    },
    {
        id: '1668cc22-27a6-408b-b6ef-2bc7470d23b2',
        name: 'Employer Standard',
        netPrice: '34900',
        priceCurrency: 'PLN',
        subscriptionVariants: [
            {name: 'employerModule', value: true},
            {name: 'relocationModule', value: true},
            {name: 'headHunterModule', value: true},
            {name: 'markerplaceModule', value: true},
            {name: 'positionsOffers', value: '10'},
            {name: 'videocallMinutes', value: '800'},
        ],
        color: 'darkBlue2',
    },
    {
        id: '1668cc22-27a6-408b-b6ef-2bc7470d23b2',
        name: 'Headhunter Basic',
        netPrice: '24900',
        priceCurrency: 'PLN',
        subscriptionVariants: [
            {name: 'relocationModule', value: true},
            {name: 'headHunterModule', value: true},
            {name: 'markerplaceModule', value: true},
            {name: 'positionsOffers', value: '5'},
            {name: 'videocallMinutes', value: '500'},
        ],
        color: 'lightGreen1',
    },
    {
        id: '1668cc22-27a6-408b-b6ef-2bc7470d23b2',
        name: 'Headhunter Standard',
        netPrice: '39000',
        priceCurrency: 'PLN',
        subscriptionVariants: [
            {name: 'relocationModule', value: true},
            {name: 'headHunterModule', value: true},
            {name: 'markerplaceModule', value: true},
            {name: 'positionsOffers', value: '1'},
            {name: 'videocallMinutes', value: '1000'},
        ],
        color: 'darkGreen2',
    },
    {
        id: '1668cc22-27a6-408b-b6ef-2bc7470d23b2',
        name: 'Premium',
        netPrice: '69000',
        priceCurrency: 'PLN',
        subscriptionVariants: [
            {name: 'employerModule', value: true},
            {name: 'relocationModule', value: true},
            {name: 'headHunterModule', value: true},
            {name: 'markerplaceModule', value: true},
            {name: 'positionsOffers', value: 'unlimited'},
            {name: 'videocallMinutes', value: '3000'},
        ],
        color: 'darkOrange1',
    },
];
