import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IOfferDetails} from '../../model/offerViewModel';

export interface IChangeIsOfferViewLoading {
    isOfferViewPageLoading: boolean;
}

export interface IChangeIsOfferViewInitialized {
    isOfferViewPageInitialized: boolean;
}

export interface ISetOfferView {
    offer: IOfferDetails;
}

export interface IOfferViewAction {
    offerId: string;
}
export interface IChangeOfferViewError {
    offerViewPageError: string;
}

export interface IOfferViewPageState {
    offerView: IOfferDetails | null;
    isOfferViewPageLoading: boolean;
    isOfferViewPageInitialized: boolean;
    offerViewPageError: string | null;
}

const initialState: IOfferViewPageState = {
    offerView: null,
    isOfferViewPageInitialized: false,
    isOfferViewPageLoading: false,
    offerViewPageError: null,
};

const offerViewPageSlice = createSlice({
    name: 'offerViewPage',
    initialState: initialState,
    reducers: {
        changeOfferViewPageError: {
            reducer: (state: IOfferViewPageState, action: PayloadAction<IChangeOfferViewError>) => {
                return {
                    ...state,
                    offerViewPageError: action.payload.offerViewPageError,
                };
            },
            prepare: (offerViewPageError: string) => {
                return {
                    payload: {
                        offerViewPageError: offerViewPageError,
                    },
                };
            },
        },
        fetchOfferView: {
            reducer: (state: IOfferViewPageState) => {
                return {
                    ...state,
                    isOfferViewLoading: true,
                };
            },
            prepare: (offerId: string) => {
                return {
                    payload: {
                        offerId: offerId,
                    },
                };
            },
        },
        setOfferView: {
            reducer: (state: IOfferViewPageState, action: PayloadAction<ISetOfferView>) => {
                return {
                    ...state,
                    offerView: action.payload.offer,
                };
            },
            prepare: (offer: IOfferDetails) => {
                return {
                    payload: {
                        offer: offer,
                    },
                };
            },
        },
        changeIsOfferViewPageLoading: {
            reducer: (state: IOfferViewPageState, action: PayloadAction<IChangeIsOfferViewLoading>) => {
                return {
                    ...state,
                    isOfferViewPageLoading: action.payload.isOfferViewPageLoading,
                };
            },
            prepare: (isOfferViewPageLoading: boolean) => {
                return {
                    payload: {
                        isOfferViewPageLoading: isOfferViewPageLoading,
                    },
                };
            },
        },
        changeIsOfferViewPageInitialized: {
            reducer: (state: IOfferViewPageState, action: PayloadAction<IChangeIsOfferViewInitialized>) => {
                return {
                    ...state,
                    isOfferViewPageInitialized: action.payload.isOfferViewPageInitialized,
                };
            },
            prepare: (isOfferViewPageInitialized: boolean) => {
                return {
                    payload: {
                        isOfferViewPageInitialized: isOfferViewPageInitialized,
                    },
                };
            },
        },
        resetToInitialOfferViewPageState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    fetchOfferView,
    changeIsOfferViewPageInitialized,
    changeIsOfferViewPageLoading,
    setOfferView,
    resetToInitialOfferViewPageState,
    changeOfferViewPageError,
} = offerViewPageSlice.actions;

export default offerViewPageSlice.reducer;
