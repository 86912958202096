import {IFormConfig, InputType, FormControlType, ValidationRules} from 'jobhunter-common-web';

const isStripeCardValid = (data: any) => {
    if (data !== null && data !== undefined && data.complete !== null && data.complete !== undefined) {
        return data.complete;
    }
};

export const paymentFormConfig = (): typeof IFormConfig => ({
    controlType: 'group',
    class: 'default-form payment',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'cardholder_name',
            class: 'row',
            controls: {
                cardholderName: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}, {name: ValidationRules.MIN_LENGTH, params: {length: 3}}],
                    placeholder: 'marketplace.paymentCard.form.placeholders.cardholderName',
                    label: 'marketplace.paymentCard.form.labels.cardholderName',
                    hostClass: 'col-xl-12 form-control',
                    type: InputType.TEXT,
                },
            },
        },
        {
            controlType: 'group',
            key: 'stripe_card',
            class: 'row',
            controls: {
                stripecard: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.STRIPECARD,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                        {name: ValidationRules.IS_VALID, params: {callback: isStripeCardValid}},
                    ],
                    label: 'marketplace.paymentCard.form.labels.cardNumber',
                    stripePublicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY as string,
                    hostClass: 'col-xl-12 form-control',
                },
            },
        },
    ],
});
