import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelDictionaryDatum} from '../../model/dictionaryDatum';
import {IBaseDictionaryState, IChangeIsInitialized, IChangeIsLoading, ISetError} from './workTypeSlice';

export interface IPreferenceTagsState extends IBaseDictionaryState {
    preferenceTags: IModelDictionaryDatum[] | null;
}

export interface IChangePreferenceTags {
    preferenceTags: IModelDictionaryDatum[] | null;
}

const initialState: IPreferenceTagsState = {
    preferenceTags: null,
    isLoading: false,
    isInitialized: false,
    error: null,
};

const preferenceTagsSlice = createSlice({
    name: 'preferenceTags',
    initialState: initialState,
    reducers: {
        changePreferenceTags: {
            reducer: (state: IPreferenceTagsState, action: PayloadAction<IChangePreferenceTags>) => {
                return {
                    preferenceTags: action.payload.preferenceTags,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(preferenceTags: IModelDictionaryDatum[] | null) {
                return {
                    payload: {preferenceTags: preferenceTags},
                };
            },
        },
        changeIsPreferenceTagsLoading: {
            reducer: (state: IPreferenceTagsState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    preferenceTags: state.preferenceTags,
                    isLoading: action.payload.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        changeIsPreferenceTagsInitialized: {
            reducer: (state: IPreferenceTagsState, action: PayloadAction<IChangeIsInitialized>) => {
                return {
                    preferenceTags: state.preferenceTags,
                    isLoading: state.isLoading,
                    isInitialized: action.payload.isInitialized,
                    error: state.error,
                };
            },
            prepare(isInitialized: boolean) {
                return {
                    payload: {isInitialized: isInitialized},
                };
            },
        },
        setPreferenceTagsError: {
            reducer: (state: IPreferenceTagsState, action: PayloadAction<ISetError>) => {
                return {
                    preferenceTags: state.preferenceTags,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
        fetchPreferenceTags: (state: IPreferenceTagsState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
    },
});

export const {
    changePreferenceTags,
    changeIsPreferenceTagsLoading,
    changeIsPreferenceTagsInitialized,
    setPreferenceTagsError,
    fetchPreferenceTags,
} = preferenceTagsSlice.actions;

export default preferenceTagsSlice.reducer;
