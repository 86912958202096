import React, {Component, Fragment} from 'react';
import {NavLink as Link} from 'react-router-dom';
import * as Icon from 'react-feather';
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    UncontrolledTooltip
} from 'reactstrap';
import {INavBarItem, INavbarNavigationProps} from '../../../types';
import Translation from "../../Translation";
import { HashLink } from 'react-router-hash-link';


type Props = INavbarNavigationProps;

class NavbarNavigation extends Component<Props> {
    render() {
      return (
            <Fragment>
                <ul className='navbar-nav d-lg-none'>
                    <NavItem className='mobile-menu me-auto'>
                        <NavLink className='nav-menu-main menu-toggle hidden-xs is-active' onClick={() => this.props.toggleMobileMenu()}>
                            <Icon.Menu className='ficon' />
                        </NavLink>
                    </NavItem>
                </ul>
                <ul className='nav navbar-nav bookmark-icons d-none d-lg-flex'>
                    {this.renderNavigation()}
                    {this.renderExtraNavigationDropdown()}
               </ul>
            </Fragment>
        );
    }

    private renderNavigation = () => {
        if (this.props.navigationMenu.length) {
            return this.props.navigationMenu
                .map((item: INavBarItem) => {
                    const IconTag = (Icon as any)[item.icon];

                    if (item.isIconOnly) {
                        return (
                            <NavItem key={item.target}>
                                <NavLink tag={Link} to={item.link} id={item.target}>
                                    <img src={item.icon} alt={item.title} className="nav-item-icon" />
                                    {this.props.isTooltipVisible ?
                                        <UncontrolledTooltip target={item.target}>{item.title}</UncontrolledTooltip> : null}
                                </NavLink>
                            </NavItem>
                        )
                    }

                    if (item.isHashLink) {
                        return (
                            <li className="nav-item" key={item.target}>
                                <HashLink to={item.link} id={item.target} className="nav-link">
                                    <IconTag className='ficon' size={18}/>
                                    <p className='d-none d-lg-block'><Translation text={item.title}/></p>
                                    {this.props.isTooltipVisible ?
                                        <UncontrolledTooltip target={item.target}>{item.title}</UncontrolledTooltip> : null}
                                </HashLink>
                            </li>
                        )
                    }

                    return (
                        <NavItem key={item.target}>
                            <NavLink tag={Link} to={item.link} id={item.target}>
                                <IconTag className='ficon' size={18}/>
                                <p className='d-none d-lg-block'><Translation text={item.title}/></p>
                                {this.props.isTooltipVisible ?
                                    <UncontrolledTooltip target={item.target}>{item.title}</UncontrolledTooltip> : null}
                            </NavLink>
                        </NavItem>
                    )
                })
                .slice(0, 10)
        } else {
            return null;
        }
    };

    private renderExtraNavigationDropdown = () => {
        if (this.props.navigationMenu.length && this.props.navigationMenu.length >= 11) {
            return (
                <NavItem className='d-none d-lg-block'>
                    <NavLink tag='span'>
                        <UncontrolledDropdown>
                            <DropdownToggle tag='span'>
                                <Icon.ChevronDown className='ficon'/>
                            </DropdownToggle>
                            <DropdownMenu end>
                                {this.props.navigationMenu
                                    .map((item: any) => {
                                        const IconTag = (Icon as any)[item.icon];
                                        return (
                                            <DropdownItem tag={Link} to={item.link} key={item.id}>
                                                <IconTag className='me-50' size={14}/>
                                                <span className='align-middle'>{item.title}</span>
                                            </DropdownItem>
                                        )
                                    })
                                    .slice(10)}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </NavLink>
                </NavItem>
            )
        } else {
            return null;
        }
    };
}

export default NavbarNavigation;
