import {combineReducers} from 'redux';
import {createBrowserHistory} from 'history';
import {
    accountSlice,
    alertSlice,
    authSlice,
    companyTypeSlice,
    contractTypeSlice,
    employmentTypeSlice,
    industrySlice,
    initLogout,
    loginSlice,
    sagaSlice,
    serviceTypeSlice,
    senioritySlice,
    technologySlice,
    workTypeSlice,
    onlineConsultationSlice,
    videoCallDetailsSlice,
    preferenceTagsSlice,
} from 'jobhunter-common-web';
import {createReduxHistoryContext} from 'redux-first-history';
import offersPageSlice from './offersPageSlice';
import offerViewPageSlice from './offerViewPageSlice';
import marketplacePageSlice from './marketplacePageSlice';

const {routerReducer} = createReduxHistoryContext({history: createBrowserHistory()});

const appReducer = combineReducers({
    auth: authSlice,
    login: loginSlice,
    account: accountSlice,
    alert: alertSlice,
    saga: sagaSlice,
    companyType: companyTypeSlice,
    contractType: contractTypeSlice,
    employmentType: employmentTypeSlice,
    industry: industrySlice,
    seniority: senioritySlice,
    technology: technologySlice,
    workType: workTypeSlice,
    serviceType: serviceTypeSlice,
    preferenceTags: preferenceTagsSlice,
    offersPage: offersPageSlice,
    marketplacePage: marketplacePageSlice,
    offerViewPage: offerViewPageSlice,
    onlineConsultation: onlineConsultationSlice,
    videoCallDetails: videoCallDetailsSlice,
    router: routerReducer,
});

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: any) => {
    if (action.type === initLogout.type) {
        return appReducer(undefined, {type: undefined});
    }

    return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
