import {createSelector} from '@reduxjs/toolkit';
import {IIndustryState} from '../reducers/industrySlice';

export const selectIndustry = (state: any): IIndustryState => {
    return state.industry;
};

export const industriesSelector = createSelector([selectIndustry], (state: IIndustryState) => state.industries);

export const industriesLoadingSelector = createSelector([selectIndustry], (state: IIndustryState) => state.isLoading);

export const industriesInitializedSelector = createSelector([selectIndustry], (state: IIndustryState) => state.isInitialized);

export const industriesErrorSelector = createSelector([selectIndustry], (state: IIndustryState) => state.error);
