import {createSelector} from '@reduxjs/toolkit';
import {ILanguageState} from '../reducers/languageSlice';

export const selectLanguage = (state: any): ILanguageState => {
    return state.language;
};

export const languagesSelector = createSelector([selectLanguage], (state: ILanguageState) => state.languages);

export const languagesLoadingSelector = createSelector([selectLanguage], (state: ILanguageState) => state.isLoading);

export const languagesInitializedSelector = createSelector([selectLanguage], (state: ILanguageState) => state.isInitialized);

export const languagesErrorSelector = createSelector([selectLanguage], (state: ILanguageState) => state.error);
