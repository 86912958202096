import React, {Component} from 'react';
import LayoutWrapper from '../../LayoutWrapper';
import {Badge} from 'reactstrap';
import {
    Avatar,
    AvatarColor,
    AvatarSize,
    Map,
    Translation,
    isNotNullOrUndefined,
    RatingComponent,
    IModelDictionaryDatum,
    withLocation,
    WithLocation,
    Loader,
    LoaderType,
    convertToPriceString,
    HtmlParserComponent,
} from 'jobhunter-common-web';
import {Box, DollarSign, TrendingUp, User} from 'react-feather';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {fetchOfferView, resetToInitialOfferViewPageState} from '../../../store/reducers/offerViewPageSlice';
import {isOfferViewPageLoadingSelector, offerViewSelector} from '../../../store/selectors/offerViewPageSelectors';
import {IOfferDetails, IOfferTechnologyTool} from '../../../model/offerViewModel';
import {withTranslation, WithTranslation} from 'react-i18next';

declare type WithLocationProps = typeof WithLocation;
interface IConnectedOfferViewProps {
    readonly fetchOfferView: typeof fetchOfferView;
    readonly offerDetails: IOfferDetails;
    readonly resetToInitialOfferViewPageState: typeof resetToInitialOfferViewPageState;
    readonly isLoading: boolean;
}
interface IOfferViewProps extends IConnectedOfferViewProps, WithLocationProps, WithTranslation {}

interface IOfferViewState {
    isApplicationModalOpen: boolean;
    offer: IOfferDetails | null;
}

class OfferView extends Component<IOfferViewProps, IOfferViewState> {
    constructor(props: IOfferViewProps) {
        super(props);
        this.state = {
            isApplicationModalOpen: false,
            offer: null,
        };
    }

    componentDidMount(): void {
        if (this.props.location && this.props.location.pathname) {
            const offerId = this.props.location.pathname.split('/').pop();
            this.props.fetchOfferView(offerId);
        }
    }

    componentWillUnmount(): void {
        this.props.resetToInitialOfferViewPageState();
    }

    render() {
        if (this.props.offerDetails === null) {
            return (
                <LayoutWrapper>
                    <p>
                        <Translation text="offers.offerView.noData" />
                    </p>
                    <Loader showLoader={this.props.isLoading} type={LoaderType.Local} />
                </LayoutWrapper>
            );
        }

        const {t} = this.props,
            offer = this.props.offerDetails.offer,
            offerDetails = offer.offer,
            offerCity = offerDetails.city.name,
            offerCountry = offerDetails.city.country.name,
            offerLocation = `${offerCity ? `${t(offerCity)},` : ''} ${offerCountry ? t(offerCountry) : ''}`,
            salary = `${convertToPriceString(offerDetails.minimumSalary)} - ${convertToPriceString(offerDetails.maximumSalary)}`;

        return (
            <LayoutWrapper>
                <div className="page-layout">
                    <div className="offer-view-container">
                        <div className="map-wrapper">
                            <Map
                                isMarkerShown
                                position={{lat: -34.397, lng: 150.644}}
                                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                                loadingElement={<div style={{height: `100%`}} />}
                                containerElement={<div style={{height: `100%`}} />}
                                mapElement={<div style={{height: `100%`, borderRadius: 6}} />}
                            />
                        </div>

                        <div className="container">
                            <div className="row">
                                <div className="page-container">
                                    <div className="position-details">
                                        <div className="header">
                                            <h3 className="job-title">
                                                {offerDetails.name ? <span>{offerDetails.name}</span> : null} {offerLocation}
                                            </h3>
                                        </div>

                                        <div className="summary">
                                            {this.renderPositionDetails(
                                                AvatarColor.LIGHT_PRIMARY,
                                                TrendingUp,
                                                'seniority',
                                                offerDetails.seniority.name
                                            )}

                                            {this.renderPositionDetails(
                                                AvatarColor.LIGHT_INFO,
                                                User,
                                                'employees',
                                                offerDetails?.organizationSize?.name
                                            )}

                                            {this.renderPositionDetails(
                                                AvatarColor.LIGHT_DANGER,
                                                Box,
                                                'relocation',
                                                offerDetails.relocation ? t('buttons.yes') : t('buttons.no')
                                            )}
                                            {this.renderPositionDetails(AvatarColor.LIGHT_SUCCESS, DollarSign, 'salary', salary)}
                                        </div>

                                        <hr />

                                        <div className="technology">
                                            <h5 className="subtitle">
                                                <Translation text="offers.offerView.description.technology" />
                                            </h5>
                                            <div className="technology-details">
                                                {this.props.offerDetails.technologyTools.map((tool: IOfferTechnologyTool) => {
                                                    return this.renderTechnologyDetails(
                                                        tool.technologyTool.name,
                                                        Number(tool.seniority.level)
                                                    );
                                                })}
                                            </div>
                                        </div>

                                        <hr />

                                        <div className="job-description">
                                            <h5 className="subtitle">
                                                <Translation text="offers.offerView.description.jobDescription" />
                                            </h5>
                                            <HtmlParserComponent htmlContent={this.props.offerDetails.offerDescription.description} />

                                            {/*<h5 className="subtitle">*/}
                                            {/*    <Translation text="offers.offerView.description.responsibilities" />*/}
                                            {/*</h5>*/}
                                            {/*<ul>*/}
                                            {/*    {offer.responsibilities.map((responsibility: string, index: number) => (*/}
                                            {/*        <li key={`responsibility_${index}`}>{responsibility}</li>*/}
                                            {/*    ))}*/}
                                            {/*</ul>*/}

                                            {/*<h5 className="subtitle">*/}
                                            {/*    <Translation text="offers.offerView.description.benefits" />*/}
                                            {/*</h5>*/}
                                            {/*<ul>*/}
                                            {/*    {offer.benefits.map((benefit: string, index: number) => (*/}
                                            {/*        <li key={`benefit_${index}`}>{benefit}</li>*/}
                                            {/*    ))}*/}
                                            {/*</ul>*/}
                                        </div>

                                        <hr />

                                        <div className="additional-information">
                                            {this.renderOfferAdditionalInformation('industry', offerDetails.industries)}
                                            {this.renderOfferAdditionalInformation('companyType', offerDetails.companyTypes)}
                                            {this.renderOfferAdditionalInformation('contractType', offerDetails.contractTypes)}
                                            {this.renderOfferAdditionalInformation('employmentType', offerDetails.employmentTypes)}
                                            {this.renderOfferAdditionalInformation('jobType', offerDetails.workTypes)}
                                        </div>

                                        {offerDetails.preferenceTags && offerDetails.preferenceTags.length
                                            ? this.renderPreferenceTags(offerDetails.preferenceTags)
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Loader showLoader={this.props.isLoading} type={LoaderType.Local} />
                </div>
            </LayoutWrapper>
        );
    }

    private renderPositionDetails = (color: typeof AvatarColor, Icon: any, label: string, description: string) => {
        return (
            <div className="summary-item">
                <Avatar color={color} size={AvatarSize.LG} icon={<Icon size={24} />} tag={'div'} />
                <div className="summary-details">
                    <p className="description">{isNotNullOrUndefined(description) ? <Translation text={description} /> : '---'}</p>
                    <p className="label">
                        <Translation text={`offers.offerView.labels.${label}`} />
                    </p>
                </div>
            </div>
        );
    };

    private renderTechnologyDetails = (label: string, level: number) => {
        return (
            <div className="technology-item" key={label}>
                <p className="label">
                    <Translation text={label} />
                </p>
                <div className="level">
                    <RatingComponent
                        id={label}
                        initialRating={level / 10}
                        emptySymbol={<div className="empty-symbol" />}
                        fullSymbol={<div className="full-symbol" />}
                        readonly
                        stop={6}
                    />
                </div>
            </div>
        );
    };

    private renderOfferAdditionalInformation = (label: string, items: string[]) => {
        return (
            <div className="information-item">
                <div className="offer-information-label">
                    <p className="label">
                        <Translation text={`offers.offerView.labels.${label}`} />
                    </p>
                </div>
                <div className="offer-information-content">
                    {items.map((item: typeof IModelDictionaryDatum) => (
                        <Badge key={`additional_info_${item.id}`} color="primary" pill>
                            <Translation text={item.name} />
                        </Badge>
                    ))}
                </div>
            </div>
        );
    };

    private renderPreferenceTags = (tags: typeof IModelDictionaryDatum[]) => {
        return (
            <>
                <hr />

                <div className="job-description preference-tags">
                    <h5 className="subtitle">
                        <Translation text="offers.offerView.description.preferenceTags" />
                    </h5>

                    {tags.map((tag) => {
                        return (
                            <Badge key={tag.id} color="primary" className="me-1" pill>
                                <Translation text={tag.name} />
                            </Badge>
                        );
                    })}
                </div>
            </>
        );
    };
}

export default connect(
    (state: RootState) => ({
        offerDetails: offerViewSelector(state),
        isLoading: isOfferViewPageLoadingSelector(state),
    }),
    {
        fetchOfferView,
        resetToInitialOfferViewPageState,
    }
)(withTranslation()(withLocation(OfferView)));
