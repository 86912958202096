import {InputDataMapper, OutputDataMapper, IMultiselectOption, IRangePickerOption} from '../types';
import {isNullOrUndefined, isNotNullOrUndefined} from './runtimeUtils';
import {IModelSeniority} from '../model/dictionaryDatum';
import {convertToKebabCase} from './stringUtils';

export const defaultDataMapper: InputDataMapper = (data: any) => data;

export const defaultOutputDataMapper: OutputDataMapper = (data: any, previousStateSnapshot: any, controlName: string) => {
    previousStateSnapshot[controlName] = data;

    return previousStateSnapshot;
};

export const defaultDataAccessor = (data: any, key: string | number) => {
    if (null === data || undefined === data) {
        return null;
    }

    return data[key];
};

export const removeExtraValueKeys = (value: object, prefix: string, index: number): void => {
    delete value[`${prefix}${index}`];
    Object.keys(value).forEach((key) => {
        if (!key.startsWith(prefix)) {
            return;
        }
        const regex = new RegExp(`(${prefix})(\\d+)`);
        const matches = regex.exec(key);
        if (undefined === matches || null === matches || matches.length < 3) {
            return;
        }
        const targetIndex = Number(matches[2]);
        if (targetIndex <= index) {
            return;
        }
        value[`${prefix}${targetIndex - 1}`] = value[key];
        delete value[key];
    });
};

export const collectionInputDataMapper = (data: any, config: any) => {
    if (isNullOrUndefined(data)) {
        return [];
    }
    if (!config || !config.multiselectOptions || !Array.isArray(config.multiselectOptions)) {
        return [];
    }

    const option = config.multiselectOptions.find((candidate: IMultiselectOption) => {
        return candidate.value === data;
    });

    return option ? option : [];
};

export const collectionOutputDataMapper = (data: any) => {
    if (Array.isArray(data)) {
        return data.length > 0 ? data : null;
    }
    if (data && typeof data === 'object') {
        return data.value;
    }

    return data;
};

export const multiCollectionInputDataMapper = (data: any, config: any) => {
    if (isNullOrUndefined(data) || data === '') {
        return [];
    }
    if (!config || !config.multiselectOptions || !Array.isArray(config.multiselectOptions)) {
        return [];
    }
    if (!Array.isArray(data)) {
        data = [data];
    }

    const isMultiOptionGrouped = config.multiselectOptions.some((option: {[key: string]: any}) => {
        return option.hasOwnProperty('options');
    });

    if (isMultiOptionGrouped) {
        const selectedOptions: any[] = [];
        data.forEach((entry: string) => {
            config.multiselectOptions.forEach((option: {[key: string]: any}) => {
                if (option.hasOwnProperty('options')) {
                    return option.options.find((candidate: IMultiselectOption) => {
                        if (candidate.value === entry) {
                            return selectedOptions.push(candidate);
                        }
                    });
                }

                if (option.value === entry) {
                    return selectedOptions.push(option);
                }
            });
        });
        return selectedOptions;
    }

    const options = data.map((entry: string) => {
        return config.multiselectOptions.find((candidate: IMultiselectOption) => {
            return candidate.value === entry;
        });
    });

    return options ? options : [];
};

export const multiCollectionOutputDataMapper = (data: any) => {
    if (Array.isArray(data)) {
        return data.length > 0
            ? data.filter((entry) => isNotNullOrUndefined(entry)).map((entry) => (isNotNullOrUndefined(entry.value) ? entry.value : entry))
            : null;
    }
    if (data && typeof data === 'object') {
        return data.value;
    }

    return data;
};

export const createFormPath = (path: string | null | undefined): string => {
    path ||= '';

    return `${filterFormPath(path)}`;
};

export const appendFormPath = (path: string | null | undefined, part: string): string => {
    path ||= '';

    return `${path}.${filterFormPath(part)}`;
};

const filterFormPath = (path: string): string => {
    return path.replace(/[\-.]/g, '_');
};

export const createFormButtonClass = (path: string | null | undefined): string => {
    path = path ? path : 'form';

    const formattedPath = convertToKebabCase(path);
    return `${formattedPath}-submit`;
};

export const createFormInputClass = (path: string | null | undefined): string => {
    path = path ? path : 'form';

    const formattedPath = convertToKebabCase(path);
    return `${formattedPath}-input`;
};

export const createFormClass = (path: string | null | undefined): string => {
    path = path ? path : 'form';
    return convertToKebabCase(path);
};

export const convertToMultiselectLabels = (data: {[key: string]: any} | null, t?: any, isFormatted?: boolean): IMultiselectOption[] => {
    if (data === null) {
        return [];
    }

    return data.map((item: {[key: string]: any}) => {
        const itemName = isFormatted ? item.name.toLowerCase() : item.name;
        return {label: t ? t(itemName) : item.name, value: item.id};
    });
};

export const sortMultiselectLabels = (multiselectOptions: IMultiselectOption[]) => {
    return multiselectOptions.sort((a: IMultiselectOption, b: IMultiselectOption) => {
        const labelA = a.label.toLowerCase(),
            labelB = b.label.toLowerCase();
        return labelA < labelB ? -1 : labelA > labelB ? 1 : 0;
    });
};

export const convertArrayToObject = (array: IRangePickerOption[], key: string): {[key: number]: IRangePickerOption} => {
    const initialValue = {};
    return array.reduce((obj: any, item: any) => {
        return {
            ...obj,
            [item[key]]: item,
        };
    }, initialValue);
};

export const convertSeniorityToRangeValues = (seniorityLevels: IModelSeniority[] | null) => {
    if (null === seniorityLevels) {
        return {};
    }
    let rangeLabels: IRangePickerOption[] = [];
    seniorityLevels.map((level: IModelSeniority) => {
        let obj: any = {
            label: level['name'],
            value: level['id'],
            level: level['level'],
        };

        return rangeLabels.push(obj);
    });

    return convertArrayToObject(rangeLabels, 'level');
};

export const formatRangePickerLabel = (value: number, labels: {[key: number]: IRangePickerOption}): string => {
    if (isNullOrUndefined(labels) && Object.keys(labels).length === 0) {
        return;
    }
    if (value === 10 || value === 30 || value === 50 || value === 70 || value === 90) {
        return '';
    }

    return labels.hasOwnProperty(value) ? labels[value].label : '';
};
