import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {catchError, switchMap} from 'rxjs/operators';
import {of, Observable} from 'rxjs';
import {
    applyOffersFilters,
    changeIsOffersLoading,
    changeOffersPageError,
    fetchOffersList,
    setOffersList,
} from '../reducers/offersPageSlice';
import {
    addAlert,
    getErrorMessage,
    AlertType,
    flattenObj,
    RestQueryParams,
    deepCloneObject,
    isNotNullOrUndefined,
} from 'jobhunter-common-web';
import {getOffersAPI} from '../../api/getOffersApi';
import {RootState} from '../reducers';
import {offerFiltersSelector} from '../selectors/offersPageSelectors';

const fetchOffersListEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getOffers(action$, state$, fetchOffersList);
};

const applyOffersFiltersEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getOffers(action$, state$, applyOffersFilters);
};

const getOffers = (action$: Observable<any>, state$: StateObservable<RootState>, actionType: any) => {
    return action$.pipe(
        ofType(actionType.type),
        switchMap((): any => {
            const filters = deepCloneObject(offerFiltersSelector(state$.value));

            if (isNotNullOrUndefined(filters?.minimumSalary)) {
                filters['minimumSalary[gte]'] = filters.minimumSalary;
                delete filters.minimumSalary;
            }

            const flattenedParams = flattenObj(filters),
                params = new RestQueryParams(flattenedParams);

            return getOffersAPI(params).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([setOffersList(resp['hydra:member'])]);
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};
const successActions = (changeSliceList?: any[]): any[] => {
    const actions = [changeIsOffersLoading(false)];

    if (changeSliceList) {
        return actions.concat(changeSliceList);
    }
    return actions;
};

const updateListErrorActions = (error: any): any[] => {
    return [
        changeIsOffersLoading(false),
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
        changeOffersPageError(getErrorMessage(error)),
    ];
};

const offersPageEpic = combineEpics(fetchOffersListEpic, applyOffersFiltersEpic);

export default offersPageEpic;
