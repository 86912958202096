import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IOffersPageState} from '../reducers/offersPageSlice';

export const selectOffersPage = (state: RootState) => {
    return state.offersPage;
};

export const offerFiltersSelector = createSelector([selectOffersPage], (state: IOffersPageState) => state.filters);
export const offersListSelector = createSelector([selectOffersPage], (state: IOffersPageState) => state.offersList);
export const isOffersLoadingSelector = createSelector([selectOffersPage], (state: IOffersPageState) => state.isOffersLoading);
export const isOffersInitializedSelector = createSelector([selectOffersPage], (state: IOffersPageState) => state.isOffersInitialized);
export const offersErrorSelector = createSelector([selectOffersPage], (state: IOffersPageState) => state.offersPageErrors);
