import React from 'react';
import {NavLink} from 'react-router-dom';
import {IBreadcrumbsItemPath, IBreadcrumbsProps} from '../../types';
import Translation from '../Translation';

type Props = IBreadcrumbsProps;

class Breadcrumbs extends React.Component<Props, any> {
    render() {
        return (
            <nav aria-label="Breadcrumb" className="breadcrumbs">
                <ol className="breadcrumb-list">
                    {this.props.breadcrumbs.map((item: IBreadcrumbsItemPath, index: number) => {
                        const itemPath = item.isLinkExternal ? {pathname: `${item.path}`} : item.path;

                        return (
                            <li className={`breadcrumb-item ${item.isItemActive ? 'item-active' : ''}`} key={index}>
                                <NavLink
                                    to={itemPath}
                                    className="breadcrumb-link"
                                    // exact={true}
                                    target={item.isLinkExternal ? '_blank' : '_self'}>
                                    {item.icon ? <i className={`feather ${item.icon}`} /> : null}
                                    <p>
                                        <Translation text={item.labelKey} />
                                    </p>
                                </NavLink>
                            </li>
                        );
                    })}
                </ol>
            </nav>
        );
    }
}

export default Breadcrumbs;
