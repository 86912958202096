import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IOfferViewPageState} from '../reducers/offerViewPageSlice';

export const selectOfferViewPage = (state: RootState) => {
    return state.offerViewPage;
};

export const offerViewSelector = createSelector([selectOfferViewPage], (state: IOfferViewPageState) => state.offerView);
export const isOfferViewPageLoadingSelector = createSelector(
    [selectOfferViewPage],
    (state: IOfferViewPageState) => state.isOfferViewPageLoading
);
export const isOfferViewPageInitializedSelector = createSelector(
    [selectOfferViewPage],
    (state: IOfferViewPageState) => state.isOfferViewPageInitialized
);
export const offerViewPageErrorSelector = createSelector([selectOfferViewPage], (state: IOfferViewPageState) => state.offerViewPageError);
