import React, {Fragment, useState, useRef} from 'react';
import classnames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import VerticalNavMenuItems from './VerticalNavMenuItems';
import VerticalMenuHeader from './VerticalMenuHeader';
import {ISidebarProps, ISideMenuGroup, ISideMenuHeader, ISideMenuItem} from '../../types';

type Props = ISidebarProps;

const Sidebar = (props: Props) => {
    const {menuCollapsed, currentActiveItem, skin, logoImage, logoMobileImage, menuData} = props;

    const [groupOpen, setGroupOpen] = useState([]);
    const [groupActive, setGroupActive] = useState([]);
    const [currentActiveGroup, setCurrentActiveGroup] = useState([]);
    const [activeItem, setActiveItem] = useState(null);
    const [menuHover, setMenuHover] = useState(false);
    const shadowRef = useRef(null);

    const onMouseEnter = () => {
        setMenuHover(true);
    };

    const scrollMenu = (container: any) => {
        if (shadowRef && container.scrollTop > 0) {
            if (!(shadowRef as any).current.classList.contains('d-block')) {
                (shadowRef as any).current.classList.add('d-block');
            }
        } else {
            if ((shadowRef as any).current.classList.contains('d-block')) {
                (shadowRef as any).current.classList.remove('d-block');
            }
        }
    };

    return (
        <Fragment>
            <div
                className={classnames('main-menu menu-fixed menu-accordion menu-shadow', {
                    expanded: menuHover || !menuCollapsed,
                    'menu-light': skin !== 'semi-dark' && skin !== 'dark',
                    'menu-dark': skin === 'semi-dark' || skin === 'dark',
                })}
                onMouseEnter={onMouseEnter}
                onMouseLeave={() => setMenuHover(false)}>
                <Fragment>
                    <VerticalMenuHeader
                        setGroupOpen={setGroupOpen}
                        menuHover={menuHover}
                        logoImage={logoImage}
                        logoImageMobile={logoMobileImage}
                        isMenuMobile={props.isMenuMobile}
                        toggleMobileMenu={props.toggleMobileMenu}
                        {...props}
                    />
                    <div className="shadow-bottom" ref={shadowRef} />
                    <PerfectScrollbar
                        className="main-menu-content"
                        options={{wheelPropagation: false}}
                        onScrollY={(container: any) => scrollMenu(container)}>
                        <div className="menu">
                            {menuData.map((menu: (ISideMenuGroup | ISideMenuItem | ISideMenuHeader)[], index: number) => {
                                return (
                                    <ul className="navigation navigation-main" key={index}>
                                        <VerticalNavMenuItems
                                            items={menu}
                                            parentItem={null}
                                            menuHover={menuHover}
                                            groupOpen={groupOpen}
                                            activeItem={activeItem}
                                            groupActive={groupActive}
                                            currentActiveGroup={currentActiveGroup}
                                            setGroupOpen={setGroupOpen}
                                            menuCollapsed={menuCollapsed}
                                            setActiveItem={setActiveItem}
                                            setGroupActive={setGroupActive}
                                            setCurrentActiveGroup={setCurrentActiveGroup}
                                            currentActiveItem={currentActiveItem}
                                            toggleMobileMenu={props.toggleMobileMenu}
                                        />
                                    </ul>
                                );
                            })}
                        </div>
                    </PerfectScrollbar>
                </Fragment>
            </div>
        </Fragment>
    );
};

export default Sidebar;
