import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {UserRole} from '../../types';

export interface IVideoCallDetailsState {
    consultationId: string | null;
    userSecret: string | null;
    userRole: UserRole | null;
    isFullScreenMode: boolean;
    audioInputId: string | null;
    audioOutputId: string | null;
    videoInputId: string | null;
}

export interface ISetVideoCallDetails {
    consultationId: string;
    userSecret: string;
    userRole: UserRole;
}

export interface IChangeScreenMode {
    isFullScreenMode: boolean;
}
export interface IChangeMediaDeviceId {
    id: string;
}

const initialState: IVideoCallDetailsState = {
    consultationId: null,
    userSecret: null,
    userRole: null,
    isFullScreenMode: false,
    audioInputId: null,
    audioOutputId: null,
    videoInputId: null,
};

const videoCallDetailsSlice = createSlice({
    name: 'videoCallDetails',
    initialState: initialState,
    reducers: {
        setVideoCallDetails: {
            reducer: (state: IVideoCallDetailsState, action: PayloadAction<ISetVideoCallDetails>) => {
                return {
                    // agoraChannel: action.payload.agoraChannel,
                    consultationId: action.payload.consultationId,
                    userSecret: action.payload.userSecret,
                    userRole: action.payload.userRole,
                    isFullScreenMode: state.isFullScreenMode,
                    audioInputId: state.audioInputId,
                    audioOutputId: state.audioOutputId,
                    videoInputId: state.videoInputId,
                };
            },
            prepare(consultationId: string, userSecret: string, userRole: UserRole) {
                return {
                    payload: {
                        consultationId: consultationId,
                        userSecret: userSecret,
                        userRole: userRole,
                    },
                };
            },
        },
        changeScreenMode: {
            reducer: (state: IVideoCallDetailsState, action: PayloadAction<IChangeScreenMode>) => {
                return {
                    consultationId: state.consultationId,
                    userSecret: state.userSecret,
                    userRole: state.userRole,
                    isFullScreenMode: action.payload.isFullScreenMode,
                    audioInputId: state.audioInputId,
                    audioOutputId: state.audioOutputId,
                    videoInputId: state.videoInputId,
                };
            },
            prepare(isFullScreenMode: boolean) {
                return {
                    payload: {
                        isFullScreenMode: isFullScreenMode,
                    },
                };
            },
        },
        changeAudioInputDevice: {
            reducer: (state: IVideoCallDetailsState, action: PayloadAction<IChangeMediaDeviceId>) => {
                return {
                    consultationId: state.consultationId,
                    userSecret: state.userSecret,
                    userRole: state.userRole,
                    isFullScreenMode: state.isFullScreenMode,
                    audioInputId: action.payload.id,
                    audioOutputId: state.audioOutputId,
                    videoInputId: state.videoInputId,
                };
            },
            prepare(id: string) {
                return {
                    payload: {
                        id: id,
                    },
                };
            },
        },
        changeAudioOutputDevice: {
            reducer: (state: IVideoCallDetailsState, action: PayloadAction<IChangeMediaDeviceId>) => {
                return {
                    consultationId: state.consultationId,
                    userSecret: state.userSecret,
                    userRole: state.userRole,
                    isFullScreenMode: state.isFullScreenMode,
                    audioInputId: state.audioInputId,
                    audioOutputId: action.payload.id,
                    videoInputId: state.videoInputId,
                };
            },
            prepare(id: string) {
                return {
                    payload: {
                        id: id,
                    },
                };
            },
        },
        changeVideoInputDevice: {
            reducer: (state: IVideoCallDetailsState, action: PayloadAction<IChangeMediaDeviceId>) => {
                return {
                    consultationId: state.consultationId,
                    userSecret: state.userSecret,
                    userRole: state.userRole,
                    isFullScreenMode: state.isFullScreenMode,
                    audioInputId: state.audioInputId,
                    audioOutputId: state.audioOutputId,
                    videoInputId: action.payload.id,
                };
            },
            prepare(id: string) {
                return {
                    payload: {
                        id: id,
                    },
                };
            },
        },
    },
});

export const {setVideoCallDetails, changeScreenMode, changeAudioInputDevice, changeAudioOutputDevice, changeVideoInputDevice} =
    videoCallDetailsSlice.actions;

export default videoCallDetailsSlice.reducer;
