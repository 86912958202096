import {Observable} from 'rxjs';
import {IAuthEntry} from '../store/reducers/authSlice';
import {jobHunterAuthAPI} from './provider/jobHunterAuth';
import {RestQueryParams} from './base/queryParams';

export function loginAPI(
    username: string,
    password: string
): Observable<IAuthEntry> {
    return jobHunterAuthAPI.post(
        'api/auth/token',
        {
            username: username,
            password: password
        },
        new RestQueryParams()
    );
}
