import {API_URL} from '../config/entrypoint';
import {ajax} from 'rxjs/ajax';

export function downloadOrganizationVerificationFileAPI(authToken: string, verificationFileId: string) {
    return ajax({
        url: `${API_URL}/api/organization_verification_files/${verificationFileId}/stream`,
        method: 'GET',
        responseType: 'blob',
        headers: {
            Authorization: 'Bearer ' + authToken,
            Accept: 'application/ld+json',
            'Content-type': 'application/pdf',
        },
    });
}
