import React, {Component} from 'react';
import {IRatingSymbolProps} from "../../../types";


type Props = IRatingSymbolProps;

class RatingSymbol extends Component<Props> {
    render() {
        const backgroundNode = this.renderIconNode(this.props.inactiveIcon),
            showbgIcon = this.props.percent < 100,
            bgIconContainerStyle = showbgIcon ? {} : {visibility: 'hidden' as any},
            iconNode = this.renderIconNode(this.props.activeIcon),
            iconContainerStyle = {
                [this.props.direction === 'rtl' ? 'right' : 'left']: 0,
                width: `${this.props.percent}%`,
            };

        return (
            <span
                className={`rating-item ${!this.props.readonly ? '' : 'disabled'}`}
                onClick={this.handleMouseClick}
                onMouseMove={this.handleMouseMove}
                onTouchMove={this.handleMouseMove}
                onTouchEnd={this.handleTouchEnd}>
                <span style={bgIconContainerStyle}>{backgroundNode}</span>
                <span className="icon-container" style={iconContainerStyle}>
                    {iconNode}
                </span>
            </span>
        );
    }

    private handleMouseMove = (e: any) => {
        if (this.props.onMouseMove) {
            this.props.onMouseMove(this.props.index, e);
        }
    };

    private handleMouseClick = (e: any) => {
        if (this.props.onClick) {
            e.preventDefault();
            this.props.onClick(this.props.index, e);
        }
    };

    private handleTouchEnd = (e: any) => {
        if (this.props.onTouchEnd) {
            this.props.onTouchEnd(this.props.index, e);
        }
    };

    private renderIconNode = (icon: any) => {
        if (React.isValidElement(icon)) {
            return icon;
        }
        if (typeof icon === 'object' && icon !== null) {
            return <span style={icon} />;
        }
        if (Object.prototype.toString.call(icon) === '[object String]') {
            return <span className={icon} />;
        }
    };
}

export default RatingSymbol;
