import React, {Component} from 'react';
import {ISideMenuItem, Translation, withRouterWrapper, WithRouterWrapperProps} from 'jobhunter-common-web';
import menu from '../PanelHost/menu-items';
import classnames from 'classnames';
import {NavLink} from 'react-router-dom';
import {WithTranslation, withTranslation} from 'react-i18next';
import {Facebook, AtSign as Email} from 'react-feather';

declare type WithRouterProps = typeof WithRouterWrapperProps;

interface IFooterProps extends WithTranslation, WithRouterProps {}

class Footer extends Component<IFooterProps> {
    render() {
        return (
            <footer className="footer">
                <h2 className="sr-only">
                    <Translation text="auth.authFooter.srTitle" />
                </h2>
                <div className="container">
                    <div className="row">
                        <div className="footer-container">
                            <div className="footer-details">
                                <div className="footer-contact">
                                    <div className="footer-logo" />
                                    <div>
                                        <a href="mailto:contact@jobhunter.io" className="social-link">
                                            <Email size={30} />
                                            <span className="sr-only">
                                                <Translation text="auth.authFooter.socialIcons.email" />
                                            </span>
                                        </a>

                                        <a href="https://www.facebook.com/groups/409242576164804" target="_blank" className="social-link">
                                            <Facebook size={30} />
                                            <span className="sr-only">
                                                <Translation text="auth.authFooter.socialIcons.facebook" />
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="footer-menu">
                                <div className="footer-contact">
                                    <h3 className="title">
                                        <Translation text="auth.authFooter.addressColumn.title" />
                                    </h3>
                                    <p>
                                        <Translation text="auth.authFooter.addressColumn.address1" />
                                    </p>
                                    <p>
                                        <Translation text="auth.authFooter.addressColumn.address2" />
                                    </p>
                                    <p>
                                        <Translation text="auth.authFooter.addressColumn.nip" />
                                    </p>
                                </div>
                                <div className="footer-navigation">
                                    <img src={require('../../assets/images/eu-partners-logo.png')} alt="EU partners logo" />
                                    <div>
                                        <ul className="navigation navigation-main">{this.renderNavigation()}</ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="footer-copyright">
                            <Translation text="footer.copyright" config={{date: new Date().getFullYear()}} />
                        </div>
                    </div>
                </div>
            </footer>
        );
    }

    private renderNavigation = () => {
        const menuItems = menu(),
            {t} = this.props;

        if (menuItems.length) {
            return menuItems.map((item: typeof ISideMenuItem) => {
                return (
                    <li
                        className={classnames({
                            'nav-item': true,
                            active: item.navLink === this.props.router.location.pathname,
                        })}
                        key={item.id}>
                        <NavLink
                            className={classnames('d-flex align-items-center')}
                            to={item.navLink}
                            target={undefined}
                            onClick={(e) => {
                                if (item.navLink.length === 0 || item.navLink === '#') {
                                    e.preventDefault();
                                }
                            }}>
                            {item.icon}
                            <span className="menu-item text-truncate">{t(item.title)}</span>
                        </NavLink>
                    </li>
                );
            });
        } else {
            return null;
        }
    };
}

export default withTranslation()(withRouterWrapper(Footer));
