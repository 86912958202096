import {createSelector} from '@reduxjs/toolkit';
import {IAccountState} from '../reducers/accountSlice';
import {RootState} from '../reducers';

export const selectAccount = (state: RootState): IAccountState => {
    return (state as any).account;
};

export const accountSelector = createSelector([selectAccount], (state: IAccountState) => state.account);

export const candidateFullInfoSelector = createSelector([selectAccount], (state: IAccountState) => state.account?.candidateFullInfo);

export const organizationFullInfoSelector = createSelector([selectAccount], (state: IAccountState) => state.account?.organizationFullInfo);

export const accountLoadingSelector = createSelector([selectAccount], (state: IAccountState) => state.isLoading);

export const accountInitializedSelector = createSelector([selectAccount], (state: IAccountState) => state.isInitialized);

export const accountErrorSelector = createSelector([selectAccount], (state: IAccountState) => state.error);
