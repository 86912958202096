import React from 'react';
import {connect} from 'react-redux';
import {Uploader} from 'rsuite';
import {authTokenSelector, isSameValue} from '../..';
import {API_URL} from '../../config/entrypoint';
import {RootState} from '../../store/reducers';
import Translation from '../Translation';
import {FileType} from '../../types';

interface IConnectedImageUploaderProps {
    readonly authToken: string;
}

interface IExtendedImageUploaderProps {
    readonly triggerText: string;
    readonly selectedImageFile?: any;
    readonly onImageChange: (imageId: string, imageFile: FileType) => void;
}

interface IImageUploaderProps extends IConnectedImageUploaderProps, IExtendedImageUploaderProps {}

interface IImageUploaderState {
    value: any[];
    isProcessing: boolean;
    inputFileUploaded: boolean;
}

class ImageUploader extends React.Component<IImageUploaderProps, IImageUploaderState> {
    constructor(props: IImageUploaderProps) {
        super(props);
        this.state = {
            value: [],
            isProcessing: false,
            inputFileUploaded: false,
        };
    }

    componentDidUpdate(prevProps: Readonly<IImageUploaderProps>): void {
        if (!isSameValue(this.props.selectedImageFile, prevProps.selectedImageFile) && this.props.selectedImageFile) {
            let fileArray = [];
            fileArray.push(this.props.selectedImageFile);
            this.setState({value: fileArray, inputFileUploaded: true});
        }
    }

    render() {
        return (
            <Uploader
                removable={true}
                className={`form-input ${this.state.value.length > 0 ? 'uploaded' : 'empty'}`}
                action={`${API_URL as string}/media_objects`}
                multiple={false}
                listType="text"
                autoUpload={true}
                headers={{Authorization: 'Bearer ' + this.props.authToken}}
                onSuccess={this.handleUploadSuccess}
                onRemove={(file: FileType) => this.removeFile(file)}
                fileList={this.state.value}>
                {this.props.triggerText && (
                    <div>
                        <Translation text={this.props.triggerText} />
                    </div>
                )}
            </Uploader>
        );
    }

    private handleUploadSuccess = (response: any, file: FileType) => {
        if (response) {
            const imageId = response['@id'].replace('/media_object/', '');
            this.props.onImageChange(imageId, file);
            this.setState({inputFileUploaded: true});
        }
    };

    private removeFile = (file: FileType) => {
        const uploadedFiles = this.state.value.filter((item: FileType) => item.fileKey !== file.fileKey);

        this.setState({value: uploadedFiles, inputFileUploaded: false});
    };
}

export default connect((state: RootState) => ({
    authToken: authTokenSelector(state),
}))(ImageUploader);
