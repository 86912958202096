import {createSelector} from '@reduxjs/toolkit';
import {IServiceTypeState} from '../reducers/serviceTypeSlice';

export const selectServiceType = (state: any): IServiceTypeState => {
    return state.serviceType;
};

export const serviceTypesSelector = createSelector([selectServiceType], (state: IServiceTypeState) => state.serviceTypes);

export const serviceTypesLoadingSelector = createSelector([selectServiceType], (state: IServiceTypeState) => state.isLoading);

export const serviceTypesInitializedSelector = createSelector([selectServiceType], (state: IServiceTypeState) => state.isInitialized);

export const serviceTypesErrorSelector = createSelector([selectServiceType], (state: IServiceTypeState) => state.error);
