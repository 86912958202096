import {AllUserConnections, ChatContact, ChatMessage, ChatServerMessagePayload} from '../../types';
import {Observable, Subject} from 'rxjs';

export type ChatConfig = {
    webRTCConfig: {
        chatSignalingServerAddress: string;
        serverUrls: RTCIceServer[];
    };
    saveMessagesConfig?: SaveMessagesConfig;
};

export type SaveMessagesConfig = {
    saveMessagesOnlyWhenPeerIsOffline: boolean;
    messagesNumberPerHistoryPage: number;
    saveFile: (authToken: string, formData: FormData) => Promise<Response>;
    sendMessageToOfflineAPI: (accessToken: string, message: ChatServerMessagePayload) => Observable<any>;
    getMessagesFromUserAPI: (accessToken: string, accountId: string, page?: number) => Observable<MessageMapperResult>;
    postMessageReadConfirmationAPI?: (accessToken: string, messages: string[]) => Observable<any>;
    getContacts?: ((accessToken: string, accountId: string) => Observable<any>) | null;
};

//*** WithChatConnection***
export interface IWithChatConnectionProps {
    authToken: string;
    accountId: string;
    channelsToJoin: ChatContact[];
}

export interface IWithChatConnectionState {
    userPeerConnections: AllUserConnections;
    chunksArrays: {[roomId: string]: string[]};
    selectedChatRoomId: string;
    alert: string[];
    nodeServerConnected: boolean;
    hadConnectionError: boolean;
    allowedChannels: ChatContact[];
}

export interface IWithChatConnection {
    peerConnections: AllUserConnections;
    addMessage: (message: typeof ChatMessage, room: string) => void;
    setSelectedChatRoomId: (roomId: string) => void;
    selectedChatRoomId: string;
    hasUnreadMessages: string[];
    hasNodeConnection: boolean;
    allowedChannels: ChatContact[];
    errors: Subject<Error>;
    confirmMessageRead: (messagesId: string[]) => void;
    getMessagesFromUser: (accountId: string, page?: number) => Observable<any>;
}

export enum SocketMessages {
    LOGGED_IN = 'logged_in',
    JOIN_CHANNELS = 'join_channels',
    CHANNEL_JOINED = 'channel_joined',
    USER_JOINED_CHANNEL = 'user_joined_channel',
    CANDIDATE = 'candidate',
    OFFER = 'offer',
    ANSWER = 'answer',
    CONNECTION = 'connection',
    DISCONNECTING = 'disconnecting',
    USER_HAS_LEFT = 'user_has_left',
    CONNECT = 'connect',
    DISCONNECT = 'disconnect',
    CONNECT_ERROR = 'connect_error',
    ALLOWED_CHANNELS_LIST = 'allowed_channels_list',
    ERROR = 'error',
    NOTIFICATION = 'notification',
}

//*** Chat Contacts Types ***

export type ContactsListResponse = {
    '@context': '/api/contexts/Account';
    '@id': '/api/accounts';
    '@type': 'hydra:Collection';
    'hydra:member': ServerChatContact[];
    'hydra:search': {
        '@type': 'hydra:IriTemplate';
        'hydra:template': '/api/accounts/chat_contacts{?type}';
        'hydra:variableRepresentation': 'BasicRepresentation';
        'hydra:mapping': {
            '@type': 'IriTemplateMapping';
            property: any;
            required: boolean;
            variable: 'type';
        }[];
    };
    'hydra:totalItems': number;
};

export type ServerChatContact = {
    '@id': string;
    '@type': 'Account';
    contact: {
        '@type': 'FullInfoOutput';
        '@id': string;
        candidateFullInfo: CandidateFullInfo | null;
        organizationFullInfo: OrganisationFullInfo | null;
    };
    unread: number;
};

export type OrganisationFullInfo = {
    '@id': string;
    '@type': 'OrganizationFullInfoOutput';
    id: string;
    organization: Organisation;
    user: User;
    verification: {
        '@id': string;
        '@type': 'OrganizationVerificationOutput';
        files: VerificationFile[];
    };
};

export type CandidateFullInfo = {
    '@id': string;
    '@type': 'CandidateFullInfoOutput';
    account: User;
    careers: CandidateCareer[];
    cv: UserAvatarOrFile;
    externalLinks: ExternalLink[];
    //todo jak to wygląda
    languageTestResults: any[];
    languages: CandidateLanguage[];
    preferences: CandidatePreferences;
    technologies: Technology[];
    technologyTestResults: TechnologyTestResult[];
};

export type ExternalLink = {
    '@id': string;
    '@type': 'ExternalLinkOutput';
    id: string;
    link: string;
    type: string;
};

export type CandidateCareer = {
    '@id': string;
    '@type': 'CareerEntryOutput';
    company: string;
    from: string;
    id: string;
    position: string;
    seniority: CandidateCareerSeniority;
    to: string | null;
};

export type CandidateCareerSeniority = {
    '@type': 'SeniorityOutput';
    '@id': string;
    id: string;
    name: string;
    level: number;
};

export type TechnologyTestResult = {
    '@id': string;
    '@type': 'TechnologyTestResultOutput';
    id: string;
    result: number;
    technology: {
        '@type': 'TechnologyOutput';
        '@id': string;
        id: string;
        name: string;
    };
};

export type CandidatePreferences = {
    '@id': string;
    '@type': 'CandidatePreferencesOutput';
    cities: City[];
    companyTypes: CompanyType[];
    contractTypes: ContractType[];
    employmentTypes: EmploymentType[];
    industries: Industry[];
    minimumSalary: string;
    relocationOnly: boolean;
    technologies: Technology[];
    workTypes: WorkType[];
};

export type WorkType = {
    '@id': string;
    '@type': 'WorkTypeOutput';
    id: string;
    name: string;
};

export type EmploymentType = {
    '@id': string;
    '@type': 'EmploymentTypeOutput';
    id: string;
    name: string;
};

export type ContractType = {
    '@id': string;
    '@type': 'ContractTypeOutput';
    id: string;
    name: string;
};

export type TechnologyTool = {
    '@id': string;
    '@type': 'TechnologyToolOutput';
    id: string;
    name: string;
};

export type CandidateTechnology = {
    '@id': string;
    '@type': 'TechnologySkillEntryOutput';
    id: string;
    seniority: CandidateCareerSeniority;
    technology: Technology;
    technologyTool: TechnologyTool[];
};

export type Technology = {
    '@id': string;
    '@type': 'TechnologyOutput';
    id: string;
    name: string;
};

export type CandidateLanguage = {
    '@id': string;
    '@type': 'LanguageEntryOutput';
    language: {
        '@type': 'LanguageOutput';
        '@id': string;
        id: string;
        name: string;
    };
    languageLevel: {
        '@type': 'LanguageLevelOutput';
        '@id': string;
        id: string;
        name: string;
    };
};

export type VerificationFile = {
    '@id': string;
    '@type': 'OrganizationVerificationFileOutput';
    file: UserAvatarOrFile;
    type: VerificationFileType;
};

export type VerificationFileType = {
    '@id': string;
    '@type': 'OrganizationVerificationFileTypeOutput';
    id: string;
    name: string;
};

export type User = {
    '@id': string;
    '@type': 'AccountBasicInfoOutput';
    about: string | null;
    avatar: UserAvatarOrFile | null;
    birthDate: string | null;
    cityName: string | null;
    country: Country;
    firstName: string | null;
    id: string;
    lastName: string | null;
    locale: string | null;
    phone: string | null;
};

export type Country = {
    '@type': 'CountryOutput';
    '@id': string;
    id: string;
    name: string;
};

export type Organisation = {
    '@id': string;
    '@type': 'OrganizationOutput';
    id: string;
    organizationCompany: OrganisationCompany;
    organizationDetails: OrganizationDetails;
};

export type OrganisationCompany = {
    '@id': string;
    '@type': 'OrganizationCompanyOutput';
    address: string | null;
    avatar: string | null;
    city: string | null;
    country: Country;
    description: string | null;
    name: string;
    phone: string | null;
    vatNumber: string | null;
};

export type City = {
    '@id': string;
    '@type': 'CityOutput';
    country: Country;
    id: string;
    name: string;
};

export type CompanyType = {
    '@id': string;
    '@type': 'CompanyTypeOutput';
    id: string;
    name: string;
};

export type OrganizationDetails = {
    '@id': string;
    '@type': 'OrganizationDetailsOutput';
    cities: City[];
    companyTypes: CompanyType[];
    industries: Industry[];
    organizationSize: number | null;
};

export type Industry = {
    '@id': string;
    '@type': 'IndustryOutput';
    id: string;
    name: string;
};

export type UserAvatarOrFile = {
    '@id': string;
    '@type': 'FileOutput';
    contentUrl: string | null;
    fileUrls: {
        largeThumb: string;
        mediumThumb: string;
        original: string;
        smallThumb: string;
        tinyThumb: string;
    };
    id: string;
};

//*** Messages mapper ***
export type MessageMapperResult = {
    messages: [ChatMessage[], ChatServerMessage[]];
    totalResults: number;
};

//*** save message to API ***
export type ChatServerMessage = {
    '@context': string;
    '@id': string;
    '@type': string;
    id: string;
    fromAccountId: string;
    toAccountId: string;
    content: string;
    sentAt: string;
    receivedAt: string;
    mediaObject: {
        '@context': string;
        '@id': string;
        '@type': string;
        id: string;
        fileUrls: {
            largeThumb: string;
            mediumThumb: string;
            original: string;
            smallThumb: string;
            tinyThumb: string;
        } | null;
        contentUrl: string;
        originalFileName: string;
    };
};

//*** save file to media_objects ***

export type MediaObjectAPIResponse = {
    '@context': '/api/contexts/MediaObject';
    '@id': string;
    '@type': 'https://schema.org/MediaObject';
    contentUrls: {
        largeThumb: string;
        mediumThumb: string;
        original: string;
        smallThumb: string;
        tinyThumb: string;
    };
    id: string;
};
