// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Y-5BwO90p0dXtMx4S0kgP{display:flex;flex:1;flex-direction:column;align-items:center;margin:6rem 0 0 0;font-family:Montserrat, sans-serif;font-weight:600}.Y-5BwO90p0dXtMx4S0kgP .yi0XZU03fTLz81_xorp4b{margin-bottom:2.9rem;font-size:5rem;color:#009fe3}.Y-5BwO90p0dXtMx4S0kgP ._3ccydDGDWL-P27UXe_D9gS{text-shadow:-0.78rem 1rem 2.7rem rgba(0,0,0,0.15);line-height:0.96;letter-spacing:0.09rem;margin-bottom:1.5rem;font-size:2.5rem;color:#000;text-align:center}\n", ""]);
// Exports
exports.locals = {
	"container": "Y-5BwO90p0dXtMx4S0kgP",
	"header": "yi0XZU03fTLz81_xorp4b",
	"description": "_3ccydDGDWL-P27UXe_D9gS"
};
module.exports = exports;
