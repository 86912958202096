// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3vwZQ3nTOrn1krCE50vQCy._2-r-e62bW9MzchBuFpfr2S{justify-content:flex-end}._3vwZQ3nTOrn1krCE50vQCy ._1EjZKFPGON3oy75wvui6Bb{color:#bc5a45;padding:0 1rem;font-size:1.2rem}._3vwZQ3nTOrn1krCE50vQCy ._2ZB3i-sGmkAbyHDY0mYBcE{font-family:Montserrat, sans-serif;font-size:1.6rem;line-height:2rem;color:#6E6B7B}\n", ""]);
// Exports
exports.locals = {
	"formGroupWrapper": "_3vwZQ3nTOrn1krCE50vQCy",
	"labelHidden": "_2-r-e62bW9MzchBuFpfr2S",
	"errorBlock": "_1EjZKFPGON3oy75wvui6Bb",
	"groupLabel": "_2ZB3i-sGmkAbyHDY0mYBcE"
};
module.exports = exports;
