import {Observable} from 'rxjs';
import {RestQueryParams} from './base/queryParams';
import {jobHunterAPI} from './provider/jobHunterAPI';

export function sendResetPasswordEmailAPI(payload: any): Observable<any> {
    return jobHunterAPI.post(
        `api/users/request_set_new_password`,
        payload,
        new RestQueryParams(),
    );
}
