import React from 'react';
import {IPriceProps, IPriceState} from '../../types';
import {isNotNullOrUndefined, isNullOrUndefined} from '../../utils/runtimeUtils';

type Props = IPriceProps;
type State = IPriceState;

class Price extends React.Component<Props, State> {
    render() {
        if (
            isNullOrUndefined(this.props.price) ||
            isNullOrUndefined(this.props.price.amount) ||
            isNaN(parseInt(this.props.price?.amount))
        ) {
            return null;
        }
        const amount = parseInt(this.props.price.amount) / 100,
            currency = isNotNullOrUndefined(this.props.price.currency?.code) ? this.props.price.currency?.code : '€'; // 'PLN';

        return (
            <React.Fragment>
                {this.props.amountComponent ? this.props.amountComponent(amount) : <span>{amount}</span>}
                {true === this.props.separateWithNbsp ? <>&nbsp;</> : ''}
                {this.props.currencyComponent ? this.props.currencyComponent(currency) : currency}
            </React.Fragment>
        );
    }
}

export default Price;
