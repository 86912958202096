// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2OLjONn4fBLxgEtRVgvaV2._2d8ZhD-901Im9flpYIqFhV{flex:1;display:flex;background-color:#fff;height:100%;border:0.1rem solid #d2d8dd;border-radius:0.4rem}._2OLjONn4fBLxgEtRVgvaV2._2d8ZhD-901Im9flpYIqFhV .kxjvxA83TyMfih82m3r6w{flex:1;display:flex;position:relative}._2OLjONn4fBLxgEtRVgvaV2._2d8ZhD-901Im9flpYIqFhV .kxjvxA83TyMfih82m3r6w ._1UpbtWX-DHluibufT7v-sl{flex:1}._2OLjONn4fBLxgEtRVgvaV2._2d8ZhD-901Im9flpYIqFhV .kxjvxA83TyMfih82m3r6w ._1UpbtWX-DHluibufT7v-sl ._2Ga8DttioBLtlshCIna7Ms{position:absolute;top:-4rem;right:0}._2OLjONn4fBLxgEtRVgvaV2._2d8ZhD-901Im9flpYIqFhV .kxjvxA83TyMfih82m3r6w._2r8B1o2I3Lp0boxiG8Paqk{width:100%;height:100%}video{max-width:unset}\n", ""]);
// Exports
exports.locals = {
	"meeting": "_2OLjONn4fBLxgEtRVgvaV2",
	"wrapper": "_2d8ZhD-901Im9flpYIqFhV",
	"agMain": "kxjvxA83TyMfih82m3r6w",
	"agContainer": "_1UpbtWX-DHluibufT7v-sl",
	"counter": "_2Ga8DttioBLtlshCIna7Ms",
	"fullScreen": "_2r8B1o2I3Lp0boxiG8Paqk"
};
module.exports = exports;
