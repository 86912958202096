import React from 'react';
import {IRadioProps} from "../../../types";
import parse from 'html-react-parser';
import Translation from "../../Translation";
import {createFormInputClass} from "../../../utils/formUtils";

type Props = IRadioProps;

class Radio extends React.Component<Props> {

  render() {
    return (
      <div className={`radio-control ${this.props.wrapperStyles ? this.props.wrapperStyles : ''}`}>
        {this.props.options.map((option: any, index: number) => {
          const isOptionDisabled = option.hasOwnProperty('isFree') ? option.isFree === false : (option.hasOwnProperty('disabled') ? option.disabled : this.props.disabled);

          return (<label key={index} className={`radio-wrapper ${option.value === this.props.value ? "selected" : ""}`}>
            <input type={"radio"}
                   name={this.props.name}
                   value={option.value}
                   onChange={this.props.handleChange}
                   className={`radio-input ${this.props.inputStyles} ${createFormInputClass(this.props.name)}`}
                   disabled={isOptionDisabled}
                   checked={option.value === this.props.value}
            />
            <span className="radio-label">
              {this.props.isDisplayValueTranslated ? <Translation text={option.displayValue} />
                  : parse(option.displayValue)}
            </span>
            <span className="radio-checkmark" />
          </label>
          )
        })}
      </div>
    );
  }
}

export default Radio;
