import React, {Component} from 'react';
import Rating from './Rating';
import {IRatingComponentProps, IRatingComponentState} from '../../types';

type Props = IRatingComponentProps;
type State = IRatingComponentState;

class RatingComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            value: props.initialRating ? props.initialRating : 0,
        };
    }

    render() {
        const start = this.props.start ? this.props.start : 0,
            stop = this.props.stop ? this.props.stop : 5,
            step = this.props.step ? this.props.step : 1,
            readonly = this.props.readonly ? this.props.readonly : false,
            quiet = this.props.quiet ? this.props.quiet : false,
            fractions = this.props.fractions ? this.props.fractions : 1,
            direction = this.props.direction ? this.props.direction : 'ltr';

        return (
            <Rating
                id={this.props.id}
                className={this.props.className}
                tabIndex={this.props.tabIndex ? this.props.tabIndex : 1}
                aria-label={this.props['aria-label']}
                totalSymbols={this.calculateTotalSymbols(start, stop, step)}
                value={this.translateValueToDisplayValue(this.state.value)}
                placeholderValue={this.translateValueToDisplayValue(this.props.placeholderRating)}
                readonly={readonly}
                quiet={quiet}
                fractions={fractions}
                direction={direction}
                emptySymbol={this.props.emptySymbol}
                fullSymbol={this.props.fullSymbol}
                placeholderSymbol={this.props.placeholderSymbol}
                onClick={this.handleClick}
                onHover={this.handleHover}
            />
        );
    }

    private calculateTotalSymbols(start: number, stop: number, step: number) {
        return Math.floor((stop - start) / step);
    }

    private handleClick = (value: number, e: any) => {
        const newValue = this.translateDisplayValueToValue(value);
        if (this.props.onClick) {
            this.props.onClick(newValue);
        }
        if (this.state.value !== newValue) {
            this.setState(
                {
                    value: newValue,
                },
                () => {
                    if (this.props.onChange) {
                        this.props.onChange(this.state.value);
                    }
                }
            );
        }
    };

    private handleHover = (displayValue?: number) => {
        const value = displayValue === undefined ? displayValue : this.translateDisplayValueToValue(displayValue);
        if (this.props.onHover) {
            this.props.onHover(value);
        }
    };

    private translateDisplayValueToValue = (displayValue: number) => {
        const step = this.props.step ? this.props.step : 1,
            start = this.props.start ? this.props.start : 0,
            fractions = this.props.fractions ? this.props.fractions : 1;

        const translatedValue = displayValue * step + start;
        return translatedValue === this.props.start ? translatedValue + 1 / fractions : translatedValue;
    };

    private translateValueToDisplayValue = (value: number | undefined) => {
        if (value === undefined) {
            return 0;
        }
        const step = this.props.step ? this.props.step : 1,
            start = this.props.start ? this.props.start : 0;

        return (value - start) / step;
    };
}

export default RatingComponent;
