import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IVideoCallDetailsState} from '../reducers/videoCallDetailsSlice';

export const selectVideoDetails = (state: RootState): IVideoCallDetailsState => {
    return state.videoCallDetails;
};

export const consultationIdSelector = createSelector([selectVideoDetails], (state: IVideoCallDetailsState) => state.consultationId);

export const userSecretSelector = createSelector([selectVideoDetails], (state: IVideoCallDetailsState) => state.userSecret);

export const userRoleSelector = createSelector([selectVideoDetails], (state: IVideoCallDetailsState) => state.userRole);

export const isFullScreenModeSelector = createSelector([selectVideoDetails], (state: IVideoCallDetailsState) => state.isFullScreenMode);

export const audioInputDeviceSelector = createSelector([selectVideoDetails], (state: IVideoCallDetailsState) => state.audioInputId);

export const audioOutputDeviceSelector = createSelector([selectVideoDetails], (state: IVideoCallDetailsState) => state.audioOutputId);

export const videoInputDeviceSelector = createSelector([selectVideoDetails], (state: IVideoCallDetailsState) => state.videoInputId);
