import React, {Component} from 'react';
import styles from './styles.module.scss';
import {Avatar, AvatarColor, AvatarSize, Translation} from 'jobhunter-common-web';
import * as Icon from 'react-feather';

export interface IDescriptionItem {
    title: string;
    subtitle: string;
    description: string;
    icon: string;
}

interface IDescriptionSectionProps {
    title: string;
    description: string;
    descriptionItems: IDescriptionItem[];
}

class DescriptionSection extends Component<IDescriptionSectionProps> {
    render() {
        return (
            <div className={styles.descriptionSection}>
                <p className={styles.sectionTitle}>
                    <Translation text={this.props.title} />
                </p>
                <p className={styles.sectionDescription}>
                    <Translation text={this.props.description} />
                </p>

                <div className={styles.sectionDetails}>
                    {this.props.descriptionItems.map((item: IDescriptionItem, index: number) => this.renderDescriptionItem(item, index))}
                </div>
            </div>
        );
    }

    private renderDescriptionItem = (item: IDescriptionItem, index: number) => {
        const IconTag = (Icon as any)[item.icon];

        return (
            <div className={styles.descriptionItem} key={`item_description_${index}`}>
                <div className={styles.iconContainer}>
                    <Avatar color={AvatarColor.LIGHT_PRIMARY} size={AvatarSize.XL} icon={<IconTag size={24} />} tag={'div'} />
                </div>

                <div className={styles.details}>
                    <p className={styles.title}>
                        <Translation text={item.title} />
                    </p>
                    <p className={styles.subtitle}>
                        <Translation text={item.subtitle} />
                    </p>
                    <p className={styles.description}>
                        <Translation text={item.description} />
                    </p>
                </div>
            </div>
        );
    };
}

export default DescriptionSection;
