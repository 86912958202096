import {PayloadAction} from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {of} from 'rxjs';
import {catchError, mergeMap, switchMap} from 'rxjs/operators';
import {loginAPI} from '../../api/loginAPI';
import {AlertType} from '../../types';
import {
    mapResponseAccountToCandidateFullInfo,
    mapResponseAccountToOrganizationFullInfo,
    setAccountState,
    setAccountStateFailure,
} from '../reducers/accountSlice';
import {addAlert} from '../reducers/alertSlice';
import {setAuthState} from '../reducers/authSlice';
import {finishLoginLoading, getLoginCredentials, IGetLoginCredentials, setLoginFailure} from '../reducers/loginSlice';
import {getAccountDataAPI} from '../../api/getAccountData';
import {fetchAllDictionaryData} from '../reducers/workTypeSlice';

const loginStart: Epic = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(getLoginCredentials.type),
        switchMap((action: PayloadAction<IGetLoginCredentials>): any => {
            return loginAPI(action.payload.username, action.payload.password).pipe(
                switchMap((resp: any) => {
                    const authToken = resp.authToken,
                        refreshToken = resp.refreshToken,
                        decoded = jwt_decode(resp.authToken),
                        userRoles = (decoded as any).roles,
                        accountId = (decoded as any).account_id,
                        userId = (decoded as any).user_id;
                    if (isRoleMatched(userRoles, action.payload.role) !== undefined) {
                        return getAccountDataAPI(authToken, accountId).pipe(
                            mergeMap((resp: any) => {
                                const actions: any[] = [
                                        finishLoginLoading(),
                                        setAuthState(
                                            (state$ as any).value.login.username,
                                            authToken,
                                            refreshToken,
                                            null,
                                            userId,
                                            accountId,
                                            userRoles,
                                            true
                                        ),
                                    ],
                                    account = {
                                        candidateFullInfo: mapResponseAccountToCandidateFullInfo(resp),
                                        organizationFullInfo: mapResponseAccountToOrganizationFullInfo(resp),
                                    };
                                actions.push(setAccountState(account));
                                actions.push(fetchAllDictionaryData());

                                return of(...actions);
                            }),
                            catchError((error: any) => {
                                return of(
                                    setAccountStateFailure(error.toString()),
                                    setLoginFailure('alerts.noAccessError'),
                                    setAuthState(null, null, null, null, null, null, null, false),
                                    addAlert({
                                        message: error.response ? error.response.message : 'alerts.baseError',
                                        type: AlertType.WARNING,
                                    })
                                );
                            })
                        );
                    } else {
                        return of(
                            setLoginFailure('alerts.noAccessError'),
                            addAlert({
                                message: 'alerts.noAccessError',
                                type: AlertType.WARNING,
                            })
                        );
                    }
                }),

                catchError((error: any) => {
                    let errorMessage = null;
                    if (error && error.response && error.response.message) {
                        errorMessage = error.response.message === 'Invalid credentials.' ? 'alerts.authError' : error.response.message;
                    }
                    return of(
                        setLoginFailure(error.toString()),
                        addAlert({
                            message: errorMessage ? errorMessage : 'alerts.baseError',
                            type: AlertType.WARNING,
                        })
                    );
                })
            );
        })
    );

export function isRoleMatched(roles: string[], substring: string): string | undefined {
    return roles.find((element: string) => {
        if (element.includes(substring)) {
            return true;
        }
    });
}

const loginEpic = combineEpics(loginStart);

export default loginEpic;
