// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2LqcbMreAIwvHBUKcbmpmd{display:flex}._2LqcbMreAIwvHBUKcbmpmd._19JOaYI7NDmhefzXGF1U3h{align-self:flex-start;position:relative;overflow:visible}._2LqcbMreAIwvHBUKcbmpmd ._1-3xiOE83tz7W3wQSwEyWn{flex:1;background:no-repeat center;background-size:cover}._2LqcbMreAIwvHBUKcbmpmd ._3pGSKkNX57z_Fu81FZij45{position:absolute;top:0;right:0;font-size:1.2rem}._2LqcbMreAIwvHBUKcbmpmd .a7k5HhkxTjLGFf96Ggk_a{display:flex;justify-content:center;align-items:center;font-size:2rem;background-color:#f6f7fb;color:#535763;width:10rem;height:10rem;border:thin solid #d2d8dd;border-radius:0.6rem;overflow:hidden}._2LqcbMreAIwvHBUKcbmpmd .HPo3YNZpOuVAqsAHwH8XM{display:flex;justify-content:center;align-items:center}._2LqcbMreAIwvHBUKcbmpmd .HPo3YNZpOuVAqsAHwH8XM button:nth-of-type(2){margin-left:2rem}\n", ""]);
// Exports
exports.locals = {
	"userInfoImageWrapper": "_2LqcbMreAIwvHBUKcbmpmd",
	"edit-mode": "_19JOaYI7NDmhefzXGF1U3h",
	"userInfoImage": "_1-3xiOE83tz7W3wQSwEyWn",
	"changeImageButton": "_3pGSKkNX57z_Fu81FZij45",
	"userPhotoPlaceholder": "a7k5HhkxTjLGFf96Ggk_a",
	"removeContainer": "HPo3YNZpOuVAqsAHwH8XM"
};
module.exports = exports;
