import {Observable} from 'rxjs';
import {jobHunterAPI, RestQueryParams} from 'jobhunter-common-web';

export function getPaymentSecretAPI(authToken: string | null, paymentId: string): Observable<any> {
    const headers: any = {
        accept: 'application/ld+json',
        'content-type': 'application/json',
    };

    if (authToken) {
        headers['Authorization'] = `Bearer ${authToken}`;
    }

    return jobHunterAPI.put(`api/payments/${paymentId}/stripe/create_payment`, {}, new RestQueryParams(), headers);
}
