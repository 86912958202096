import moment from 'moment';
import React from 'react';
import {Subscription} from 'rxjs';
import {IWeekCalendarProps, IWeekCalendarState} from '../../../types';
import CalendarTimeSlots from '../Calendar/CalendarTimeSlots';
import {isNotNullOrUndefined} from '../../../utils/runtimeUtils';
import {formatHoursAndMinutes} from '../../../utils/timeUtils';
import DateComponent from '../../DateComponent';
import Translation from '../../Translation';

export enum DateChangeType {
    PREV = 'previous',
    NEXT = 'next',
}

type Props = IWeekCalendarProps;
type State = IWeekCalendarState;

class WeekCalendar extends React.Component<Props, State> {
    readonly subscriptions: Subscription[] = [];

    constructor(props: Props) {
        super(props);

        this.state = {
            value: null,
            isLoading: false,
            calendarStartDate: this.props.startDate ? this.props.startDate : new Date(),
            selectedDatesList: null,
            selectedDate: null,
            availableDates: null,
            timeSlots: null,
            expandedSlotsShown: false,
        };
    }

    componentDidMount(): void {
        let days = this.addDays(this.state.calendarStartDate);
        this.setState({selectedDatesList: days});
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (this.props.startDate && this.props.startDate.getTime() !== prevProps.startDate?.getTime()) {
            let days = this.addDays(this.props.startDate);
            this.setState({
                selectedDatesList: days,
                calendarStartDate: this.props.startDate,
            });
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        let isBtnDisabled = new Date(this.state.calendarStartDate).getTime() < new Date().getTime();

        let selectedDate: any = null;
        if (isNotNullOrUndefined(this.props.selectedDate)) {
            selectedDate = this.props.selectedDate;
            if (typeof selectedDate === 'object' && selectedDate?.name === 'selectedDate') {
                selectedDate = selectedDate?.value;
            }
        }

        return (
            <React.Fragment>
                <div className="week-calendar-container">
                    <button
                        className="controls prev-control"
                        onClick={(e: any) => this.onDateChange(e, DateChangeType.PREV)}
                        disabled={isBtnDisabled}>
                        <i className="feather icon-chevron-left" />
                    </button>

                    <div className="week-calendar-controls">
                        <div className="dates-wrapper">
                            {this.state.selectedDatesList &&
                                this.state.selectedDatesList.map((day: Date, index: number) => {
                                    return (
                                        <div className="date-details" key={index}>
                                            <div className="date">
                                                <p className="week-day">
                                                    <DateComponent date={day} format="ddd" />,
                                                </p>
                                                <p>
                                                    <DateComponent date={day} format="DD.MM" />
                                                </p>
                                            </div>
                                            {this.renderTimeSlots(day, selectedDate)}
                                        </div>
                                    );
                                })}
                        </div>
                        <button
                            className="expand-calendar"
                            onClick={(e: any) => {
                                e.preventDefault();
                                return this.setState({expandedSlotsShown: !this.state.expandedSlotsShown});
                            }}>
                            <Translation text="mentors.mentorItem.seeMoreDates" />
                            {this.state.expandedSlotsShown ? (
                                <i className="feather icon-chevron-up" />
                            ) : (
                                <i className="feather icon-chevron-down" />
                            )}
                        </button>
                    </div>

                    <button className="controls next-control" onClick={(e: any) => this.onDateChange(e, DateChangeType.NEXT)}>
                        <i className="feather icon-chevron-right" />
                    </button>
                </div>
            </React.Fragment>
        );
    }

    private addDays = (date: Date): Date[] => {
        let days = [];
        for (let i = 0; i < this.props.daysNumberDisplay; i++) {
            days.push(moment(date).add(i, 'd').toDate());
        }

        return days;
    };

    private renderTimeSlots = (date: Date, selectedDate: any) => {
        let availableTimeSlots: any[] = this.filterDates(date);
        if (!availableTimeSlots) {
            return;
        }

        return (
            <CalendarTimeSlots
                onChange={this.props.onChange}
                selectedDate={selectedDate}
                availableTimeSlots={availableTimeSlots}
                placeholder={this.props.placeholder}
                checkboxSlots={this.props.checkboxSlots}
                isLabelHidden={this.props.isLabelHidden}
                expandedSlotsShown={this.state.expandedSlotsShown}
                timeSlotsType={this.props.timeSlotsType}
                slotsNumberDisplayed={this.props.slotsNumberDisplayed}
            />
        );
    };

    private onDateChange = (e: any, type: DateChangeType) => {
        e.preventDefault();

        let days = [];

        if (type === DateChangeType.NEXT) {
            let date = moment(this.state.calendarStartDate).add(this.props.daysNumberDisplay, 'd').toDate();
            days = this.addDays(date);
            const eventData = {
                target: {
                    value: {
                        name: 'weekDate',
                        value: date,
                    },
                },
            };

            this.setState({
                calendarStartDate: date,
                selectedDatesList: days,
            });

            this.props.onChange(eventData);
        }

        if (type === DateChangeType.PREV) {
            let date = moment(this.state.calendarStartDate).subtract(this.props.daysNumberDisplay, 'd').toDate();
            days = this.addDays(date);
            const eventData = {
                target: {
                    value: {
                        name: 'weekDate',
                        value: date,
                    },
                },
            };

            this.setState({
                calendarStartDate: date,
                selectedDatesList: days,
            });
            this.props.onChange(eventData);
        }
    };

    private filterDates = (date: Date): any[] => {
        let year = new Date(date).getFullYear(),
            month = new Date(date).getMonth() + 1,
            day = new Date(date).getDate();
        let availableTimeSlots: {[key: string]: any}[] = [];
        if (
            this.props.availableConsultationSlots &&
            year in this.props.availableConsultationSlots &&
            month in this.props.availableConsultationSlots[year] &&
            day in this.props.availableConsultationSlots[year][month]
        ) {
            let time = this.props.availableConsultationSlots[year][month][day];

            if (time) {
                Object.keys(time).forEach((key: any) => {
                    availableTimeSlots.push({
                        value: new Date(time[key].starts_at).toISOString(),
                        displayValue: this.formatTime(time[key].starts_at),
                        disabled: new Date(time[key].starts_at) < new Date() || !time[key].is_free,
                    });
                });
            }
        }

        return availableTimeSlots;
    };

    private formatTime(date: string): string {
        let hours = new Date(date).getHours(),
            minutes = new Date(date).getMinutes();

        return formatHoursAndMinutes(hours, minutes);
    }
}

export default WeekCalendar;
