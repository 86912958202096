import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import { Disc, Circle, X } from 'react-feather';
import {IVerticalMenuHeaderProps, IVerticalMenuHeaderState} from "../../../types";


type Props = IVerticalMenuHeaderProps;
type State = IVerticalMenuHeaderState

class VerticalMenuHeader extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            logo: null,
            isLogoMobile: false,
        }
    }

    componentDidMount(): void {
        this.setLogo();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.menuCollapsed !== prevProps.menuCollapsed || this.props.menuHover !== prevProps.menuHover) {
            this.setLogo();
        }
    }

    render() {
        return (
            <div className='navbar-header'>
                <ul className='nav navbar-nav flex-row'>
                    <li className='nav-item me-auto'>
                        <NavLink to={'/'} className='navbar-brand'>
                            <span className={`brand-logo ${this.state.isLogoMobile ? 'logo-mobile' : ''}`}>
                              <img src={this.state.logo} alt='logo' />
                            </span>
                        </NavLink>
                    </li>
                    <li className='nav-item nav-toggle'>
                        <div className='nav-link modern-nav-toggle cursor-pointer'>
                            {this.renderToggleIcon()}
                        </div>
                    </li>
                    {this.props.isMenuMobile ? (
                        <li className='nav-item nav-toggle'>
                            <div className='nav-link modern-nav-toggle cursor-pointer'>
                                <X
                                    size={20}
                                    data-tour='toggle-icon'
                                    className='text-primary toggle-icon'
                                    onClick={() => this.props.toggleMobileMenu()} />
                            </div>
                        </li>
                    ) : null}
                </ul>
            </div>
        );
    }

    private renderToggleIcon = () => {
        if (!this.props.menuCollapsed) {
            return (
                <Disc
                    size={20}
                    data-tour='toggle-icon'
                    className='text-primary toggle-icon d-none d-xl-block'
                    onClick={() => this.props.setMenuCollapsed(true)} />
            )
        } else {
            return (
                <Circle
                    size={20}
                    data-tour='toggle-icon'
                    className='text-primary toggle-icon d-none d-xl-block'
                    onClick={() => this.props.setMenuCollapsed(false)} />
            )
        }
    };

    private setLogo = () => {
        if (!this.props.menuHover && this.props.menuCollapsed) {
            this.setState({logo: this.props.logoImageMobile, isLogoMobile: true});
        } else {
            this.setState({logo: this.props.logoImage, isLogoMobile: false});
        }
    }
}

export default VerticalMenuHeader;
