import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {RootState} from '../reducers';
import {catchError, concatMap, filter, map, switchMap, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {
    changeIsTechnologyToolInitialized,
    changeIsTechnologyToolLoading,
    changeTechnologyTool,
    fetchTechnologyTools,
    setTechnologyToolError,
} from '../reducers/technologyToolSlice';
import {
    changeIsWorkTypeInitialized,
    changeIsWorkTypeLoading,
    changeWorkType,
    fetchAllDictionaryData,
    fetchOffersDictionaryData,
    fetchWorkTypes,
    setWorkTypeError,
} from '../reducers/workTypeSlice';
import {
    changeIsTechnologyInitialized,
    changeIsTechnologyLoading,
    changeTechnology,
    setTechnologyError,
    fetchTechnologies,
} from '../reducers/technologySlice';
import {DictionaryName} from '../../model/dictionaryDatum';
import {changeCountry, changeIsCountryInitialized, changeIsCountryLoading, fetchCountries, setCountryError} from '../reducers/countrySlice';
import {changeCity, changeIsCityInitialized, changeIsCityLoading, fetchCities, setCityError} from '../reducers/citySlice';
import {
    changeIsSeniorityInitialized,
    changeIsSeniorityLoading,
    changeSeniority,
    fetchSeniority,
    setSeniorityError,
} from '../reducers/senioritySlice';
import {
    changeLanguage,
    changeIsLanguageLoading,
    fetchLanguages,
    changeIsLanguageInitialized,
    setLanguageError,
} from '../reducers/languageSlice';
import {
    changeIsLanguageLevelInitialized,
    changeIsLanguageLevelLoading,
    changeLanguageLevel,
    fetchLanguageLevels,
    setLanguageLevelError,
} from '../reducers/languageLevelSlice';
import {
    changeIsIndustryInitialized,
    changeIsIndustryLoading,
    changeIndustry,
    fetchIndustries,
    setIndustryError,
} from '../reducers/industrySlice';
import {
    changeIsCompanyTypeInitialized,
    changeIsCompanyTypeLoading,
    changeCompanyType,
    fetchCompanyTypes,
    setCompanyTypeError,
} from '../reducers/companyTypeSlice';
import {
    changeIsContractTypeInitialized,
    changeIsContractTypeLoading,
    changeContractType,
    fetchContractTypes,
    setContractTypeError,
} from '../reducers/contractTypeSlice';
import {
    changeEmploymentType,
    changeIsEmploymentTypeInitialized,
    changeIsEmploymentTypeLoading,
    fetchEmploymentTypes,
    setEmploymentTypeError,
} from '../reducers/employmentTypeSlice';
import {isNullOrUndefined} from '../../utils/runtimeUtils';
import {authTokenSelector} from '../selectors/authSelectors';
import {addAlert} from '../reducers/alertSlice';
import {AlertType} from '../../types';
import {getDictionaryDataAPI} from '../../api/getDictionaryData';
import {
    changeIsServiceTypeInitialized,
    changeIsServiceTypeLoading,
    changeServiceType,
    fetchServiceTypes,
    setServiceTypeError,
} from '../reducers/serviceTypeSlice';
import {
    changeIsOrganizationSizeInitialized,
    changeIsOrganizationSizeLoading,
    changeOrganizationSize,
    fetchOrganizationSizes,
    setOrganizationSizeError,
} from '../reducers/organizationSizeSlice';
import {
    changeIsVerificationFileTypeInitialized,
    changeIsVerificationFileTypeLoading,
    changeOrganizationVerificationFileType,
    fetchVerificationFileTypes,
    setVerificationFileTypeError,
} from '../reducers/organizationVerificationFileTypeSlice';
import {
    changeIsPreferenceTagsInitialized,
    changeIsPreferenceTagsLoading,
    changePreferenceTags,
    fetchPreferenceTags,
    setPreferenceTagsError,
} from '../reducers/preferenceTagsSlice';

const fetchTechnologyToolsEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    getAction(
        action$,
        state$,
        fetchTechnologyTools,
        DictionaryName.TECHNOLOGY_TOOL,
        changeTechnologyTool,
        changeIsTechnologyToolLoading,
        changeIsTechnologyToolInitialized,
        setTechnologyToolError
    );

const fetchWorkTypesEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    getAction(
        action$,
        state$,
        fetchWorkTypes,
        DictionaryName.WORK_TYPE,
        changeWorkType,
        changeIsWorkTypeLoading,
        changeIsWorkTypeInitialized,
        setWorkTypeError
    );

const fetchTechnologiesEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    getAction(
        action$,
        state$,
        fetchTechnologies,
        DictionaryName.TECHNOLOGY,
        changeTechnology,
        changeIsTechnologyLoading,
        changeIsTechnologyInitialized,
        setTechnologyError
    );

const fetchCountriesEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    getAction(
        action$,
        state$,
        fetchCountries,
        DictionaryName.COUNTRY,
        changeCountry,
        changeIsCountryLoading,
        changeIsCountryInitialized,
        setCountryError
    );

const fetchCitiesEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    getAction(action$, state$, fetchCities, DictionaryName.CITY, changeCity, changeIsCityLoading, changeIsCityInitialized, setCityError);

const fetchSeniorityEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    getAction(
        action$,
        state$,
        fetchSeniority,
        DictionaryName.SENIORITY,
        changeSeniority,
        changeIsSeniorityLoading,
        changeIsSeniorityInitialized,
        setSeniorityError
    );

const fetchLanguagesEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    getAction(
        action$,
        state$,
        fetchLanguages,
        DictionaryName.LANGUAGE,
        changeLanguage,
        changeIsLanguageLoading,
        changeIsLanguageInitialized,
        setLanguageError
    );

const fetchLanguageLevelsEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    getAction(
        action$,
        state$,
        fetchLanguageLevels,
        DictionaryName.LANGUAGE_LEVEL,
        changeLanguageLevel,
        changeIsLanguageLevelLoading,
        changeIsLanguageLevelInitialized,
        setLanguageLevelError
    );

const fetchIndustriesEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    getAction(
        action$,
        state$,
        fetchIndustries,
        DictionaryName.INDUSTRY,
        changeIndustry,
        changeIsIndustryLoading,
        changeIsIndustryInitialized,
        setIndustryError
    );

const fetchCompanyTypesEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    getAction(
        action$,
        state$,
        fetchCompanyTypes,
        DictionaryName.COMPANY_TYPE,
        changeCompanyType,
        changeIsCompanyTypeLoading,
        changeIsCompanyTypeInitialized,
        setCompanyTypeError
    );

const fetchContractTypesEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    getAction(
        action$,
        state$,
        fetchContractTypes,
        DictionaryName.CONTRACT_TYPE,
        changeContractType,
        changeIsContractTypeLoading,
        changeIsContractTypeInitialized,
        setContractTypeError
    );

const fetchVerificationFileTypesEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    getAction(
        action$,
        state$,
        fetchVerificationFileTypes,
        DictionaryName.ORGANIZATION_VERIFICATION_FILE_TYPE,
        changeOrganizationVerificationFileType,
        changeIsVerificationFileTypeLoading,
        changeIsVerificationFileTypeInitialized,
        setVerificationFileTypeError
    );

const fetchEmploymentTypesEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    getAction(
        action$,
        state$,
        fetchEmploymentTypes,
        DictionaryName.EMPLOYMENT_TYPE,
        changeEmploymentType,
        changeIsEmploymentTypeLoading,
        changeIsEmploymentTypeInitialized,
        setEmploymentTypeError
    );

const fetchServiceTypesEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    getAction(
        action$,
        state$,
        fetchServiceTypes,
        DictionaryName.SERVICE_TYPE,
        changeServiceType,
        changeIsServiceTypeLoading,
        changeIsServiceTypeInitialized,
        setServiceTypeError
    );

const fetchOrganizationSizesEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    getAction(
        action$,
        state$,
        fetchOrganizationSizes,
        DictionaryName.ORGANIZATION_SIZE,
        changeOrganizationSize,
        changeIsOrganizationSizeLoading,
        changeIsOrganizationSizeInitialized,
        setOrganizationSizeError
    );

const fetchPreferenceTagsEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    getAction(
        action$,
        state$,
        fetchPreferenceTags,
        DictionaryName.PREFERENCE_TAGS,
        changePreferenceTags,
        changeIsPreferenceTagsLoading,
        changeIsPreferenceTagsInitialized,
        setPreferenceTagsError
    );

const fetchAllDictionaryDataEpic: Epic = (action$) =>
    action$.pipe(
        ofType(fetchAllDictionaryData.type),
        concatMap(() => {
            return of(
                fetchTechnologyTools(),
                fetchWorkTypes(),
                fetchTechnologies(),
                fetchCountries(),
                fetchCities(),
                fetchSeniority(),
                fetchLanguages(),
                fetchLanguageLevels(),
                fetchIndustries(),
                fetchCompanyTypes(),
                fetchContractTypes(),
                fetchEmploymentTypes(),
                fetchServiceTypes(),
                fetchVerificationFileTypes(),
                fetchOrganizationSizes(),
                fetchPreferenceTags()
            );
        }),
        catchError((error: any) => of(addAlert({message: getErrorMessage(error), type: AlertType.WARNING})))
    );

const fetchSelectedDictionaryDataEpic: Epic = (action$) =>
    action$.pipe(
        ofType(fetchOffersDictionaryData.type),
        concatMap(() => {
            return of(
                fetchWorkTypes(),
                fetchTechnologies(),
                fetchSeniority(),
                fetchIndustries(),
                fetchCompanyTypes(),
                fetchContractTypes(),
                fetchServiceTypes(),
                fetchEmploymentTypes(),
                fetchPreferenceTags()
            );
        }),
        catchError((error: any) => of(addAlert({message: getErrorMessage(error), type: AlertType.WARNING})))
    );

export type FetchAction = {
    token: string | null;
    dictionaryName: string | null;
    changeSliceList: any;
    changeIsSliceLoading: any;
    changeIsSliceInitialized: any;
    setSliceError: any;
};

const fetchSubject = new BehaviorSubject<FetchAction>({
    token: null,
    dictionaryName: null,
    changeSliceList: null,
    changeIsSliceLoading: null,
    changeIsSliceInitialized: null,
    setSliceError: null,
});

const resultsSubject = new BehaviorSubject<any>(null);
fetchSubject
    .asObservable()
    .pipe(
        concatMap((fetch) => {
            if (
                // isNullOrUndefined(fetch.token) ||
                isNullOrUndefined(fetch.dictionaryName)
            ) {
                return of(null);
            }

            return getDictionaryData(
                fetch.token as string,
                fetch.dictionaryName as string,
                fetch.changeSliceList,
                fetch.changeIsSliceLoading,
                fetch.changeIsSliceInitialized,
                fetch.setSliceError
            );
        }),
        tap((action) => resultsSubject.next(action))
    )
    .subscribe(); // subscription with same lifetime as the application, no need to unsubscribe

const doFetch = (
    state: RootState,
    dictionaryName: string,
    changeSliceList: null,
    changeIsSliceLoading: null,
    changeIsSliceInitialized: null,
    setSliceError: null
) => {
    const authToken = authTokenSelector(state);

    fetchSubject.next({
        token: authToken,
        dictionaryName: dictionaryName,
        changeSliceList: changeSliceList,
        changeIsSliceLoading: changeIsSliceLoading,
        changeIsSliceInitialized: changeIsSliceInitialized,
        setSliceError: setSliceError,
    });

    return resultsSubject.asObservable().pipe(
        filter((action: any) => null !== action),
        concatMap((action) => of(action))
    );
};

const getDictionaryData = (
    authToken: string,
    dictionaryName: string,
    changeSliceList: any,
    changeIsSliceLoading: any,
    changeIsSliceInitialized: any,
    setSliceError: any
) => {
    return getList(
        getDictionaryDataAPI(dictionaryName, authToken),
        (resp: any) => fetchListSuccessActions(resp['hydra:member'], changeSliceList, changeIsSliceLoading, changeIsSliceInitialized),
        (error: any) => fetchListErrorActions(error, setSliceError, changeIsSliceLoading)
    );
};

export const getList = (api: Observable<any>, successActions: (resp: any) => any[], errorActions: (error: any) => any[]) => {
    return api.pipe(
        switchMap((resp: any) => of(...successActions(resp))),
        catchError((error: any) => of(...errorActions(error)))
    );
};

export const getAction = (
    action$: Observable<any>,
    state$: StateObservable<any>,
    actionType: any,
    dictionaryName: string,
    changeSliceList: any,
    changeIsSliceLoading: any,
    changeIsSliceInitialized: any,
    setSliceError: any
) => {
    return action$.pipe(
        ofType(actionType.type),
        map(() => state$.value),
        switchMap((state: RootState) => {
            return doFetch(state, dictionaryName, changeSliceList, changeIsSliceLoading, changeIsSliceInitialized, setSliceError);
        })
    );
};

export const fetchListSuccessActions = (
    resp: any,
    changeSliceList: any,
    changeIsSliceLoading: any,
    changeIsSliceInitialized: any
): any[] => {
    return [changeSliceList(resp), changeIsSliceLoading(false), changeIsSliceInitialized(true)];
};

export const fetchListErrorActions = (error: any, setSliceError: any, setSliceIsLoading: any): any[] => {
    return [
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
        setSliceError(getErrorMessage(error)),
        setSliceIsLoading(false),
    ];
};

export const getErrorMessage = (error: any) => {
    let errorMessage;
    if (error.response && error.response.message) {
        errorMessage = error.response.message;
    } else if (error.response && error.response['hydra:description']) {
        errorMessage = error.response['hydra:description'];
    } else {
        errorMessage = 'Something went wrong. Please try again later.';
    }

    return errorMessage;
};

const dictionaryDataEpic = combineEpics(
    fetchTechnologyToolsEpic,
    fetchWorkTypesEpic,
    fetchTechnologiesEpic,
    fetchCountriesEpic,
    fetchCitiesEpic,
    fetchSeniorityEpic,
    fetchLanguagesEpic,
    fetchLanguageLevelsEpic,
    fetchIndustriesEpic,
    fetchCompanyTypesEpic,
    fetchContractTypesEpic,
    fetchAllDictionaryDataEpic,
    fetchEmploymentTypesEpic,
    fetchServiceTypesEpic,
    fetchVerificationFileTypesEpic,
    fetchOrganizationSizesEpic,
    fetchPreferenceTagsEpic,
    fetchSelectedDictionaryDataEpic
);

export default dictionaryDataEpic;
