import React, {Component} from 'react';
import {UserRole, VideoChat, withRouterWrapper, WithRouterWrapperProps} from 'jobhunter-common-web';
import {catchError, map} from 'rxjs/operators';
import {of, Subscription} from 'rxjs';
import {getOnlineConsultationAPI} from '../../api/getOnlineConsultationAPI';
import styles from './styles.module.scss';

const queryString = require('query-string');

type WithRouter = typeof WithRouterWrapperProps;
interface IVideoHostProps extends WithRouter {}

interface IVideoHostState {
    onlineConsultation: {[key: string]: any} | null;
    userSecret: string | null;
    participantAgoraToken: string | null;
    userId: string | null;
    userToken: string | null;
    userRole: typeof UserRole | null;
}

class VideoHost extends Component<IVideoHostProps, IVideoHostState> {
    readonly subscriptions: Subscription[] = [];
    constructor(props: IVideoHostProps) {
        super(props);

        this.state = {
            onlineConsultation: null,
            userSecret: null,
            participantAgoraToken: null,
            userId: null,
            userToken: null,
            userRole: null,
        };
    }

    componentDidMount() {
        if (this.props.router && this.props.router.location && this.props.router.location.search) {
            const searchParams = queryString.parse(this.props.router.location.search),
                consultationId = searchParams.consultationId,
                agoraToken = this.props.router.location.search.split('agoraToken=').pop(), // this.props.router.searchParams.get('agoraToken'),
                role = searchParams.role;

            this.setState({userRole: role, participantAgoraToken: agoraToken});
            this.getConsultationDetails(consultationId, agoraToken);
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <div className={styles.consultationHost}>
                {this.state.onlineConsultation ? (
                    <VideoChat
                        onlineConsultation={this.state.onlineConsultation}
                        userSecret={this.state.userSecret}
                        userRole={this.state.userRole}
                        userId={this.state.userId}
                        userToken={this.state.userToken}
                        participantAgoraToken={this.state.participantAgoraToken}
                    />
                ) : null}
            </div>
        );
    }

    private getConsultationDetails = (consultationId: string, accessSecret: string) => {
        return this.subscriptions.push(
            getOnlineConsultationAPI(consultationId, accessSecret)
                .pipe(
                    map((resp: any) => {
                        const applicantData = resp.participants.filter(
                                (participant: {[key: string]: any}) => participant.role === this.state.userRole
                            ),
                            userSecret = applicantData[0].accessSecret,
                            userId = applicantData[0].id,
                            userToken = applicantData[0].agoraToken;

                        this.setState({
                            onlineConsultation: resp,
                            userSecret: userSecret,
                            userId: userId,
                            userToken: userToken,
                        });
                    }),
                    catchError((error: any) => {
                        return of(error);
                    })
                )
                .subscribe()
        );
    };
}

export default withRouterWrapper(VideoHost);
