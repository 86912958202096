import {createSelector} from '@reduxjs/toolkit';
import {IOrganizationVerificationFileTypeState} from '../reducers/organizationVerificationFileTypeSlice';

export const selectOrganizationVerificationFileType = (state: any): IOrganizationVerificationFileTypeState => {
    return state.organizationVerificationFileType;
};

export const verificationFileTypesSelector = createSelector(
    [selectOrganizationVerificationFileType],
    (state: IOrganizationVerificationFileTypeState) => state.verificationFileTypes
);

export const verificationFileTypesLoadingSelector = createSelector(
    [selectOrganizationVerificationFileType],
    (state: IOrganizationVerificationFileTypeState) => state.isLoading
);

export const verificationFileTypesInitializedSelector = createSelector(
    [selectOrganizationVerificationFileType],
    (state: IOrganizationVerificationFileTypeState) => state.isInitialized
);

export const verificationFileTypesErrorSelector = createSelector(
    [selectOrganizationVerificationFileType],
    (state: IOrganizationVerificationFileTypeState) => state.error
);
