import React from 'react';
import styles from './styles.module.scss';
import {ChatMessage, ChatType, LoaderType, MessageContentType, ReactstrapColors} from "../../../../../types";
import {saveAs} from 'file-saver';
import Loader from "../../../../Loader";
import {Download} from 'react-feather';
import {authTokenSelector, downloadFile, Translation} from "../../../../../index";
import {connect} from "react-redux";

interface IChatListItemMenuProps {
    readonly message: ChatMessage;
    readonly contentType: MessageContentType;
    readonly messageType?: ChatType;
    readonly errorModuleHandle: (errorMessage: string) => void;
    readonly authToken: string
}

class ChatListItemContent extends React.Component<IChatListItemMenuProps> {
    render() {
        const messageType = this.props.message.messageType;
        return (
            <React.Fragment>
                {messageType === MessageContentType.IMAGE && (
                    <img src={this.props.message.messageContent} alt="" className={styles.chatImage} />
                )}
                {messageType === MessageContentType.TEXT && (
                    <div
                        className={`${ this.props.messageType === ChatType.RESPONSE ?
                            styles.responseMessage :
                            styles.chatMessage}
                            ${this.props.messageType === ChatType.MESSAGE ?
                            styles.message :
                            ''}`}>
                        {this.props.message.messageContent}
                    </div>
                )}
                {messageType === MessageContentType.FILE && (
                    <div className={styles.filePreviewContainer}>
                        {this.renderPreview()}
                    </div>
                )}
                {messageType === MessageContentType.ERROR && (
                    <div
                        className={`${ this.props.messageType === ChatType.RESPONSE ?
                            styles.responseMessage :
                            styles.chatMessage}
                            ${this.props.messageType === ChatType.MESSAGE ?
                            styles.message :
                            ''}`}>
                        <Translation text={'videoChat.chat.errorMessage'} />
                    </div>
                )}
                {messageType === MessageContentType.PLACEHOLDER && (
                    <div className={styles.loaderWrapper}>
                        <Loader color={this.props.messageType === ChatType.RESPONSE ? ReactstrapColors.PRIMARY : ReactstrapColors.LIGHT} transparent={true} showLoader={true} type={LoaderType.Local} />
                    </div>
                )}
            </React.Fragment>
        );
    }

    private renderPreview() {
        const previewFileURL = this.props.message.messagePreview || this.props.message.messageMedia;
        const downloadFileURL = this.props.message.messageMedia;
        return (
            <>
                {
                    (this.props.message.messageContent.endsWith('.png') ||
                        this.props.message.messageContent.endsWith('.jpg') ||
                        this.props.message.messageContent.endsWith('.jpeg')) &&
                    <img src={previewFileURL} className={styles.chatImage} />
                }
                <div className={styles.fileDescriptionBar}>
                    <div>{this.props.message.messageContent}</div>
                    <button className="badge bg-black" onClick={() => this.downloadSelectedFile(downloadFileURL, this.props.message.messageContent)}>
                    {/*    <a className={`badge ${this.props.messageType === ChatType.RESPONSE ? 'bg-black' : ''}`} href={`${downloadFileURL}?token=${this.props.authToken}`} target="_blank" >*/}
                            <Download/>
                        {/*</a>*/}
                    </button>
                </div>
            </>
        );
    }

    private async downloadSelectedFile(url, name) {
        try {
            const token = this.props.authToken;
            if(!token) {
                return ;
            }
            const data = await downloadFile(token, url);
            const isResponseOk = this.validateResponse(data);
            if(isResponseOk) {
                const blob = await data.blob();
                saveAs(blob, name)
            }
        }
        catch (error: any) {
            return this.props.errorModuleHandle(error?.message)
        }
    }

    private validateResponse(response) {
        if (!response.ok) {
            return this.props.errorModuleHandle('Bad server answer')
        }
        return response;
    }
}

export default connect(state => ({
    authToken: authTokenSelector(state),
}))(ChatListItemContent);
