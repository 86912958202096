// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3DFBqBKrO4LfEZ98F_HsMC{background:#f4f4f4;height:calc(100vh - 35rem);overflow:auto;flex-grow:1}._3DFBqBKrO4LfEZ98F_HsMC ._1vhf9wGujy03Fgsi_qzB0a{height:100%;display:flex;flex-direction:column;justify-content:center;align-items:center}._3DFBqBKrO4LfEZ98F_HsMC ._1vhf9wGujy03Fgsi_qzB0a span{font-size:4rem;margin-bottom:1.6rem;color:#334048}._3DFBqBKrO4LfEZ98F_HsMC ._1vhf9wGujy03Fgsi_qzB0a p{font-family:\"Ubuntu\", sans-serif;font-size:1.4rem;line-height:1.7rem;color:#334048}\n", ""]);
// Exports
exports.locals = {
	"chatContainer": "_3DFBqBKrO4LfEZ98F_HsMC",
	"emptyMessagesContainer": "_1vhf9wGujy03Fgsi_qzB0a"
};
module.exports = exports;
