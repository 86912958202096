import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelDictionaryDatum} from '../../model/dictionaryDatum';
import {IBaseDictionaryState, IChangeIsInitialized, IChangeIsLoading, ISetError} from './workTypeSlice';

export interface IServiceTypeState extends IBaseDictionaryState {
    serviceTypes: IModelDictionaryDatum[] | null;
}

export interface IChangeServiceType {
    serviceTypes: IModelDictionaryDatum[] | null;
}

const initialState: IServiceTypeState = {
    serviceTypes: null,
    isLoading: false,
    isInitialized: false,
    error: null,
};

const serviceTypeSlice = createSlice({
    name: 'serviceType',
    initialState: initialState,
    reducers: {
        changeServiceType: {
            reducer: (state: IServiceTypeState, action: PayloadAction<IChangeServiceType>) => {
                return {
                    serviceTypes: action.payload.serviceTypes,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(serviceTypes: IModelDictionaryDatum[] | null) {
                return {
                    payload: {serviceTypes: serviceTypes},
                };
            },
        },
        changeIsServiceTypeLoading: {
            reducer: (state: IServiceTypeState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    serviceTypes: state.serviceTypes,
                    isLoading: action.payload.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        changeIsServiceTypeInitialized: {
            reducer: (state: IServiceTypeState, action: PayloadAction<IChangeIsInitialized>) => {
                return {
                    serviceTypes: state.serviceTypes,
                    isLoading: state.isLoading,
                    isInitialized: action.payload.isInitialized,
                    error: state.error,
                };
            },
            prepare(isInitialized: boolean) {
                return {
                    payload: {isInitialized: isInitialized},
                };
            },
        },
        setServiceTypeError: {
            reducer: (state: IServiceTypeState, action: PayloadAction<ISetError>) => {
                return {
                    serviceTypes: state.serviceTypes,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
        fetchServiceTypes: (state: IServiceTypeState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
    },
});

export const {changeServiceType, changeIsServiceTypeInitialized, changeIsServiceTypeLoading, setServiceTypeError, fetchServiceTypes} =
    serviceTypeSlice.actions;

export default serviceTypeSlice.reducer;
