import {Observable} from 'rxjs';
import {jobHunterAuthAPI} from './provider/jobHunterAuth';
import {RestQueryParams} from './base/queryParams';
import {IAuthTokens} from '../store/reducers/authSlice';

export function refreshTokenAPI(
    refreshToken: string,
): Observable<IAuthTokens> {
    return jobHunterAuthAPI.post(
        'api/auth/refresh',
        {
            refresh_token: refreshToken
        },
        new RestQueryParams(),
    );
}
