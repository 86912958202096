import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelDictionaryDatum} from '../../model/dictionaryDatum';
import {IBaseDictionaryState, IChangeIsInitialized, IChangeIsLoading, ISetError} from './workTypeSlice';

export interface IContractTypeState extends IBaseDictionaryState {
    contractTypes: IModelDictionaryDatum[] | null;
}

export interface IChangeContractType {
    contractTypes: IModelDictionaryDatum[] | null;
}

const initialState: IContractTypeState = {
    contractTypes: null,
    isLoading: false,
    isInitialized: false,
    error: null,
};

const contractTypeSlice = createSlice({
    name: 'contractType',
    initialState: initialState,
    reducers: {
        changeContractType: {
            reducer: (state: IContractTypeState, action: PayloadAction<IChangeContractType>) => {
                return {
                    contractTypes: action.payload.contractTypes,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(contractTypes: IModelDictionaryDatum[] | null) {
                return {
                    payload: {contractTypes: contractTypes},
                };
            },
        },
        changeIsContractTypeLoading: {
            reducer: (state: IContractTypeState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    contractTypes: state.contractTypes,
                    isLoading: action.payload.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        changeIsContractTypeInitialized: {
            reducer: (state: IContractTypeState, action: PayloadAction<IChangeIsInitialized>) => {
                return {
                    contractTypes: state.contractTypes,
                    isLoading: state.isLoading,
                    isInitialized: action.payload.isInitialized,
                    error: state.error,
                };
            },
            prepare(isInitialized: boolean) {
                return {
                    payload: {isInitialized: isInitialized},
                };
            },
        },
        setContractTypeError: {
            reducer: (state: IContractTypeState, action: PayloadAction<ISetError>) => {
                return {
                    contractTypes: state.contractTypes,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
        fetchContractTypes: (state: IContractTypeState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
    },
});

export const {changeContractType, changeIsContractTypeLoading, changeIsContractTypeInitialized, setContractTypeError, fetchContractTypes} =
    contractTypeSlice.actions;

export default contractTypeSlice.reducer;
