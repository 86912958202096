import {createAPIHandler} from '../base/apiFactory';
import {IAuthTokens} from '../../store/reducers/authSlice';
import {API_URL} from '../../config/entrypoint';

export const jobHunterAuthAPI = createAPIHandler({
    url: API_URL,
    defaultHeaders: {
        'Accept': 'application/ld+json',
        'Content-type': 'application/ld+json',
        'Accept-Language': 'pl-PL, pl;q=0.9, en;q=0.5, *;q=0.2'
    },
    mapper: (data: {
        token: string;
        refresh_token: string
    }): IAuthTokens => {
        return {
            authToken: data.token,
            refreshToken: data.refresh_token
        };
    },
    isList: false
});
