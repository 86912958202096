import * as React from 'react';
import {ILoaderProps, ILoaderState, LoaderType, ReactstrapColors} from '../../types';
import styles from './styles.module.scss';
import {Spinner} from 'reactstrap';

type Props = ILoaderProps;
type State = ILoaderState;

class Loader extends React.Component<Props, State> {
    constructor(props: ILoaderProps) {
        super(props);

        this.state = {
            showLocalLoader: false,
        };
    }

    showLocalLoader = () => {
        this.setState((previousState) => {
            return {
                showLocalLoader: !previousState.showLocalLoader,
            };
        });
    };

    render() {
        const loaderType = this.props.type === LoaderType.Local ? styles.local : '';
        const showGlobalLoader = this.props.showLoader && this.isGlobal ? '' : styles.isHidden;
        const showLocalLoader = this.props.showLocalLoader ? styles.isVisible : '';
        const transparent = this.props.transparent ? styles.transparent : '';

        return (
            <div className={`${styles.loaderContainer} ${loaderType} ${showGlobalLoader} ${showLocalLoader} ${transparent}`}>
                <Spinner color={this.props.color ? this.props.color : ReactstrapColors.PRIMARY} />
            </div>
        );
    }

    private isGlobal(): boolean {
        return this.props.type === LoaderType.Global || this.props.type === undefined;
    }
}

export default Loader;
