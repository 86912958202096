import {Observable} from 'rxjs';
import {RestQueryParams} from './base/queryParams';
import {jobHunterAPI} from './provider/jobHunterAPI';

export function changePasswordAPI(oldPassword: string, newPassword: string, accessToken: string): Observable<any> {
    return jobHunterAPI.post(
        'api/users/change_password',
        {
            oldPassword: oldPassword,
            newPassword: newPassword,
        },
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`,
        }
    );
}
