import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelSeniority} from '../../model/dictionaryDatum';
import {IBaseDictionaryState, IChangeIsInitialized, IChangeIsLoading, ISetError} from './workTypeSlice';

export interface ISeniorityState extends IBaseDictionaryState {
    seniority: IModelSeniority[] | null;
}

export interface IChangeSeniority {
    seniority: IModelSeniority[] | null;
}

const initialState: ISeniorityState = {
    seniority: null,
    isLoading: false,
    isInitialized: false,
    error: null,
};

const senioritySlice = createSlice({
    name: 'seniority',
    initialState: initialState,
    reducers: {
        changeSeniority: {
            reducer: (state: ISeniorityState, action: PayloadAction<IChangeSeniority>) => {
                return {
                    seniority: action.payload.seniority,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(seniority: IModelSeniority[] | null) {
                return {
                    payload: {seniority: seniority},
                };
            },
        },
        changeIsSeniorityLoading: {
            reducer: (state: ISeniorityState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    seniority: state.seniority,
                    isLoading: action.payload.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        changeIsSeniorityInitialized: {
            reducer: (state: ISeniorityState, action: PayloadAction<IChangeIsInitialized>) => {
                return {
                    seniority: state.seniority,
                    isLoading: state.isLoading,
                    isInitialized: action.payload.isInitialized,
                    error: state.error,
                };
            },
            prepare(isInitialized: boolean) {
                return {
                    payload: {isInitialized: isInitialized},
                };
            },
        },
        setSeniorityError: {
            reducer: (state: ISeniorityState, action: PayloadAction<ISetError>) => {
                return {
                    seniority: state.seniority,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
        fetchSeniority: (state: ISeniorityState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
    },
});

export const {changeSeniority, changeIsSeniorityLoading, changeIsSeniorityInitialized, setSeniorityError, fetchSeniority} =
    senioritySlice.actions;

export default senioritySlice.reducer;
