import {FormControlType, IFormConfig, InputType, ValidationRules} from 'jobhunter-common-web';

export const loginFormConfig: typeof IFormConfig = {
    controlType: 'form',
    class: 'auth-form',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'login_email',
            controlType: 'group',
            class: 'row',
            controls: {
                email: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.IS_EMAIL, params: {}},
                    ],
                    placeholder: 'offers.offerView.applicationFormLogin.form.labels.email',
                    isLabelHidden: false,
                    label: 'offers.offerView.applicationFormLogin.form.labels.email',
                    type: InputType.EMAIL,
                    hostClass: 'col-xl-12 form-control',
                },
                password: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 6}},
                    ],
                    placeholder: 'offers.offerView.applicationFormLogin.form.labels.password',
                    isLabelHidden: false,
                    label: 'offers.offerView.applicationFormLogin.form.labels.password',
                    type: InputType.PASSWORD,
                    hostClass: 'col-xl-12 form-control',
                },
            },
        },
        {
            controlType: 'group',
            key: 'login_submit',
            class: 'login-submit-button',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: 'submit',
                    inputStyles: 'btn btn-primary',
                    defaultContainerStyles: '',
                    labelHidden: true,
                    containerStyles: 'button-container mt-2',
                    defaultValue: null,
                    btnText: 'buttons.login',
                },
            },
        },
    ],
};
