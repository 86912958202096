import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import store, {history, persistor} from './store';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/lib/integration/react';
import * as serviceWorker from './serviceWorker';
import Moment from 'react-moment';
import './i18n';
import {HistoryRouter as Router} from 'redux-first-history/rr6';
import App from './App';

Moment.startPooledTimer(1000);

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <div className={`accessibility-wrapper menu-position-top`}>
                <Router history={history}>
                    <App />
                </Router>
            </div>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
