import React, {Component} from 'react';
import Nouislider from "nouislider-react";
import {isNotNullOrUndefined} from "../../..";
import {IRangePickerProps, IRangePickerState} from "../../../types";
import {withTranslation} from "react-i18next";


type Props = IRangePickerProps;
type State = IRangePickerState;


class RangePicker extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            value: null,
        }
    }

    componentDidMount(): void {
    }

    render() {
        const hasLabels = isNotNullOrUndefined(this.props.labels) && Object.keys(this.props.labels).length > 0;
        if (!hasLabels) {
            return null;
        }

        const {t} = this.props;

        return (
            <div className="noUi-wrapper">
                <Nouislider
                    onChange={(value: string[]) => this.onRangeChange(value)}
                    onSlide={(value: any) => this.onRangeChange(value)}
                    step={10}
                    start={isNotNullOrUndefined(this.props.defaultValue) ? this.mapDefaultValueToStartValue() : [0]}
                    pips={{
                        mode: 'steps',
                        // filter: (value: any, type: any) => {
                        //     return type === 0 ? -1 : 1;
                        // },
                        format: {
                            to: (value: number) => {
                                if (this.props.labelFormat) {
                                    return this.props.isRangeLabelTranslated ?
                                        t(this.props.labelFormat(value))
                                        : this.props.labelFormat(value);
                                } else {
                                    return this.props.isRangeLabelTranslated ?
                                        t((this.props.labels as any)[value].label) :
                                        (this.props.labels as any)[value].label
                                }
                            }
                        },
                        density: 10
                    }}
                    // toDo check if there is an option to have pip clickable and update value
                    // clickablePips
                    range={{
                        min: 0,
                        max: this.props.maxRangeStep ? this.props.maxRangeStep - 10 : 100 - 10
                    }}
                    tooltips={[
                        {to: (value: number) => `${this.props.isRangeLabelTranslated ? `${t(this.props.labels[value].label)} ${value}%` 
                                : `${this.props.labels[value].label} ${value}%`}`, from: (value: number) => value}
                    ]}
                    disabled={this.props.disabled}
                />
            </div>
        );
    }

    private onRangeChange = (value: string[]) => {
        this.setState({value: value});

        const key = value[0].split('.')[0];
        const valueId = this.props.labels[key].value;

        if (this.props.isMultiRangeControl) {
            const valueIdsList = [];
            for (let i = 0; i <= Number(key);  i++) {
                if (i % 10 === 0) {
                    Object.keys(this.props.labels).forEach((key) => {
                        if (i === Number(key)) {
                            valueIdsList.push(this.props.labels[key].value)
                        }
                    });
                }
            }
            this.props.onChange(valueIdsList);
        } else {
            this.props.onChange(valueId);
        }
    }

    private mapDefaultValueToStartValue = () => {
        const defaultValue = this.props.value;
        if (this.props.labels) {
            let value = [0];
            Object.keys(this.props.labels).forEach((key) => {
                if (this.props.labels[key].value === defaultValue) {
                    return value = [this.props.labels[key].level];
                }
            });
            return value;
        } else {
            return [0];
        }
    }
}

export default withTranslation()(RangePicker);
