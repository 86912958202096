import {createSelector} from '@reduxjs/toolkit';
import {IOrganizationSizeState} from '../reducers/organizationSizeSlice';

export const selectOrganizationSize = (state: any): IOrganizationSizeState => {
    return state.organizationSize;
};

export const organizationSizesSelector = createSelector(
    [selectOrganizationSize],
    (state: IOrganizationSizeState) => state.organizationSizes
);

export const organizationSizesLoadingSelector = createSelector(
    [selectOrganizationSize],
    (state: IOrganizationSizeState) => state.isLoading
);

export const organizationSizesInitializedSelector = createSelector(
    [selectOrganizationSize],
    (state: IOrganizationSizeState) => state.isInitialized
);

export const organizationSizesErrorSelector = createSelector([selectOrganizationSize], (state: IOrganizationSizeState) => state.error);
