import React, {RefObject} from 'react';
import styles from './styles.module.scss';
import ChatListItem from '../ChatListItem';
import ChatActions from '../ChatActions';
import {
    ChatContact,
    ChatMessage,
    ChatType,
    FileStreamType,
    IBaseChatProps,
    LoaderType,
    PeerConnection
} from "../../../../types";
import Translation from "../../../Translation";
import classnames from "classnames";
import {MessageSquare} from "react-feather";
import PerfectScrollbar from 'react-perfect-scrollbar'
import Loader from "../../../Loader";
import {Observable} from "rxjs";
import {MESSAGES_NUMBER_PER_PAGE} from "../../config";
import cloneDeep from 'lodash/cloneDeep';

interface IChatListProps extends IBaseChatProps {
    readonly confirmMessageRead: (messages: string[]) => void;
    readonly getMessagesFromUser: (accountId: string, page?: number) => Observable<boolean>;
    readonly messages: ChatMessage[];
    readonly accountId: string;
    readonly addFile: (data: FileStreamType) => void;
    readonly error: string;
    readonly errorModuleHandle: (errorMessage: string) => void;
    readonly peerConnection: PeerConnection | null;
    readonly selectedChatRoomId: string | null;
    readonly chatContact: ChatContact | undefined;
    readonly avatarUrl?: string;
}

interface IChatListState {
    prevHeight: number,
    isLoadingData: boolean,
}

class ChatList extends React.Component<IChatListProps, IChatListState> {
    private readonly containerRef: RefObject<any>;
    private scrollbarRef: any;

    constructor(props: IChatListProps) {
        super(props);
        this.containerRef = React.createRef();
        this.scrollbarRef = React.createRef();
        this.state = {
            prevHeight: 0,
            isLoadingData: false,
        }
    }

    componentDidUpdate(prevProps: Readonly<IChatListProps>, prevState: Readonly<IChatListState>) {
        if(prevProps.selectedChatRoomId !== this.props.selectedChatRoomId &&
            this.scrollbarRef.current) {
           //zmiana chatu
           return this.scrollbarRef.current.scrollTop =  this.scrollbarRef.current.scrollHeight;
        }

        if (this.props.messages?.length > prevProps.messages?.length &&
            this.scrollbarRef.current) {
            const newMessage = this.props.messages?.[this.props.messages.length-1];
            if(prevProps.messages?.[prevProps.messages.length-1]?.messageId !== newMessage.messageId && this.props.accountId === newMessage.to) {
                this.props.confirmMessageRead([newMessage.messageId]);
            }

            if(!this.state.isLoadingData && prevProps.messages?.[prevProps.messages.length-1]?.messageId !== newMessage?.messageId) {
                //nowa wiadomość
              return this.scrollbarRef.current.scrollTop =  this.scrollbarRef.current.scrollHeight;
            }
            return this.scrollbarRef.current.scrollTop = this.scrollbarRef.current.scrollHeight - this.state.prevHeight
        }
    }

    render() {
        return (
            <>
                <div className={styles.chatContainer}>
                    {this.renderChatList()}
                    {this.renderEmptyChatList()}
                    <div ref={this.containerRef} />
                </div>
                {this.props.selectedChatRoomId && (
                <ChatActions
                    peerConnection={this.props.peerConnection}
                    addMessage={this.props.addMessage}
                    addFile={this.props.addFile}
                    error={this.props.error}
                    errorModuleHandle={this.props.errorModuleHandle}
                />
                )}
            </>
        );
    }

    private renderEmptyChatList() {
        if (this.hasMessages) {
            return null;
        }

        return (
            <div className={classnames('start-chat-area', { 'd-none': this.hasMessages })}>
                <div className='start-chat-icon mb-1'>
                    <MessageSquare />
                </div>
                <h4 className='sidebar-toggle start-chat-text'>
                    <Translation text={'videoChat.chat.noMessages'}/>
                </h4>
            </div>
        );
    }

    private renderChatList() {
        if (!this.hasMessages) {
            return null;
        }

        const messages = this.props.messages.sort(
            (a: ChatMessage, b: ChatMessage) => {
                return new Date(a.date).getTime() - new Date(b.date).getTime();
            }
        );

        return (
            <PerfectScrollbar  onScrollUp={this.handleLoadHistory} containerRef={(ref) => this.scrollbarRef.current = ref} className='user-chats'>
                <div className='chats' style={{minHeight: '100.01%'}}>
                    {this.state.isLoadingData && <Loader showLoader={this.state.isLoadingData} type={LoaderType.Local} />}
                    {messages.map((message: ChatMessage, index: number) => {
                        const prevMessageAuthor = index > 0 ? messages[index - 1].from : null;
                        const prevMessageAuthorType = prevMessageAuthor ? (this.props.accountId === prevMessageAuthor ? ChatType.MESSAGE : ChatType.RESPONSE) : null;
                        return (
                            <React.Fragment key={index}>
                                <ChatListItem
                                    avatarUrl={this.props.avatarUrl}
                                    chatContact={this.props.chatContact}
                                    prevMessageAuthorType={prevMessageAuthorType}
                                    errorModuleHandle={this.props.errorModuleHandle}
                                    message={message}
                                    authorType={this.props.accountId === message.from ? ChatType.MESSAGE : ChatType.RESPONSE}
                                />
                            </React.Fragment>
                        );
                    })}
                </div>
            </PerfectScrollbar>
        );
    }

    private handleLoadHistory = () => {
        const totalMessagesNumber = this.props.peerConnection.totalMessagesNumber;
        const page = this.props.peerConnection.biggestPageNumber;
        if(this.scrollbarRef.current.scrollTop !== 0) {
            // console.log('not on top!')
            return;
        }

        if(this.state.isLoadingData) {
            // console.log('LOADING...');
            return;
        }
        if(totalMessagesNumber === 0 && page !== 0) {
            // console.log('No results to scroll up to');
            return;
        }

        if( page >= Math.ceil((totalMessagesNumber)/MESSAGES_NUMBER_PER_PAGE)) {
            // console.log('Max list depth reached');
            return
        }

        if(this.scrollbarRef.current.scrollTop === 0 && !this.state.isLoadingData) {
            const prevHeight = cloneDeep(this.scrollbarRef.current.scrollHeight);
            this.setState({isLoadingData: true, prevHeight});
            this.props.getMessagesFromUser(this.props.chatContact.accountId, page + 1).subscribe(
                (isDone) => {
                if(isDone) {
                    this.setState({prevHeight, isLoadingData: false})
                }
            })
        }
    }

    private get hasMessages(): boolean {
        return this.props.messages?.length > 0;
    }
}

export default ChatList;
