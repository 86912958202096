// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".zcrOGWWYLkqi8mqa1emIj{height:0.8rem;width:100%;background-color:#f4f4f4;margin:0 1rem;border-radius:0.2rem}.zcrOGWWYLkqi8mqa1emIj ._2PGvO-YzIw3y9MlCOI-Lr8{height:100%;background:repeating-linear-gradient(-50deg, #7367F0, #7367F0 0.5rem, #037fb2 0, #037fb2 1rem)}\n", ""]);
// Exports
exports.locals = {
	"container": "zcrOGWWYLkqi8mqa1emIj",
	"filler": "_2PGvO-YzIw3y9MlCOI-Lr8"
};
module.exports = exports;
