import React, {Component} from 'react';
import {MoreHorizontal} from "react-feather";
import {IVerticalNavMenuSectionHeaderProps} from '../../../types';


type Props = IVerticalNavMenuSectionHeaderProps;

class VerticalNavMenuSectionHeader extends Component<Props> {
    render() {
        return (
            <li className='navigation-header'>
                <span>{this.props.item.header}</span>
                <MoreHorizontal className='feather-more-horizontal' />
            </li>
        );
    }
}

export default VerticalNavMenuSectionHeader;
