import React from 'react';
import {connect} from 'react-redux';
import {AlertObject, removeAlert} from '../../store/reducers/alertSlice';
import {alertsSelector, disabledAlertsSelector} from '../../store/selectors/alertSelectors';
import {AlertType, IToastProps, IToastState} from '../../types';
import {isSameValue, Translation} from '../../index';
import {Toast, ToastBody} from 'reactstrap';

type Props = IToastProps;
type State = IToastState;

class ToastComponent extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            alerts: this.props.alerts,
            alertsDisabled: this.props.alertsDisabled,
        };
    }

    componentDidMount() {
        if (this.props.alerts) {
            this.removeAlerts();
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (this.props.alerts && !isSameValue(this.props.alerts, prevProps.alerts)) {
            this.removeAlerts();
        }
    }

    render() {
        if (this.props.alertsDisabled || !this.props.alerts.length) {
            return null;
        }

        return (
            <React.Fragment>
                {this.props.alerts.map((alert: AlertObject) => {
                    const message: any = !alert.isTranslated ? alert.message : <Translation text={alert.message} />,
                        isTypeWarning = this.props.alertType === AlertType.WARNING || alert.type === AlertType.WARNING;

                    return (
                        <Toast className={isTypeWarning ? 'warning' : ''} key={alert.id}>
                            <ToastBody>
                                <div className="message-content">{message}</div>
                                <button type="button" className="ms-1 btn-close" onClick={() => this.closeToast(alert.id)} />
                            </ToastBody>
                        </Toast>
                    );
                })}
            </React.Fragment>
        );
    }

    private closeToast = (id: number) => {
        this.props.removeAlert(id);
    };

    private removeAlerts = () => {
        this.props.alerts.map((alert: AlertObject) => {
            if (alert.displayFor !== 0) {
                setTimeout(() => {
                    this.props.removeAlert(alert.id);
                }, alert.displayFor);
            }

            return alert;
        });
    };
}

export default connect(
    (state: any) => ({
        alerts: alertsSelector(state),
        alertsDisabled: disabledAlertsSelector(state),
    }),
    {
        removeAlert,
    }
)(ToastComponent);
