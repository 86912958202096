import {createSelector} from '@reduxjs/toolkit';
import {ITechnologyState} from '../reducers/technologySlice';

export const selectTechnology = (state: any): ITechnologyState => {
    return state.technology;
};

export const technologiesSelector = createSelector([selectTechnology], (state: ITechnologyState) => state.technologies);

export const technologiesLoadingSelector = createSelector([selectTechnology], (state: ITechnologyState) => state.isLoading);

export const technologiesInitializedSelector = createSelector([selectTechnology], (state: ITechnologyState) => state.isInitialized);

export const technologiesErrorSelector = createSelector([selectTechnology], (state: ITechnologyState) => state.error);
