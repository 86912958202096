import {PayloadAction} from '@reduxjs/toolkit';
import {combineEpics, Epic, ofType} from 'redux-observable';
import {of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {
    fetchAllDictionaryData,
    getAccountDataAPI,
    getErrorMessage,
    mapResponseAccountToCandidateFullInfo,
    setAccountState,
    setAccountStateFailure,
    setAuthState,
    setLoginFailure,
} from '../..';
import {AlertType} from '../../types';
import {addAlert} from '../reducers/alertSlice';
import jwt_decode from 'jwt-decode';
import {initAuthTokenChange, ISetAuthToken} from '../reducers/authSlice';
import {finishLoginLoading} from '../reducers/loginSlice';
import {mapResponseAccountToOrganizationFullInfo} from '../reducers/accountSlice';
import {isRoleMatched} from './loginEpic';

const setAuthTokenEpic: Epic = (action$) =>
    action$.pipe(
        ofType(initAuthTokenChange.type),
        switchMap((action: PayloadAction<ISetAuthToken>) => {
            const authToken = action.payload.authToken,
                decoded = jwt_decode(authToken),
                tokenExpiration = (decoded as any).exp,
                userRoles = (decoded as any).roles,
                accountId = (decoded as any).account_id,
                username = (decoded as any).username,
                userId = (decoded as any).user_id,
                hasExpired = Math.floor(new Date().getTime() / 1000) > tokenExpiration;

            if (hasExpired) {
                return of(addAlert({message: 'Provided token has expired or is not valid', type: AlertType.WARNING}));
            } else {
                if (isRoleMatched(userRoles, action.payload.userRole) !== undefined) {
                    return getAccountDataAPI(authToken, accountId).pipe(
                        switchMap((resp: any) => {
                            const actions: any[] = [
                                    finishLoginLoading(),
                                    setAuthState(username, authToken, null, null, userId, accountId, userRoles, true),
                                ],
                                account = {
                                    candidateFullInfo: mapResponseAccountToCandidateFullInfo(resp),
                                    organizationFullInfo: mapResponseAccountToOrganizationFullInfo(resp),
                                };
                            actions.push(setAccountState(account));
                            actions.push(fetchAllDictionaryData());

                            return of(...actions);
                        }),
                        catchError((error: any) => {
                            return of(
                                setAccountStateFailure(getErrorMessage(error)),
                                setLoginFailure('alerts.noAccessError'),
                                setAuthState(null, null, null, null, null, null, null, false),
                                addAlert({
                                    message: error.response ? error.response.message : 'alerts.baseError',
                                    type: AlertType.WARNING,
                                })
                            );
                        })
                    );
                } else {
                    return of(
                        setLoginFailure('alerts.noAccessError'),
                        addAlert({
                            message: 'alerts.noAccessError',
                            type: AlertType.WARNING,
                        })
                    );
                }
            }
        }),
        catchError((error: any) => of(addAlert({message: getErrorMessage(error), type: AlertType.WARNING})))
    );

const authEpic = combineEpics(setAuthTokenEpic);

export default authEpic;
