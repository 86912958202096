import {createSelector} from '@reduxjs/toolkit';
import {IPreferenceTagsState} from '../reducers/preferenceTagsSlice';

export const selectPreferenceTags = (state: any): IPreferenceTagsState => {
    return state.preferenceTags;
};

export const preferenceTagsSelector = createSelector([selectPreferenceTags], (state: IPreferenceTagsState) => state.preferenceTags);

export const preferenceTagsLoadingSelector = createSelector([selectPreferenceTags], (state: IPreferenceTagsState) => state.isLoading);

export const preferenceTagsInitializedSelector = createSelector(
    [selectPreferenceTags],
    (state: IPreferenceTagsState) => state.isInitialized
);

export const preferenceTagsErrorSelector = createSelector([selectPreferenceTags], (state: IPreferenceTagsState) => state.error);
