import {
    accountSelector,
    IAccount,
    initLogout,
    isAuthenticatedSelector,
    NavBar,
    NotFound,
    Toast,
    usernameSelector,
    fetchOffersDictionaryData,
    Sidebar,
    changeMenuHidden,
    changeMenuCollapsed,
    menuHiddenSelector,
} from 'jobhunter-common-web';
import React from 'react';
import {connect} from 'react-redux';
import {Route, Routes} from 'react-router-dom';
import {Subscription} from 'rxjs';
import {fixInjectedProperties, lazyInject} from '../../ioc';
import {IAlertManagerService} from '../../service/alertManagerService';
import {RootState} from '../../store/reducers';
import Marketplace from '../Marketplace';
import Pricing from '../Pricing';
import Offers from '../Offers';
import LandingPage from '../LandingPage';
import navigationMenu from './navigation-items';
import Company from '../Company';
import Candidate from '../Candidate';
import OfferView from '../Offers/OfferView';
import userMenu from './user-menu';
import unauthorizedUserMenu from './unauthorized-user-menu';
import VideoHost from '../VideoHost';
import EuGrantInfo from '../EuGrantInfo';
import themeConfig from './themeConfig';
import menu from './menu-items';
import classnames from 'classnames';
import Footer from '../Footer';
import AccessibilityControlButton from '../AccessibilityControlButton';

interface IConnectedPanelHostProps {
    readonly isAuthenticated: boolean;
    readonly account: typeof IAccount;
    readonly username: string;
    readonly menuHidden: boolean;
    readonly initLogout: typeof initLogout;
    readonly fetchOffersDictionaryData: typeof fetchOffersDictionaryData;
    readonly changeMenuHidden: typeof changeMenuHidden;
    readonly changeMenuCollapsed: typeof changeMenuCollapsed;
}

interface IExternalPanelHostProps {
    envData: any;
}

interface IPanelHostProps extends IConnectedPanelHostProps, IExternalPanelHostProps {}

interface IPanelHostState {}

class PanelHost extends React.Component<IPanelHostProps, IPanelHostState> {
    private subscription: Subscription | null = null;
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IPanelHostProps) {
        super(props);

        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.props.fetchOffersDictionaryData();
    }

    componentWillUnmount() {
        if (null !== this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    render() {
        const isHidden = this.props.menuHidden;
        return (
            <div
                className={classnames(`wrapper vertical-layout navbar-floating footer-static vertical-overlay-menu`, {
                    'menu-hide': isHidden,
                    'menu-open': !isHidden,
                })}
                {...(isHidden ? {'data-col': '1-column'} : {})}>
                <NavBar
                    navigationMenu={navigationMenu}
                    account={this.props.account}
                    isAuthenticated={this.props.isAuthenticated}
                    userMenu={userMenu}
                    customComponent={<AccessibilityControlButton />}
                    unauthorizedUserMenu={unauthorizedUserMenu}
                    toggleMobileMenu={() => this.toggleMobileMenu(!isHidden)}
                    customDropdownMenuClassName="jobhunter-web-custom-dropdown-menu"
                />

                <Sidebar
                    skin={themeConfig.layout.skin}
                    logoImage={themeConfig.app.appLogoImage}
                    logoMobileImage={themeConfig.app.appLogoMobile}
                    menuData={[menu()]}
                    menuCollapsed={false}
                    setMenuCollapsed={() => this.props.changeMenuCollapsed(false)}
                    currentActiveItem={null}
                    isMenuMobile={true}
                    toggleMobileMenu={() => this.toggleMobileMenu(!isHidden)}
                />

                <div
                    className={classnames('sidenav-overlay', {
                        show: !isHidden,
                    })}
                    onClick={() => this.toggleMobileMenu(!isHidden)}></div>

                <Routes>
                    <Route path="/" element={<LandingPage />} key="dashboard" />
                    <Route path="offers" element={<Offers />} key="offers" />
                    <Route path="offers/offer-view/:offerId" element={<OfferView />} key="offer-view" />
                    <Route path="candidate" element={<Candidate />} key="candidate" />
                    <Route path="company" element={<Company />} key="company" />
                    <Route path="pricing" element={<Pricing />} key="pricing" />
                    <Route path="marketplace" element={<Marketplace />} key="marketplace" />
                    <Route path="eu-grant-info" element={<EuGrantInfo />} key="eu-grant-info" />
                    <Route path="video" element={<VideoHost />} key="video" />
                    <Route path="*" element={<NotFound />} key="not-found" />
                </Routes>

                <Footer />

                <Toast />
            </div>
        );
    }

    private toggleMobileMenu = (isHidden: boolean) => {
        this.props.changeMenuHidden(isHidden);
    };
}

export default connect(
    (state: RootState) => ({
        isAuthenticated: isAuthenticatedSelector(state),
        account: accountSelector(state),
        username: usernameSelector(state),
        menuHidden: menuHiddenSelector(state),
    }),
    {
        initLogout,
        fetchOffersDictionaryData,
        changeMenuHidden,
        changeMenuCollapsed,
    }
)(PanelHost);
