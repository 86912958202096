import {InputBasic, InputType, Translation} from 'jobhunter-common-web';
import React, {Component} from 'react';
import styles from './styles.module.scss';
import {withTranslation, WithTranslation} from 'react-i18next';
import {BehaviorSubject, Subscription} from 'rxjs';
import {debounceTime, filter, tap} from 'rxjs/operators';
import Offers from './Offers';

interface IOffersHostProps extends WithTranslation {}

interface IOffersHostState {
    searchValue: string;
}

class OffersHost extends Component<IOffersHostProps, IOffersHostState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IOffersHostProps) {
        super(props);

        this.state = {
            searchValue: '',
        };
    }

    componentDidMount(): void {
        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data),
                    debounceTime(100),
                    tap((data: any) => this.onFormValueChange(data.value))
                )
                .subscribe()
        );
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        const {t} = this.props;
        return (
            <div className="page-layout" id="offers-list">
                <div className={styles.offersHeader}>
                    <p className={styles.title}>
                        <Translation text="offers.title" />
                    </p>
                    <p className={styles.description}>
                        <Translation text="offers.description" />
                    </p>

                    <div className="form-control input-search offers-form-control">
                        <InputBasic
                            type={InputType.TEXT}
                            placeholder={t('offers.form.placeholders.search')}
                            className="input"
                            value={this.state.searchValue}
                            name="searchInput"
                            handleChange={(e: any) => this.onValueStateChange(e)}
                            autoComplete="off"
                        />
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="page-container offers-container">
                            <Offers />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private onValueStateChange = (value: any) => {
        this.onValueStateChange$.next({value: value.target.value});
    };

    private onFormValueChange = (value: any) => {
        this.setState({
            searchValue: value,
        });
    };
}

export default withTranslation()(OffersHost);
