import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelDictionaryDatum} from '../../model/dictionaryDatum';
import {IBaseDictionaryState, IChangeIsInitialized, IChangeIsLoading, ISetError} from './workTypeSlice';

export interface ILanguageLevelState extends IBaseDictionaryState {
    languageLevels: IModelDictionaryDatum[] | null;
}

export interface IChangeLanguageLevel {
    languageLevels: IModelDictionaryDatum[] | null;
}

const initialState: ILanguageLevelState = {
    languageLevels: null,
    isLoading: false,
    isInitialized: false,
    error: null,
};

const languageLevelSlice = createSlice({
    name: 'languageLevel',
    initialState: initialState,
    reducers: {
        changeLanguageLevel: {
            reducer: (state: ILanguageLevelState, action: PayloadAction<IChangeLanguageLevel>) => {
                return {
                    languageLevels: action.payload.languageLevels,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(languageLevels: IModelDictionaryDatum[] | null) {
                return {
                    payload: {languageLevels: languageLevels},
                };
            },
        },
        changeIsLanguageLevelLoading: {
            reducer: (state: ILanguageLevelState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    languageLevels: state.languageLevels,
                    isLoading: action.payload.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        changeIsLanguageLevelInitialized: {
            reducer: (state: ILanguageLevelState, action: PayloadAction<IChangeIsInitialized>) => {
                return {
                    languageLevels: state.languageLevels,
                    isLoading: state.isLoading,
                    isInitialized: action.payload.isInitialized,
                    error: state.error,
                };
            },
            prepare(isInitialized: boolean) {
                return {
                    payload: {isInitialized: isInitialized},
                };
            },
        },
        setLanguageLevelError: {
            reducer: (state: ILanguageLevelState, action: PayloadAction<ISetError>) => {
                return {
                    languageLevels: state.languageLevels,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
        fetchLanguageLevels: (state: ILanguageLevelState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
    },
});

export const {
    changeLanguageLevel,
    changeIsLanguageLevelLoading,
    changeIsLanguageLevelInitialized,
    setLanguageLevelError,
    fetchLanguageLevels,
} = languageLevelSlice.actions;

export default languageLevelSlice.reducer;
