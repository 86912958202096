import {Observable} from 'rxjs';
import {RestQueryParams} from './base/queryParams';
import {jobHunterAPI} from './provider/jobHunterAPI';

export function updateAccountDataAPI(
    accountId: string,
    accessToken: string,
    firstName: string | null,
    lastName: string | null,
    birthdate: string | null,
    phone: string | null,
    avatarId: string | null
): Observable<any> {
    let payload = {
        firstName: firstName,
        lastName: lastName,
        birthdate: birthdate,
        phone: phone,
        avatarId: avatarId,
    };
    return jobHunterAPI.put(`update-account/${accountId}`, payload, new RestQueryParams(), {
        Authorization: `Bearer ${accessToken}`,
    });
}
