export const mockRelocationServices: {[key: string]: any}[] = [
    {
        id: '1668cc22-27a6-408b-b6ef-2bc7470d23c2',
        name: 'Standard',
        netPrice: '290000',
        priceCurrency: 'PLN',
        relocationPackageServices: ['visaDocuments', 'temporaryAccommodation', 'bankAccount', 'polishLessons'],
        color: 'darkBlue1',
    },
    {
        id: '1668cc22-27a6-408b-b6ef-2bc7470d23c3',
        name: 'Premium',
        netPrice: '490000',
        priceCurrency: 'PLN',
        relocationPackageServices: [
            'visaDocuments',
            'temporaryAccommodation',
            'permanentAccommodation',
            'bankAccount',
            'accountingOffice',
            'legalSupport',
            'polishLessons',
        ],
        color: 'darkGreen2',
    },
    {
        id: '1668cc22-27a6-408b-b6ef-2bc7470d23c4',
        name: 'Premium Family',
        netPrice: '890000',
        priceCurrency: 'PLN',
        relocationPackageServices: [
            'visaDocuments',
            'temporaryAccommodation',
            'permanentAccommodation',
            'bankAccount',
            'accountingOffice',
            'legalSupport',
            'familyVisaDocuments',
            'polishLessons',
            'buddy',
        ],
        color: 'darkOrange1',
    },
];
