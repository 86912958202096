import React from 'react';
import ReactPaginate from 'react-paginate';
import {IPaginationProps, IPaginationState} from '../../types';
import {isSameValue} from '../..';

const queryString = require('query-string');

type Props = IPaginationProps;
type State = IPaginationState;

class Pagination extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedItemsPerPage: 25,
            selectedPage: 0,
            pageCount: 1,
        };
    }

    componentDidMount() {
        this.setTotalPageCount();
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (!isSameValue(this.props.listMetadata, prevProps.listMetadata)) {
            this.setTotalPageCount();
        }

        if (!isSameValue(this.props.currentPage, prevProps.currentPage)) {
            this.setState({selectedPage: this.props.currentPage - 1}, () => console.log('selected:', this.state.selectedPage));
        }
    }

    render() {
        return <React.Fragment>{this.renderPagination()}</React.Fragment>;
    }

    private renderPagination = () => {
        return (
            <nav aria-label="Page navigation" className={`${this.state.pageCount === 0 ? 'hidden' : ''} pagination-container`}>
                <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    // initialPage={this.state.selectedPage}
                    onPageChange={this.onPageChange}
                    containerClassName="pagination custom-pagination"
                    activeClassName="active"
                    previousClassName="prev-link"
                    nextClassName="next-link"
                    disableInitialCallback={true}
                    forcePage={this.state.selectedPage}
                />
            </nav>
        );
    };

    private onPageChange = (data: {[key: string]: any}) => {
        let selectedPage = Number(data.selected);

        this.setState({selectedPage: selectedPage}, () => {
            let localSearchParams = {
                itemsPerPage: this.props.itemsPerPage ? this.props.itemsPerPage : this.state.selectedItemsPerPage,
                page: selectedPage + 1,
            };
            this.props.changePage(localSearchParams);
        });
    };

    private setTotalPageCount = () => {
        if (this.props.listMetadata) {
            const lastItemUrl = this.props.listMetadata['hydra:last'];

            if (lastItemUrl) {
                const str = lastItemUrl.split('?').pop(),
                    searchParams = queryString.parse(str),
                    totalPageNumber = searchParams && searchParams.page ? Number(searchParams.page) : 0;

                this.setState({pageCount: totalPageNumber});
            }
        } else {
            this.setState({pageCount: 0});
        }
    };
}

export default Pagination;
