import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {ITagsInputAutocompleteControlProps} from "../../../types";
import ReactTags from 'react-tag-autocomplete'

type Props = ITagsInputAutocompleteControlProps;

class TagsInputAutocompleteControl extends Component<Props> {
    render() {
        const {t} = this.props;

        return (
            <ReactTags
                tags={this.props.value || []}
                suggestions={this.props.autocompleteList}
                onDelete={this.handleChange}
                onAddition={this.handleChange}
                placeholderText={t(this.props.placeholder)}
                name={this.props.name}
            />
        );
    }

    private handleChange = (e) => {
        console.log('event on change/delete', e);

        const eventData = {target: {name: this.props.name, value: e}};
        this.props.handleChange(eventData);
    }
}

export default withTranslation()(TagsInputAutocompleteControl);
