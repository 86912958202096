import React, {Component} from 'react';
import {Button} from 'reactstrap';
import styles from './styles.module.scss';
import {Translation} from 'jobhunter-common-web';

interface IRegistrationSectionProps {
    redirectLink: string;
    title: string;
}

class RegisterSection extends Component<IRegistrationSectionProps> {
    render() {
        return (
            <div className={styles.sectionWrapper}>
                <div className={styles.registerSection}>
                    <div className={styles.registerContent}>
                        <p>
                            <Translation text={this.props.title} />
                        </p>
                        <Button color="primary" onClick={() => this.redirect()}>
                            <Translation text="buttons.register" />
                        </Button>
                    </div>
                    <div className={styles.registerImage} />
                </div>
                <div className={styles.blogSection}>
                    <p className={styles.title}>Check out our IT blog</p>
                    <ul className={styles.articleTitleList}>
                        <li className={styles.articleTitle}>
                            <a
                                href="https://blog.jobhunter.ovh/blog/pros-and-cons-of-being-a-digital-nomad/"
                                className={styles.articleTitleLink}>
                                Pros and cons of being a Digital Nomad
                            </a>
                        </li>
                        <li className={styles.articleTitle}>
                            <a
                                href="https://blog.jobhunter.ovh/blog/11-job-interview-questions-that-a-programmer-should-prepare-for/"
                                className={styles.articleTitleLink}>
                                11 job interview questions that a programmer should prepare for
                            </a>
                        </li>
                        <li className={styles.articleTitle}>
                            <a
                                href="https://blog.jobhunter.ovh/blog/how-to-recruit-eastern-programmers-for-your-company/"
                                className={styles.articleTitleLink}>
                                How to recruit Eastern programmers for your company
                            </a>
                        </li>
                    </ul>

                    <Button color="primary" onClick={() => this.redirectToBlog()}>
                        Blog
                    </Button>
                </div>
            </div>
        );
    }

    private redirect = () => {
        window.open(this.props.redirectLink);
    };

    private redirectToBlog = () => {
        window.open('https://blog.jobhunter.ovh/');
    };
}

export default RegisterSection;
