import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IBlockerEvent} from '../../types';

export interface IConsultationDataState {
    availableConsultationSlots: {[key: string]: any} | null;
    slotsToBlock: IBlockerEvent[];
    isLoading: boolean;
    error: string | null;
}

export interface IChangeAvailableConsultationSlots {
    consultationSlots: {[key: string]: any}[];
}

export interface ISetSlotsToBlock {
    slotsToBlock: IBlockerEvent[];
}

export interface IChangeConsultationDataLoading {
    isLoading: boolean;
}

export interface ISetConsultationDataError {
    error: string | null;
}

const initialState: IConsultationDataState = {
    availableConsultationSlots: null,
    slotsToBlock: [],
    isLoading: false,
    error: null,
};

const consultationDataSlice = createSlice({
    name: 'consultationData',
    initialState: initialState,
    reducers: {
        getAvailableConsultationSlots: {
            reducer: (state: IConsultationDataState) => {
                return {
                    availableConsultationSlots: state.availableConsultationSlots,
                    slotsToBlock: state.slotsToBlock,
                    isLoading: true,
                    error: state.error,
                };
            },
            prepare(from: string, until: string, calendarId: string) {
                return {
                    payload: {
                        from: from,
                        until: until,
                        calendarId: calendarId,
                    },
                };
            },
        },
        changeAvailableConsultationSlots: {
            reducer: (state: IConsultationDataState, action: PayloadAction<IChangeAvailableConsultationSlots>) => {
                return {
                    availableConsultationSlots: action.payload.consultationSlots,
                    slotsToBlock: state.slotsToBlock,
                    isLoading: state.isLoading,
                    error: state.error,
                };
            },
            prepare(consultationSlots: any[]) {
                return {
                    payload: {consultationSlots: consultationSlots},
                };
            },
        },
        setSlotsToBlock: {
            reducer: (state: IConsultationDataState, action: PayloadAction<ISetSlotsToBlock>) => {
                return {
                    availableConsultationSlots: state.availableConsultationSlots,
                    slotsToBlock: action.payload.slotsToBlock,
                    isLoading: state.isLoading,
                    error: state.error,
                };
            },
            prepare(slotsToBlock: IBlockerEvent[]) {
                return {
                    payload: {slotsToBlock: slotsToBlock},
                };
            },
        },
        changeConsultationDataLoading: {
            reducer: (state: IConsultationDataState, action: PayloadAction<IChangeConsultationDataLoading>) => {
                return {
                    availableConsultationSlots: state.availableConsultationSlots,
                    slotsToBlock: state.slotsToBlock,
                    isLoading: action.payload.isLoading,
                    error: state.error,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        setConsultationDataError: {
            reducer: (state: IConsultationDataState, action: PayloadAction<ISetConsultationDataError>) => {
                return {
                    availableConsultationSlots: state.availableConsultationSlots,
                    slotsToBlock: state.slotsToBlock,
                    isLoading: state.isLoading,
                    error: action.payload.error,
                };
            },
            prepare(error: string) {
                return {
                    payload: {error: error},
                };
            },
        },
    },
});

export const {
    changeAvailableConsultationSlots,
    setSlotsToBlock,
    getAvailableConsultationSlots,
    changeConsultationDataLoading,
    setConsultationDataError,
} = consultationDataSlice.actions;
export default consultationDataSlice.reducer;
