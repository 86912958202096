import React from 'react';
import {connect} from 'react-redux';
import {of, Subscription} from 'rxjs';
import {catchError, delay, tap} from 'rxjs/operators';
import {confirmRegistrationAPI} from '../../../api/confirmRegistration';
import {addAlert} from '../../../store/reducers/alertSlice';
import {AlertType} from '../../../types';
import Loader from '../../Loader';
import Translation from '../../Translation';
import {RouterProps} from '../../RouterWrapperComponent';
import {withRouter} from '../../../index';

interface IConnectedConfirmRegistrationProps {
    addAlert: typeof addAlert;
}

interface IExternalConfirmRegistrationProps {}

interface IConfirmRegistrationProps extends IConnectedConfirmRegistrationProps, IExternalConfirmRegistrationProps, RouterProps {}

interface IConfirmRegistrationState {
    isLoading: boolean;
}

class ConfirmRegistration extends React.Component<IConfirmRegistrationProps, IConfirmRegistrationState> {
    private subscriptions: Subscription[] = [];

    constructor(props: IConfirmRegistrationProps) {
        super(props);

        this.state = {
            isLoading: false,
        };
    }

    componentWillUnmount(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <article>
                <Loader showLoader={this.state.isLoading} />
                <header className="auth-form-header confirm-form-header">
                    <h1 className="auth-title confirm-title">
                        <Translation text="auth.confirmRegistration.title" />
                    </h1>
                </header>
                <div className="registration-confirmation-container">
                    <p className="registration-confirmation-info">
                        <Translation text="auth.confirmRegistration.subtitle" />
                    </p>
                    <button className="btn btn-primary btn-confirm" onClick={() => this.confirmRegistration()}>
                        <Translation text="buttons.confirm" />
                    </button>
                </div>
            </article>
        );
    }

    private confirmRegistration = (): void => {
        const params = this.props.params;

        if (!(params as any).id) {
            this.props.addAlert({message: 'auth.alert.invalidConfirmRegistrationURL', type: AlertType.WARNING});
            return;
        }

        this.setState({isLoading: true});
        this.subscriptions.push(
            confirmRegistrationAPI((params as any).id)
                .pipe(
                    catchError((error: any) => {
                        let message = '';
                        const response = error.response;

                        if (response['hydra:description']) {
                            message =
                                response['hydra:description'] && response['hydra:description'].includes('Token not found')
                                    ? 'auth.alert.invalidConfirmRegistrationURL'
                                    : response['hydra:description'];
                        } else if (response.message) {
                            if (response.message === 'JWT Token not found') {
                                message = 'auth.alert.authenticationError';
                            } else {
                                message = response.message;
                            }
                        }
                        this.props.addAlert({message: message, type: AlertType.WARNING});
                        this.setState({isLoading: false});

                        return of();
                    }),
                    tap(() => this.props.addAlert({message: 'auth.alert.confirmRegistrationSuccess'})),
                    delay(1500),
                    tap(() => {
                        this.props.navigate('/');
                        this.setState({isLoading: false});
                    })
                )
                .subscribe()
        );
    };
}

export default connect(() => ({}), {
    addAlert,
})(withRouter(ConfirmRegistration));
