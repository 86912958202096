import React, {Component} from 'react';
import {Button} from 'reactstrap';
import {
    ISelectedTechnologyItem,
    ISelectedTechnologyToolItem,
    ITechnologyControlDisplayValueItem,
    ITechnologyControlProps,
    ITechnologyControlState,
    ITechnologyControlValue
} from "../../../types";
import Translation from "../../Translation";
import RatingComponent from '../../RatingComponent';
import AddTechnologySkill from "./AddTechnologySkill";


type Props = ITechnologyControlProps;
type State = ITechnologyControlState;


class TechnologyControl extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            controlValue: null,
            value: null,
            isModalVisible: false,
        };
    }

    componentDidMount() {
        if (this.props.value) {
            this.convertDefaultValueToDisplayValue();
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (this.props.value !== prevProps.value) {
            this.convertDefaultValueToDisplayValue();
        }
    }

    render() {
        return (
            <div className="technology-control-wrapper">
                <div className="container">
                    {this.state.controlValue
                        ? this.state.controlValue.map((item: ITechnologyControlDisplayValueItem) => {
                              return (
                                  <div className="control-rating-item" key={item.id}>
                                      <div className="label-wrapper">
                                          <label className="rating-label">
                                              <Translation text={item.label} />
                                          </label>
                                          <Button color="primary" outline onClick={() => this.removeItem(item)}>x</Button>
                                      </div>
                                      <RatingComponent
                                          id={item.id}
                                          initialRating={item.level}
                                          emptySymbol={<div className="empty-symbol" />}
                                          fullSymbol={<div className="full-symbol" />}
                                          readonly
                                          stop={6}
                                      />
                                  </div>
                              );
                          })
                        : null}

                    <Button color="primary" onClick={() => this.toggleModal()}>
                        <Translation text="buttons.addNew" />
                    </Button>
                </div>

                {this.state.isModalVisible ?
                    <AddTechnologySkill  toggleModal={this.toggleModal}
                                         value={this.props.value}
                                         onAddValue={this.saveSelectedValue}
                                         isModalVisible={this.state.isModalVisible}
                                         technologyTools={this.props.technologyTools}
                                         technologies={this.props.technologies}
                                         seniorityLevels={this.props.seniorityLevels}/> : null}
            </div>
        );
    }

    private toggleModal = () => {
        this.setState({isModalVisible: !this.state.isModalVisible});
    };

    private saveSelectedValue = (value: {[key: string]: any}) => {
        if (!this.props.technologyTools) {
            return;
        }

        const formValue = value,
            selectedTechnologies = this.state.controlValue ? Array.from(this.state.controlValue) : [],
            stateTechnologyToolsValue: ISelectedTechnologyToolItem[] = this.state.value && this.state.value.technologyTools ? Array.from(this.state.value.technologyTools) : [],
            stateTechnologyValue: ISelectedTechnologyItem[] = this.state.value && this.state.value.technologies ? Array.from(this.state.value.technologies) : [],
            selectedTechnology = formValue.technology,
            selectedTechnologyTools = formValue.technologyTool,
            selectedLevel = formValue.technologyLevel === '' ? this.props.seniorityLevels?.[0].value : formValue.technologyLevel;

        stateTechnologyValue.push({
            technologyId: selectedTechnology,
            seniorityId: selectedLevel,
        });
        const filteredTechnologyValues = stateTechnologyValue.reduce((acc: ISelectedTechnologyItem[], current: ISelectedTechnologyItem) => {
            const x = acc.find((item: ISelectedTechnologyItem) => item.technologyId === current.technologyId);
            return !x ? acc.concat([current]) : acc;
        }, []);

        selectedTechnologyTools?.map((tool: string) => {
            return this.props.technologyTools?.map((item) => {
                if (item.id === tool) {
                    stateTechnologyToolsValue.push({
                        technologyToolId: tool,
                        seniorityId: selectedLevel,
                    });
                }
            });
        });

        const stateValue: ITechnologyControlValue = {
            technologyTools: stateTechnologyToolsValue,
            technologies: filteredTechnologyValues,
        };

        selectedTechnologyTools?.map((tool: string) => {
            return this.props.technologyTools?.map((item) => {
                if (item.id === tool) {
                    let level = 0;
                    Object.keys(this.props.seniorityLevels).map((key: string) => {
                        let value = this.props.seniorityLevels[key];
                        if (value.value === selectedLevel) {
                            return level = value.level / 10
                        }
                    })

                    selectedTechnologies.push({
                        id: tool,
                        label: item.name,
                        level: level
                    });
                }
            });
        });

        this.setState({
            controlValue: selectedTechnologies,
            value: stateValue,
            isModalVisible: false,
        });

        this.props.handleChange(stateValue);
    };

    private convertDefaultValueToDisplayValue = () => {
        const displayValues = [];
        this.props.value.technologyTools.map((tool) => {
            let level = 0;
            Object.keys(this.props.seniorityLevels).map((key: string) => {
                let value = this.props.seniorityLevels[key];
                if (value.value === tool.seniorityId) {
                    return level = value.level / 10
                }
            })
            const value = {
                id: tool.technologyToolId,
                label: this.props.technologyTools.find((technologyTool) => technologyTool.id === tool.technologyToolId).name,
                level: level
            }
            return displayValues.push(value);
        });

        this.setState({controlValue: displayValues, value: this.props.value});
    }

    private removeItem = (item: ITechnologyControlDisplayValueItem) => {
        let controlValues: ITechnologyControlDisplayValueItem[] = this.state.controlValue ? Array.from(this.state.controlValue) : [],
            technologies: ISelectedTechnologyItem[] = this.state.value && this.state.value.technologies ? Array.from(this.state.value.technologies) : [],
            technologyTools: ISelectedTechnologyToolItem[] = this.state.value && this.state.value.technologyTools ? Array.from(this.state.value.technologyTools) : [];
        controlValues = controlValues?.filter((value: ITechnologyControlDisplayValueItem) => value.id !== item.id);
        technologyTools = technologyTools?.filter((value: ISelectedTechnologyToolItem) => value.technologyToolId !== item.id);

        const stateValue: ITechnologyControlValue = {
            technologyTools: technologyTools,
            technologies: technologies,
        };
        this.setState({controlValue: controlValues, value: stateValue});
        this.props.handleChange(stateValue);
    }
}

export default TechnologyControl;
