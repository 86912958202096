import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelApiObject} from '../../model/base';
import {IModelCity, IModelDictionaryDatum, IModelSeniority} from '../../model/dictionaryDatum';
import {IFileOutput} from '../../model/user';

export enum CandidateStatus {
    HIRED = 'hired',
    CANDIDATE = 'candidate',
}

export interface ICandidateAccountCompletion {
    personal: boolean;
    career: boolean;
    skill_tests: boolean;
    preferences: boolean;
}

export interface IOrganizationAccountCompletion {
    personal: boolean;
    verification: boolean;
    about_company: boolean;
}

interface IUserAvatar extends IModelApiObject {
    contentUrl: string | null;
    fileUrls: string[];
    id: string;
}

export interface IUser extends IModelApiObject {
    about: string | null;
    active: boolean | null;
    avatar: IFileOutput | null;
    birthDate: string | null;
    cityName: string | null;
    country: IModelDictionaryDatum;
    firstName: string | null;
    id: string;
    lastName: string | null;
    locale: string | null;
    phone: string | null;
    filled: ICandidateAccountCompletion | IOrganizationAccountCompletion | null;
    misc: string | null;
    userId: string | null;
    candidateStatus?: CandidateStatus;
    email?: string;
    leads?: number;
}

export interface ICandidateCareer extends IModelApiObject {
    company: string;
    from: string;
    id: string;
    position: string;
    seniority: IModelSeniority;
    to: string | null;
}

export interface ITechnologyTestResult extends IModelApiObject {
    id: string;
    result: number | null;
    technology: ISkillItem;
}

export interface ILanguageTestResult extends IModelApiObject {
    id: string;
    result: number | null;
    language: ISkillItem;
}

interface ICandidatePreferences extends IModelApiObject {
    cities: IModelCity[];
    companyTypes: IModelDictionaryDatum[];
    contractTypes: IModelDictionaryDatum[];
    employmentTypes: IModelDictionaryDatum[];
    industries: IModelDictionaryDatum[];
    minimumSalary: string;
    relocationOnly: boolean;
    technologies: IModelDictionaryDatum[];
    workTypes: IModelDictionaryDatum[];
}

export interface ISkillItem extends IModelDictionaryDatum {
    isTestable: boolean;
}

export interface ICandidateTechnology extends IModelApiObject {
    id: string;
    seniority: IModelSeniority;
    technology: ISkillItem;
    technologyTool: IModelDictionaryDatum[];
}

export interface ICandidateLanguage extends IModelApiObject {
    language: ISkillItem;
    languageLevel: IModelDictionaryDatum;
}

export interface IVerificationFile extends IModelApiObject {
    file: IFileOutput;
    type: IModelDictionaryDatum;
    fileName: string | null;
}

export interface IExternalLink extends IModelApiObject {
    id: string;
    link: string;
    type: string;
}

export interface ICandidateFullInfo extends IModelApiObject {
    account: IUser;
    careers: ICandidateCareer[];
    cv: IUserAvatar;
    externalLinks: IExternalLink[];
    languageTestResults: ILanguageTestResult[];
    languages: ICandidateLanguage[];
    preferences: ICandidatePreferences;
    technologies: ICandidateTechnology[];
    technologyTestResults: ITechnologyTestResult[];
    id?: string;
}

export interface IOrganisationCompany extends IModelApiObject {
    address: string | null;
    avatar: IFileOutput | null;
    city: string | null;
    country: IModelDictionaryDatum;
    description: string | null;
    name: string;
    phone: string | null;
    vatNumber: string | null;
}

export interface IOrganizationDetails extends IModelApiObject {
    cities: IModelCity[];
    companyTypes: IModelDictionaryDatum[];
    industries: IModelDictionaryDatum[];
    organizationSize: number | null;
}

export interface IOrganisation extends IModelApiObject {
    id: string;
    organizationCompany: IOrganisationCompany;
    organizationDetails: IOrganizationDetails;
}

export interface IOrganizationVerificationDocuments extends IModelApiObject {
    files: IVerificationFile[];
}

export interface IOrganizationFullInfo extends IModelApiObject {
    id: string;
    initiallyAccepted: boolean | null;
    organization: IOrganisation;
    user: IUser;
    verification: IOrganizationVerificationDocuments;
}

interface IAccountFullInfo {
    candidateFullInfo: ICandidateFullInfo | null;
    organizationFullInfo: IOrganizationFullInfo | null;
}

export interface IAccount extends IAccountFullInfo {}

export interface IAccountState {
    account: IAccount;
    isLoading: boolean;
    isInitialized: boolean;
    error: string | null;
}

export interface IAccountPayload {
    candidateFullInfo: ICandidateFullInfo | null;
    organizationFullInfo: IOrganizationFullInfo | null;
}

export interface ISetAccountState {
    account: IAccount;
}

export interface IUpdateAccountState {
    candidateFullInfo: ICandidateFullInfo;
    organizationFullInfo: IOrganizationFullInfo;
}

export interface ISetAccountStateFailure {
    error: string;
}

export interface IChangeAccountStateLoading {
    isLoading: boolean;
}

export interface IUpdateAccount {
    account: IAccount;
}

export interface IChangeAccountAvatar {
    mediaObject: IFileOutput | null;
}

const initialState: IAccountState = {
    account: {
        candidateFullInfo: null,
        organizationFullInfo: null,
    },
    isLoading: false,
    isInitialized: false,
    error: null,
};

export const mapResponseAccountToCandidateFullInfo = (response: any): ICandidateFullInfo | null => {
    return response.candidateFullInfo
        ? {
              account: response.candidateFullInfo.account,
              careers: response.candidateFullInfo.careers,
              cv: response.candidateFullInfo.cv,
              externalLinks: response.candidateFullInfo.externalLinks,
              languageTestResults: response.candidateFullInfo.languageTestResults,
              languages: response.candidateFullInfo.languages,
              preferences: response.candidateFullInfo.preferences,
              technologies: response.candidateFullInfo.technologies,
              technologyTestResults: response.candidateFullInfo.technologyTestResults,
          }
        : null;
};

export const mapResponseAccountToOrganizationFullInfo = (response: any): IOrganizationFullInfo | null => {
    return response.organizationFullInfo
        ? {
              id: response.organizationFullInfo.id,
              initiallyAccepted: response.initiallyAccepted,
              organization: response.organizationFullInfo.organization,
              user: response.organizationFullInfo.user,
              verification: response.organizationFullInfo.verification,
          }
        : null;
};

const accountSlice = createSlice({
    name: 'account',
    initialState: initialState,
    reducers: {
        setAccountState: {
            reducer: (state: IAccountState, action: PayloadAction<ISetAccountState>) => {
                return {
                    ...state,
                    account: action.payload.account,
                };
            },
            prepare(account: IAccountPayload) {
                return {
                    payload: {
                        account: account,
                    },
                };
            },
        },
        updateAccountState: {
            reducer: (state: IAccountState, action: PayloadAction<IUpdateAccountState>) => {
                return {
                    account: {
                        candidateFullInfo: action.payload.candidateFullInfo,
                        organizationFullInfo: action.payload.organizationFullInfo,
                    },
                    isLoading: true,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(candidateFullInfo: ICandidateFullInfo, organizationFullInfo: IOrganizationFullInfo) {
                return {
                    payload: {
                        candidateFullInfo: candidateFullInfo,
                        organizationFullInfo: organizationFullInfo,
                    },
                };
            },
        },
        updateAccount: {
            reducer: (state: IAccountState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(account: IAccount) {
                return {
                    payload: {account: account},
                };
            },
        },
        setAccountStateFailure: {
            reducer: (state: IAccountState, action: PayloadAction<ISetAccountStateFailure>) => {
                return {
                    ...state,
                    error: action.payload.error,
                };
            },
            prepare(error: string) {
                return {
                    payload: {
                        error: error,
                    },
                };
            },
        },
        changeAccountStateLoading: {
            reducer: (state: IAccountState, action: PayloadAction<IChangeAccountStateLoading>) => {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {
                        isLoading: isLoading,
                    },
                };
            },
        },
        changeAccountAvatar: {
            reducer: (state: IAccountState) => {
                return {
                    ...state,
                };
            },
            prepare(mediaObject: IFileOutput | null) {
                return {
                    payload: {
                        mediaObject: mediaObject,
                    },
                };
            },
        },
        refreshAccountStateData: (state: IAccountState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
    },
});

export const {
    setAccountState,
    updateAccountState,
    setAccountStateFailure,
    changeAccountStateLoading,
    updateAccount,
    changeAccountAvatar,
    refreshAccountStateData,
} = accountSlice.actions;

export default accountSlice.reducer;
