import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IOnlineConsultationState} from '../reducers/onlineConsultationSlice';

export const selectOnlineConsultation = (state: RootState): IOnlineConsultationState => {
    return state.onlineConsultation;
};

export const onlineConsultationSelector = createSelector(
    [selectOnlineConsultation],
    (state: IOnlineConsultationState) => state.onlineConsultation
);

export const showOnlineConsulationLoaderSelector = createSelector(
    [selectOnlineConsultation],
    (state: IOnlineConsultationState) => state.isLoading
);

export const onlineConsultationErrorSelector = createSelector([selectOnlineConsultation], (state: IOnlineConsultationState) => state.error);

export const agoraTokenSelector = createSelector(
    [selectOnlineConsultation],
    (state: IOnlineConsultationState) => state.onlineConsultation?.agoraToken
);

export const agoraChannelSelector = createSelector(
    [selectOnlineConsultation],
    (state: IOnlineConsultationState) => state.onlineConsultation?.agoraChannel
);

export const onlineConsultationStartsAtSelector = createSelector(
    [selectOnlineConsultation],
    (state: IOnlineConsultationState) => state.onlineConsultation?.startsAt
);

export const onlineConsultationEndsAtSelector = createSelector(
    [selectOnlineConsultation],
    (state: IOnlineConsultationState) => state.onlineConsultation?.endsAt
);

export const onlineConsultationStatusSelector = createSelector(
    [selectOnlineConsultation],
    (state: IOnlineConsultationState) => state.onlineConsultation?.status
);
