import React, {Component} from 'react';
import LayoutWrapper from '../LayoutWrapper';
import {Translation} from 'jobhunter-common-web';

class EuGrantInfo extends Component {
    render() {
        return (
            <LayoutWrapper>
                <div className="page-layout">
                    <div className="container">
                        <div className="row">
                            <div className="page-container offers-container">
                                <div className="eu-grant-layout">
                                    <div className="eu-partners"></div>
                                    <div className="content">
                                        <p>
                                            <Translation text="euGrantInfo.projectInformation" />
                                        </p>
                                        <p>
                                            <Translation text="euGrantInfo.projectDetails.agreementNumber" />
                                        </p>
                                        <p>
                                            <Translation text="euGrantInfo.projectDetails.beneficiary" />
                                        </p>
                                        <p>
                                            <Translation text="euGrantInfo.projectDetails.totalPrice" />
                                        </p>
                                        <p>
                                            <Translation text="euGrantInfo.projectDetails.euFunding" />
                                        </p>
                                        <p>
                                            <Translation text="euGrantInfo.projectDetails.projectPurpose" />
                                        </p>
                                        <p>
                                            <Translation text="euGrantInfo.projectDetails.results" />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutWrapper>
        );
    }
}

export default EuGrantInfo;
