import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelDictionaryDatum} from '../../model/dictionaryDatum';
import {IBaseDictionaryState, IChangeIsInitialized, IChangeIsLoading, ISetError} from './workTypeSlice';

export interface ICompanyTypeState extends IBaseDictionaryState {
    companyTypes: IModelDictionaryDatum[] | null;
}

export interface IChangeCompanyType {
    companyTypes: IModelDictionaryDatum[] | null;
}

const initialState: ICompanyTypeState = {
    companyTypes: null,
    isLoading: false,
    isInitialized: false,
    error: null,
};

const companyTypeSlice = createSlice({
    name: 'companyType',
    initialState: initialState,
    reducers: {
        changeCompanyType: {
            reducer: (state: ICompanyTypeState, action: PayloadAction<IChangeCompanyType>) => {
                return {
                    companyTypes: action.payload.companyTypes,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(companyTypes: IModelDictionaryDatum[] | null) {
                return {
                    payload: {companyTypes: companyTypes},
                };
            },
        },
        changeIsCompanyTypeLoading: {
            reducer: (state: ICompanyTypeState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    companyTypes: state.companyTypes,
                    isLoading: action.payload.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        changeIsCompanyTypeInitialized: {
            reducer: (state: ICompanyTypeState, action: PayloadAction<IChangeIsInitialized>) => {
                return {
                    companyTypes: state.companyTypes,
                    isLoading: state.isLoading,
                    isInitialized: action.payload.isInitialized,
                    error: state.error,
                };
            },
            prepare(isInitialized: boolean) {
                return {
                    payload: {isInitialized: isInitialized},
                };
            },
        },
        setCompanyTypeError: {
            reducer: (state: ICompanyTypeState, action: PayloadAction<ISetError>) => {
                return {
                    companyTypes: state.companyTypes,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
        fetchCompanyTypes: (state: ICompanyTypeState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
    },
});

export const {changeCompanyType, changeIsCompanyTypeLoading, changeIsCompanyTypeInitialized, setCompanyTypeError, fetchCompanyTypes} =
    companyTypeSlice.actions;

export default companyTypeSlice.reducer;
