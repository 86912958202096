import {Observable} from 'rxjs';
import {jobHunterAPI} from './provider/jobHunterAPI';
import {RestQueryParams} from './base/queryParams';
import {ChatServerMessagePayload} from '../types';

export function sendMessageToOfflineAPI(accessToken: string, message: ChatServerMessagePayload): Observable<any> {
    return jobHunterAPI.post(`api/messages`, message, new RestQueryParams(), {
        Authorization: `Bearer ${accessToken}`,
    });
}
