import React from 'react';
import {connect} from 'react-redux';
import {Uploader} from 'rsuite';
import {RootState} from '../../../store/reducers';
import Translation from '../../Translation';
import {addAlert, AlertType, authTokenSelector, isSameValue} from '../../..';
import {convertApiErrorToErrorMessage} from '../../../utils/alertUtils';
import {FileType, IFileUploaderProps, IFileUploaderState} from '../../../types';
import {File} from 'react-feather';
import {API_URL} from '../../../config/entrypoint';
import {IModelMediaObject} from '../../../model/mediaObject';
import {IFileOutput} from '../../../model/user';
import {IFileUploadListElement} from '../../AccountImageHost';

type Props = IFileUploaderProps;
type State = IFileUploaderState;

class InputFileUploader extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            value: [],
            selectedFile: null,
            isProcessing: false,
            inputFileUploaded: false,
            progressValue: null,
        };
    }

    componentDidMount() {
        if (this.props.defaultFileValue) {
            this.setDefaultFileValue(this.props.defaultFileValue);
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (!isSameValue(this.props.defaultFileValue, prevProps.defaultFileValue) && this.props.defaultFileValue) {
            this.setDefaultFileValue(this.props.defaultFileValue);
        }
    }

    render() {
        return (
            <div className="uploader-control">
                <div className="uploader-input-icon">
                    <File size={`${this.props.iconSize ? this.props.iconSize : 60}`} />
                </div>
                <div className="uploader-wrapper">
                    <Uploader
                        removable={this.props.isFileRemovable}
                        className={`form-input ${this.state.value.length > 0 ? 'uploaded' : 'empty'}`}
                        action={``}
                        multiple={false}
                        autoUpload={false}
                        onChange={this.handleChange}
                        headers={{Authorization: 'Bearer ' + this.props.authToken}}
                        onSuccess={this.handleUploadSuccess}
                        fileList={this.state.value}
                        onProgress={this.updateProgressData}
                        onRemove={(file: FileType) => this.removeFile(file)}
                        onError={this.handleUploadError}
                        // onUpload={this.props.onFileUpload}
                        accept={this.props.acceptedFileExtension ? this.props.acceptedFileExtension : null}
                        listType="text">
                        {this.props.label && (
                            <div>
                                <button
                                    className={`${
                                        this.state.value.length ? 'btn btn-outline-primary' : 'btn btn-primary'
                                    } file-upload-button`}
                                    onClick={(e: any) => e.preventDefault()}>
                                    {this.state.value.length ? (
                                        <Translation text={`buttons.change`} />
                                    ) : (
                                        <Translation text={'buttons.add'} />
                                    )}
                                </button>
                            </div>
                        )}
                    </Uploader>
                    <div className="format-details">
                        {this.props.acceptedFileExtension ? (
                            <p>
                                <Translation text="form.fileUploadAllowedFiles" /> {this.props.acceptedFileExtension}{' '}
                            </p>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }

    private handleChange = (file: any) => {
        if (!file.length) {
            return;
        }
        this.props.onFileChange && this.props.onFileChange(file);
        return this.setState(
            {
                value: this.props.isMultiple ? file : file.slice(-1),
                selectedFile: file.length > 1 ? file.slice(-1) : file[0].blobFile,
            },
            () => this.saveFile()
        );
    };

    private handleUploadSuccess = (response: any) => {
        if (response) {
            this.setState({inputFileUploaded: true, progressValue: null});
        }
    };

    private handleUploadError = (error: any) => {
        const message = convertApiErrorToErrorMessage(error);
        this.props.addAlert({message: message, type: AlertType.WARNING});
        this.setState({progressValue: null, inputFileUploaded: false});
    };

    private removeFile = (file: FileType) => {
        const uploadedFiles = this.state.value.filter((item: FileType) => item.fileKey !== file.fileKey);

        this.setState(
            {value: uploadedFiles, selectedFile: null, inputFileUploaded: false},
            () => this.props.onFileUpload && this.props.onFileUpload(null)
        );
    };

    private updateProgressData = (progressValue: number) => {
        this.setState({progressValue: progressValue});
    };

    private saveFile = () => {
        if (!this.state.selectedFile) {
            return;
        }
        this.setState({isProcessing: true});
        const formData = new FormData();
        const imageData = this.state.selectedFile;
        formData.append('file', imageData);
        formData.append('public', 'false'); // field public has value true for avatar images and false for all the rest files

        window
            .fetch(`${API_URL as string}/api/media_objects`, {
                method: 'POST',
                body: formData,
                headers: new Headers({
                    Authorization: 'Bearer ' + this.props.authToken,
                }),
            })
            .then((resp: any) => {
                return resp.json().then((fileUploadObj: IModelMediaObject) => {
                    if (fileUploadObj) {
                        if (this.props.onFileUpload) {
                            const fileOutput: IFileOutput = {
                                id: fileUploadObj.id,
                                fileUrls: fileUploadObj.contentUrls,
                                contentUrl: null,
                                originalFileName: fileUploadObj?.originalFileName,
                            };
                            this.props.onFileUpload(fileOutput);
                        }
                        this.setState({isProcessing: false});
                    }
                });
            });
    };

    private setDefaultFileValue = (file: IFileOutput) => {
        if (!file.id || !file.contentUrl) {
            return;
        }

        const mediaList: IFileUploadListElement = {
            name: file.id,
            fileKey: file.id,
            url: file.contentUrl,
        };
        this.setState({value: [mediaList]});
    };
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
    }),
    {
        addAlert,
    }
)(InputFileUploader);
