import React from 'react';
import AgoraRTC from 'agora-rtc-sdk';
import styles from './styles.module.scss';
import DeviceSelect from "./DeviceSelect";
import ProgressBar from "./ProgressBar";
import {connect} from "react-redux";
import { changeAudioInputDevice, changeAudioOutputDevice, changeVideoInputDevice } from '../../../../store/reducers/videoCallDetailsSlice';
import {Button, Modal, ModalBody, ModalHeader} from 'reactstrap';
import Translation from "../../../Translation";
import {IVideoSettingsProps, IVideoSettingsState, MediaDeviceType} from "../../../../types";


const USER_ID = Math.floor(Math.random() * 1000000001);
type Props = IVideoSettingsProps;
type State = IVideoSettingsState;

class VideoSettings extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            cameraDevices: [],
            speakerDevices: [],
            microphoneDevices: [],
            selectedAudioInputId: null,
            selectedAudioOutputId: null,
            selectedVideoInputId: null,
            microphoneAudioLevel: 0
        }
    }

    localStream = AgoraRTC.createStream({
        streamID: USER_ID,
        audio: true,
        video: true,
        screen: false
    });

    componentDidMount() {
        // this.initLocalStream();
        this.getMediaDevices();
    }

    componentWillUnmount(): void {
        this.handleExit();
    }

    render() {
        return (
            <Modal isOpen={this.props.isModalVisible} toggle={() => this.props.toggleModal()}>
                <ModalHeader toggle={() => this.props.toggleModal()} />

                <div id="agora_local" className={styles.agoraLocalStream} />

                <ModalBody>
                    <div className={styles.details}>
                        <div className={styles.header}>
                            <h3>
                                <Translation text={'videoChat.settings.title'}/>
                            </h3>
                        </div>
                        <DeviceSelect data={this.state.cameraDevices}
                                      label={'videoChat.settings.selectCamera'}
                                      onSelect={this.onDeviceChange}/>

                        <DeviceSelect data={this.state.speakerDevices}
                                      label={'videoChat.settings.selectSpeaker'}
                                      onSelect={this.onDeviceChange}
                                      isDisabled={true}/>

                        <DeviceSelect data={this.state.microphoneDevices}
                                      label={'videoChat.settings.selectMicrophone'}
                                      onSelect={this.onDeviceChange}/>
                        <div id="mic-test"/>

                        <span className={styles.audioTestLabel}>
                            <Translation text={'videoChat.settings.testMicrophone'}/>
                        </span>
                        <div className={styles.progressContainer}>
                            <span className={styles.audioLevelLabel}>poor</span>
                            <ProgressBar completed={this.state.microphoneAudioLevel}/>
                            <span className={styles.audioLevelLabel}>excellent</span>
                        </div>

                        <div className={styles.btnContainer}>
                            <Button className='btn save-video-settings' color="primary"
                                    onClick={this.saveSettings}>
                                <Translation text={'videoChat.settings.saveChanges'}/>
                            </Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        )
    }

    // private initLocalStream = () => {
    //     let me = this;
    //     me.localStream.init(
    //         () => {
    //             console.log("getUserMedia successfully");
    //             me.localStream.play("agora_local");
    //         },
    //         (err) => {
    //             console.log("getUserMedia failed", err);
    //         }
    //     );
    // };

    handleExit = () => {
        this.localStream && this.localStream.close();
    };

    private getMediaDevices() {
        AgoraRTC.getDevices((devices) => {
            let microphoneDevices = devices.filter((device) => {
                return device.kind === MediaDeviceType.AUDIO_INPUT;
            });
            let videoDevices = devices.filter((device) => {
                return device.kind === MediaDeviceType.VIDEO_INPUT;
            });
            let speakerDevices = devices.filter((device) => {
                return device.kind === MediaDeviceType.AUDIO_OUTPUT;
            });

            this.setState({
                cameraDevices: videoDevices,
                speakerDevices: speakerDevices,
                microphoneDevices: microphoneDevices
            });
        });
    }

    // private onCameraSelect = (event: any) => {
    //     const selectedCameraId = event.value;
    //
    //     if(this.localStream) {
    //         this.localStream.switchDevice(
    //             "video",
    //             selectedCameraId,
    //               () => console.log('Camera is changed'),
    //               (error) => console.log('An error occurred while changing camera:', error)
    //         )
    //     }
    // };

    // toDo change speakers, for now controls for changing speakers are disabled
    // private onSpeakerSelect = (event: any) => {
    //     // const selectedSpeakerId = event.target.value;
    //     if(this.localStream) {
    //       // this.localStream.switchDevice(
    //       //   "audio",
    //       //   event.target.value,
    //       //   () => console.log('Speakers are changed'),
    //       //   (error) => console.log('An error occurred while changing speakers:', error)
    //       // )
    //     }
    // };

    // private onMicrophoneSelect = (event: any) => {
    //     console.log('event', event);
    //     const selectedMicrophoneId = event.value;
    //     this.setState({selectedAudioInputId: selectedMicrophoneId});
    //
    //     if(this.localStream) {
    //         this.localStream.switchDevice(
    //             "audio",
    //             selectedMicrophoneId,
    //             () => {
    //                 this.testMicrophone();
    //                 console.log('Microphone is changed')
    //             },
    //             (error) => console.log('An error occurred while changing microphone:', error)
    //         )
    //     }
    // };

    private testMicrophone() {
        if (!this.state.selectedAudioInputId) {
            return;
        }

        AgoraRTC.getDevices(() => {
            let stream = AgoraRTC.createStream({
                streamID: USER_ID,
                audio: true,
                microphoneId: this.state.selectedAudioInputId ? this.state.selectedAudioInputId : undefined,
                video: false,
            });

            stream.init(() => {
                stream.play("mic-test");
                setInterval(() => {
                    if(stream.getAudioLevel()) {
                        this.setState({microphoneAudioLevel: stream.getAudioLevel()})
                    }
                }, 1000);
            })
        });
    }

    private onDeviceChange = (event: any) => {
        if (event && event.kind === MediaDeviceType.AUDIO_INPUT) {
            this.setState({selectedAudioInputId: event.value});
            this.testMicrophone();
        }

        if (event && event.kind === MediaDeviceType.AUDIO_OUTPUT) {
            this.setState({selectedAudioOutputId: event.value});
        }

        if (event && event.kind === MediaDeviceType.VIDEO_INPUT) {
            this.setState({selectedVideoInputId: event.value});
        }
    };

    private saveSettings = () => {
        if (this.state.selectedAudioInputId) {
            this.props.changeAudioInputDevice(this.state.selectedAudioInputId);
        }

        if (this.state.selectedAudioOutputId) {
            this.props.changeAudioOutputDevice(this.state.selectedAudioOutputId);
        }

        if (this.state.selectedVideoInputId) {
            this.props.changeVideoInputDevice(this.state.selectedVideoInputId);
        }
        this.props.toggleModal();
    }
}

export default connect(
    () => ({}),
    {
        changeAudioInputDevice,
        changeAudioOutputDevice,
        changeVideoInputDevice,
    }
)(VideoSettings);
