// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3XfDmLdPTZoACUrv7VcZP-{position:absolute;top:-4rem;right:0;font-size:2rem;color:#009fe3;font-weight:500;font-family:Montserrat, sans-serif;filter:drop-shadow(0 1.3rem 1.35rem rgba(0,0,0,0.15));z-index:9999}._3XfDmLdPTZoACUrv7VcZP-._3YXWzYnQGkJBQbpM_cu4DJ{color:#fa7d03}._3XfDmLdPTZoACUrv7VcZP-._3YXWzYnQGkJBQbpM_cu4DJ:before{content:'-';color:#fa7d03}._3XfDmLdPTZoACUrv7VcZP- ._2DpyLAze3ge38cO2D-yC81{margin-left:1rem;background:#009fe3;border-radius:50%;width:2rem;height:2rem;display:flex;justify-content:center;align-items:center;color:#fff;font-size:1.4rem}._3XfDmLdPTZoACUrv7VcZP- ._2DpyLAze3ge38cO2D-yC81:hover{cursor:pointer}._3XfDmLdPTZoACUrv7VcZP-._3ulUx6lPKLcBinVYKMwUOn{display:none}@media (max-width: 1024px){._3XfDmLdPTZoACUrv7VcZP-{font-size:1.6rem}}\n", ""]);
// Exports
exports.locals = {
	"counter": "_3XfDmLdPTZoACUrv7VcZP-",
	"exceeded": "_3YXWzYnQGkJBQbpM_cu4DJ",
	"tooltipWrapper": "_2DpyLAze3ge38cO2D-yC81",
	"hidden": "_3ulUx6lPKLcBinVYKMwUOn"
};
module.exports = exports;
