import React from 'react';
import {ICounterProps, ICounterState, TooltipPlacement, UserRole} from '../../../types';
import {isSameValue} from '../../../utils/comparatorUtils';
import styles from "./styles.module.scss";
import Tooltip from "../../Tooltip";


type Props = ICounterProps;
type State = ICounterState;

class Counter extends React.Component<Props, State> {
    private countdownInterval: any;
    private countInterval: any;

    constructor(props: Props) {
        super(props);

        this.state = {
            count: 0,
            isConsultationExceeded: false
        };
    }

    componentDidMount() {
        if (this.props.onlineConsultation.endsAt) {
            this.setState({count: this.getConsultationDuration(this.props.onlineConsultation.endsAt, new Date().toISOString())}, () => {
                if (this.state.count >= 0) {
                    this.countdownInterval = setInterval(() => {
                        this.setState(() => ({
                            count: Math.floor(this.state.count) - 1
                        }))
                    }, 1000);
                }
            });
        }
    }

    componentDidUpdate(
        prevProps: Props,
        prevState: State
    ) {

        if (!isSameValue(this.props.onlineConsultation.endsAt, prevProps.onlineConsultation.endsAt) && this.props.onlineConsultation.endsAt) {
            this.setState({count: this.getConsultationDuration(this.props.onlineConsultation.endsAt, new Date().toISOString())});
        }

        if (this.state.count !== prevState.count && this.state.count < 0 && prevState.count >= 0) {
            if (this.countdownInterval) {
                clearInterval(this.countdownInterval);
            }
            this.setState({isConsultationExceeded: true});

            if (this.props.userRole !== UserRole.CANDIDATE) {
                this.countInterval = setInterval(() => {
                    this.setState(({count}) => ({
                        count: Math.abs(count) + 1
                    }), () => {
                        if (this.state.count >= 5 * 60) {
                            this.props.endConsultation();
                        }
                    })
                }, 1000)
            }
        }
    }

    componentWillUnmount() {
        if (this.countdownInterval) {
            clearInterval(this.countdownInterval);
        }

        if (this.countInterval) {
            clearInterval(this.countInterval);
        }
    }

    render() {
        return(
            <React.Fragment>
                <span id="counter-info" className={`${styles.counter} 
                                  ${this.state.isConsultationExceeded ? styles.exceeded : ''} 
                                  ${!this.state.isConsultationExceeded && this.state.count > 300 ? styles.hidden : ''}`}>
                    {this.secondsToHms(this.state.count)}

                    <Tooltip tooltipText={'videoChat.consultationTimeTooltip'}
                             target={'counter-info'}
                             placement={TooltipPlacement.LEFT}>
                        <span className={styles.tooltipWrapper}>?</span>
                    </Tooltip>
                </span>
            </React.Fragment>
        );
    }

    private secondsToHms(d: number) {
        if (d <= 0) {
            return "00:00:00";
        }

        d = Number(Math.abs(d));
        let h = Math.floor(d / 3600),
            m = Math.floor(d % 3600 / 60),
            s = Math.floor(d % 3600 % 60);

        let hDisplay = h >= 10 ? h : "0" + h,
            mDisplay = m >= 10 ? m : "0" + m,
            sDisplay = s >= 10 ? s : "0" + s;
        return `${hDisplay}:${mDisplay}:${sDisplay}`;
    }

    private getConsultationDuration(endsAt: string, currentDate: string): number {
        let duration = (new Date(endsAt).getTime()) - (new Date(currentDate).getTime());
        return duration / 1000;
    }
}

export default Counter;
