import {Observable} from 'rxjs';
import {RestQueryParams} from './base/queryParams';
import {jobHunterAPI} from './provider/jobHunterAPI';

export function resetPasswordAPI(authToken: string, password: string): Observable<any> {
    return jobHunterAPI.post(
        `api/users/set_new_password`,
        {
            password: password,
        },
        new RestQueryParams(),
        {
            'X-Authentication-Token': authToken
        }
    );
}
