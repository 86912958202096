import {Observable} from 'rxjs';
import {jobHunterAPI} from './provider/jobHunterAPI';
import {RestQueryParams} from './base/queryParams';
import {NotificationMessage} from '../types';

export function editChatNotification(accessToken: string, payload: NotificationMessage, notificationId: string): Observable<any> {
    return jobHunterAPI.put(`notifications/${notificationId}/chat`, payload, new RestQueryParams(), {
        Authorization: `Bearer ${accessToken}`,
    });
}
