import React from 'react';
import styles from "./styles.module.scss";
import {ConsultationStatus, IUpcomingCallProps, UserRole} from "../../../types";
import Loader from "../../Loader";
import Translation from "../../Translation";
import {of, Subscription} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {getOnlineConsultationAPI} from '../../../api/getOnlineConsultation';
import DateComponent from "../../DateComponent";


type Props = IUpcomingCallProps;

class UpcomingCall extends React.Component<Props> {
    readonly subscriptions: Subscription[] = [];

    componentDidMount() {
        if (this.props.attendeeRole === UserRole.CANDIDATE && this.props.consultationId) {
            const secret = this.props.participantAgoraToken ? this.props.participantAgoraToken : null;
            return this.subscriptions.push(
                getOnlineConsultationAPI(this.props.consultationId, secret, this.props.authToken).pipe(
                    map((resp: any) => {
                        if (resp.status === ConsultationStatus.FINISHED) {
                            this.props.changeConsultationStatus(ConsultationStatus.FINISHED);
                        }
                    }),
                    catchError(error => of(error))
                ).subscribe()
            )
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription => subscription.unsubscribe()));
    }

    render() {
        if (!this.props.consultationTime) {
            return <Loader showLoader={true}/>
        }
        const consultationDate = new Date(this.props.consultationTime),
            consultationHours = consultationDate.getHours() < 10 ? `0${consultationDate.getHours()}` :
                consultationDate.getHours(),
            consultationMinutes = consultationDate.getMinutes() < 10 ? `0${consultationDate.getMinutes()}` :
                consultationDate.getMinutes();

        return (
            <div className={`${styles.host}`}>
                <div className={styles.header}>
                    {/*<img src={require(`./../../assets/images/logo_sm.png`)} alt="logo" className={styles.logo}/>*/}

                    <h1>
                        <Translation text={'videoChat.consultationStart'}/>
                        <span>{consultationHours}:{consultationMinutes},
                            <span className={styles.date}>
                                <DateComponent date={this.props.consultationTime} format="D MMMM YYYY" />
                            </span>
                        </span>
                    </h1>
                    <p className={styles.timezone}><Translation text={'videoChat.consultationTimeZone'}/></p>
                </div>
                <div className={styles.information}>
                    {this.props.attendeeRole && this.props.attendeeRole === UserRole.CANDIDATE ?
                        (<p className={styles.warning}><Translation text={'videoChat.wailTillConsultationStarts'}/></p>) :
                        (<p className={styles.warning}><Translation text={'videoChat.startConsultation'}/></p>)
                    }
                </div>
            </div>
        );
    }
}

export default UpcomingCall;
