import React from 'react';
import {connect} from 'react-redux';
import {of, Subscription} from 'rxjs';
import {catchError, delay, tap} from 'rxjs/operators';
import {AlertType, IFormConfig, WithRouterWrapperProps} from '../../..';
import {resetPasswordAPI} from '../../../api/resetPassword';
import {addAlert} from '../../../store/reducers/alertSlice';
import Form from '../../Form';
import Loader from '../../Loader';
import Translation from '../../Translation';
import {withRouterWrapper} from '../../hoc/withRouterWrapper';

interface IConnectedNewPasswordProps {
    addAlert: typeof addAlert;
}

interface IExternalNewPasswordProps {
    formConfig: IFormConfig;
}

interface INewPasswordProps extends IConnectedNewPasswordProps, IExternalNewPasswordProps, WithRouterWrapperProps {}

interface INewPasswordState {
    formConfig: IFormConfig;
    value: any;
    isLoading: boolean;
}

class NewPassword extends React.Component<INewPasswordProps, INewPasswordState> {
    private subscriptions: Subscription[] = [];

    constructor(props: INewPasswordProps) {
        super(props);

        this.state = {
            formConfig: this.props.formConfig,
            value: null,
            isLoading: false,
        };
    }

    componentWillUnmount(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <article className="col-xl-10">
                <header className="auth-form-header">
                    <h1 className="auth-title">
                        <Translation text="auth.newPassword.title" />
                    </h1>
                    <div className="auth-subtitle-container">
                        <p className="auth-subtitle reset-subtitle">
                            <Translation text="auth.newPassword.subtitle" />
                        </p>
                    </div>
                </header>
                <Loader showLoader={this.state.isLoading} />
                <Form config={this.state.formConfig} submitForm={this.resetPassword} controlName={'newPasswordForm'} value={{}} />
            </article>
        );
    }
    private resetPassword = (event: any, value: any, valid: boolean, touched: boolean) => {
        event.preventDefault();
        if (!valid || !touched) {
            return;
        }

        const params = this.props.router?.params?.id;
        if (!params) {
            this.props.addAlert({message: 'auth.alert.invalidResetPasswordURL', type: AlertType.WARNING});
            return;
        }
        this.setState({isLoading: true});
        this.subscriptions.push(
            resetPasswordAPI(params, value.plainPassword)
                .pipe(
                    catchError((error: any) => {
                        let message = '';
                        const response = error.response;

                        if (response['hydra:description']) {
                            message =
                                response['hydra:description'] && response['hydra:description'].includes('Token not found')
                                    ? 'auth.alert.invalidConfirmRegistrationURL'
                                    : response['hydra:description'];
                        } else if (response.message) {
                            if (response.message === 'JWT Token not found') {
                                message = 'auth.alert.authenticationError';
                            } else {
                                message = response.message;
                            }
                        }
                        this.props.addAlert({message: message, type: AlertType.WARNING});
                        this.setState({isLoading: false});

                        return of();
                    }),
                    tap(() => this.props.addAlert({message: 'auth.alert.confirmResetPasswordSuccess'})),
                    delay(1500),
                    tap(() => {
                        this.props.router.navigate('/');
                        this.setState({isLoading: false});
                    })
                )
                .subscribe()
        );
    };
}

export default connect(() => ({}), {
    addAlert,
})(withRouterWrapper(NewPassword));
