import React, {Component} from 'react';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import {Form, FormControlChangeType, getLoginCredentials, IFormConfig, isAuthenticatedSelector, Translation} from 'jobhunter-common-web';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import LoginForm from '../../../Marketplace/MarketplaceCard/LoginForm';
import {offerApplicationFormConfig} from './offerApplicationFormConfig';

interface IExternalOfferApplicationModalProps {
    isModalOpen: boolean;
    toggleModal: (item?: {[key: string]: any} | null) => void;
    offer: {[key: string]: any} | null | undefined;
}

interface IConnectedOfferApplicationModalProps {
    isAuthenticated: boolean;
    getLoginCredentials: typeof getLoginCredentials;
}

interface IOfferApplicationModalProps extends IExternalOfferApplicationModalProps, IConnectedOfferApplicationModalProps {}

interface IOfferApplicationModalState {
    value: any;
    formConfig: typeof IFormConfig | null;
    isLoading: boolean;
}

class OfferApplicationModal extends Component<IOfferApplicationModalProps, IOfferApplicationModalState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IOfferApplicationModalProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: null,
            isLoading: false,
        };
    }

    componentDidMount(): void {
        if (this.props.isAuthenticated) {
            this.setState({formConfig: offerApplicationFormConfig()});
        }

        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    tap((data: any) => this.setState({value: data.value}))
                )
                .subscribe()
        );
    }

    componentDidUpdate(prevProps: Readonly<IOfferApplicationModalProps>): void {
        if (this.props.isAuthenticated !== prevProps.isAuthenticated && this.props.isAuthenticated) {
            this.setState({formConfig: offerApplicationFormConfig()});
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <Modal isOpen={this.props.isModalOpen} toggle={() => this.props.toggleModal()}>
                <ModalHeader className="custom-header" toggle={() => this.props.toggleModal()} />
                <ModalBody>
                    <p className="modal-title">
                        <Translation text="offers.offerView.applicationForm.title" />
                    </p>

                    {this.props.isAuthenticated ? (
                        <>
                            <p className="modal-description">
                                <Translation text="offers.offerView.applicationForm.description" />
                            </p>
                            {this.state.formConfig && (
                                <Form
                                    config={this.state.formConfig}
                                    onValueStateChange={this.onValueStateChange}
                                    value={this.state.value}
                                    submitForm={this.apply}
                                    controlName={'offerApplicationForm'}
                                />
                            )}
                        </>
                    ) : (
                        <LoginForm modalDescription="offers.offerView.applicationFormLogin.description" />
                    )}
                </ModalBody>
            </Modal>
        );
    }
    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private apply = () => {
        console.log('apply');
    };
}

export default connect(
    (state: RootState) => ({
        isAuthenticated: isAuthenticatedSelector(state),
    }),
    {
        getLoginCredentials,
    }
)(OfferApplicationModal);
