import React, {Component} from 'react';
import {IHtmlParserComponentProps} from '../../types';
import parse from 'html-react-parser';

type Props = IHtmlParserComponentProps;

class HtmlParserComponent extends Component<Props> {
    render() {
        return <>{parse(this.props.htmlContent)}</>;
    }
}

export default HtmlParserComponent;
