import {IUserMenuItem} from 'jobhunter-common-web';

export const unauthorizedUserMenu: typeof IUserMenuItem[] = [
    {
        id: 'candidate-login',
        link: `${process.env.REACT_APP_CANDIDATE_URL}/auth/login`,
        icon: 'User',
        title: 'userMenu.candidateLogin',
    },
    {
        id: 'organisation-login',
        link: `${process.env.REACT_APP_ORGANIZATION_URL}/auth/login`,
        icon: 'Briefcase',
        title: 'userMenu.organisationLogin',
    },
];

export default unauthorizedUserMenu;
