import React from 'react';
import Avatar from "../../../Avatar";
import classnames from "classnames";
import {Award, Coffee} from "react-feather";
import {AvatarColor, ChatContact, ChatMessage, ChatType} from "../../../../types";
import ChatListItemContent from "./ChatListItemContent";

interface IChatListItemProps {
    readonly message: ChatMessage;
    readonly authorType: ChatType;
    readonly prevMessageAuthorType: ChatType | null;
    readonly errorModuleHandle: (errorMessage: string) => void;
    readonly chatContact: ChatContact | undefined;
    readonly avatarUrl?: string;
}

class ChatListItem extends React.Component<IChatListItemProps> {

    private menuElt: any;

    constructor(props: IChatListItemProps) {
        super(props);
        this.menuElt = React.createRef();
    }

    render() {
        const message = this.props.message,
            sameAuthorOfPrevMessage = this.props.prevMessageAuthorType === this.props.authorType;

        return (
            <React.Fragment>
                <div
                    className={classnames('chat', {
                        'chat-left': this.props.authorType === ChatType.RESPONSE
                    })}
                >
                    {!sameAuthorOfPrevMessage && (
                        <div className='chat-avatar'>
                            <Avatar
                                imgWidth={36}
                                imgHeight={36}
                                tag={'div'}
                                color={AvatarColor.PRIMARY}
                                className='box-shadow-1 cursor-pointer'
                                img={this.props.authorType === ChatType.RESPONSE ? (this.props.chatContact.avatarUrl ? this.props.chatContact.avatarUrl : undefined) : this.props.avatarUrl}
                                icon={this.props.authorType !== ChatType.RESPONSE ? <Coffee/> : <Award/>}
                            />
                        </div>
                    )}
                    <div className='chat-body'>
                           <div className='chat-content'>
                               <ChatListItemContent
                                   errorModuleHandle={this.props.errorModuleHandle}
                                   message={message}
                                   contentType={this.props.message.messageType}
                                   messageType={this.props.authorType}
                               />
                           </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    // private convertDate(value: Date): string {
    //     if (value === null || value === undefined) {
    //         return "";
    //     }
    //
    //     const options = {
    //         hour: "2-digit",
    //         minute: "2-digit",
    //         hour12: true
    //     };
    //
    //     return new Date(value).toLocaleString("en-GB", options as any);
    // }
}

export default ChatListItem;
