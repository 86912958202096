import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelApiResponseViewObject} from '../../model/base';

export interface IPaginationParams {
    page: number;
    itemsPerPage: number;
}

export interface IRelocationsPageState {
    relocations: {[key: string]: any}[] | null;
    relocationsMetadata: IModelApiResponseViewObject | null;
    pagination: IPaginationParams | null;
    isRelocationsPageLoading: boolean;
    isRelocationsPageInitialized: boolean;
    relocationsPageError: string | null;
}

export interface ISetRelocations {
    relocations: {[key: string]: any}[] | null;
}

export interface ISetMetadata {
    metadata: IModelApiResponseViewObject | null;
}

export interface IChangePagination {
    pagination: IPaginationParams;
}

export interface IChangeIsRelocationsPageLoading {
    isRelocationsPageLoading: boolean;
}

export interface IChangeIsRelocationsPageInitialized {
    isRelocationsPageInitialized: boolean;
}

export interface IChangeRelocationsPageError {
    error: string | null;
}

const initialState: IRelocationsPageState = {
    relocations: null,
    relocationsMetadata: null,
    pagination: {
        itemsPerPage: 25,
        page: 1,
    },
    isRelocationsPageLoading: false,
    isRelocationsPageInitialized: false,
    relocationsPageError: null,
};

const relocationsPageSlice = createSlice({
    name: 'relocationsPage',
    initialState: initialState,
    reducers: {
        setRelocations: {
            reducer: (state: IRelocationsPageState, action: PayloadAction<ISetRelocations>) => {
                return {
                    ...state,
                    relocations: action.payload.relocations,
                };
            },
            prepare(relocations: {[key: string]: any}[] | null) {
                return {
                    payload: {
                        relocations: relocations,
                    },
                };
            },
        },
        setRelocationsMetadata: {
            reducer: (state: IRelocationsPageState, action: PayloadAction<ISetMetadata>) => {
                return {
                    ...state,
                    relocationsMetadata: action.payload.metadata,
                };
            },
            prepare(metadata: IModelApiResponseViewObject | null) {
                return {
                    payload: {
                        metadata: metadata,
                    },
                };
            },
        },
        changeRelocationsPagination: {
            reducer: (state: IRelocationsPageState, action: PayloadAction<IChangePagination>) => {
                return {
                    ...state,
                    isRelocationsPageLoading: true,
                    pagination: action.payload.pagination,
                };
            },
            prepare(pagination: IPaginationParams) {
                return {
                    payload: {pagination: pagination},
                };
            },
        },
        changeIsRelocationsPageLoading: {
            reducer: (state: IRelocationsPageState, action: PayloadAction<IChangeIsRelocationsPageLoading>) => {
                return {
                    ...state,
                    isRelocationsPageLoading: action.payload.isRelocationsPageLoading,
                };
            },
            prepare(isRelocationsPageLoading: boolean) {
                return {
                    payload: {isRelocationsPageLoading: isRelocationsPageLoading},
                };
            },
        },
        changeIsRelocationsPageInitialized: {
            reducer: (state: IRelocationsPageState, action: PayloadAction<IChangeIsRelocationsPageInitialized>) => {
                return {
                    ...state,
                    isRelocationsPageInitialized: action.payload.isRelocationsPageInitialized,
                };
            },
            prepare(isRelocationsPageInitialized: boolean) {
                return {
                    payload: {isRelocationsPageInitialized: isRelocationsPageInitialized},
                };
            },
        },
        changeRelocationsPageError: {
            reducer: (state: IRelocationsPageState, action: PayloadAction<IChangeRelocationsPageError>) => {
                return {
                    ...state,
                    relocationsPageError: action.payload.error,
                };
            },
            prepare(error: any) {
                return {
                    payload: {error: error},
                };
            },
        },
        fetchRelocations: (state: IRelocationsPageState) => {
            return {
                ...state,
                isRelocationsPageLoading: true,
            };
        },
        resetToInitialRelocationsPageState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setRelocations,
    setRelocationsMetadata,
    changeRelocationsPagination,
    changeIsRelocationsPageLoading,
    changeIsRelocationsPageInitialized,
    changeRelocationsPageError,
    fetchRelocations,
    resetToInitialRelocationsPageState,
} = relocationsPageSlice.actions;

export default relocationsPageSlice.reducer;
