import React from 'react';
import {
    ChatContact,
    ChatMessage,
    ChatServerMessagePayload,
    MessageContentType,
    PeerConnection,
    SignalAnswer,
    SignalCandidate,
    SignalOffer,
} from '../../../types';
import io, {Socket} from 'socket.io-client';
import {expand, from, Observable, of, Subject, Subscription} from 'rxjs';
import {catchError, map, mergeMap, take} from 'rxjs/operators';
import WebWorker from '../../Chat/fileWorkerSetup';
import worker from '../../Chat/fileWorker';
import uniqBy from 'lodash/uniqBy';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import {
    ChatConfig,
    ChatServerMessage,
    IWithChatConnectionProps,
    IWithChatConnectionState,
    MediaObjectAPIResponse,
    SocketMessages,
} from '../chat.types';
import NULL_PEER_CONNECTION from './NullConnection';
import jwt_decode from 'jwt-decode';

export default <P extends IWithChatConnectionProps = IWithChatConnectionProps>(
    ConnectedComponent: React.ComponentType<P>,
    config: ChatConfig
): any =>
    class WithChatConnection extends React.Component<IWithChatConnectionProps, IWithChatConnectionState> {
        private socket: Socket | null = null;
        private worker;
        private errors: Subject<Error> = new Subject();
        private subscriptions: Subscription[] = [];
        private reconnectionTimeout: null | ReturnType<typeof setTimeout> = null;
        private contactsTimeout: null | ReturnType<typeof setTimeout> = null;

        private configuration: RTCConfiguration = {
            iceServers: config.webRTCConfig.serverUrls,
        };

        constructor(props: IWithChatConnectionProps) {
            super(props);
            this.state = {
                allowedChannels: [],
                userPeerConnections: {},
                chunksArrays: {},
                selectedChatRoomId: '',
                alert: [],
                nodeServerConnected: false,
                hadConnectionError: false,
            };
        }

        componentDidMount() {
            this.worker = new WebWorker(worker);
            this.worker.addEventListener('message', (e) => {
                const room = e.data.room,
                    message = e.data.message;

                this.setState((state) => {
                    const unseen = this.countUnseen(room, state.userPeerConnections[room].unseenMessages);
                    return {
                        alert: unseen === 0 ? state.alert.filter((id) => id !== room) : [...state.alert, room],
                        userPeerConnections: {
                            ...state.userPeerConnections,
                            [room]: {
                                ...state.userPeerConnections[room],
                                unseenMessages: unseen,
                                messages: [...state.userPeerConnections[room].messages, message],
                            },
                        },
                        chunksArrays: {...state.chunksArrays, [room]: []},
                    };
                });
            });

            this.handleSocketConnection();
        }

        componentDidUpdate(prevProps: IWithChatConnectionProps, prevState: IWithChatConnectionState) {
            if (prevProps.authToken !== this.props.authToken && !this.socket?.connected) {
                //login to chat on token
                this.socket?.connect();
            }

            if (prevProps.channelsToJoin !== this.props.channelsToJoin) {
                this.joinChannelsInNode();
            }

            if (prevState.allowedChannels !== this.state.allowedChannels) {
                this.state.allowedChannels.forEach((contact) => {
                    this.updatePeerConnection({}, contact.roomId);
                    if (contact.unreadMessagesCount > 0) {
                        this.getMessagesFromUser(contact.accountId).subscribe();
                    }
                });
            }

            if (prevState.selectedChatRoomId !== this.state.selectedChatRoomId && this.state.selectedChatRoomId) {
                //todo UWAGA na razie nie ma accountId w hoc
                const contactId = this.partnerId(this.state.selectedChatRoomId);
                this.getMessagesFromUser(contactId, 1).subscribe();
            }

            if (!prevState.nodeServerConnected && this.state.nodeServerConnected) {
                this.joinChannelsInNode();
            }

            if (!prevState.nodeServerConnected && this.state.nodeServerConnected && this.state.hadConnectionError) {
                this.setState({hadConnectionError: false});
                this.joinChannelsInNode();

                if (config.saveMessagesConfig?.getContacts) {
                    this.contactsTimeout = setTimeout(() => {
                        config.saveMessagesConfig.getContacts(this.props.authToken, this.props.accountId).subscribe((contacts) => {
                            if (contacts && Array.isArray(contacts)) {
                                contacts.map((contact) => this.getMessagesFromUser(contact?.accountId).subscribe());
                            }
                        });
                    }, 3000);
                }
            }
        }

        componentWillUnmount() {
            this.reconnectionTimeout && clearTimeout(this.reconnectionTimeout);
            this.contactsTimeout && clearTimeout(this.contactsTimeout);
            this.socket.disconnect();
            Object.keys(this.state.userPeerConnections).forEach((room: string) => this.closeConnection(room));
            this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        }

        render() {
            return (
                <ConnectedComponent
                    {...(this.props as P)}
                    canSendMessagesToOffline={config.saveMessagesConfig}
                    errors={this.errors}
                    confirmMessageRead={this.confirmMessageRead}
                    hasNodeConnection={!!this.socket?.connected}
                    hasUnreadMessages={this.state.alert}
                    addMessage={this.addMessage}
                    allowedChannels={this.state.allowedChannels}
                    selectedChatRoomId={this.state.selectedChatRoomId}
                    getMessagesFromUser={this.getMessagesFromUser}
                    setSelectedChatRoomId={this.setSelectedChatRoomId}
                    peerConnections={this.state.userPeerConnections}
                />
            );
        }

        private joinChannelsInNode() {
            const channels = this.channelIds;
            this.socket?.emit(SocketMessages.JOIN_CHANNELS, {channels: channels, socketId: this.socket.id});
        }

        private handleSocketConnection() {
            this.socket = io(config.webRTCConfig.chatSignalingServerAddress, {
                reconnectionDelay: 60000,
                auth: {token: this.props.authToken},
            });

            if (this.socket instanceof Socket) {
                this.socket.on(SocketMessages.CONNECT, () => this.setState({nodeServerConnected: true}));

                this.socket.on(SocketMessages.DISCONNECT, () => this.setState({nodeServerConnected: false}));

                this.socket.on(SocketMessages.USER_HAS_LEFT, ({room}) => this.closeConnection(room));
                // Handling token expiration and multi login
                this.socket.on(SocketMessages.CONNECT_ERROR, (error: any) => {
                    if (error.message === 'already_online') {
                        this.reconnectionTimeout = setTimeout(() => {
                            this.socket?.connect();
                        }, 5000);
                    }
                    this.errors.next(error);
                });

                this.socket.io.on('reconnect', () => this.setState(() => ({hadConnectionError: true})));

                this.socket.on(SocketMessages.CHANNEL_JOINED, ({id, clientsNumber, channelId}) => {
                    if (clientsNumber > 1) {
                        this.updatePeerConnection({peerIsOnline: true}, channelId);
                    }
                });

                this.socket.on(SocketMessages.ANSWER, async ({toRoom, answer}: SignalAnswer) => {
                    const remoteDesc = await new RTCSessionDescription(answer);
                    const peerConnection = this.state.userPeerConnections[toRoom].connection;
                    await peerConnection.setRemoteDescription(remoteDesc);
                    this.updatePeerConnection({connection: peerConnection}, toRoom);
                });

                this.socket.on(SocketMessages.CANDIDATE, async ({toRoom, candidate}: SignalCandidate) => {
                    try {
                        if (!candidate) {
                            return;
                        }
                        const peerConnection = this.state.userPeerConnections[toRoom].connection;
                        if (peerConnection) {
                            await peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
                            this.updatePeerConnection({connection: peerConnection}, toRoom);
                        }
                    } catch (e) {
                        return;
                    }
                });

                this.socket.on(SocketMessages.OFFER, async ({toRoom, offer}: SignalOffer) => {
                    const connectionRoom = this.state.userPeerConnections?.[toRoom];
                    if (connectionRoom.connection && connectionRoom.makingOffer && !connectionRoom.peerIsPolite) {
                        return;
                    }

                    const peerConnection = new RTCPeerConnection(this.configuration);
                    peerConnection.onicecandidate = ({candidate}: SignalCandidate) => this.onIceCandidate(candidate, toRoom);
                    peerConnection.ondatachannel = (event) => {
                        const receiveChannel = event.channel;
                        receiveChannel.onopen = () => {
                            this.clearBuffer(toRoom);
                        };
                        receiveChannel.onclose = () => {
                            this.updatePeerConnection({peerIsOnline: false}, toRoom);
                        };
                        receiveChannel.onmessage = (message: any) => this.handleMessage(message, toRoom);
                        this.updatePeerConnection({connection: peerConnection, channel: receiveChannel}, toRoom);
                    };
                    peerConnection.onconnectionstatechange = () => this.onConnectionStateChange(peerConnection, toRoom);
                    peerConnection.oniceconnectionstatechange = () => this.onIceConnectionStateChange(peerConnection);
                    await peerConnection.setRemoteDescription(new RTCSessionDescription(offer));
                    const answer = await peerConnection.createAnswer();
                    await peerConnection.setLocalDescription(answer);
                    this.updatePeerConnection({connection: peerConnection}, toRoom);
                    this.socket?.emit(SocketMessages.ANSWER, {toRoom, answer});
                });

                this.socket.on(SocketMessages.ALLOWED_CHANNELS_LIST, (ids: string[]) => {
                    const allowedChannels = this.props.channelsToJoin.filter((channel) => ids.indexOf(channel.accountId) !== -1);
                    this.setState({allowedChannels});
                });

                this.socket.on(SocketMessages.USER_JOINED_CHANNEL, (data: string, room: string) => {
                    this.updatePeerConnection({peerIsPolite: true, peerIsOnline: true}, room);
                });
            }
        }

        private onIceCandidate(candidate: RTCIceCandidate, room: string) {
            this.socket?.emit(SocketMessages.CANDIDATE, {toRoom: room, candidate});
        }

        private onIceConnectionStateChange(peerConnection: RTCPeerConnection) {
            if (peerConnection.iceConnectionState === 'failed') {
                // @ts-ignore
                if (peerConnection?.restartIce) {
                    // @ts-ignore
                    peerConnection.restartIce();
                }
            }
        }

        private onConnectionStateChange(peerConnection: RTCPeerConnection, toRoom: string) {
            if (peerConnection.connectionState === 'failed') {
                const attemptNumber = this.state.userPeerConnections[toRoom].connectionAttemptNumber;
                if (attemptNumber < 1) {
                    this.updatePeerConnection({connectionAttemptNumber: attemptNumber + 1}, toRoom);
                    this.sendOffer(toRoom);
                } else {
                    this.state.userPeerConnections[toRoom].messagesBuffer.forEach((message) => {
                        if (typeof message !== 'string') {
                            this.removePlaceholder(message[0], toRoom, message[1], true);
                        }
                    });

                    this.setState((state) => ({
                        userPeerConnections: {
                            ...state.userPeerConnections,
                            [toRoom]: {
                                ...state.userPeerConnections[toRoom],
                                messagesBuffer: [],
                            },
                        },
                    }));
                }
            }
        }

        private handleError(error) {
            this.errors.next(error);
            return of('');
        }

        private async sendOffer(room: string) {
            if (this.state.userPeerConnections[room].makingOffer) {
                return;
            }

            const peerConnection = await new RTCPeerConnection(this.configuration);
            const createdChannel = await peerConnection.createDataChannel(room);

            peerConnection.onconnectionstatechange = () => this.onConnectionStateChange(peerConnection, room);

            peerConnection.oniceconnectionstatechange = () => this.onIceConnectionStateChange(peerConnection);

            peerConnection.onnegotiationneeded = async () => {
                try {
                    this.updatePeerConnection({makingOffer: true}, room);
                    //Channel Methods:
                    createdChannel.onopen = () => {
                        this.clearBuffer(room);
                    };
                    createdChannel.onclose = () => this.updatePeerConnection({peerIsOnline: false}, room);
                    createdChannel.onmessage = (message: MessageEvent) => this.handleMessage(message, room);

                    const offer = await peerConnection.createOffer();
                    await peerConnection.setLocalDescription(offer);

                    this.updatePeerConnection({connection: peerConnection, channel: createdChannel, makingOffer: false}, room);
                    this.socket?.emit(SocketMessages.OFFER, {toRoom: room, offer});
                } catch (err: any) {
                    this.updatePeerConnection({makingOffer: false}, room);
                    return this.errors.next(err);
                }
            };

            peerConnection.onicecandidate = ({candidate}: SignalCandidate) => this.onIceCandidate(candidate, room);
        }

        private get channelIds() {
            const channels = this.props.channelsToJoin;
            if (channels.length > 0) {
                return channels.map((channel) => channel.accountId);
            }
            return [];
        }

        private clearBuffer(room: string) {
            const selectedRoom = this.state.userPeerConnections[room];
            const updatedMessages = cloneDeep(selectedRoom.messages);

            selectedRoom.messagesBuffer.forEach((textMessage) => {
                if (typeof textMessage === 'string') {
                    return selectedRoom.channel.send(JSON.stringify(textMessage));
                }

                selectedRoom.messages.forEach((msg, index) => {
                    if (msg.messageId === textMessage[0].messageId) {
                        const message = cloneDeep(textMessage[0]);
                        message.messageId = textMessage[1];
                        return updatedMessages.splice(index, 1, message);
                    }
                    return msg;
                });

                const fileMessage = Object.assign({}, textMessage[0], {messageMedia: '', messageId: textMessage[1]});
                return selectedRoom.channel.send(JSON.stringify(fileMessage));
            });

            this.setState((state) => ({
                userPeerConnections: {
                    ...state.userPeerConnections,
                    [room]: {
                        ...state.userPeerConnections[room],
                        messages: updatedMessages,
                        messagesBuffer: [],
                    },
                },
            }));
        }

        private partnerId(roomId: string) {
            const decoded: any = this.props.accountId ? this.props.accountId : jwt_decode(this.props.authToken);
            const accountId = this.props.accountId || decoded?.account_id;
            return roomId.split('.').find((id) => id != accountId);
        }

        private updatePeerConnection(update: Partial<PeerConnection>, toRoom: string) {
            const updatedConnection = merge({}, NULL_PEER_CONNECTION, {channelId: toRoom}, this.state.userPeerConnections[toRoom], update);
            this.setState((state) => ({
                userPeerConnections: {
                    ...state.userPeerConnections,
                    [toRoom]: updatedConnection,
                },
            }));
        }

        private closeConnection(room: string) {
            const connection: PeerConnection = this.state.userPeerConnections?.[room];
            if (!connection) {
                return;
            }

            connection.channel?.close();
            connection.connection?.close();
            // this.updatePeerConnection({...NULL_PEER_CONNECTION, messages: this.state.userPeerConnections?.[room]?.messages || []}, room);

            this.setState((state) => ({
                chunksArrays: {
                    ...state.chunksArrays,
                    [room]: [],
                },
                userPeerConnections: {
                    ...state.userPeerConnections,
                    [room]: {
                        biggestPageNumber: 0,
                        makingOffer: false,
                        peerIsOnline: false,
                        peerIsPolite: false,
                        connection: null,
                        channelId: room,
                        unseenMessages: 0,
                        messagesBuffer: [],
                        messages: state.userPeerConnections?.[room]?.messages || [],
                        channel: null,
                        connectionAttemptNumber: 0,
                        totalMessagesNumber: 0,
                    },
                },
            }));
        }

        private setSelectedChatRoomId = (roomId: string) =>
            this.setState((state) => ({
                selectedChatRoomId: roomId,
                alert: state.alert.filter((id) => id !== roomId),
                userPeerConnections: {
                    ...state.userPeerConnections,
                    [roomId]: {
                        ...state.userPeerConnections[roomId],
                        unseenMessages: 0,
                    },
                },
            }));

        private addMyMessage(placeholder: ChatMessage, room: string, message: ChatMessage | string) {
            this.setState(
                (state) => ({
                    userPeerConnections: {
                        ...state.userPeerConnections,
                        [room]: {
                            ...state.userPeerConnections[room],
                            messages: state?.userPeerConnections?.[room]?.messages
                                ? [...state.userPeerConnections[room].messages, placeholder]
                                : [placeholder],
                        },
                    },
                }),
                () => this.saveMessage(message, room)
            );
        }

        private saveMessage(message: ChatMessage | string, room: string) {
            const connectionRoom = this.state.userPeerConnections[room];
            this.subscriptions.push(
                this.saveIncomingFileOrDataToServer(message, room).subscribe((messageServerId: string) => {
                    if (!messageServerId && typeof message !== 'string') {
                        return this.removePlaceholder(message, room, '', true);
                    }

                    if (typeof message !== 'string' && (message as ChatMessage).messageType === MessageContentType.FILE) {
                        //create URL
                        message.messageMedia = URL.createObjectURL(message.messageMedia);
                    }
                    //offline
                    if ((!connectionRoom || !connectionRoom.peerIsOnline) && typeof message !== 'string' && 'from' in message) {
                        return this.removePlaceholder(message, room, messageServerId);
                    }
                    //online & connected
                    if (connectionRoom && connectionRoom.peerIsOnline && connectionRoom?.channel?.readyState === 'open') {
                        if (typeof message === 'string') {
                            connectionRoom.channel?.send(JSON.stringify(message));
                            return;
                        } else {
                            const messageToSendWebrtc = cloneDeep(message);
                            messageToSendWebrtc.messageId = messageServerId;
                            if (messageToSendWebrtc.messageType === MessageContentType.FILE) {
                                const fileMessage = {...messageToSendWebrtc};
                                delete fileMessage.messageMedia;
                                connectionRoom.channel?.send(JSON.stringify(fileMessage));
                            } else {
                                connectionRoom.channel?.send(JSON.stringify(messageToSendWebrtc));
                            }

                            return this.removePlaceholder(message, room, messageServerId);
                        }
                    }
                    if (
                        connectionRoom &&
                        connectionRoom.peerIsOnline &&
                        !connectionRoom.makingOffer &&
                        typeof message !== 'string' &&
                        'from' in message
                    ) {
                        if (!connectionRoom.connection || connectionRoom.connection.connectionState === 'closed') {
                            this.sendOffer(room);
                        }
                    }

                    //add message to buffer
                    this.setState((state) => ({
                        userPeerConnections: {
                            ...state.userPeerConnections,
                            [room]: {
                                ...state.userPeerConnections[room],
                                messagesBuffer: state?.userPeerConnections?.[room]?.messagesBuffer
                                    ? [
                                          ...state.userPeerConnections[room].messagesBuffer,
                                          typeof message === 'string' ? message : [message, messageServerId],
                                      ]
                                    : [typeof message === 'string' ? message : [message, messageServerId]],
                            },
                        },
                    }));
                })
            );
        }

        private addMessage = (message: ChatMessage | string, room: string) => {
            //Done 1: add Placeholder
            if (typeof message !== 'string') {
                const placeholderMessage = {
                    ...message,
                    messageType: MessageContentType.PLACEHOLDER,
                };
                return this.addMyMessage(placeholderMessage, room, message);
            }
            return this.saveMessage(message, room);
        };

        private removePlaceholder(message: ChatMessage, room: string, serverId: string, error = false) {
            const updatedMessages = cloneDeep(this.state.userPeerConnections[room].messages);
            this.state.userPeerConnections[room].messages.map((msg, index) => {
                if (msg.messageId === message.messageId) {
                    if (error) {
                        const errorMessage = cloneDeep(message);
                        errorMessage.messageType = MessageContentType.ERROR;
                        return updatedMessages.splice(index, 1, errorMessage);
                    }
                    const newMessage = cloneDeep(message);
                    newMessage.messageId = serverId;
                    return updatedMessages.splice(index, 1, newMessage);
                }
                return msg;
            });

            this.setState((state) => ({
                userPeerConnections: {
                    ...state.userPeerConnections,
                    [room]: {
                        ...state.userPeerConnections[room],
                        messages: updatedMessages,
                        totalMessagesNumber: error
                            ? state.userPeerConnections[room].totalMessagesNumber
                            : state.userPeerConnections[room].totalMessagesNumber + 1,
                    },
                },
            }));
        }

        private peerIsOnline = (roomId: string): boolean => this.state.userPeerConnections?.[roomId]?.peerIsOnline;

        private saveIncomingFileOrDataToServer(message: ChatMessage | string, room: string): Observable<string> {
            console.log('TU ZACZYNAM PODRÓŻ');
            if (typeof message === 'string') {
                return of(null);
            }

            if (!config.saveMessagesConfig || (config.saveMessagesConfig?.saveMessagesOnlyWhenPeerIsOffline && this.peerIsOnline(room))) {
                console.log('wbiłem w dupę');
                return of(message.messageId);
            }

            if (message.messageType === MessageContentType.FILE) {
                return this.saveFileToServer(message.messageMedia, message.messageContent, message.to);
            }
            console.log('Nie wbiłem w save');
            return this.saveMessageToServer(message);
        }

        private saveFileToServer(fullFile: File, fileName: string, contactId: string): Observable<any> {
            const serverMessage: ChatServerMessagePayload = {
                toAccountId: contactId,
                content: fileName,
                mediaObjectId: null,
            };
            console.log('TUPTUŚ');
            const formData = new FormData();
            formData.append('file', fullFile, fileName);
            formData.append('public', 'false');

            return from(config.saveMessagesConfig?.saveFile(this.props.authToken, formData)).pipe(
                take(1),
                mergeMap((resp: Response) => {
                    console.log('Tu na start! ', resp);
                    return resp?.json();
                }),
                mergeMap((fileUploadObj: MediaObjectAPIResponse) => {
                    const fileId = fileUploadObj.id;
                    serverMessage.mediaObjectId = fileId;
                    console.log('SAVE FILE ', fileUploadObj);
                    console.log('Message ', serverMessage);
                    return config.saveMessagesConfig?.sendMessageToOfflineAPI(this.props.authToken, serverMessage).pipe();
                }),
                take(1),
                map((message: ChatServerMessage) => {
                    console.log('JESTEM TU');
                    return message.id;
                }),
                catchError((error) => this.handleError(error))
            );
        }

        private saveMessageToServer(message: ChatMessage): Observable<string> {
            const serverMessage: ChatServerMessagePayload = {
                toAccountId: message.to,
                content: message.messageContent,
                mediaObjectId: null,
            };

            return config.saveMessagesConfig.sendMessageToOfflineAPI(this.props.authToken, serverMessage).pipe(
                take(1),
                map((message: ChatServerMessage) => message.id),
                catchError((error) => this.handleError(error))
            );
        }

        private getMessagesFromUser = (accountId: string, page?: number): Observable<any> => {
            if (
                !this.state.allowedChannels ||
                this.state.allowedChannels?.length === 0 ||
                accountId === this.props.accountId ||
                !config.saveMessagesConfig?.getMessagesFromUserAPI
            ) {
                return of(true);
            }

            const contact = this.state.allowedChannels.find((channel: ChatContact) => channel.accountId === accountId);

            let allChatMessagesToAdd = [];
            let allUnseenMessagesCount = 0;
            let pageNumber = page || 1;

            if (contact) {
                return config.saveMessagesConfig.getMessagesFromUserAPI(this.props.authToken, accountId, page || 1).pipe(
                    expand((mappedResponse) => {
                        const chatMessages = mappedResponse.messages[0];
                        const unseenMessages = mappedResponse.messages[1];
                        const totalMessagesNumber = mappedResponse.totalResults;
                        const peerConnection = this.state.userPeerConnections[contact.roomId];
                        const oldMessages = peerConnection.messages;
                        allUnseenMessagesCount = allUnseenMessagesCount + unseenMessages.length;
                        allChatMessagesToAdd = [...chatMessages, ...allChatMessagesToAdd];

                        // if first message is already loaded get next page
                        const firstMessageFromNewPageIsAlreadyInChat = !!oldMessages.find(
                            (mes) => mes.messageId === chatMessages?.[0]?.messageId
                        );
                        const newMessagesLengthIsEqualToUnseenMessagesLength = chatMessages.length === unseenMessages.length;

                        const needMoreResults =
                            (firstMessageFromNewPageIsAlreadyInChat || newMessagesLengthIsEqualToUnseenMessagesLength) &&
                            totalMessagesNumber > 0 &&
                            Math.ceil(totalMessagesNumber / config.saveMessagesConfig.messagesNumberPerHistoryPage) > pageNumber &&
                            !page;

                        if (needMoreResults) {
                            pageNumber++;
                            return config.saveMessagesConfig.getMessagesFromUserAPI(this.props.authToken, accountId, pageNumber);
                        }

                        const roomId = contact.roomId;
                        const messagesInState = this.state.userPeerConnections[roomId].messages;
                        const messagesToAddToState = uniqBy([].concat(allChatMessagesToAdd, messagesInState), 'messageId');

                        this.setState((state) => ({
                            userPeerConnections: {
                                ...state.userPeerConnections,
                                [roomId]: {
                                    ...state.userPeerConnections[roomId],
                                    unseenMessages: this.state.selectedChatRoomId === roomId ? 0 : allUnseenMessagesCount,
                                    messages: messagesToAddToState,
                                    biggestPageNumber:
                                        state.userPeerConnections[roomId]?.biggestPageNumber > pageNumber
                                            ? state.userPeerConnections[roomId]?.biggestPageNumber
                                            : pageNumber,
                                    totalMessagesNumber:
                                        totalMessagesNumber > state.userPeerConnections[roomId].totalMessagesNumber
                                            ? totalMessagesNumber
                                            : state.userPeerConnections[roomId].totalMessagesNumber,
                                },
                            },
                        }));
                        return of(true);
                    }),
                    take(1),
                    catchError((error) => this.handleError(error))
                );
            }
            return of(true);
        };

        private confirmMessageRead = (messagesId: string[]) => {
            if (!config.saveMessagesConfig?.postMessageReadConfirmationAPI) {
                return;
            }

            config.saveMessagesConfig
                .postMessageReadConfirmationAPI(this.props.authToken, messagesId)
                .pipe(
                    take(1),
                    catchError((error) => this.handleError(error))
                )
                .subscribe();
        };

        private countUnseen(roomId: string, unseenMessages: number): number {
            if (this.state.selectedChatRoomId === roomId) {
                return 0;
            }
            return unseenMessages + 1;
        }

        private handleMessage(message: MessageEvent, room: string) {
            const messageContent = typeof message.data === 'string' ? JSON.parse(message.data) : message.data;

            if (!messageContent.messageType) {
                this.setState((state) => ({
                    chunksArrays: {
                        ...state.chunksArrays,
                        [room]: state.chunksArrays[room] ? [...state.chunksArrays?.[room], messageContent] : [messageContent],
                    },
                }));
            } else if (messageContent?.messageType === MessageContentType.FILE) {
                //Done to sync with state
                this.setState(
                    (state) => state,
                    () => this.worker.postMessage({room, messageMedia: this.state.chunksArrays[room], message: messageContent})
                );
                // setTimeout(() =>  this.worker.postMessage({room, messageMedia: this.state.chunksArrays[room], message: messageContent}), 750)
                // this.worker.postMessage({room, messageMedia: this.state.chunksArrays[room], message: messageContent})
            } else {
                this.setState((state) => {
                    const unseen = this.countUnseen(room, state.userPeerConnections[room].unseenMessages);
                    return {
                        alert: unseen === 0 ? state.alert.filter((id) => id !== room) : [...state.alert, room],
                        userPeerConnections: {
                            ...state.userPeerConnections,
                            [room]: {
                                ...state.userPeerConnections[room],
                                unseenMessages: unseen,
                                messages: [...state.userPeerConnections[room].messages, messageContent],
                                totalMessagesNumber: state.userPeerConnections[room].totalMessagesNumber + 1,
                            },
                        },
                    };
                });
            }
        }
    };
