import React, {Component, Fragment} from 'react';
import Stepper from 'bs-stepper';
import classnames from 'classnames';
import {IWizardProps, IWizardState} from '../../types';
import {Translation} from '../../index';

type Props = IWizardProps;
type State = IWizardState;

class WizardComponent extends Component<Props, State> {
    private readonly elementRef: any;
    private stepper: any;

    constructor(props: Props) {
        super(props);

        this.state = {
            activeIndex: 0,
        };

        this.elementRef = React.createRef();
    }

    componentDidMount(): void {
        this.stepper = new Stepper((this.elementRef as any).current, this.props.options);

        if (this.props.activeTab) {
            this.setState({activeIndex: this.props.activeTab});
        }

        (this.elementRef as any).current.addEventListener('shown.bs-stepper', (event: any) => {
            this.setState({activeIndex: event.detail.indexStep});
        });

        if (this.props.instance) {
            this.props.instance(this.stepper);
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (this.props.activeTab !== prevProps.activeTab && this.props.activeTab) {
            this.setState({activeIndex: this.props.activeTab});
        }
    }

    render() {
        return (
            <div
                ref={this.elementRef as any}
                className={classnames(
                    'bs-stepper',
                    {
                        vertical: this.props.type === 'vertical',
                        'vertical wizard-modern': this.props.type === 'modern-vertical',
                        'wizard-modern': this.props.type === 'modern-horizontal',
                    },
                    this.props.className ? {[this.props.className]: this.props.className} : null
                )}>
                <div
                    className={classnames(
                        'bs-stepper-header',
                        this.props.headerClassName ? {[this.props.headerClassName]: this.props.headerClassName} : null
                    )}>
                    {this.renderHeader()}
                </div>
                <div className="bs-stepper-content">{this.renderContent()}</div>
            </div>
        );
    }

    private renderContent = () => {
        return this.props.steps.map((step, index) => {
            return (
                <div
                    className={classnames(
                        'content',
                        {'active dstepper-block': this.state.activeIndex === index},
                        this.props.contentClassName ? {[this.props.contentClassName]: this.props.contentClassName} : null
                    )}
                    id={step.id}
                    key={step.id}>
                    {step.content}
                </div>
            );
        });
    };

    private renderHeader = () => {
        return this.props.steps.map((step, index) => {
            return (
                <Fragment key={step.id}>
                    {index !== 0 && index !== this.props.steps.length ? <div className="line">{this.props.separator}</div> : null}

                    <div
                        className={classnames('step', {
                            crossed: this.state.activeIndex > index,
                            active: index === this.state.activeIndex,
                        })}
                        data-target={`#${step.id}`}>
                        <button
                            type="button"
                            className={classnames('step-trigger', step.className ? {[step.className]: step.className} : null)}>
                            <span className="bs-stepper-box">{step.icon ? step.icon : index + 1}</span>
                            <span className="bs-stepper-label">
                                <span className="bs-stepper-title">
                                    <Translation text={step.title} />
                                </span>
                                {step.subtitle ? (
                                    <span className="bs-stepper-subtitle">
                                        <Translation text={step.subtitle} />
                                    </span>
                                ) : null}
                            </span>
                        </button>
                    </div>
                </Fragment>
            );
        });
    };
}

export default WizardComponent;
