// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3l_M8VkocVK4MdX1iwXPs9{height:73.1rem;color:#fa7d03;display:flex;flex-direction:column;justify-content:center;align-items:center;font-size:3rem}._3l_M8VkocVK4MdX1iwXPs9 p{text-align:center;font-size:1.6rem}._1sUz3DpkvxCGGqGmxrqeRz{display:flex;box-sizing:border-box;height:100%}._1sUz3DpkvxCGGqGmxrqeRz .m124BAvssmu5Anq7APf59{width:100%;height:100%;margin-left:2rem}._3nu4zTiQHxbXji5OPOt-Xi{width:100%}._1Q1YRl20tO6iLQg3uR5aX5{position:relative;border-radius:0.8rem;font-size:1.8rem;width:100%;height:7.5rem;display:inline-flex;align-items:center;background-color:#ffffff;color:#263238;font-weight:800;text-shadow:0px 13px 27px rgba(0,0,0,0.15);box-shadow:0 0 15px 0 rgba(0,0,0,0.15);margin:1rem 0;padding:2rem}._2zGnKvpdNimiKnHg_krZva{width:2rem;height:2rem;border:0.1rem solid #000;position:absolute;top:-0.5rem;right:-0.5rem;border-radius:50%;font-size:0.8rem;display:inline-flex;justify-content:center;align-items:center;background-color:#ff585f;font-weight:800}.J_COTkI70846LcLu1UVJl{background-color:#fff;box-shadow:inset 4px 4px #ff585f,inset -4px 4px #ff585f,inset 4px -4px #ff585f,inset -4px -4px #ff585f;border-radius:0.8rem}._2DmI3Y1fmrsDbJr153fQCH{max-height:6.5rem;max-width:100%;text-overflow:ellipsis;overflow:hidden}._2DmI3Y1fmrsDbJr153fQCH ._2gSsJl_CDIKaLQhvREx313{max-width:calc(100% - 8rem)}._2OWCYNe52O2vWR5K8v4rhc{width:0.8rem;height:0.8rem;border-radius:0.4rem;filter:drop-shadow(0px 5px 2.5px rgba(0,0,0,0.05));background-color:#00e194;margin:0 1rem}._3EHKo2WHbFxiHBc7OkhNA6{font-size:1.2rem;letter-spacing:0.1rem;color:#666666;font-weight:700;font-family:\"Ubuntu\", sans-serif;text-transform:uppercase}._2oUAhr0GWKcckMTge16m43{font-weight:700;color:#ff484c;margin-top:0.5rem}.d-qFexi-XtWxOCRKSJh41{color:#263238;opacity:0.4;margin-top:0.5rem}@media (max-width: 1024px){._1sUz3DpkvxCGGqGmxrqeRz{flex-direction:column}._1sUz3DpkvxCGGqGmxrqeRz .m124BAvssmu5Anq7APf59{margin:2rem 0 0}}\n", ""]);
// Exports
exports.locals = {
	"noChatInfo": "_3l_M8VkocVK4MdX1iwXPs9",
	"chatWrapper": "_1sUz3DpkvxCGGqGmxrqeRz",
	"chatContainer": "m124BAvssmu5Anq7APf59",
	"listWrapper": "_3nu4zTiQHxbXji5OPOt-Xi",
	"listItem": "_1Q1YRl20tO6iLQg3uR5aX5",
	"messageInfo": "_2zGnKvpdNimiKnHg_krZva",
	"activeChat": "J_COTkI70846LcLu1UVJl",
	"userName": "_2DmI3Y1fmrsDbJr153fQCH",
	"nameWithIndicator": "_2gSsJl_CDIKaLQhvREx313",
	"onlineIndicator": "_2OWCYNe52O2vWR5K8v4rhc",
	"statusInfo": "_3EHKo2WHbFxiHBc7OkhNA6",
	"activeError": "_2oUAhr0GWKcckMTge16m43",
	"inactiveError": "d-qFexi-XtWxOCRKSJh41"
};
module.exports = exports;
