import {
    Form,
    FormControlChangeType,
    IFormConfig,
    serviceTypesSelector,
    IModelDictionaryDatum,
    convertToMultiselectLabels,
    sortMultiselectLabels,
} from 'jobhunter-common-web';
import React, {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {BehaviorSubject, Subscription} from 'rxjs';
import {debounceTime, filter, tap} from 'rxjs/operators';
import {RootState} from '../../../store/reducers';
import {marketplaceFiltersFormConfig} from './marketplaceFiltersFormConfig';
import styles from './styles.module.scss';

interface IConnectedMarketplaceFiltersProps {
    readonly serviceTypes: typeof IModelDictionaryDatum[] | null;
}

interface IExternalMarketplaceFiltersProps {
    readonly changeFilters: (value: {[key: string]: any}) => void;
}

interface IMarketplaceFiltersProps extends IConnectedMarketplaceFiltersProps, IExternalMarketplaceFiltersProps, WithTranslation {}

interface IMarketplaceFiltersState {
    value: any;
    formConfig: typeof IFormConfig | null;
    isLoading: boolean;
}

class MarketplaceFilters extends Component<IMarketplaceFiltersProps, IMarketplaceFiltersState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IMarketplaceFiltersProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: null,
            isLoading: false,
        };
    }

    componentDidMount(): void {
        this.setFormConfig();

        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    debounceTime(250),
                    tap((data: any) => this.onFormValueChange(data.value))
                )
                .subscribe()
        );
    }

    componentDidUpdate(prevProps: IMarketplaceFiltersProps) {
        if (this.props.serviceTypes !== prevProps.serviceTypes) {
            this.setFormConfig();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <div className={styles.formContainer}>
                {this.state.formConfig && (
                    <Form
                        config={this.state.formConfig}
                        onValueStateChange={this.onValueStateChange}
                        value={this.state.value}
                        controlName={'marketplaceFiltersForm'}
                    />
                )}
            </div>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onFormValueChange = (value: any) => {
        this.props.changeFilters(value);
    };

    private setFormConfig = () => {
        const {t} = this.props,
            serviceTypes = this.props.serviceTypes ? sortMultiselectLabels(convertToMultiselectLabels(this.props.serviceTypes, t)) : [];
        this.setState({formConfig: marketplaceFiltersFormConfig(serviceTypes)});
    };
}

export default connect(
    (state: RootState) => ({
        serviceTypes: serviceTypesSelector(state),
    }),
    {}
)(withTranslation()(MarketplaceFilters));
