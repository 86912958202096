import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {catchError, concatMap, debounceTime, filter, map, switchMap, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable, ObservableInput, of} from 'rxjs';
import {
    addAlert,
    AlertType,
    deepCloneObject,
    flattenObj,
    getErrorMessage,
    getMetadataDetails,
    IModelApiResponseViewObject,
    isNotNullOrUndefined,
    RestQueryParams,
} from 'jobhunter-common-web';
import {RootState} from '../reducers';
import {getServicesAPI} from '../../api/getServicesApi';
import {
    applyMarketplaceFilters,
    changeIsMarketplaceServicesLoading,
    fetchMarketplaceServices,
    setMarketplaceMetadata,
    setMarketplaceServicesData,
} from '../reducers/marketplacePageSlice';
import {marketplaceServicesFiltersSelector} from '../selectors/marketplacePageSelectors';

const fetchMarketplaceServicesEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getMarketplaceServices(action$, state$, fetchMarketplaceServices);
};

const applyPurchasedServicesFilters: Epic = (action$, state$: StateObservable<RootState>) =>
    getAction(action$, state$, applyMarketplaceFilters, doFetch);

export type FetchAction = {flattenedParams: any};
const fetchSubject = new BehaviorSubject<FetchAction>({flattenedParams: null});
const resultsSubject = new BehaviorSubject<any>(null);
fetchSubject
    .asObservable()
    .pipe(
        debounceTime(250),
        switchMap((fetch) => {
            return getServicesList(new RestQueryParams(fetch.flattenedParams));
        }),
        tap((action) => resultsSubject.next(action))
    )
    .subscribe();

const doFetch = (state: RootState) => {
    const filters = deepCloneObject(marketplaceServicesFiltersSelector(state));

    if (isNotNullOrUndefined(filters?.grossPrice)) {
        filters['grossPrice[lte]'] = filters.grossPrice;
        delete filters.grossPrice;
    }

    const filterObj = {
            ...filters,
        },
        flattened = flattenObj(filterObj);

    fetchSubject.next({flattenedParams: flattened});

    return resultsSubject.asObservable().pipe(
        filter((action: any) => null !== action),
        concatMap((action) => of(action, changeIsMarketplaceServicesLoading(false)))
    );
};

const getMarketplaceServices = (action$: Observable<any>, state$: StateObservable<any>, actionType: any) => {
    return action$.pipe(
        ofType(actionType.type),
        switchMap((): any => {
            return getServicesList();
        }),
        catchError((error) => of(...marketplaceServicesErrorActions(error)))
    );
};

export const getAction = (
    action$: Observable<any>,
    state$: StateObservable<any>,
    actionType: any,
    doFetch: (state: RootState) => ObservableInput<any>
) => {
    return action$.pipe(
        ofType(actionType.type),
        map(() => state$.value),
        switchMap(doFetch)
    );
};

export const getServicesList = (params?: typeof RestQueryParams) => {
    return getServicesAPI(params).pipe(
        switchMap((resp: any) => {
            const marketplaceServices = resp['hydra:member'],
                metadata = getMetadataDetails(resp['hydra:view']),
                actions = marketplaceServicesSuccessActions(marketplaceServices, metadata);
            return of(...actions);
        }),
        catchError((error: any) => of(...marketplaceServicesErrorActions(error)))
    );
};

const marketplaceServicesSuccessActions = (services: any[], metadata: typeof IModelApiResponseViewObject | null): any[] => {
    return [setMarketplaceServicesData(services), setMarketplaceMetadata(metadata), changeIsMarketplaceServicesLoading(false)];
};

const marketplaceServicesErrorActions = (error: any): any[] => {
    return [changeIsMarketplaceServicesLoading(false), addAlert({message: getErrorMessage(error), type: AlertType.WARNING})];
};

const marketplacePageEpic = combineEpics(fetchMarketplaceServicesEpic, applyPurchasedServicesFilters);

export default marketplacePageEpic;
