import React from 'react';
import {ArrowRightCircle, Eye, EyeOff, Minus, Plus} from 'react-feather';
import {WithTranslation, withTranslation} from 'react-i18next';

interface IExternalAccessibilityMenuProps {
    readonly children?: any;
}

interface IAccessibilityMenuProps extends IExternalAccessibilityMenuProps, WithTranslation {}

function AccessibilityMenu(props: React.PropsWithChildren<IAccessibilityMenuProps>) {
    const [accessibilityMenuOpen, setAccessibilityMenuOpen] = React.useState(false);
    const [contrastMode, setContrastMode] = React.useState(false);
    const [fontSize, setFontSize] = React.useState(10);

    return (
        <div className={`accessibility-wrapper font-size-${fontSize} menu-position-top`}>
            {props.children}
            <button
                title={props.t('accessibilityMenu.openAccessibilityMenu')}
                type="button"
                id="open_menu_button"
                onClick={() => toggleAccessibilityMenu()}
                className={`open-accessibility-menu-button ${accessibilityMenuOpen ? 'hidden' : ''}`}>
                <span className="sr-only">{props.t('accessibilityMenu.openAccessibilityMenu')}</span>
            </button>
            <div className={`accessibility-buttons ${accessibilityMenuOpen ? '' : 'hidden'}`}>
                <div>
                    <button
                        className="close-button"
                        title={props.t('accessibilityMenu.closeAccessibilityMenu')}
                        onClick={() => toggleAccessibilityMenu()}>
                        <span className="sr-only">{props.t('accessibilityMenu.closeAccessibilityMenu')}</span>
                        <ArrowRightCircle />
                    </button>
                </div>
                <div className="button-wrapper">
                    <span>
                        {props.t('accessibilityMenu.contrastMode.title', {
                            mode: contrastMode
                                ? props.t('accessibilityMenu.contrastMode.on')
                                : props.t('accessibilityMenu.contrastMode.off'),
                        })}
                    </span>
                    <button
                        title={props.t('accessibilityMenu.contrastMode.toggleButton')}
                        className="accessibility-button"
                        onClick={() => toggleContrastMode()}>
                        {contrastMode ? <EyeOff size={18} id={'contrast_mode_icon'} /> : <Eye size={18} id={'contrast_mode_icon'} />}
                    </button>
                </div>

                <div className="button-wrapper">
                    <span>{props.t('accessibilityMenu.fontSize.title')}</span>
                    <div className="font-size-controller">
                        <button
                            title={props.t('accessibilityMenu.fontSize.increase')}
                            className="accessibility-button font-controller"
                            onClick={() => changeFontSize(true)}>
                            <Plus size={20} id={'file_size_up'} />
                            <span className="sr-only">{props.t('accessibilityMenu.fontSize.increase')}</span>
                        </button>

                        <button
                            title={props.t('accessibilityMenu.fontSize.decrease')}
                            className="accessibility-button font-controller"
                            onClick={() => changeFontSize(false)}>
                            <Minus size={20} id={'file_size_down'} />
                            <span className="sr-only">{props.t('accessibilityMenu.fontSize.decrease')}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

    function toggleContrastMode() {
        setContrastMode(!contrastMode);
        document.body.classList.toggle('contrast-mode');
    }

    function toggleAccessibilityMenu() {
        setAccessibilityMenuOpen(!accessibilityMenuOpen);
    }

    function changeFontSize(increase: boolean) {
        if ((fontSize === 20 && increase) || (fontSize === 6 && !increase)) {
            return;
        }
        if (increase) {
            setFontSize(fontSize + 2);
            document.documentElement.style.fontSize = (fontSize + 2) * 6.25 + '%';
        } else {
            setFontSize(fontSize - 2);
            document.documentElement.style.fontSize = (fontSize - 2) * 6.25 + '%';
        }
    }
}

export default withTranslation()(AccessibilityMenu);
