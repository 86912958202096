import React, {Component} from 'react';
import {Translation} from 'jobhunter-common-web';
import OffersHost from '../Offers';
import LayoutWrapper from '../LayoutWrapper';

class LandingPage extends Component {
    render() {
        return (
            <LayoutWrapper>
                <div className="page-layout">
                    <div className="banner">
                        <h1 className="title">
                            <Translation text="landingPage.title" />
                        </h1>
                    </div>
                    <OffersHost />
                </div>
            </LayoutWrapper>
        );
    }
}

export default LandingPage;
