import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelDictionaryDatum} from '../../model/dictionaryDatum';

export interface IBaseDictionaryState {
    isLoading: boolean;
    isInitialized: boolean;
    error: string | null;
}

export interface IChangeIsLoading {
    isLoading: boolean;
}

export interface IChangeIsInitialized {
    isInitialized: boolean;
}

export interface ISetError {
    error: string | null;
}

export interface IWorkTypeState extends IBaseDictionaryState {
    workTypes: IModelDictionaryDatum[] | null;
}

export interface IChangeWorkType {
    workTypes: IModelDictionaryDatum[] | null;
}

const initialState: IWorkTypeState = {
    workTypes: null,
    isLoading: false,
    isInitialized: false,
    error: null,
};

const workTypeSlice = createSlice({
    name: 'workType',
    initialState: initialState,
    reducers: {
        changeWorkType: {
            reducer: (state: IWorkTypeState, action: PayloadAction<IChangeWorkType>) => {
                return {
                    workTypes: action.payload.workTypes,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(workTypes: IModelDictionaryDatum[] | null) {
                return {
                    payload: {workTypes: workTypes},
                };
            },
        },
        changeIsWorkTypeLoading: {
            reducer: (state: IWorkTypeState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    workTypes: state.workTypes,
                    isLoading: action.payload.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        changeIsWorkTypeInitialized: {
            reducer: (state: IWorkTypeState, action: PayloadAction<IChangeIsInitialized>) => {
                return {
                    workTypes: state.workTypes,
                    isLoading: state.isLoading,
                    isInitialized: action.payload.isInitialized,
                    error: state.error,
                };
            },
            prepare(isInitialized: boolean) {
                return {
                    payload: {isInitialized: isInitialized},
                };
            },
        },
        setWorkTypeError: {
            reducer: (state: IWorkTypeState, action: PayloadAction<ISetError>) => {
                return {
                    workTypes: state.workTypes,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
        fetchWorkTypes: (state: IWorkTypeState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        fetchAllDictionaryData: (state: IWorkTypeState) => {
            return {
                ...state,
            };
        },
        fetchOffersDictionaryData: (state: IWorkTypeState) => {
            return {
                ...state,
            };
        },
    },
});

export const {
    changeWorkType,
    changeIsWorkTypeLoading,
    changeIsWorkTypeInitialized,
    setWorkTypeError,
    fetchWorkTypes,
    fetchAllDictionaryData,
    fetchOffersDictionaryData,
} = workTypeSlice.actions;

export default workTypeSlice.reducer;
