import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelDictionaryDatum} from '../../model/dictionaryDatum';
import {IBaseDictionaryState, IChangeIsInitialized, IChangeIsLoading, ISetError} from './workTypeSlice';

export interface ILanguageState extends IBaseDictionaryState {
    languages: IModelDictionaryDatum[] | null;
}

export interface IChangeLanguage {
    languages: IModelDictionaryDatum[] | null;
}

const initialState: ILanguageState = {
    languages: null,
    isLoading: false,
    isInitialized: false,
    error: null,
};

const languageSlice = createSlice({
    name: 'language',
    initialState: initialState,
    reducers: {
        changeLanguage: {
            reducer: (state: ILanguageState, action: PayloadAction<IChangeLanguage>) => {
                return {
                    languages: action.payload.languages,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(languages: IModelDictionaryDatum[] | null) {
                return {
                    payload: {languages: languages},
                };
            },
        },
        changeIsLanguageLoading: {
            reducer: (state: ILanguageState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    languages: state.languages,
                    isLoading: action.payload.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        changeIsLanguageInitialized: {
            reducer: (state: ILanguageState, action: PayloadAction<IChangeIsInitialized>) => {
                return {
                    languages: state.languages,
                    isLoading: state.isLoading,
                    isInitialized: action.payload.isInitialized,
                    error: state.error,
                };
            },
            prepare(isInitialized: boolean) {
                return {
                    payload: {isInitialized: isInitialized},
                };
            },
        },
        setLanguageError: {
            reducer: (state: ILanguageState, action: PayloadAction<ISetError>) => {
                return {
                    languages: state.languages,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
        fetchLanguages: (state: ILanguageState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
    },
});

export const {changeLanguage, changeIsLanguageLoading, changeIsLanguageInitialized, setLanguageError, fetchLanguages} =
    languageSlice.actions;

export default languageSlice.reducer;
