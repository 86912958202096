import {createSelector} from '@reduxjs/toolkit';
import {ILanguageLevelState} from '../reducers/languageLevelSlice';

export const selectLanguageLevel = (state: any): ILanguageLevelState => {
    return state.languageLevel;
};

export const languageLevelsSelector = createSelector([selectLanguageLevel], (state: ILanguageLevelState) => state.languageLevels);

export const languageLevelsLoadingSelector = createSelector([selectLanguageLevel], (state: ILanguageLevelState) => state.isLoading);

export const languageLevelsInitializedSelector = createSelector([selectLanguageLevel], (state: ILanguageLevelState) => state.isInitialized);

export const languageLevelsErrorSelector = createSelector([selectLanguageLevel], (state: ILanguageLevelState) => state.error);
