import {jobHunterAPI, RestQueryParams} from 'jobhunter-common-web';
import {Observable} from 'rxjs';

export function getOnlineConsultationAPI(consultationId: string, agoraToken: string): Observable<any> {
    const headers: any = {
        accept: 'application/ld+json',
        'content-type': 'application/json',
        'X-Online-Consultation-Participant-Token': agoraToken,
    };

    return jobHunterAPI.get(`api/online_consultations/${consultationId}`, new RestQueryParams(), headers);
}
