import {PayloadAction} from '@reduxjs/toolkit';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {of} from 'rxjs';
import {catchError, debounceTime, mergeMap, switchMap} from 'rxjs/operators';
import {authTokenSelector, deepCloneObject, selectAccount, updateAccountDataAPI, userAPI, UserRole} from '../..';
import {AlertType} from '../../types';
import {
    changeAccountAvatar,
    changeAccountStateLoading,
    IAccountPayload,
    IChangeAccountAvatar,
    IUpdateAccount,
    mapResponseAccountToCandidateFullInfo,
    mapResponseAccountToOrganizationFullInfo,
    refreshAccountStateData,
    setAccountState,
    setAccountStateFailure,
    updateAccount,
} from '../reducers/accountSlice';
import {addAlert} from '../reducers/alertSlice';
import jwt_decode from 'jwt-decode';

const refreshAccountStateDataEpic: Epic = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(refreshAccountStateData.type),
        debounceTime(250),
        switchMap(() => {
            const authToken = authTokenSelector(state$.value),
                decoded = jwt_decode(authToken);
            return userAPI(authToken, (decoded as any).user_id).pipe(
                mergeMap((resp: any) => {
                    const actions: any[] = [changeAccountStateLoading(false)],
                        account: IAccountPayload = {
                            candidateFullInfo: mapResponseAccountToCandidateFullInfo(resp),
                            organizationFullInfo: mapResponseAccountToOrganizationFullInfo(resp),
                        };

                    actions.push(setAccountState(account));

                    return of(...actions);
                }),
                catchError((error: any) => {
                    return of(
                        changeAccountStateLoading(false),
                        setAccountStateFailure(error.toString()),
                        addAlert({
                            message: error.response ? error.response.message : 'alerts.baseError',
                            type: AlertType.WARNING,
                        })
                    );
                })
            );
        })
    );

const updateAccountData: Epic = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(updateAccount.type),
        switchMap((action: PayloadAction<IUpdateAccount>): any => {
            return updateAccountDataAPI(
                (action.payload as any).account.id,
                (state$ as any).value.auth.authToken,
                (action.payload as any).account.firstName,
                (action.payload as any).account.lastName,
                (action.payload as any).account.birthDate,
                (action.payload as any).account.phone,
                (action.payload as any).account.avatar
            ).pipe(
                mergeMap((account: any) => {
                    // toDo add update account
                    const stateAccount = (state$ as any).value.account.account,
                        accountPayload = {
                            candidateFullInfo: null,
                            organizationFullInfo: null,
                        },
                        actions: any[] = updateAccountActions(accountPayload);
                    return of(...actions);
                }),
                catchError((error: any) => {
                    return of(
                        changeAccountStateLoading(false),
                        setAccountStateFailure(getErrorMessage(error)),
                        addAlert({message: getErrorMessage(error), type: AlertType.WARNING})
                    );
                })
            );
        })
    );

const changeAccountAvatarEpic: Epic = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(changeAccountAvatar.type),
        switchMap((action: PayloadAction<IChangeAccountAvatar>): any => {
            const stateAccount = selectAccount((state$ as any).value),
                authToken = authTokenSelector((state$ as any).value),
                decoded = jwt_decode(authToken),
                userRoles = (decoded as any).roles,
                accountPayload = {
                    candidateFullInfo: deepCloneObject(stateAccount.account.candidateFullInfo),
                    organizationFullInfo: deepCloneObject(stateAccount.account.organizationFullInfo),
                };

            if (userRoles.includes(UserRole.CANDIDATE)) {
                accountPayload.candidateFullInfo.account.avatar = action.payload.mediaObject;
            } else {
                accountPayload.organizationFullInfo.organization.organizationCompany.avatar = action.payload.mediaObject;
            }

            const actions: any[] = updateAccountActions(accountPayload);
            return of(...actions);
        }),
        catchError((error: any) => {
            return of(
                changeAccountStateLoading(false),
                setAccountStateFailure(getErrorMessage(error)),
                addAlert({message: getErrorMessage(error), type: AlertType.WARNING})
            );
        })
    );

const updateAccountActions = (accountPayload: IAccountPayload, alertMessage?: string): any[] => {
    const message = alertMessage ? alertMessage : 'profile.profileForm.profileUpdated';
    return [setAccountState(accountPayload), addAlert({message: message}), changeAccountStateLoading(false)];
};

function getErrorMessage(error: any): string {
    let errorMessage = 'alerts.baseError';

    if (error) {
        if (error.response && error.response['hydra:description']) {
            errorMessage = error.response['hydra:description'];
        } else if (error['hydra:description']) {
            errorMessage = error['hydra:description'];
        } else if (error.message) {
            errorMessage = error.message;
        }
    }

    return errorMessage;
}

const accountEpic = combineEpics(updateAccountData, refreshAccountStateDataEpic, changeAccountAvatarEpic);

export default accountEpic;
