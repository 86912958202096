import {getChatContacts, setChatContacts} from '../reducers/chatContactsSlice';
import {getChatContactsListAPI} from '../../api/getChatContactsListAPI';
import {authTokenSelector} from '../selectors/authSelectors';
import {of} from 'rxjs';
import {catchError, mergeMap, switchMap} from 'rxjs/operators';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {addAlert} from '../reducers/alertSlice';
import {AlertType, ChatContact} from '../../types';
import jwt_decode from 'jwt-decode';

const getChatContactsEpic: Epic = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(getChatContacts.type),
        switchMap(() => {
            //todo accountId
            const authToken = authTokenSelector(state$.value);
            // const accountId = accountIdSelector(state$.value) || 'test';
            if (!authToken) {
                return of(null);
            }
            const decoded: any = jwt_decode(authToken);
            const accountId = decoded?.account_id;
            return getChatContactsListAPI(authToken, accountId).pipe(
                mergeMap((response: ChatContact[]) => {
                    return of(setChatContacts(response));
                })
            );
        }),
        catchError((error: any) => {
            //todo loader
            return of(
                addAlert({
                    message: error.response ? error.response.message : 'alerts.baseError',
                    type: AlertType.WARNING,
                })
            );
        })
    );
const chatContactsEpic = combineEpics(getChatContactsEpic);

export default chatContactsEpic;
