import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelDictionaryDatum} from '../../model/dictionaryDatum';
import {IBaseDictionaryState, IChangeIsInitialized, IChangeIsLoading, ISetError} from './workTypeSlice';

export interface ITechnologyState extends IBaseDictionaryState {
    technologies: IModelDictionaryDatum[] | null;
}

export interface IChangeTechnology {
    technologies: IModelDictionaryDatum[] | null;
}

const initialState: ITechnologyState = {
    technologies: null,
    isLoading: false,
    isInitialized: false,
    error: null,
};

const technologySlice = createSlice({
    name: 'technology',
    initialState: initialState,
    reducers: {
        changeTechnology: {
            reducer: (state: ITechnologyState, action: PayloadAction<IChangeTechnology>) => {
                return {
                    technologies: action.payload.technologies,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(technologies: IModelDictionaryDatum[] | null) {
                return {
                    payload: {technologies: technologies},
                };
            },
        },
        changeIsTechnologyLoading: {
            reducer: (state: ITechnologyState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    technologies: state.technologies,
                    isLoading: action.payload.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        changeIsTechnologyInitialized: {
            reducer: (state: ITechnologyState, action: PayloadAction<IChangeIsInitialized>) => {
                return {
                    technologies: state.technologies,
                    isLoading: state.isLoading,
                    isInitialized: action.payload.isInitialized,
                    error: state.error,
                };
            },
            prepare(isInitialized: boolean) {
                return {
                    payload: {isInitialized: isInitialized},
                };
            },
        },
        setTechnologyError: {
            reducer: (state: ITechnologyState, action: PayloadAction<ISetError>) => {
                return {
                    technologies: state.technologies,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
        fetchTechnologies: (state: ITechnologyState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
    },
});

export const {changeTechnology, changeIsTechnologyLoading, changeIsTechnologyInitialized, setTechnologyError, fetchTechnologies} =
    technologySlice.actions;

export default technologySlice.reducer;
