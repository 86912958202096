import {createSelector} from '@reduxjs/toolkit';
import {ISeniorityState} from '../reducers/senioritySlice';

export const selectSeniority = (state: any): ISeniorityState => {
    return state.seniority;
};

export const senioritySelector = createSelector([selectSeniority], (state: ISeniorityState) => state.seniority);

export const seniorityLoadingSelector = createSelector([selectSeniority], (state: ISeniorityState) => state.isLoading);

export const seniorityInitializedSelector = createSelector([selectSeniority], (state: ISeniorityState) => state.isInitialized);

export const seniorityErrorSelector = createSelector([selectSeniority], (state: ISeniorityState) => state.error);
