import React from 'react';
import styles from './styles.module.scss';
import AgoraVideoCall from './AgoraVideoCall';
import {AGORA_APP_ID} from './agora.config';
import {connect} from 'react-redux';
import {RootState} from '../../store/reducers';
import UpcomingCall from './UpcomingCall';
import CallExpired from './CallExpired';
import CallNotFound from './CallNotFound';
import {of, Subscription} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {ConsultationStatus, IVideoChatProps, IVideoChatState, LoaderType, UserRole} from '../../types';
import {
    authTokenSelector,
    changeOnlineConsultationStatus,
    confirmPresenceAPI,
    isAuthenticatedSelector,
    isFullScreenModeSelector,
    isSameValue,
    onlineConsultationErrorSelector,
    withLocation,
} from '../..';
import Loader from '../Loader';
import {onlineConsultationStatusSelector} from '../../store/selectors/onlineConsultationSelectors';
// import jwtDecode from "jwt-decode";

type Props = IVideoChatProps;
type State = IVideoChatState;

class VideoChat extends React.Component<Props, State> {
    private subscriptions: Subscription[] = [];
    private participantPresenceInterval: any = null;
    private consultationShouldStartInterval: any;
    private consultationInterval: any;
    private videoProfile: string;
    private transcode: string;
    private attendeeMode: string;
    private baseMode: string;
    private appId: string = AGORA_APP_ID;
    private uid: string | null;

    constructor(props: Props) {
        super(props);
        this.videoProfile = '480p_4';
        this.transcode = 'interop';
        this.attendeeMode = 'video';
        this.baseMode = 'avc';
        if (!this.appId) {
            return;
        }
        this.uid = null;

        this.state = {
            isLoading: true,
            participantsPresent: false,
            shouldConsultationStart: false,
            contacts: [],
        };
    }

    componentDidMount(): void {
        if (this.props.onlineConsultation) {
            this.setState({isLoading: false});
            if (this.consultationShouldStartInterval) clearInterval(this.consultationShouldStartInterval);
            this.consultationShouldStartInterval = setInterval(() => {
                this.setState({
                    shouldConsultationStart: new Date().getTime() >= new Date(this.props.onlineConsultation.startsAt).getTime(),
                });
            }, 1000);
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (!isSameValue(this.props.onlineConsultation, prevProps.onlineConsultation) && this.props.onlineConsultation) {
            this.setState({isLoading: false});
            if (this.consultationShouldStartInterval) clearInterval(this.consultationShouldStartInterval);
            this.consultationShouldStartInterval = setInterval(() => {
                this.setState({
                    shouldConsultationStart: new Date().getTime() >= new Date(this.props.onlineConsultation.startsAt).getTime(),
                });
            }, 1000);
        }

        if (!isSameValue(this.state.shouldConsultationStart, prevState.shouldConsultationStart) && this.state.shouldConsultationStart) {
            if (this.props.userRole === UserRole.CANDIDATE) {
                if (this.participantPresenceInterval) clearInterval(this.participantPresenceInterval);
                this.participantPresenceInterval = setInterval(() => {
                    this.checkParticipantPresence();
                }, 1000);
            }
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        if (this.participantPresenceInterval) clearInterval(this.participantPresenceInterval);
        if (this.consultationShouldStartInterval) clearInterval(this.consultationShouldStartInterval);
        if (this.consultationInterval) clearInterval(this.consultationInterval);
    }

    render() {
        return (
            <div className={`${styles.wrapper} ${styles.meeting} row`}>
                <div className={`${styles.agMain} ${this.props.isFullScreenMode ? styles.fullScreen : ''} col-12`}>
                    <div className={styles.agContainer}>{this.renderConsultationView()}</div>
                </div>
            </div>
        );
    }

    private renderConsultationView = () => {
        if (!this.props.onlineConsultation) {
            return <CallNotFound />;
        }

        if (
            (this.props.consultationStatus && this.props.consultationStatus === ConsultationStatus.FINISHED) ||
            (this.props.onlineConsultation &&
                (this.props.onlineConsultation.status === ConsultationStatus.FINISHED ||
                    (new Date().getTime() > new Date(this.props.onlineConsultation.endsAt).getTime() &&
                        (this.props.onlineConsultation.status === ConsultationStatus.NOT_STARTED ||
                            this.props.onlineConsultation.status === ConsultationStatus.SCHEDULED))))
        ) {
            return <CallExpired />;
        }

        if (
            !this.state.shouldConsultationStart ||
            (this.props.userRole &&
                this.props.userRole === UserRole.CANDIDATE &&
                this.props.onlineConsultation &&
                this.state.shouldConsultationStart &&
                !this.state.participantsPresent)
        ) {
            return (
                <UpcomingCall
                    consultationTime={this.props.onlineConsultation.startsAt}
                    consultationId={this.props.onlineConsultation?.id}
                    changeConsultationStatus={this.props.changeOnlineConsultationStatus}
                    authToken={this.props.authToken}
                    attendeeRole={this.props.userRole}
                    participantAgoraToken={this.props.participantAgoraToken}
                />
            );
        } else if (
            (this.props.userRole && this.props.userRole !== UserRole.CANDIDATE && this.state.shouldConsultationStart) ||
            (this.props.userRole === UserRole.CANDIDATE && this.state.participantsPresent)
        ) {
            return (
                <AgoraVideoCall
                    videoProfile={this.videoProfile}
                    channel={this.props.onlineConsultation.agoraChannel}
                    userSecret={this.props.userSecret}
                    consultation={this.props.onlineConsultation}
                    transcode={this.transcode}
                    attendeeMode={this.attendeeMode}
                    baseMode={this.baseMode}
                    appId={this.appId}
                    uid={this.props.userId}
                    token={this.props.userToken}
                    attendeeRole={this.props.userRole}
                    consultationStartTime={this.props.onlineConsultation.startsAt}
                />
            );
        }

        return <Loader type={LoaderType.Local} showLoader={this.state.isLoading} />;
    };

    private checkParticipantPresence = () => {
        if (!this.props.userSecret) {
            return;
        }

        this.subscriptions.push(
            confirmPresenceAPI(this.props.userSecret, true)
                .pipe(
                    tap((response: any) => {
                        let participants = response.participantAvailability['hydra:member'];
                        let participantsPresent: boolean = false;
                        // let participantsPresent = participants.every((item: any) => !!(item.availabilityExpiresAt && item.availableFrom));

                        participants
                            .filter(
                                (item: {[key: string]: any}) =>
                                    item.participantRole === UserRole.ORGANIZATION || item.participantRole === UserRole.HEADHUNTER
                            )
                            .map((participant: any) => {
                                if (participant.availabilityExpiresAt === null && participant.availableFrom === null) {
                                    return (participantsPresent = false);
                                } else if (new Date().getTime() > new Date(participant.availabilityExpiresAt).getTime()) {
                                    return (participantsPresent = false);
                                } else {
                                    return (participantsPresent = true);
                                }
                            });
                        this.setState({participantsPresent: participantsPresent}, () => {
                            if (
                                this.state.participantsPresent &&
                                this.props.onlineConsultation &&
                                (this.props.onlineConsultation.status === ConsultationStatus.NOT_STARTED ||
                                    this.props.onlineConsultation.status === ConsultationStatus.SCHEDULED)
                            ) {
                                this.props.changeOnlineConsultationStatus(ConsultationStatus.STARTED);
                            }
                        });
                    }),
                    catchError((error: any) => {
                        return of(error);
                    })
                )
                .subscribe()
        );
    };
}

export default connect(
    (state: RootState) => ({
        error: onlineConsultationErrorSelector(state),
        isFullScreenMode: isFullScreenModeSelector(state),
        authToken: authTokenSelector(state),
        isAuthenticated: isAuthenticatedSelector(state),
        consultationStatus: onlineConsultationStatusSelector(state),
    }),
    {
        changeOnlineConsultationStatus,
    }
)(withLocation(VideoChat));
