import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelDictionaryDatum} from '../../model/dictionaryDatum';
import {IBaseDictionaryState, IChangeIsInitialized, IChangeIsLoading, ISetError} from './workTypeSlice';

export interface IOrganizationVerificationFileTypeState extends IBaseDictionaryState {
    verificationFileTypes: IModelDictionaryDatum[] | null;
}

export interface IChangeOrganizationVerificationFileType {
    verificationFileTypes: IModelDictionaryDatum[] | null;
}

const initialState: IOrganizationVerificationFileTypeState = {
    verificationFileTypes: null,
    isLoading: false,
    isInitialized: false,
    error: null,
};

const organizationVerificationFileTypeSlice = createSlice({
    name: 'organizationVerificationFileType',
    initialState: initialState,
    reducers: {
        changeOrganizationVerificationFileType: {
            reducer: (state: IOrganizationVerificationFileTypeState, action: PayloadAction<IChangeOrganizationVerificationFileType>) => {
                return {
                    verificationFileTypes: action.payload.verificationFileTypes,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(verificationFileTypes: IModelDictionaryDatum[] | null) {
                return {
                    payload: {verificationFileTypes: verificationFileTypes},
                };
            },
        },
        changeIsVerificationFileTypeLoading: {
            reducer: (state: IOrganizationVerificationFileTypeState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    verificationFileTypes: state.verificationFileTypes,
                    isLoading: action.payload.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        changeIsVerificationFileTypeInitialized: {
            reducer: (state: IOrganizationVerificationFileTypeState, action: PayloadAction<IChangeIsInitialized>) => {
                return {
                    verificationFileTypes: state.verificationFileTypes,
                    isLoading: state.isLoading,
                    isInitialized: action.payload.isInitialized,
                    error: state.error,
                };
            },
            prepare(isInitialized: boolean) {
                return {
                    payload: {isInitialized: isInitialized},
                };
            },
        },
        setVerificationFileTypeError: {
            reducer: (state: IOrganizationVerificationFileTypeState, action: PayloadAction<ISetError>) => {
                return {
                    verificationFileTypes: state.verificationFileTypes,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
        fetchVerificationFileTypes: (state: IOrganizationVerificationFileTypeState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
    },
});

export const {
    changeOrganizationVerificationFileType,
    changeIsVerificationFileTypeInitialized,
    changeIsVerificationFileTypeLoading,
    setVerificationFileTypeError,
    fetchVerificationFileTypes,
} = organizationVerificationFileTypeSlice.actions;

export default organizationVerificationFileTypeSlice.reducer;
