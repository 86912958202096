// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3uAS57M6hpUnf4IlgDXjJZ{display:none;flex:2 1;height:100%}._19mUygEO8sVIA3CYE-DCI2{display:flex;flex-direction:column;flex:1;padding:1.2rem 3.4rem 2.5rem}._19mUygEO8sVIA3CYE-DCI2 .rNleLhDfg3chi_1V8zus_ h3{margin-bottom:2rem;text-shadow:-0.39rem 52rem 1.35rem rgba(0,0,0,0.15);font-family:Montserrat, sans-serif;font-size:2.2rem;font-weight:500;line-height:1.4;letter-spacing:0.031rem;text-align:center;color:#000}._19mUygEO8sVIA3CYE-DCI2 ._1EPVaBiNZ_G1bvfz_xzYXq{font-family:Montserrat, sans-serif;font-size:1.4rem;font-weight:500;line-height:3.4;letter-spacing:0.045rem;color:#000}._19mUygEO8sVIA3CYE-DCI2 ._2-qBZtWdDGhi_ixKPpZguF{display:flex;justify-content:center;align-items:center;margin-bottom:2rem}._19mUygEO8sVIA3CYE-DCI2 ._2-qBZtWdDGhi_ixKPpZguF ._1--yHFZ6qgPDgo_sTJ8lcu{font-family:Montserrat, sans-serif;font-size:1.2rem;font-weight:normal;line-height:1.82;color:#000}._19mUygEO8sVIA3CYE-DCI2 ._2Mk5RBmKytgAyYrOklLn2Y{display:flex;justify-content:center;align-items:center}\n", ""]);
// Exports
exports.locals = {
	"agoraLocalStream": "_3uAS57M6hpUnf4IlgDXjJZ",
	"details": "_19mUygEO8sVIA3CYE-DCI2",
	"header": "rNleLhDfg3chi_1V8zus_",
	"audioTestLabel": "_1EPVaBiNZ_G1bvfz_xzYXq",
	"progressContainer": "_2-qBZtWdDGhi_ixKPpZguF",
	"audioLevelLabel": "_1--yHFZ6qgPDgo_sTJ8lcu",
	"btnContainer": "_2Mk5RBmKytgAyYrOklLn2Y"
};
module.exports = exports;
