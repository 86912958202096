import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {UserRole} from '../../types';

export interface IAuthEntry {
    username: string | null;
    authToken: string | null;
    refreshToken: string | null;
    userId: string | null;
    accountId: string | null;
    userRoles: UserRole[] | null;
}

export interface IAuthTokens {
    authToken: string;
    refreshToken: string;
}

export interface IAuthState {
    username: string | null;
    authToken: string | null;
    userId: string | null;
    accountId: string | null;
    userRoles: UserRole[] | null;
    refreshToken: string | null;
    // isRefreshToken: boolean;
    secret: string | null;
    error: string | null;
    isAuthenticated: boolean;
}

export interface ISetAuthTokens {
    readonly authToken: string | null;
    readonly refreshToken: string | null;
}

export interface ISetAuthState {
    readonly username: string | null;
    readonly authToken: string | null;
    readonly refreshToken: string | null;
    readonly secret: string | null;
    readonly userId: string | null;
    readonly accountId: string | null;
    readonly userRoles: UserRole[] | null;
    readonly isAuthenticated: boolean;
}

export interface ISetAuthError {
    readonly error: string;
}

export interface ISetAuthToken {
    readonly authToken: string;
    readonly userRole: UserRole;
}

export interface IChangeIsAuthenticated {
    readonly isAuthenticated: boolean;
}

export interface IRenewAuthToken {
    readonly authToken: string;
    readonly refreshToken: string;
}

const initialState: IAuthState = {
    username: null,
    authToken: null,
    refreshToken: null,
    secret: null,
    userId: null,
    accountId: null,
    userRoles: null,
    error: null,
    isAuthenticated: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setAuthState: {
            reducer: (state: IAuthState, action: PayloadAction<ISetAuthState>) => {
                return {
                    username: action.payload.username,
                    authToken: action.payload.authToken,
                    refreshToken: action.payload.refreshToken,
                    secret: action.payload.secret,
                    userId: action.payload.userId,
                    accountId: action.payload.accountId,
                    userRoles: action.payload.userRoles,
                    error: state.error,
                    isAuthenticated: action.payload.isAuthenticated,
                };
            },
            prepare(
                username: string | null,
                authToken: string | null,
                refreshToken: string | null,
                secret: string | null,
                userId: string | null,
                accountId: string | null,
                userRoles: UserRole[] | null,
                isAuthenticated: boolean
            ) {
                return {
                    payload: {
                        username: username,
                        authToken: authToken,
                        refreshToken: refreshToken,
                        secret: secret,
                        userId: userId,
                        accountId: accountId,
                        userRoles: userRoles,
                        isAuthenticated: isAuthenticated,
                    },
                };
            },
        },
        setAuthStateFailure: {
            reducer: (state: IAuthState, action: PayloadAction<ISetAuthError>) => {
                return {
                    ...state,
                    error: action.payload.error,
                };
            },
            prepare(error: string) {
                return {
                    payload: {
                        error: error,
                    },
                };
            },
        },
        setAuthTokens: {
            reducer: (state: IAuthState, action: PayloadAction<ISetAuthTokens>) => {
                return {
                    ...state,
                    authToken: action.payload.authToken,
                    refreshToken: action.payload.refreshToken,
                };
            },
            prepare(authToken: string, refreshToken: string) {
                return {
                    payload: {
                        authToken: authToken,
                        refreshToken: refreshToken,
                    },
                };
            },
        },
        initAuthTokenChange: {
            reducer: (state: IAuthState) => {
                return {
                    ...state,
                };
            },
            prepare(authToken: string, userRole: UserRole) {
                return {
                    payload: {
                        authToken: authToken,
                        userRole: userRole,
                    },
                };
            },
        },
        changeIsAuthenticated: {
            reducer: (state: IAuthState, action: PayloadAction<IChangeIsAuthenticated>) => {
                return {
                    ...state,
                    isAuthenticated: action.payload.isAuthenticated,
                };
            },
            prepare(isAuthenticated: boolean) {
                return {
                    payload: {
                        isAuthenticated: isAuthenticated,
                    },
                };
            },
        },
        renewAuthToken: {
            reducer: (state: IAuthState) => {
                return {
                    ...state,
                };
            },
            prepare(refreshToken: string, authToken: string) {
                return {
                    payload: {
                        authToken: authToken,
                        refreshToken: refreshToken,
                    },
                };
            },
        },
    },
});

export const {setAuthState, setAuthTokens, initAuthTokenChange, setAuthStateFailure, changeIsAuthenticated, renewAuthToken} =
    authSlice.actions;

export default authSlice.reducer;
