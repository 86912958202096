import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {catchError, switchMap, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {PayloadAction} from '@reduxjs/toolkit';
import {addAlert} from '../reducers/alertSlice';
import {
    changePasswordStart,
    IChangePasswordStart,
    changeIsPasswordChanged,
    setChangePasswordFailure,
    changePasswordLoading,
} from '../reducers/changePasswordSlice';
import {changePasswordAPI} from '../../api/changePassword';
import {AlertType} from '../../types';

const changePasswordStartEpic: Epic = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(changePasswordStart.type),
        switchMap((action: PayloadAction<IChangePasswordStart>): any => {
            return changePasswordAPI(action.payload.oldPassword, action.payload.newPassword, (state$ as any).value.auth.authToken).pipe(
                mergeMap(() => {
                    return of(
                        changeIsPasswordChanged(true),
                        addAlert({message: 'settings.changePassword.alerts.passwordChanged'}),
                        changePasswordLoading(false)
                    );
                }),
                catchError((error: any) => {
                    return of(
                        setChangePasswordFailure(error.toString()),
                        addAlert({message: error.response['hydra:description'], type: AlertType.WARNING})
                    );
                })
            );
        })
    );

const resetPasswordEpic = combineEpics(changePasswordStartEpic);

export default resetPasswordEpic;
