import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {isSameValue} from 'jobhunter-common-web';
import {IOfferItem} from '../../model/offersModel';

export interface IOffersPageState {
    offersList: IOfferItem[] | null;
    isOffersLoading: boolean;
    isOffersInitialized: boolean;
    offersPageErrors: string | null;
    filters: IOfferFilters | null;
    isOfferActionComplete: boolean;
}

interface IOffersLoading {
    isOffersLoading: boolean;
}
interface IOffersInitialized {
    isOffersInitialized: boolean;
}

export interface IChangeOffersFilters {
    filters: IOfferFilters | null;
}
interface ISetOffersList {
    offersList: IOfferItem[] | null;
}

export interface IOfferFilters {
    offerTechnologies: {
        technology: {
            id: string[] | null;
        };
    } | null;
    seniority: {
        id?: string[] | null;
    } | null;
    contractTypes: {
        id: string[] | null;
    } | null;
    industries: {
        id: string[] | null;
    } | null;
    companyTypes: {
        id: string[] | null;
    } | null;
    workTypes: {
        id: string[] | null;
    } | null;
    employmentTypes: {
        id: string[] | null;
    } | null;
    minimumSalary: string | null;
    relocation: boolean; // true,
    name?: string;
    exists?: {
        soughtPosition: boolean;
    } | null;
    soughtPosition?: {
        id: string[] | null;
    } | null;
    offer?: {
        name?: string | null;
    } | null;
}
interface IOffersPageError {
    offersPageErrors: string | null;
}

const initialState: IOffersPageState = {
    offersList: null,
    filters: null,
    isOffersInitialized: false,
    isOffersLoading: false,
    isOfferActionComplete: false,
    offersPageErrors: null,
};

const offersPageSlice = createSlice({
    name: 'offersPage',
    initialState: initialState,
    reducers: {
        applyOffersFilters: (state: IOffersPageState) => {
            return {
                ...state,
                isOfferListLoading: true,
            };
        },
        resetOffersFiltersToDefaultAccountFilters: (state: IOffersPageState) => {
            return {
                ...state,
                isOfferListLoading: true,
            };
        },
        changeOffersFilters: {
            reducer: (state: IOffersPageState, action: PayloadAction<IChangeOffersFilters>) => {
                if (isSameValue(action.payload.filters, state.filters)) {
                    return {
                        ...state,
                    };
                }

                return {
                    ...state,
                    isOfferListLoading: true,
                    filters: action.payload.filters,
                };
            },
            prepare(filters: IOfferFilters | null) {
                return {
                    payload: {
                        filters: filters
                            ? {
                                  offerTechnologies: filters?.offerTechnologies,
                                  seniority: filters.seniority?.id ? filters.seniority : null,
                                  contractTypes: filters?.contractTypes,
                                  industries: filters?.industries,
                                  companyTypes: filters?.companyTypes,
                                  workTypes: filters?.workTypes,
                                  employmentTypes: filters?.employmentTypes,
                                  soughtPosition: filters?.soughtPosition,
                                  minimumSalary: filters.minimumSalary ? filters.minimumSalary : null,
                                  relocation: filters?.relocation,
                                  name: filters?.name,
                                  exists: filters?.exists,
                                  offer: filters?.offer,
                              }
                            : null,
                    },
                };
            },
        },
        changeOffersPageError: {
            reducer: (state: IOffersPageState, action: PayloadAction<IOffersPageError>) => {
                return {
                    ...state,
                    offersPageErrors: action.payload.offersPageErrors,
                };
            },
            prepare: (offersPageErrors: string | null) => {
                return {
                    payload: {
                        offersPageErrors: offersPageErrors,
                    },
                };
            },
        },
        setOffersList: {
            reducer: (state: IOffersPageState, action: PayloadAction<ISetOffersList>) => {
                return {
                    ...state,
                    offersList: action.payload.offersList,
                };
            },
            prepare: (offersList: IOfferItem[] | null) => {
                return {
                    payload: {
                        offersList: offersList,
                    },
                };
            },
        },
        fetchOffersList: (state: IOffersPageState) => {
            return {
                ...state,
                isOffersLoading: true,
            };
        },
        resetToInitialOffersState: () => {
            return {
                ...initialState,
            };
        },
        changeIsOffersLoading: {
            reducer: (state: IOffersPageState, action: PayloadAction<IOffersLoading>) => {
                return {
                    ...state,
                    isOffersLoading: action.payload.isOffersLoading,
                };
            },
            prepare: (isOffersLoading: boolean) => {
                return {
                    payload: {
                        isOffersLoading: isOffersLoading,
                    },
                };
            },
        },
        changeIsOffersInitialized: {
            reducer: (state: IOffersPageState, action: PayloadAction<IOffersInitialized>) => {
                return {
                    ...state,
                    isOffersInitialized: action.payload.isOffersInitialized,
                };
            },
            prepare: (isOffersInitialized: boolean) => {
                return {
                    payload: {
                        isOffersInitialized: isOffersInitialized,
                    },
                };
            },
        },
    },
});

export const {
    fetchOffersList,
    resetToInitialOffersState,
    changeOffersPageError,
    changeOffersFilters,
    applyOffersFilters,
    resetOffersFiltersToDefaultAccountFilters,
    changeIsOffersInitialized,
    changeIsOffersLoading,
    setOffersList,
} = offersPageSlice.actions;

export default offersPageSlice.reducer;
