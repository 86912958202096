import {Avatar, AvatarColor, AvatarSize} from 'jobhunter-common-web';
import React from 'react';
import {Eye, EyeOff, Minus, Plus} from 'react-feather';
import {WithTranslation, withTranslation} from 'react-i18next';
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap';

function AccessibilityControlButton(props: WithTranslation) {
    const [contrastMode, setContrastMode] = React.useState(false);
    const [fontSize, setFontSize] = React.useState(10);

    return (
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
            <DropdownToggle href="/" tag="a" className="nav-link accessibility dropdown-user-link" onClick={(e) => e.preventDefault()}>
                <Avatar color={AvatarColor.LIGHT_PRIMARY} size={AvatarSize.LG} img={false} icon={<AccessibilityIcon />} tag={'div'} />
                <span className="sr-only">{props.t('accessibilityMenu.openAccessibilityMenu')}</span>
            </DropdownToggle>
            <DropdownMenu className="jobhunter-web-custom-dropdown-menu" end>
                <DropdownItem>
                    <div className="button-wrapper">
                        <span>
                            {props.t('accessibilityMenu.contrastMode.title', {
                                mode: contrastMode
                                    ? props.t('accessibilityMenu.contrastMode.on')
                                    : props.t('accessibilityMenu.contrastMode.off'),
                            })}
                        </span>
                        <div
                            title={props.t('accessibilityMenu.contrastMode.toggleButton')}
                            className="accessibility-button"
                            onClick={() => toggleContrastMode()}>
                            {contrastMode ? <EyeOff size={18} id={'contrast_mode_icon'} /> : <Eye size={18} id={'contrast_mode_icon'} />}
                        </div>
                    </div>
                </DropdownItem>
                <DropdownItem>
                    <div className="button-wrapper">
                        <span>{props.t('accessibilityMenu.fontSize.title')}</span>
                        <div className="font-size-controller">
                            <div
                                title={props.t('accessibilityMenu.fontSize.increase')}
                                className="accessibility-button font-controller"
                                onClick={() => changeFontSize(true)}>
                                <Plus size={20} id={'file_size_up'} />
                                <span className="sr-only">{props.t('accessibilityMenu.fontSize.increase')}</span>
                            </div>

                            <div
                                title={props.t('accessibilityMenu.fontSize.decrease')}
                                className="accessibility-button font-controller"
                                onClick={() => changeFontSize(false)}>
                                <Minus size={20} id={'file_size_down'} />
                                <span className="sr-only">{props.t('accessibilityMenu.fontSize.decrease')}</span>
                            </div>
                        </div>
                    </div>
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    );

    function toggleContrastMode() {
        setContrastMode(!contrastMode);
        document.body.classList.toggle('contrast-mode');
    }

    function changeFontSize(increase: boolean) {
        if ((fontSize === 20 && increase) || (fontSize === 6 && !increase)) {
            return;
        }
        if (increase) {
            setFontSize(fontSize + 2);

            document.documentElement.style.fontSize = (fontSize + 2) * 6.25 + '%';
        } else {
            setFontSize(fontSize - 2);
            document.documentElement.style.fontSize = (fontSize - 2) * 6.25 + '%';
        }
    }

    function AccessibilityIcon() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 122.88 122.88">
                <path d="M61.44 0A61.46 61.46 0 1118 18 61.21 61.21 0 0161.44 0zm-.39 74.18L52.1 98.91a4.94 4.94 0 01-2.58 2.83 5 5 0 01-6.82-6.24l6.24-17.28a26.3 26.3 0 001.17-4 40.64 40.64 0 00.54-4.18c.24-2.53.41-5.27.54-7.9s.22-5.18.29-7.29c.09-2.63-.62-2.8-2.73-3.3l-.44-.1-18-3.39A5 5 0 0127.08 46a5 5 0 015.05-7.74l19.34 3.63c.77.07 1.52.16 2.31.25a57.64 57.64 0 007.18.53A81.13 81.13 0 0069.9 42c.9-.1 1.75-.21 2.6-.29l18.25-3.42a5 5 0 013.75.71 5 5 0 011.3 7 5 5 0 01-3.21 2.09l-17.44 3.28c-.58.13-1.1.22-1.56.29-1.82.31-2.72.47-2.61 3.06.08 1.89.31 4.15.61 6.51.35 2.77.81 5.71 1.29 8.4.31 1.77.6 3.19 1 4.55s.79 2.75 1.39 4.42l6.11 16.9a5 5 0 01-6.82 6.24 4.94 4.94 0 01-2.58-2.83L63 74.23l-1-1.83-1 1.78zm.39-53.52a8.83 8.83 0 11-6.24 2.59 8.79 8.79 0 016.24-2.59zm36.35 4.43a51.42 51.42 0 1015 36.35 51.27 51.27 0 00-15-36.35z"></path>
            </svg>
        );
    }
}

export default withTranslation()(AccessibilityControlButton);
