import {jobHunterAPI} from './provider/jobHunterAPI';
import {Observable} from 'rxjs';
import {RestQueryParams} from './base/queryParams';

export function setConsultationDurationAPI(
    secret: string,
    consultationId: string,
    durationInSecond: number,
    durationStartAt?: string,
    durationEndsAt?: string
): Observable<any> {
    let payload = {
        durationStartsAt: durationStartAt ? durationStartAt : null,
        durationEndsAt: durationEndsAt ? durationEndsAt : null,
        durationInSecond: durationInSecond,
    };

    return jobHunterAPI.put(`api/online_consultations/${consultationId}/finish`, payload, new RestQueryParams(), {
        'X-Online-Consultation-Secret': secret,
    });
}
