import {ChatMessage, MessageContentType} from "../../../types";
import {convertServerMessageIntoChatMessage} from "../../../utils/convertServerMessageIntoChatMessage";
import {ChatServerMessage} from "../../../components/hoc/chat.types";

export const serverMessageIntoChatMessageMapper = (messages: ChatServerMessage[], accountId: string): [ChatMessage[], ChatServerMessage[]] => {
	if(!messages || messages.length === 0) {
		return [[], []];
	}
	let originalMessages = [];
	const messagesConverted = messages.map(message => {
		const mesConv = convertServerMessageIntoChatMessage(message, accountId)
		if(mesConv[1] !== null) {
			originalMessages.push(mesConv[1])
		}
		return mesConv[0]
	});

	return [messagesConverted, originalMessages];
}
