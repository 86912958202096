// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._29HPKitod5m0yq7zF3MLSJ{height:100%;width:100%}._29HPKitod5m0yq7zF3MLSJ iframe{height:100%;width:100%}\n", ""]);
// Exports
exports.locals = {
	"youtubeVideoContainer": "_29HPKitod5m0yq7zF3MLSJ"
};
module.exports = exports;
