import {createSelector} from '@reduxjs/toolkit';
import {IWorkTypeState} from '../reducers/workTypeSlice';

export const selectWorkType = (state: any): IWorkTypeState => {
    return state.workType;
};

export const workTypesSelector = createSelector([selectWorkType], (state: IWorkTypeState) => state.workTypes);

export const workTypesLoadingSelector = createSelector([selectWorkType], (state: IWorkTypeState) => state.isLoading);

export const workTypesInitializedSelector = createSelector([selectWorkType], (state: IWorkTypeState) => state.isInitialized);

export const workTypesErrorSelector = createSelector([selectWorkType], (state: IWorkTypeState) => state.error);
