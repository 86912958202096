// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1F2jPcD4GU9VNwtdJJ7rPz{display:flex;margin-bottom:1rem}._1F2jPcD4GU9VNwtdJJ7rPz ._1fCP2Oci8dgofCbp_dtu7X{font-family:Montserrat, sans-serif;font-size:1.4rem;font-weight:500;line-height:3.4;letter-spacing:0.045rem;color:#000;display:flex;flex:2}._1F2jPcD4GU9VNwtdJJ7rPz ._32pnH_FeNvPJXLVunq7MNF{display:flex;flex:2}._1F2jPcD4GU9VNwtdJJ7rPz ._32pnH_FeNvPJXLVunq7MNF:disabled{opacity:0.5}\n", ""]);
// Exports
exports.locals = {
	"settingsItem": "_1F2jPcD4GU9VNwtdJJ7rPz",
	"label": "_1fCP2Oci8dgofCbp_dtu7X",
	"select": "_32pnH_FeNvPJXLVunq7MNF"
};
module.exports = exports;
