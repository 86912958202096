import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelDictionaryDatum} from '../../model/dictionaryDatum';
import {IBaseDictionaryState, IChangeIsInitialized, IChangeIsLoading, ISetError} from './workTypeSlice';

export interface IIndustryState extends IBaseDictionaryState {
    industries: IModelDictionaryDatum[] | null;
}

export interface IChangeIndustry {
    industries: IModelDictionaryDatum[] | null;
}

const initialState: IIndustryState = {
    industries: null,
    isLoading: false,
    isInitialized: false,
    error: null,
};

const industrySlice = createSlice({
    name: 'industry',
    initialState: initialState,
    reducers: {
        changeIndustry: {
            reducer: (state: IIndustryState, action: PayloadAction<IChangeIndustry>) => {
                return {
                    industries: action.payload.industries,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(industries: IModelDictionaryDatum[] | null) {
                return {
                    payload: {industries: industries},
                };
            },
        },
        changeIsIndustryLoading: {
            reducer: (state: IIndustryState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    industries: state.industries,
                    isLoading: action.payload.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        changeIsIndustryInitialized: {
            reducer: (state: IIndustryState, action: PayloadAction<IChangeIsInitialized>) => {
                return {
                    industries: state.industries,
                    isLoading: state.isLoading,
                    isInitialized: action.payload.isInitialized,
                    error: state.error,
                };
            },
            prepare(isInitialized: boolean) {
                return {
                    payload: {isInitialized: isInitialized},
                };
            },
        },
        setIndustryError: {
            reducer: (state: IIndustryState, action: PayloadAction<ISetError>) => {
                return {
                    industries: state.industries,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
        fetchIndustries: (state: IIndustryState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
    },
});

export const {changeIndustry, changeIsIndustryLoading, changeIsIndustryInitialized, setIndustryError, fetchIndustries} =
    industrySlice.actions;

export default industrySlice.reducer;
