import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IMarketplaceState} from '../reducers/marketplacePageSlice';

export const selectMarketplacePage = (state: RootState): IMarketplaceState => {
    return state.marketplacePage;
};

export const marketplaceServicesSelector = createSelector([selectMarketplacePage], (state: IMarketplaceState) => state.marketplaceServices);

export const marketplaceServicesFiltersSelector = createSelector([selectMarketplacePage], (state: IMarketplaceState) => state.filters);

export const isMarketplaceServicesLoadingSelector = createSelector(
    [selectMarketplacePage],
    (state: IMarketplaceState) => state.isMarketplaceServicesLoading
);

export const isMarketplaceServicesInitializedSelector = createSelector(
    [selectMarketplacePage],
    (state: IMarketplaceState) => state.isMarketplaceServicesInitialized
);

export const marketplaceServicesErrorSelector = createSelector(
    [selectMarketplacePage],
    (state: IMarketplaceState) => state.marketplaceServicesError
);

export const marketplaceMetadataSelector = createSelector([selectMarketplacePage], (state: IMarketplaceState) => state.marketplaceMetadata);
