import React from 'react';
import Translation from '../../Translation';
import styles from "./styles.module.scss";


class CallExpired extends React.Component<any> {
    render() {
        return (
            <div className={`${styles.host}`}>
                <h1 className={styles.header}><Translation text={'videoChat.consultationEnded'}/></h1>
                <p className={styles.info}>
                    <Translation text={'videoChat.rescheduleConsultation'}/>
                </p>
            </div>
        );
    }
}

export default CallExpired;
