import React, {Component} from 'react';
import { TagsInput } from "react-tag-input-component";
import {ITagsInputControlProps} from "../../../types";
import {withTranslation} from "react-i18next";

type Props = ITagsInputControlProps;

class TagsInputControl extends Component<Props> {
    render() {
        const {t} = this.props;

        return (
            <div>
                <TagsInput
                    value={this.props.value || []}
                    placeHolder={t(this.props.placeholder)}
                    onChange={this.handleChange}
                    name={this.props.name}
                />
            </div>
        );
    }

    private handleChange = (e) => {
        const eventData = {target: {name: this.props.name, value: e}};
        this.props.handleChange(eventData);
    }
}

export default withTranslation()(TagsInputControl);
