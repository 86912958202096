import React from 'react';
import Select, {components} from 'react-select';
import {IMultiselectOption, IMultiSelectProps, IMultiSelectState} from '../../../types';
import {MultiSelectType} from '../index';
import Flag from 'react-world-flags';
import {isNotNullOrUndefined, Translation} from '../../../index';
import {BehaviorSubject, Subscription} from 'rxjs';
import {debounceTime, tap} from 'rxjs/operators';
import {createFormInputClass} from '../../../utils/formUtils';

const {Option} = components,
    colourStyles = {
        control: (styles) => ({...styles, backgroundColor: '#fff'}),
        option: (styles, {isDisabled, isFocused, isSelected}) => {
            return {
                ...styles,
                backgroundColor: isDisabled ? undefined : isSelected ? '#7367f0' : isFocused ? 'rgba(115, 103, 240, 0.6)' : undefined,
                color: isDisabled ? '#ccc' : isSelected ? '#fff' : '#6e6b7b',
                cursor: isDisabled ? 'not-allowed' : 'default',

                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled ? (isSelected ? '#7367f0' : 'rgba(115, 103, 240, 0.6)') : undefined,
                },
            };
        },
        multiValue: (styles) => {
            return {
                ...styles,
                backgroundColor: '#7367f0',
                borderRadius: '3px',
            };
        },
        multiValueLabel: (styles) => ({
            ...styles,
            color: '#fff',
            fontFamily: 'Montserrat, sans-serif',
            fontSize: '12px',
            lineHeight: '18px',
        }),
        multiValueRemove: (styles) => ({
            ...styles,
            color: '#fff',
            ':hover': {
                opacity: 0.6,
            },
        }),
        menu: (styles) => ({
            ...styles,
            zIndex: 9,
        }),
    };

class MultiSelect extends React.Component<IMultiSelectProps, IMultiSelectState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IMultiSelectProps) {
        super(props);

        this.state = {
            value: null,
            valueHidden: false,
        };
    }

    private get options() {
        const options = this.props.options;

        return typeof options === 'function' ? options() : options;
    }

    componentDidMount() {
        if (this.props.handleInputChange && typeof this.props.handleInputChange === 'function') {
            this.subscriptions.push(
                this.onValueStateChange$
                    .pipe(
                        debounceTime(500),
                        tap((data: any) => this.handleInputChange(data))
                    )
                    .subscribe()
            );
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return <React.Fragment>{this.renderMultiselect(this.props.multiselectType)}</React.Fragment>;
    }

    private renderMultiselect = (type: MultiSelectType) => {
        let value = this.value;

        switch (type) {
            case MultiSelectType.SINGLE:
                return (
                    <Select
                        className={`basic-single ${this.props.isCurrencySelect ? 'currency-select' : ''}`}
                        inputClassName={createFormInputClass(this.props.name)}
                        classNamePrefix="select"
                        value={value}
                        name={this.props.name}
                        options={this.options}
                        isSearchable
                        placeholder={this.props.placeholder}
                        onChange={this.changeHandler}
                        isDisabled={this.props.isDisabled}
                        menuPlacement={this.props.menuPlacement}
                        openMenuOnClick={this.props.openMenuOnClick}
                        onInputChange={this.onValueStateChange}
                        components={
                            this.props.isComponentCustom && {
                                Option: this.customSelectOption,
                                SingleValue: this.customSelectValue,
                            }
                        }
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        styles={colourStyles}
                    />
                );

            case MultiSelectType.SEARCHABLE:
                return (
                    <Select
                        isMulti
                        value={value}
                        name={this.props.name}
                        options={this.options}
                        className="basic-multi-select"
                        inputClassName={createFormInputClass(this.props.name)}
                        classNamePrefix="select"
                        isSearchable
                        onChange={this.changeHandler}
                        placeholder={this.props.placeholder}
                        isDisabled={this.props.isDisabled}
                        openMenuOnClick={this.props.openMenuOnClick}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        styles={colourStyles}
                    />
                );

            case MultiSelectType.CUSTOM:
                return (
                    <Select
                        closeMenuOnSelect={false}
                        value={value}
                        isMulti
                        options={this.options}
                        onChange={this.changeHandler}
                        openMenuOnClick={this.props.openMenuOnClick}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        styles={colourStyles}
                    />
                );

            case MultiSelectType.GROUPED:
                return (
                    <Select
                        name={this.props.name}
                        value={value}
                        options={this.options}
                        onChange={this.changeHandler}
                        className={`basic-single ${this.props.isCurrencySelect ? 'currency-select' : ''}`}
                        inputClassName={createFormInputClass(this.props.name)}
                        classNamePrefix="select"
                        isSearchable
                        placeholder={this.props.placeholder}
                        isDisabled={this.props.isDisabled}
                        menuPlacement={this.props.menuPlacement}
                        onInputChange={this.onValueStateChange}
                        openMenuOnClick={this.props.openMenuOnClick}
                        components={this.props.isGroupedComponentCustom && {GroupHeading: this.renderCustomGroupHeading}}
                        formatGroupLabel={this.formatGroupLabel}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        styles={colourStyles}
                    />
                );

            default:
                return (
                    <Select
                        value={value}
                        isMulti
                        isSearchable={isNotNullOrUndefined(this.props.isSearchable) ? this.props.isSearchable : true}
                        name={this.props.name}
                        options={this.options}
                        className="basic-multi-select"
                        inputClassName={createFormInputClass(this.props.name)}
                        classNamePrefix="select"
                        onChange={this.changeHandler}
                        placeholder={this.props.placeholder}
                        isDisabled={this.props.isDisabled}
                        components={this.props.isCustomMultiValueContainer && {ValueContainer: this.renderValueContainer}}
                        openMenuOnClick={this.props.openMenuOnClick}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        styles={colourStyles}
                    />
                );
        }
    };

    private onValueStateChange = (data: any) => {
        this.restoreValue();
        this.onValueStateChange$.next(data);
    };

    private changeHandler = (values: IMultiselectOption | IMultiselectOption[], action: any) => {
        this.restoreValue();
        if (undefined !== this.props.handleChange && null !== this.props.handleChange) {
            this.props.handleChange(values, action);
        }
    };

    private handleInputChange = (newValue: string) => {
        if (!newValue) {
            return;
        }

        // const inputValue = newValue.replace(/\W/g, '');
        this.setState({value: newValue}, () => {
            this.props.handleInputChange(this.state.value);
        });
        return newValue;
    };

    private customSelectOption = (props) => {
        return (
            <Option {...props}>
                {this.props.isCustomLogoOption ? (
                    <div className="custom-option">
                        <div className="logo-container">{props.data.logo ? <img src={props.data.logo} alt="logo" /> : null}</div>
                        <div className="label">{props.data.label}</div>
                    </div>
                ) : (
                    <div>
                        <Flag code={props.data.value} />
                        {props.data.label}
                    </div>
                )}
            </Option>
        );
    };

    private renderValueContainer = ({children, getValue, ...props}) => {
        let maxToShow = 1,
            length = props.selectProps.value ? props.selectProps.value.length : 0,
            displayLabels = React.Children.toArray(children).slice(0, maxToShow),
            shouldBadgeShow = length > maxToShow,
            displayLength = length - maxToShow;

        return (
            <components.ValueContainer {...props}>
                {!props.selectProps.inputValue && displayLabels}

                <div>{shouldBadgeShow && `(+ ${displayLength})`}</div>
            </components.ValueContainer>
        );
    };

    private customSelectValue = (props) => {
        return (
            <React.Fragment>
                {this.props.isCustomLogoOption ? (
                    <div className="custom-select-value">
                        <div className="logo-container">{props.data.logo ? <img src={props.data.logo} alt="logo" /> : null}</div>
                        <div className="label">{props.data.label}</div>
                    </div>
                ) : (
                    <div className="country-select-value">
                        <Flag code={props.data.value} />
                        {props.data.label}
                    </div>
                )}
            </React.Fragment>
        );
    };

    private formatGroupLabel = (data) => {
        return (
            <div className="grouped-label-container">
                <span className="grouped-label">
                    {this.props.isGroupedLabelTranslated ? <Translation text={`multiselectGroupedLabels.${data.label}`} /> : data.label}
                </span>
                {/*<span className="grouped-label">{data.options.length}</span>*/}
            </div>
        );
    };

    private handleGroupedHeaderClick = (id: string) => {
        const groupHeader = document.querySelector(`#${id}`);
        if (groupHeader.classList.contains('selected')) {
            groupHeader.classList.remove('selected');
        } else {
            groupHeader.classList.add('selected');
        }

        const node = document.querySelector(`#${id}`).parentElement.nextElementSibling;
        const classes = node.classList;
        if (classes.contains('collapsed')) {
            node.classList.remove('collapsed');
        } else {
            node.classList.add('collapsed');
        }
    };

    // Create custom GroupHeading component, which will wrap
    // react-select GroupHeading component inside a div and
    // register onClick event on that div
    private renderCustomGroupHeading = (props: any) => {
        return (
            <div className="group-heading-wrapper" onClick={() => this.handleGroupedHeaderClick(props.id)}>
                <components.GroupHeading {...props} />
            </div>
        );
    };

    private onFocus = () => {
        this.hideValueUntilInputOrBlur();
    };

    private onBlur = () => {
        this.restoreValue();
    };

    private hideValueUntilInputOrBlur = () => {
        if (this.props.hideValueOnFocus) {
            this.setState({valueHidden: true});
        }
    };

    private restoreValue = () => {
        if (this.props.hideValueOnFocus) {
            this.setState({valueHidden: false});
        }
    };

    private get value(): any {
        if (this.props.hideValueOnFocus && this.state.valueHidden) {
            return null;
        }

        // hack to unselect previously selected value when no new value is selected
        return undefined === this.props.value ? null : this.props.value;
    }
}

export default MultiSelect;
