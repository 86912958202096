import React, {Component} from 'react';
import LayoutWrapper from '../LayoutWrapper';
import styles from './styles.module.scss';
import {InputBasic, InputType, Translation, IModelApiResponseViewObject, deepCloneObject} from 'jobhunter-common-web';
import {withTranslation, WithTranslation} from 'react-i18next';
import {BehaviorSubject, Subscription} from 'rxjs';
import {debounceTime, filter, tap} from 'rxjs/operators';
import MarketplaceFilters from './MarketplaceFilters';
import MarketplaceCard from './MarketplaceCard';
import {connect} from 'react-redux';
import {RootState} from '../../store/reducers';
import {
    applyMarketplaceFilters,
    changeMarketplaceFilters,
    fetchMarketplaceServices,
    IMarketplaceFilters,
    resetToInitialMarketplacePageState,
} from '../../store/reducers/marketplacePageSlice';
import {
    isMarketplaceServicesLoadingSelector,
    marketplaceMetadataSelector,
    marketplaceServicesFiltersSelector,
    marketplaceServicesSelector,
} from '../../store/selectors/marketplacePageSelectors';
import {IModelService} from '../../model/service';

interface IConnectedMarketplaceProps {
    readonly marketplaceServices: IModelService[] | null;
    readonly isLoading: boolean;
    readonly marketPlaceFilters: IMarketplaceFilters | null;
    readonly marketplaceMetadata: typeof IModelApiResponseViewObject | null;
    readonly fetchMarketplaceServices: typeof fetchMarketplaceServices;
    readonly resetToInitialMarketplacePageState: typeof resetToInitialMarketplacePageState;
    readonly applyMarketplaceFilters: typeof applyMarketplaceFilters;
    readonly changeMarketplaceFilters: typeof changeMarketplaceFilters;
}

interface IMarketplaceProps extends WithTranslation, IConnectedMarketplaceProps {}

interface IMarketplaceState {
    searchValue: string;
}

class Marketplace extends Component<IMarketplaceProps, IMarketplaceState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IMarketplaceProps) {
        super(props);

        this.state = {
            searchValue: '',
        };
    }

    componentDidMount(): void {
        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data),
                    debounceTime(100),
                    tap((data: any) => this.onFormValueChange(data.value))
                )
                .subscribe()
        );
        this.props.fetchMarketplaceServices();
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.props.resetToInitialMarketplacePageState();
    }

    render() {
        const {t} = this.props;
        return (
            <LayoutWrapper>
                <div className="page-layout">
                    <div className={styles.marketplaceHost}>
                        <div className={styles.marketplaceHeader}>
                            <p className={styles.title}>
                                <Translation text="marketplace.title" />
                            </p>
                            <p className={styles.description}>
                                <Translation text="marketplace.description" />
                            </p>

                            <div className="form-control input-search offers-form-control">
                                <InputBasic
                                    type={InputType.TEXT}
                                    placeholder={t('offers.form.placeholders.search')}
                                    className="input"
                                    value={this.state.searchValue}
                                    name="searchInput"
                                    handleChange={(e: any) => this.onValueStateChange(e)}
                                    autoComplete="off"
                                />
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <div className="page-container offers-container">
                                    <MarketplaceFilters changeFilters={this.changeFilters} />

                                    <div className={styles.services}>
                                        {this.props.marketplaceServices &&
                                            this.props.marketplaceServices.map((service: IModelService, index: number) => {
                                                return <MarketplaceCard service={service} key={`service_${index}`} />;
                                            })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutWrapper>
        );
    }

    private onValueStateChange = (value: any) => {
        this.onValueStateChange$.next({value: value.target.value});
    };

    private onFormValueChange = (value: string) => {
        this.setState({
            searchValue: value,
        });
        this.changeFilters({title: value});
    };

    private changeFilters = (value: {[key: string]: any}) => {
        const stateFilters = deepCloneObject(this.props.marketPlaceFilters),
            filters: IMarketplaceFilters = stateFilters ? stateFilters : {};

        if (value.hasOwnProperty('title')) {
            filters['title'] = value.title;
        }

        if (value.hasOwnProperty('serviceType')) {
            filters['serviceTypes'] = value.serviceType;
        }

        if (value.hasOwnProperty('minPrice')) {
            filters['grossPrice'] = String(value.minPrice * 100);
        }
        this.props.changeMarketplaceFilters(filters);
        this.props.applyMarketplaceFilters();
    };
}

export default connect(
    (state: RootState) => ({
        marketplaceServices: marketplaceServicesSelector(state),
        marketPlaceFilters: marketplaceServicesFiltersSelector(state),
        marketplaceMetadata: marketplaceMetadataSelector(state),
        isLoading: isMarketplaceServicesLoadingSelector(state),
    }),
    {fetchMarketplaceServices, resetToInitialMarketplacePageState, applyMarketplaceFilters, changeMarketplaceFilters}
)(withTranslation()(Marketplace));
